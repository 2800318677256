import { checkPhotoPerspective } from './checkPhotoPerspective';
import { REQUIRED_PHOTOS_AMOUNT } from '../constants/RequiredPhotosAmount.js';
import { PerspectiveType } from '../constants/PerspectiveType';

export const sortPhotosByPerspective = photos => {
  if (photos?.length) {
    const obligatoryPhotos = photos.filter(photo => photo.perspective !== 'OTHER');
    const additionalPhotos = photos.filter(photo => photo.perspective === 'OTHER');

    const sortedObligatoryPhotos = [];
    let sortedPhotos = [];

    obligatoryPhotos.map((photo, index) => {
      sortedObligatoryPhotos[checkPhotoPerspective(photo, index)] = photo;
      return null;
    });
    const obligatorySlots = new Array(REQUIRED_PHOTOS_AMOUNT).fill({});

    const obligatorySorted = obligatorySlots.map((el, index) => {
      if (sortedObligatoryPhotos[index]) {
        return sortedObligatoryPhotos[index];
      }
      return el;
    });
    sortedPhotos = [...obligatorySorted, ...additionalPhotos];

    addAnotherImageBugFix(photos, sortedPhotos);
    return sortedPhotos;
  }
  return null;
};

const addAnotherImageBugFix = (photos, sortedPhotos) => {
  if (photos.length === sortedPhotos.length) return;
  photos.forEach(item => {
    if (!sortedPhotos.includes(item)) {
      item.perspective = PerspectiveType.OTHER;
      sortedPhotos.push(item);
    }
  });
  sortedPhotos.forEach((item, index) => {
    item.sortNumber = index + 1;
  });
};
