import React, { useRef } from 'react';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import Breadcrumbs from '../../common/components/Breadcrumbs';
import GroupForm from '../form/GroupForm';
import validationSchema from './CreateGroupForm.schema';
import { createGroup } from '../GroupsActions';

const breadcrumbsItems = [
  { id: 1, type: 'home' },
  { id: 2, type: 'link', text: 'Groups', url: '/groups' },
  { id: 3, type: 'span', text: 'Add Group' },
];

const CreateGroupPage = () => {
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const initialValues = {
    name: '',
    dealer: [],
  };

  const handleNameChange = () => {
    dispatch(createGroup({ ...formRef.current.values, dealersIdsToAdd: [] }));
  };

  return (
    <div className="content">
      <Breadcrumbs items={breadcrumbsItems} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        innerRef={formRef}
        validateOnBlur={true}
      >
        {form => (
          <GroupForm
            title="Create a new group"
            form={form}
            dealers={[]}
            changeNameHandler={handleNameChange}
            isCreateGroupForm
          />
        )}
      </Formik>
    </div>
  );
};

export default CreateGroupPage;
