import { apiClient, handleError } from '../api/ApiClient';
import { prepareSearchData, SORT_DESC } from '../helpers/prepareSearchData';
import {
  REPORTS_SEARCH_SUCCESS,
  CLEAR_REPORTS,
  REPORTS_SEARCH_FAILURE,
  REPORTS_SEARCH,
} from './ReportsReducer';
import { showAlert } from '../common/components/alert/AlertActions';
import AlertVariant from '../common/components/alert/AlertVariant';

export const REPORTS_ENDPOINT = '/auction/report';
export const REPORTS_USER_ENDPOINT = '/user/report';
export const REPORT_TYPE = 'text/csv';
export const REPORT_FILE_NAME = 'Report.csv';

export const searchReports = values => dispatch => {
  dispatch({ type: REPORTS_SEARCH });
  return apiClient
    .post(`${REPORTS_ENDPOINT}/search`, prepareSearchData(values, { createdOn: SORT_DESC }))
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: REPORTS_SEARCH_SUCCESS,
          payload: {
            items: res?.data?.data?.content,
            totalItems: res?.data?.data?.totalElements,
          },
        });
      }
    })
    .catch(err => {
      dispatch({ type: REPORTS_SEARCH_FAILURE });
      dispatch(handleError(err));
    });
};

export const searchUserReports = values => dispatch => {
  dispatch({ type: REPORTS_SEARCH });
  return apiClient
    .post(
      `${REPORTS_USER_ENDPOINT}/search`,
      prepareSearchData(values, { createdOn: SORT_DESC }),
    )
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: REPORTS_SEARCH_SUCCESS,
          payload: {
            items: res?.data?.data?.content,
            totalItems: res?.data?.data?.totalElements,
          },
        });
      }
    })
    .catch(err => {
      dispatch({ type: REPORTS_SEARCH_FAILURE });
      dispatch(handleError(err));
    });
};

export const generateReport = values => dispatch => {
  return apiClient
    .post(`${REPORTS_ENDPOINT}/generate`, prepareSearchData(values))
    .then(res => {
      if (res.status === 200) {
        const url = URL.createObjectURL(new Blob([res.data], { type: REPORT_TYPE }));
        const a = document.createElement('a');
        a.href = url;
        a.download = REPORT_FILE_NAME;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    });
};

export const generateUserReport = values => dispatch => {
  return apiClient
    .post(`${REPORTS_USER_ENDPOINT}/generate`, prepareSearchData(values))
    .then(res => {
      if (res.status === 200) {
        const url = URL.createObjectURL(new Blob([res.data], { type: REPORT_TYPE }));
        const a = document.createElement('a');
        a.href = url;
        a.download = REPORT_FILE_NAME;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    });
};

export const sendReport = values => dispatch => {
  return apiClient
    .post(`${REPORTS_ENDPOINT}/send`, prepareSearchData(values, { createdOn: SORT_DESC }))
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Report sent successfully', AlertVariant.SUCCESS));
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    });
};

export const clearReports = () => dispatch => {
  dispatch({
    type: CLEAR_REPORTS,
  });
};
