import { isEmpty, replace, forEach } from 'lodash';

export const PrepareUrl = (url, params) => {
  forEach(params, (param, key) => {
    url = replace(url, `:${key}`, param);
  });
  return url;
};
export const PrepareUrlWithQueryParams = (baseUrl, params) => {
  let query = '';
  if (!isEmpty(params)) {
    query = '?';
    Object.keys(params).forEach(key => {
      if (Object.prototype.hasOwnProperty.call(params, key) && params[key]) {
        if (query === '?') {
          query += `${key}=${params[key]}`;
        } else {
          query += `&${key}=${params[key]}`;
        }
      }
    });
  }
  return baseUrl + (query !== '?' ? query : '');
};
