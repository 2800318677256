export const UserStatus = {
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  BLOCKED: 'BLOCKED',
  BLOCKED_DUE_TO_PAYMENT: 'BLOCKED_DUE_TO_PAYMENT',
  REJECTED: 'REJECTED',
};

export const DealerUserStatusName = {
  PENDING: 'Pending',
  ACTIVE: 'Active',
  BLOCKED: 'Archived',
  BLOCKED_DUE_TO_PAYMENT: 'Blocked due to payment',
  REJECTED: 'Rejected',
};
