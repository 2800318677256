import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Button, Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import Breadcrumbs from '../../common/components/Breadcrumbs';
import validationSchema from '../form/SiteCreatorForm.schema';
import SiteCreatorForm from '../form/SiteCreatorForm';
import CommonLoader from '../../common/components/CommonLoader';
import { getSite, editSite, deleteSite } from '../SiteCreatorActions';

const breadcrumbsItems = [
  { id: 1, type: 'home' },
  { id: 2, type: 'link', text: 'Site Creator', url: '/site-creators' },
  { id: 3, type: 'span', text: 'Edit Site' },
];

const initialValues = {
  title: '',
  link: '',
  isActive: false,
  content: '',
};

const EditSitePage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [site, setSite] = useState(initialValues);
  const data = useSelector(state => state.siteCreator.get('siteDetails'));
  const loaderStatus = useSelector(state => state.siteCreator.get('loaderStatus'));

  useEffect(() => {
    setSite({
      title: data?.title,
      link: data?.link,
      content: data?.content,
      isActive: data?.isActive,
    });
  }, [data]);

  useEffect(() => {
    dispatch(getSite(id));
  }, [dispatch, id]);

  const handleSubmit = (values, form) => {
    dispatch(editSite(id, get(data, 'version'), values, form)).catch(() =>
      form.setSubmitting(false),
    );
  };

  const handleDeleteSite = () => {
    dispatch(deleteSite(id));
  };

  return (
    <div className="content">
      <Row className="delete-button-wrapper">
        <Col xs="9">
          <Breadcrumbs items={breadcrumbsItems} />
        </Col>
        {data?.isCustomSite && (
          <Col xs="3">
            <Button className="float-right" color="danger" onClick={() => setShowAlert(true)}>
              Delete site
            </Button>
          </Col>
        )}
      </Row>
      <CommonLoader status={loaderStatus}>
        <Formik
          initialValues={site}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          validateOnBlur={true}
          enableReinitialize
        >
          {() => <SiteCreatorForm title="Edit site" isCustom={data?.isCustomSite} />}
        </Formik>
      </CommonLoader>
      <SweetAlert
        danger
        showCancel
        show={showAlert}
        title="Warning!"
        onConfirm={() => {
          setShowAlert(false);
          handleDeleteSite();
        }}
        onCancel={() => setShowAlert(false)}
        confirmBtnBsStyle="info"
      >
        Are you sure to remove a site?
      </SweetAlert>
    </div>
  );
};

export default EditSitePage;
