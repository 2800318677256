import React, { useState } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { Field, getIn } from 'formik';
import AsyncSelect from 'react-select/async';

const FormAsyncSelectFieldInner = props => {
  const {
    label,
    placeholder,
    form,
    field,
    onChangeHandler,
    loadOptions,
    defaultOptions,
    onInputChange = () => {},
    loadRightAway,
    disabled,
  } = props;

  const [isActiveFetchData, setActiveFetchData] = useState(loadRightAway);

  const error = getIn(form.errors, field.name);
  const touched = getIn(form.touched, field.name);
  const value = getIn(form.values, field.name, '');

  const onFocusHandler = () => {
    setActiveFetchData(true);
  };

  const valueSelect = {
    label: value?.postcode || value?.label,
    value: value?.id || value?.value,
  };

  const loadOneOption = (inputValue, callback) => {
    if (!valueSelect?.label) {
      callback(false);
      return false;
    }

    callback([valueSelect]);
    return true;
  };

  return (
    <Row>
      <Col>
        <Label>{label}</Label>
        <FormGroup>
          <AsyncSelect
            loadOptions={isActiveFetchData ? loadOptions : loadOneOption}
            defaultOptions={defaultOptions}
            placeholder={placeholder}
            value={valueSelect}
            isDisabled={form.isSubmitting || disabled}
            onBlur={() => form.setFieldTouched(field.name, true)}
            onInputChange={onInputChange}
            onChange={option => {
              form.setFieldValue(field.name, option);
              onChangeHandler(option);
            }}
            onFocus={onFocusHandler}
          />
          {(form.submitCount > 0 || touched) && error && (
            <label style={{ color: 'red ' }}>{error?.value ?? error}</label>
          )}
        </FormGroup>
      </Col>
    </Row>
  );
};

const FormAsyncSelectField = props => {
  return <Field {...props} component={FormAsyncSelectFieldInner} />;
};

FormAsyncSelectField.defaultProps = {
  placeholder: '',
  options: [],
  onChangeHandler: () => {},
  loadRightAway: true,
  disabled: false,
};

FormAsyncSelectField.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  onChangeHandler: PropTypes.func,
  loadRightAway: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default FormAsyncSelectField;
