import React from 'react';
import { useSelector } from 'react-redux';
import CurrencyFormat from 'react-currency-format';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { dateTimeToString } from '../../common/utils/date/Date.utils';
import { AuctionStatus } from '../../constants/AuctionStatus';

const VehicleBidsLaunchTableRow = ({ item, showRemoveItemAlert }) => {
  const { firstName, lastName } = item?.bidder;
  const bidderFullName = `${firstName} ${lastName}`;
  const status = useSelector(state => state.vehicles.get('auctionDetails'))?.status;

  return (
    <>
      <td>{item.bidder?.organization?.tradeName}</td>
      <td>{bidderFullName}</td>
      <td>
        <CurrencyFormat
          value={item?.amountInPence / 100}
          thousandSeparator={true}
          allowNegative={false}
          prefix="£"
          displayType="text"
        />
      </td>
      <td>{dateTimeToString(item?.createdOn)}</td>
      <td className="text-right">
        <Button
          className="btn-link"
          color="primary"
          id={`tooltip${item.id}`}
          size="sm"
          type="button"
          onClick={() => showRemoveItemAlert(item)}
          disabled={status !== AuctionStatus.PUBLISHED}
        >
          <i className="fa fa-times" />
        </Button>
      </td>
    </>
  );
};

VehicleBidsLaunchTableRow.propTypes = {
  item: PropTypes.object.isRequired,
  showRemoveItemAlert: PropTypes.func.isRequired,
};

export default VehicleBidsLaunchTableRow;
