import React from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CommonTable from '../common/components/CommonTable';
import ReportsUserTableRow from './ReportsUserTableRow';
import CommonLoader from '../common/components/CommonLoader';

const headers = [
  {
    label: 'Organisation name',
  },
  {
    label: 'Is Main Account',
  },
  {
    label: 'first Name',
  },
  {
    label: 'last Name',
  },
  {
    label: 'Email',
  },
  {
    label: 'Phone',
  },
  {
    label: 'Status',
  },
  {
    label: 'Location',
  },
  {
    label: 'Position',
  },
];

const ReportsUserTable = ({ data, offset, changeOffset }) => {
  const loaderStatus = useSelector(state => state.reports.get('loaderStatus'));
  return (
    <Col xs="12">
      <CommonLoader status={loaderStatus}>
        <CommonTable
          rowComponent={ReportsUserTableRow}
          data={data}
          offset={offset}
          onOffsetChange={changeOffset}
          headers={headers}
          title="Reports"
          hasRowPointer={false}
        />
      </CommonLoader>
    </Col>
  );
};

ReportsUserTable.propTypes = {
  data: PropTypes.object.isRequired,
  offset: PropTypes.number.isRequired,
  changeOffset: PropTypes.func.isRequired,
};

export default ReportsUserTable;
