import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Field } from 'formik';
import { get } from 'lodash';

const plugins = [
  'advlist autolink lists link image charmap print preview anchor',
  'searchreplace visualblocks code fullscreen',
  'insertdatetime media table paste code help wordcount',
];

const toolbar = `undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | 
                        bullist numlist outdent indent | removeformat | help | code | link | image`;

const SiteCreatorEditorInner = ({ field, form }) => {
  const value = get(form.values, field.name, '');
  const handleEditorChange = content => {
    form.setFieldValue(field.name, content);
  };
  return (
    <Editor
      value={value}
      apiKey={process.env.REACT_APP_EDITOR_API_KEY}
      init={{
        height: 500,
        menubar: true,
        plugins,
        toolbar,
      }}
      onEditorChange={handleEditorChange}
      disabled={form.isSubmitting}
    />
  );
};

const SiteCreatorEditor = props => {
  return <Field {...props} component={SiteCreatorEditorInner} />;
};

export default SiteCreatorEditor;
