import React from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import FormAsyncSelectField from '../../common/components/formFields/FormAsyncSelectField';
import { dataToSelect } from '../../common/helpers';
import { getDealers } from '../DealersActions';

const DealersSelectField = props => {
  const dispatch = useDispatch();

  const loadOptions = _.debounce((inputValue, callback) => {
    dispatch(
      getDealers(inputValue, dealers => {
        callback(dataToSelect(dealers?.data?.content, ['id', 'tradeName']));
      }),
    );
    return true;
  }, 500);

  return <FormAsyncSelectField {...props} loadOptions={loadOptions} defaultOptions />;
};

DealersSelectField.propTypes = {};

export default DealersSelectField;
