import React, { useState, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import {
  Button,
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AdminRole } from 'constants/AdminRole';
import { logout } from '../../../auth/AuthActions';

const LoggedNavbar = ({ handleMiniClick }) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [color, setColor] = useState('navbar-transparent');
  const accountType = useSelector(state => state.auth.get('accountType'));

  const dispatch = useDispatch();

  const updateColor = useCallback(() => {
    if (window.innerWidth < 993 && collapseOpen) {
      setColor('bg-white');
    } else {
      setColor('navbar-transparent');
    }
  }, [collapseOpen]);

  useEffect(() => {
    window.addEventListener('resize', updateColor);
    return () => {
      window.removeEventListener('resize', updateColor);
    };
  }, [updateColor]);

  useEffect(() => {
    if (
      window.outerWidth < 993 &&
      // e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open');
    }
  });

  const toggleSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
  };

  const toggleCollapse = () => {
    setCollapseOpen(!collapseOpen);
    setColor(!collapseOpen ? 'bg-white' : 'navbar-transparent');
  };

  return (
    <>
      <Navbar className={classnames('navbar-absolute fixed-top', color)} expand="lg">
        <Container fluid>
          <div className="navbar-wrapper">
            {accountType !== AdminRole.DASHBOARD && (
              <>
                <div className="navbar-minimize">
                  <Button
                    className="btn-icon btn-round"
                    color="default"
                    id="minimizeSidebar"
                    onClick={handleMiniClick}
                  >
                    <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
                    <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
                  </Button>
                </div>

                <div
                  className={classnames('navbar-toggle', {
                    toggled: collapseOpen,
                  })}
                >
                  <button className="navbar-toggler" type="button" onClick={toggleSidebar}>
                    <span className="navbar-toggler-bar bar1" />
                    <span className="navbar-toggler-bar bar2" />
                    <span className="navbar-toggler-bar bar3" />
                  </button>
                </div>
              </>
            )}
            <NavbarBrand href="#pablo" onClick={e => e.preventDefault()}>
              <span className="d-none d-md-block">Auction4Dealers</span>
              <span className="d-block d-md-none">Auction4Dealers</span>
            </NavbarBrand>
          </div>
          <button
            aria-controls="navigation-index"
            aria-expanded={collapseOpen}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            // data-target="#navigation"
            data-toggle="collapse"
            type="button"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <Collapse className="justify-content-end" navbar isOpen={collapseOpen}>
            <Nav navbar>
              <NavItem>
                <NavLink
                  className="btn-magnify cursor-pointer"
                  onClick={() => dispatch(logout())}
                >
                  <i className="nc-icon nc-user-run" />
                  <p>
                    <span className="d-lg-none d-md-block">Logout</span>
                  </p>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default LoggedNavbar;
