import React from 'react';
import PropTypes from 'prop-types';
import VehicleBidsLaunchTable from './VehicleBidsLaunchTable';
import { dateToString } from '../../common/utils/date/Date.utils';

const VehicleInfoAndBidsLaunch = ({ bids, removeItemHandler, vehicleTitle }) => {
  return (
    <div>
      <p
        style={{
          marginBottom: 0,
        }}
      >
        Launch: {dateToString(bids?.items[0]?.auctionLaunchedOn) || '-'}
      </p>
      <p>{vehicleTitle || ''}</p>
      <VehicleBidsLaunchTable data={bids} removeItemHandler={removeItemHandler} includeForm />
    </div>
  );
};

VehicleInfoAndBidsLaunch.propTypes = {
  bids: PropTypes.object.isRequired,
  removeItemHandler: PropTypes.func.isRequired,
  vehicleTitle: PropTypes.string,
};

export default VehicleInfoAndBidsLaunch;
