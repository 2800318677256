import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';
import { Field } from 'formik';

const FormCheckboxFieldInner = props => {
  const { label, form, field, disabled, invalid } = props;

  const error = form.errors[field.name];
  const touched = form.touched[field.name];
  const value = form.values[field.name] || false;

  return (
    <FormGroup check disabled={disabled || form.isSubmitting}>
      <Label check>
        <Input
          type="checkbox"
          checked={value}
          onBlur={() => form.setFieldTouched(field.name, true)}
          onChange={({ target }) => form.setFieldValue(field.name, target.checked)}
          disabled={disabled || form.isSubmitting}
        />
        {label}
        <span className="form-check-sign">
          <span className="check" />
        </span>
      </Label>
      {invalid
        ? (form.submitCount > 0 || touched) &&
          error && <label style={{ color: 'red ' }}>{error}</label>
        : ''}
    </FormGroup>
  );
};

const FormCheckboxField = props => {
  return <Field {...props} component={FormCheckboxFieldInner} />;
};

FormCheckboxField.defaultProps = {
  disabled: false,
  invalid: true,
};

FormCheckboxField.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
};

export default FormCheckboxField;
