import React from 'react';
import Loader from 'react-loader-spinner';
import { Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';

export const LoaderStatus = {
  NOT_PENDING: 'NOT_PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

const CommonLoader = ({ status = LoaderStatus.NOT_PENDING, children, failureMessage }) => {
  return (
    <>
      {LoaderStatus.FAILURE === status && !failureMessage && children}
      {LoaderStatus.FAILURE === status && failureMessage && (
        <Card>
          <CardBody>{failureMessage}</CardBody>
        </Card>
      )}
      {(LoaderStatus.NOT_PENDING === status || LoaderStatus.SUCCESS === status) && children}
      {LoaderStatus.IN_PROGRESS === status && (
        <div className="text-center">
          <Card className="p-5">
            <Loader height={30} color="Blue" type="Oval" />
          </Card>
        </div>
      )}
    </>
  );
};

CommonLoader.defaultProps = {
  status: LoaderStatus.IN_PROGRESS,
};

CommonLoader.propTypes = {
  status: PropTypes.oneOf(Object.keys(LoaderStatus)),
};

export default CommonLoader;
