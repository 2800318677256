import React from 'react';
import { Formik } from 'formik';
import { Col, Row } from 'reactstrap';
import { useDispatch } from 'react-redux';
import Breadcrumbs from '../../common/components/Breadcrumbs';
import validationSchema from '../form/SiteCreatorForm.schema';
import SiteCreatorForm from '../form/SiteCreatorForm';
import { createSite } from '../SiteCreatorActions';

const breadcrumbsItems = [
  { id: 1, type: 'home' },
  { id: 2, type: 'link', text: 'Site Creator', url: '/site-creators' },
  { id: 3, type: 'span', text: 'Add Site' },
];

const initialValues = {
  title: '',
  link: '',
  isActive: false,
  content: '',
};

const CreateSitePage = () => {
  const dispatch = useDispatch();

  const handleSubmit = (values, form) => {
    dispatch(createSite(values, form)).catch(() => form.setSubmitting(false));
  };

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Breadcrumbs items={breadcrumbsItems} />
        </Col>
        <Col md="12">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validateOnBlur={true}
          >
            {() => <SiteCreatorForm title="Create a new site" />}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};

export default CreateSitePage;
