import { fromJS } from 'immutable';
import { LoaderStatus } from '../common/components/CommonLoader';

const initialData = fromJS({
  items: [],
  totalItems: 0,
  loaderStatus: LoaderStatus.NOT_PENDING,
  auctionDetails: null,
  vehicleFeatures: [],
  searchParams: null,
  selectedVehicleId: null,
});

export const SEARCH_AUCTIONS = 'SEARCH_AUCTIONS';
export const SEARCH_AUCTIONS_SUCCESS = 'SEARCH_AUCTIONS_SUCCESS';
export const GET_AUCTION_DETAILS_SUCCESS = 'GET_AUCTION_DETAILS_SUCCESS';
export const CLEAR_AUCTION_DETAILS_SUCCESS = 'CLEAR_AUCTION_DETAILS_SUCCESS';
export const FETCH_VEHICLE_FEATURES_SUCCESS = 'FETCH_VEHICLE_FEATURES_SUCCESS';
export const SET_VEHICLES_SEARCH_PARAMS = 'SET_VEHICLES_SEARCH_PARAMS';
export const SET_SELECTED_VEHICLE = 'SET_SELECTED_VEHICLE';

export default (state = initialData, { type, payload }) => {
  switch (type) {
    case SEARCH_AUCTIONS_SUCCESS: {
      const { items, totalItems } = payload;
      return state.merge({
        items,
        totalItems,
        loaderStatus: LoaderStatus.SUCCESS,
      });
    }
    case GET_AUCTION_DETAILS_SUCCESS: {
      return state.merge({
        auctionDetails: payload,
      });
    }
    case CLEAR_AUCTION_DETAILS_SUCCESS: {
      return state.merge({
        auctionDetails: null,
      });
    }
    case FETCH_VEHICLE_FEATURES_SUCCESS: {
      return state.merge({
        vehicleFeatures: payload,
      });
    }
    case SET_VEHICLES_SEARCH_PARAMS: {
      return state.merge({
        searchParams: payload,
      });
    }
    case SEARCH_AUCTIONS: {
      return state.merge({
        loaderStatus: LoaderStatus.IN_PROGRESS,
      });
    }
    case SET_SELECTED_VEHICLE: {
      return state.merge({
        selectedVehicleId: payload,
      });
    }
    default:
      return state;
  }
};
