import { fromJS } from 'immutable';

const initialData = fromJS({
  isAuthenticated: false,
  accountType: '',
});

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';

export default (state = initialData, { type, payload }) => {
  switch (type) {
    case LOGIN_SUCCESS:
      return state.merge({
        isAuthenticated: true,
        accountType: payload.accountType,
      });
    case LOGOUT:
    case LOGIN_FAILURE:
      return state.merge(initialData);
    default:
      return state;
  }
};
