import { fromJS } from 'immutable';
import { LoaderStatus } from '../common/components/CommonLoader';

const initialData = fromJS({
  userDetails: {},
  loaderStatus: LoaderStatus.IN_PROGRESS,
  userActivities: [],
  loaderUserActivities: LoaderStatus.IN_PROGRESS,
  totalItemsActivities: 0,
  items: [],
  totalItems: 0,
});

export const SEARCH_USERS = 'SEARCH_USERS';
export const SEARCH_USERS_SUCCESS = 'SEARCH_USERS_SUCCESS';
export const SEARCH_USERS_FAILURE = 'SEARCH_USERS_FAILURE';
export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const GET_USER_ACTIVITIES = 'GET_USER_ACTIVITIES';
export const GET_USER_ACTIVITIES_SUCCESS = 'GET_USER_ACTIVITIES_SUCCESS';
export const GET_USER_ACTIVITIES_FAILURE = 'GET_USER_ACTIVITIES_FAILURE';

export default (state = initialData, { type, payload }) => {
  switch (type) {
    case SEARCH_USERS:
      return state.merge({
        loaderStatus: LoaderStatus.IN_PROGRESS,
      });
    case SEARCH_USERS_SUCCESS:
      return state.merge({
        loaderStatus: LoaderStatus.SUCCESS,
        items: payload.items,
        totalItems: payload.totalItems,
      });
    case SEARCH_USERS_FAILURE:
      return state.merge({
        loaderStatus: LoaderStatus.NOT_PENDING,
      });
    case GET_USER:
      return state.merge({
        loaderStatus: LoaderStatus.IN_PROGRESS,
      });
    case GET_USER_SUCCESS:
      return state.merge({
        userDetails: payload.data,
        loaderStatus: LoaderStatus.SUCCESS,
      });
    case GET_USER_FAILURE:
      return state.merge({
        userDetails: {},
        loaderStatus: LoaderStatus.NOT_PENDING,
      });
    case GET_USER_ACTIVITIES:
      return state.merge({
        loaderUserActivities: LoaderStatus.IN_PROGRESS,
      });
    case GET_USER_ACTIVITIES_SUCCESS:
      return state.merge({
        userActivities: payload.items,
        totalItemsActivities: payload.totalItems,
        loaderUserActivities: LoaderStatus.SUCCESS,
      });
    case GET_USER_ACTIVITIES_FAILURE:
      return state.merge({
        userActivities: [],
        loaderUserActivities: LoaderStatus.NOT_PENDING,
      });
    case UPDATE_USER:
      return state.merge({
        loaderStatus: LoaderStatus.IN_PROGRESS,
      });
    case UPDATE_USER_SUCCESS:
      return state.merge({
        loaderStatus: LoaderStatus.SUCCESS,
      });
    case UPDATE_USER_FAILURE:
      return state.merge({
        loaderStatus: LoaderStatus.NOT_PENDING,
      });

    default:
      return state;
  }
};
