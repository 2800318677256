import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import SweetAlert from 'react-bootstrap-sweetalert';
import uuid from 'react-uuid';
import { GridContextProvider, GridDropZone, GridItem, swap } from 'react-grid-drag';
import VehicleImagesPopupSlider from '../../common/components/VehicleImagesPopupSlider';
import { sortPhotosByPerspective } from '../../helpers/sortPhotosByPerspective';
import { checkInteriorPerspective } from '../../helpers/checkInteriorPerspective';
import { PhotoUploadState } from '../../constants/PhotoUploadState';
import { REQUIRED_PHOTOS_AMOUNT } from '../../constants/RequiredPhotosAmount';
import arrowsIcon from '../../assets/img/arrows.png';
import bucketIcon from '../../assets/img/bucket.svg';
import { PerspectiveType } from '../../constants/PerspectiveType';
import addPhotoIcon from '../../assets/img/addPhotoIcon.svg';
import warningIcon from '../../assets/img/warningIcon.svg';
import { getPhotoDescription } from '../../helpers/getPhotoDescription';
import VehiclePhotoEditForm from './VehiclePhotoEditForm';

const VehicleMediaImageForm = ({ form }) => {
  const [showPopupSlider, setShowPopupSlider] = useState(false);
  const [photoEditIndex, setPhotoEditIndex] = useState(null);
  const [showDamagesPopupSlider, setShowDamagesPopupSlider] = useState(false);
  const [damages, setDamages] = useState([]);
  const [initialPhoto, setInitialPhoto] = useState(null);
  const [gridColumnCount, setGridColumnCount] = useState(3);
  const [isInteriorPhoto, setIsInteriorPhoto] = useState(false);

  const updateDimensions = () => {
    if (window.innerWidth < 350) {
      setGridColumnCount(1);
    } else if (window.innerWidth < 700) {
      setGridColumnCount(2);
    } else if (window.innerWidth < 1000) {
      setGridColumnCount(3);
    } else if (window.innerWidth < 1200) {
      setGridColumnCount(4);
    } else if (window.innerWidth < 1400) {
      setGridColumnCount(5);
    } else if (window.innerWidth < 1600) {
      setGridColumnCount(6);
    } else if (window.innerWidth < 1800) {
      setGridColumnCount(7);
    } else {
      setGridColumnCount(8);
    }
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const handleOpenSlider = index => {
    setInitialPhoto(index);
    setShowPopupSlider(true);
  };

  const handleAddAdditionalPhoto = () => {
    setPhotoEditIndex(-1);
  };

  const handleAddObligatoryPhoto = () => {};

  const onChange = (sourceId, sourceIndex, targetIndex) => {
    const nextState = swap(form?.values?.photos, sourceIndex, targetIndex);
    nextState.forEach((item, index) => {
      item.perspective = getPhotoDescription(index + 1).PERSPECTIVE;
      item.sortNumber = 1;
    });
    form.setFieldValue('photos', nextState);
  };

  const handleEditPhotoClick = index => {
    setPhotoEditIndex(index);
  };

  const handleDelete = mediaMetadataId => {
    const newState = form?.values?.photos.filter(
      item => item.mediaMetadataId !== mediaMetadataId,
    );
    newState.forEach((item, index) => {
      item.perspective = getPhotoDescription(index + 1).PERSPECTIVE;
      item.sortNumber = index;
    });
    const photosToDelete = form?.values?.photosToDelete || [];
    photosToDelete.push(mediaMetadataId);
    form.setFieldValue('photos', newState);
    form.setFieldValue('photosToDelete', photosToDelete);
  };

  const handleUpdatePhoto = (photo, photosIdsToDelete) => {
    const { photos } = form.values;
    const photosToDelete = form.values.photosToDelete || [];
    if (photoEditIndex === -1) {
      photos.push(photo);
    } else {
      photos[photoEditIndex] = photo;
    }
    form.setFieldValue('photos', photos);
    form.setFieldValue('photosToDelete', [...photosToDelete, ...photosIdsToDelete]);
    setPhotoEditIndex(null);
  };

  const canEdit = item => {
    if (item.status === PhotoUploadState.IN_PROGRESS) return false;
    return item.status !== PhotoUploadState.PENDING;
  };

  const getPhotoUrl = item => {
    if (item?.urlTemp) {
      return item?.urlTemp;
    }
    return `${item?.fileUrl}-thumbnail`;
  };

  const sortedPhotos = sortPhotosByPerspective(form?.values?.photos);
  return (
    <>
      <h6>Add photo</h6>
      <Row className="media-upload">
        <Col>
          <GridContextProvider onChange={onChange}>
            <GridDropZone
              id="items"
              boxesPerRow={gridColumnCount}
              rowHeight={(gridColumnCount > 1 ? 115 : 150) + 8}
              style={{
                height: `${((gridColumnCount > 1 ? 115 : 150) + 8) *
                  Math.ceil(((sortedPhotos?.length || 0) + 1) / gridColumnCount) +
                  30}px`,
              }}
            >
              {sortedPhotos?.length >= REQUIRED_PHOTOS_AMOUNT
                ? sortedPhotos.map((item, index) => (
                    <GridItem key={item.mediaMetadataId || uuid()}>
                      {item?.fileUrl ? (
                        <div
                          key={item?.mediaMetadataId || uuid()}
                          className="vehicle_details__photo-wrapper"
                        >
                          {/* <CommonShadowLoader */}
                          {/*  status={getLoaderStatusByPhotoStatus(item.status)} */}
                          {/* > */}
                          <div>
                            <img
                              src={getPhotoUrl(item)}
                              alt="Vehicle"
                              className="vehicle_details__photo no-selectable"
                            />
                          </div>
                          {canEdit(item) && (
                            <>
                              <div className="vehicle_details__drag-position no-selectable">
                                <img src={arrowsIcon} height={20} width={20} alt="alt" />
                              </div>
                              <div className="vehicle_details__photo-icon-button-container no-selectable">
                                <button
                                  data-index={index}
                                  type="button"
                                  className="vehicle_details__photo-icon-button"
                                  onClick={() => handleOpenSlider(index)}
                                >
                                  <i className="fas fa-eye vehicle_details__photo-icon" />
                                </button>
                                <button
                                  data-index={index}
                                  type="button"
                                  className="vehicle_details__photo-icon-button"
                                  onClick={() => handleEditPhotoClick(index)}
                                >
                                  <i className="fas fa-pen vehicle_details__photo-icon" />
                                </button>
                                <button
                                  data-index={index}
                                  type="button"
                                  className="vehicle_details__photo-icon-button"
                                  onClick={() => handleDelete(item.mediaMetadataId)}
                                >
                                  <img src={bucketIcon} height={18} width={15} alt="alt" />
                                </button>
                              </div>
                            </>
                          )}
                          {item.perspective !== PerspectiveType.MASTER &&
                            item?.damages?.length > 0 && (
                              <div className="vehicle_details__photo-damages-icon no-selectable">
                                <img src={warningIcon} alt="Warning icon" />
                              </div>
                            )}
                          {/* </CommonShadowLoader> */}
                        </div>
                      ) : (
                        <button
                          key={item?.id || uuid()}
                          type="button"
                          data-index={index + 1}
                          className="vehicle_details__add-obligatory-photo-button m-1"
                          onClick={handleAddObligatoryPhoto}
                        >
                          <label
                            htmlFor="photo-upload"
                            className="vehicle_details__add-photo-upload-label"
                          >
                            <img src={addPhotoIcon} alt="Plus icon" />
                          </label>
                        </button>
                      )}
                    </GridItem>
                  ))
                : null}
              <GridItem key={uuid()} onMouseDown={e => e.stopPropagation()}>
                <button
                  type="button"
                  className="vehicle_details__add-photo-button m-1"
                  onClick={handleAddAdditionalPhoto}
                >
                  <div className="vehicle_details__add-button_icon">
                    <i className="fa fa-plus-circle" aria-hidden="true" />
                  </div>
                </button>
              </GridItem>
            </GridDropZone>
          </GridContextProvider>
        </Col>
      </Row>
      <SweetAlert
        custom
        onConfirm={() => {}}
        title=""
        showConfirm={false}
        onCancel={() => setPhotoEditIndex(null)}
        show={photoEditIndex !== null}
        customClass="vehicle_details__edit_photo_popup"
      >
        <VehiclePhotoEditForm
          photoEditIndex={photoEditIndex}
          photos={form?.values?.photos || []}
          handleSave={handleUpdatePhoto}
          handleCloseAlert={() => setPhotoEditIndex(null)}
        />
      </SweetAlert>
      <SweetAlert
        custom
        show={showPopupSlider}
        onCancel={() => {
          setShowPopupSlider(false);
        }}
        onConfirm={() => {}}
        title=""
        customButtons={
          <>
            <button
              type="button"
              className="photo_gallery_close_button"
              onClick={() => setShowPopupSlider(false)}
            >
              X
            </button>
          </>
        }
        customClass="photo_gallery_popup"
      >
        <VehicleImagesPopupSlider
          photos={form?.values?.photos}
          initial={initialPhoto}
          onDamageClick={(damages, perspective) => {
            setIsInteriorPhoto(checkInteriorPerspective(perspective));
            setShowDamagesPopupSlider(true);
            setDamages(damages);
          }}
        />
      </SweetAlert>
      <SweetAlert
        custom
        show={showDamagesPopupSlider}
        onCancel={() => {
          setShowDamagesPopupSlider(false);
        }}
        onConfirm={() => {}}
        title=""
        customButtons={
          <>
            <button
              type="button"
              className="photo_gallery_return_button"
              onClick={() => setShowDamagesPopupSlider(false)}
            >
              <i className="fas fa-chevron-left" />
            </button>
          </>
        }
        customClass="photo_gallery_popup"
      >
        <VehicleImagesPopupSlider
          photos={damages}
          initial={0}
          isDamageSlider
          showImageTitle={!isInteriorPhoto}
        />
      </SweetAlert>
    </>
  );
};

VehicleMediaImageForm.propTypes = {
  form: PropTypes.object.isRequired,
};

export default VehicleMediaImageForm;
