import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React from 'react';
import RouterPaths from '../../constants/RouterPaths';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector(state => state.auth.get('isAuthenticated'));

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? <Component {...props} /> : <Redirect to={RouterPaths.LOGIN} />
      }
    />
  );
};

export default ProtectedRoute;
