import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SiteStatusName } from 'constants/SiteCreator';
import Breadcrumbs from '../common/components/Breadcrumbs';
import SiteCreatorTable from './SiteCreatorTable';
import RouterPaths from '../constants/RouterPaths';
import SiteCreatorSearch from './search/SiteCreatorSearch';
import { setQueryString, useQueryString } from '../common/helpers/queryString';
import { searchSite } from './SiteCreatorActions';

const breadcrumbsItems = [
  { id: 1, type: 'home' },
  { id: 2, type: 'span', text: 'Site Creator' },
];

const SiteCreatorPage = ({ history, location }) => {
  const query = useQueryString();
  const dispatch = useDispatch();

  const items = useSelector(state => state.siteCreator.get('items'));
  const totalItems = useSelector(state => state.siteCreator.get('totalItems'));

  const [searchParams, setSearchParams] = useState({
    offset: query.get('page') ? +query.get('page') - 1 : 0,
  });

  useEffect(() => {
    dispatch(searchSite(searchParams));
  }, [dispatch, searchParams]);

  const handleSearch = (values, { setSubmitting }) => {
    const { title, status } = values;
    let isActive = '';
    if (status) {
      isActive = status.label === SiteStatusName.ACTIVE;
    }
    setSearchParams(prevState => ({ ...prevState, title, isActive, offset: 0 }));
    setSubmitting(false);
  };

  const changeOffset = value => {
    setSearchParams(prevState => ({ ...prevState, offset: value }));
    history.push(setQueryString(location, 'page', value + 1));
  };

  return (
    <div className="content">
      <Row>
        <Col xs="9">
          <Breadcrumbs items={breadcrumbsItems} />
        </Col>
        <Col xs="3">
          <Button
            className="float-right"
            color="primary"
            onClick={() => history.push(RouterPaths.CREATE_SITE)}
          >
            Add site
          </Button>
        </Col>
      </Row>
      <Row>
        <SiteCreatorSearch handleSearch={handleSearch} />
      </Row>
      <Row>
        <SiteCreatorTable
          data={{ items, total: totalItems }}
          offset={searchParams.offset}
          changeOffset={changeOffset}
        />
      </Row>
    </div>
  );
};

export default withRouter(SiteCreatorPage);
