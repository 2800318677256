import React from 'react';
import { Link } from 'react-router-dom';
import { Nav } from 'reactstrap';

const Breadcrumbs = ({ items }) => {
  if (!items || !items.length) {
    return null;
  }

  return (
    <Nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {items.map(item => {
          switch (item.type) {
            case 'home':
              return (
                <li className="breadcrumb-item" key={item.id}>
                  <Link to="/">
                    <i className="fa fa-home mr-2" />
                    Home
                  </Link>
                </li>
              );
            case 'link':
              return (
                <li className="breadcrumb-item" key={item.id}>
                  <Link to={item.url}>{item.text}</Link>
                </li>
              );
            case 'span':
              return (
                <li className="breadcrumb-item active" aria-current="page" key={item.id}>
                  <span>{item.text}</span>
                </li>
              );
            default:
              return null;
          }
        })}
      </ol>
    </Nav>
  );
};

export default Breadcrumbs;
