import React from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CommonTable from '../common/components/CommonTable';
import SiteCreatorTableRow from './SiteCreatorTableRow';
import CommonLoader from '../common/components/CommonLoader';
import { PrepareUrl } from '../common/helpers/PrepareUrl';
import RouterPaths from '../constants/RouterPaths';

const headers = [
  {
    label: 'ID',
  },
  {
    label: 'Name',
  },
  {
    label: 'Status',
  },
  {
    label: 'Action',
  },
];

const SiteCreatorTable = ({ data, offset, changeOffset, history }) => {
  const loaderStatus = useSelector(state => state.siteCreator.get('loaderStatus'));

  return (
    <Col xs="12">
      <CommonLoader status={loaderStatus}>
        <CommonTable
          rowComponent={SiteCreatorTableRow}
          data={data}
          offset={offset}
          onOffsetChange={changeOffset}
          headers={headers}
          title="Site creator"
          handleRowClick={item =>
            history.push(PrepareUrl(RouterPaths.EDIT_SITE, { id: item.id }))
          }
        />
      </CommonLoader>
    </Col>
  );
};

SiteCreatorTable.propTypes = {
  data: PropTypes.object.isRequired,
  offset: PropTypes.number.isRequired,
  changeOffset: PropTypes.func.isRequired,
};

export default withRouter(SiteCreatorTable);
