import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';
import { FieldArray } from 'formik';

const FormGroupCheckboxFieldInner = props => {
  const {
    form,
    name,
    checkboxes,
    inline = false,
    checkDependencies,
    isRoleGroupCheckbox,
    push,
    remove,
  } = props;

  const error = form.errors[name];
  const touched = form.touched[name];
  const value = form.values[name] || [];

  useEffect(() => {
    if (isRoleGroupCheckbox) {
      checkDependencies(value, remove);
    }
  }, [value, isRoleGroupCheckbox, remove, checkDependencies]);

  return (
    <>
      {checkboxes.map(checkbox => (
        <FormGroup
          check
          disabled={form.isSubmitting || checkbox.disabled}
          key={checkbox.label}
          inline={inline}
        >
          <Label check>
            <Input
              type="checkbox"
              disabled={checkbox.disabled}
              checked={value.includes(checkbox.value)}
              onBlur={() => form.setFieldTouched(name, true)}
              onChange={({ target }) => {
                if (target.checked) {
                  push(checkbox.value);
                } else {
                  const idx = value.indexOf(checkbox.value);
                  if (idx !== -1) {
                    remove(idx);
                  }
                }
              }}
            />
            {checkbox.label}
            <span className="form-check-sign">
              <span className="check" />
            </span>
          </Label>
        </FormGroup>
      ))}

      {(form.submitCount > 0 || touched) && error && (
        <label style={{ color: 'red ' }}>{error}</label>
      )}
    </>
  );
};

const FormGroupCheckboxField = props => {
  return (
    <FieldArray
      name={props.name}
      render={arrayProps => <FormGroupCheckboxFieldInner {...props} {...arrayProps} />}
    />
  );
};

FormGroupCheckboxField.propTypes = {
  checkboxes: PropTypes.array.isRequired,
  checkDependencies: PropTypes.func,
  isRoleGroupCheckbox: PropTypes.bool,
};

FormGroupCheckboxField.defaultProps = {
  checkDependencies: () => {},
  isRoleGroupCheckbox: false,
};

export default FormGroupCheckboxField;
