import * as Yup from 'yup';

export default () => {
  return Yup.object().shape({
    firstName: Yup.string()
      .required('Field is required')
      .max(50, 'First name is too long - should be 50 chars maximum'),
    lastName: Yup.string()
      .required('Field is required')
      .max(50, 'Last name is too long - should be 50 chars maximum'),
    email: Yup.string()
      .email('Field must be a valid email')
      .required('Field is required')
      .max(50, 'Field must be at most 50 characters'),
    phone: Yup.string()
      .required('Field is required')
      .max(15, 'Max length equals 15 characters'),
    status: Yup.object().shape({
      value: Yup.string().required('Field is required'),
    }),
    location: Yup.object().shape({
      value: Yup.string().required('Field is required'),
    }),
    role: Yup.array().required('Field is required'),
  });
};
