import React, { useRef } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { get } from 'lodash';

export const FormInputFieldType = {
  TEXT: 'text',
  NUMBER: 'number',
  DATE: 'date',
  TIME: 'time',
  PASSWORD: 'password',
  TEXTAREA: 'textarea',
};

const FormInputFieldInner = props => {
  let ref = useRef(null);
  const {
    label,
    placeholder,
    type,
    form,
    field,
    noStyling = false,
    disabled = false,
    style,
    setRef,
    upperCase,
    inputClassName,
  } = props;
  const error = form.errors[field.name];
  const touched = form.touched[field.name];
  const value = get(form.values, field.name, '');
  const renderValue = valueToRender => {
    if (upperCase) {
      return valueToRender.toUpperCase();
    }
    return valueToRender;
  };

  const element = (
    <>
      <Input
        className={inputClassName}
        style={style}
        placeholder={placeholder}
        type={type}
        value={renderValue(value)}
        disabled={form.isSubmitting || disabled}
        onBlur={() => form.setFieldTouched(field.name, true)}
        onChange={({ target }) => {
          form.setFieldValue(field.name, target.value);
        }}
        innerRef={input => {
          if (setRef) {
            ref = input;
            setRef(ref);
          }
        }}
      />
      {(form.submitCount > 0 || touched) && error && (
        <label style={{ color: 'red ' }}>{error}</label>
      )}
    </>
  );

  return noStyling ? (
    element
  ) : (
    <Row>
      <Col>
        <Label>{label}</Label>
        <FormGroup>{element}</FormGroup>
      </Col>
    </Row>
  );
};

const FormInputField = props => {
  return <Field {...props} component={FormInputFieldInner} />;
};

FormInputField.defaultProps = {
  label: '',
  style: null,
  type: FormInputFieldType.TEXT,
  placeholder: '',
  disabled: false,
  setRef: null,
  upperCase: false,
  inputClassName: '',
};

FormInputField.propTypes = {
  label: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  setRef: PropTypes.func,
  upperCase: PropTypes.bool,
  inputClassName: PropTypes.string,
};

export default FormInputField;
