export const FormRegExp = {
  STRING_WITH_SPECIAL_CHARS: /^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ\-*.,&$"^%'\s]+$/,
  STRING_ONLY_LETTERS: /^[A-Za-z]+$/,
  INT_WITHOUT_PREFIX_AND_SPECIAL_CHARS: /[£,]/gi,
  INTEGER: /^\d+$/,
  PRICE: /\B(?=(\d{3})+(?!\d))/g,
  YEAR: /^[12][0-9]{3}$/,
  URL: /https?:\/\/www\.?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/g,
  URL_NO_HTTPS: /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/gi,
  ALPHANUMERIC_WITHOUT_SPACES_UPPERCASE: /^[A-Z0-9]*$/,
};
