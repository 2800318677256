import { FormRegExp } from '../constants/FormRegExp';

export const parsePriceFormatToInt = (value, isPence = true) => {
  if (typeof value === 'string') {
    const preparedPrice = Number(
      value.replace(FormRegExp.INT_WITHOUT_PREFIX_AND_SPECIAL_CHARS, ''),
    );
    return isPence ? preparedPrice : preparedPrice * 100;
  }
  return null;
};
