import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { reverse } from 'named-urls';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RequestForChangesTableRow from './RequestForChangesTableRow';
import CommonTable from '../../common/components/CommonTable';
import { OrganizationChangeTypes } from '../../constants/OrganizationChangeTypes';
import RouterPaths from '../../constants/RouterPaths';
import CommonLoader from '../../common/components/CommonLoader';

const headers = [
  {
    label: 'ID',
  },
  {
    label: 'Dealer',
  },
  {
    label: 'Change',
  },
  {
    label: 'Action',
    className: 'text-right',
  },
];

const RequestForChangesTable = ({ data, offset, changeOffset, history }) => {
  const loaderStatus = useSelector(state => state.requests.get('loaderStatus'));
  const handleRedirectAction = item => {
    if (item.type === OrganizationChangeTypes.ADD_NEW_EMPLOYEE)
      return history.push(reverse(RouterPaths.EDIT_USER, { id: item?.value }));
    return history.push(
      reverse(RouterPaths.DEALER_INFORMATION, { id: item?.organization?.id }),
    );
  };
  return (
    <Row>
      <Col xs="12">
        <CommonLoader status={loaderStatus}>
          <CommonTable
            rowComponent={RequestForChangesTableRow}
            data={data}
            offset={offset}
            onOffsetChange={changeOffset}
            headers={headers}
            title="Requests"
            handleRowClick={item => handleRedirectAction(item)}
          />
        </CommonLoader>
      </Col>
    </Row>
  );
};

RequestForChangesTable.propTypes = {
  changeOffset: PropTypes.func.isRequired,
};

export default withRouter(RequestForChangesTable);
