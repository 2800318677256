import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Button, Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import Breadcrumbs from '../../common/components/Breadcrumbs';
import validationSchema from './EditVehicleForm.schema';
import VehicleForm from '../form/VehicleForm';
import VehicleSectionsNav from '../VehicleSectionsNav';
import {
  cancelAuction,
  clearAuctionDetails,
  getAuctionDetails,
  revokeAuction,
  updateVehicle,
} from '../VehicleActions';
import { prepareSelectValue } from '../../helpers/prepareSelectValue';
import { AuctionStatusType, AuctionStatusTypeName } from '../../constants/AuctionStatusType';
import { AuctionTimeName } from '../../constants/AuctionTime';
import { convertSnakeCaseStringToCapitalize } from '../../helpers/convertSnakeCaseStringToCapitalize';
import { VehicleServiceHistoryName } from '../../constants/VehicleServiceHistory';
import { AuctionStatus } from '../../constants/AuctionStatus';
import { DueInDateType, DueInDateTypeName } from '../../constants/DueInDateType';
import { AuctionStatusToVehicleStatus } from '../../constants/AuctionStatusToVehicleStatus';
import { isTodayOrFutureDate } from '../../common/utils/date/Date.utils';

const { WAITING, TO_ACCEPT, SOLD } = AuctionStatus;
const REVOKE_AUCTION_STATUSES = [WAITING, TO_ACCEPT, SOLD];

const breadcrumbsItems = [
  { id: 1, type: 'home' },
  { id: 2, type: 'link', text: 'Vehicles', url: '/vehicles' },
  { id: 3, type: 'span', text: 'Edit Vehicle' },
];

const EditVehiclePage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [isRevokeAlertVisible, setIsRevokeAlertVisible] = useState(false);

  const launchingCounterSerial = useSelector(state => state.vehicles.get('auctionDetails'))
    ?.launchingCounterSerial;
  const auctionDetails = useSelector(state => state.vehicles.get('auctionDetails'));
  const basicData = useSelector(state => state.vehicles.get('auctionDetails'))?.basicData;
  const seller = useSelector(state => state.vehicles.get('auctionDetails'))?.seller;
  const tradeName = useSelector(state => state.vehicles.get('auctionDetails'))?.seller
    ?.organization?.tradeName;
  const photos = useSelector(state => state.vehicles.get('auctionDetails'))?.vehicleMedia
    ?.photos;
  const videos = useSelector(state => state.vehicles.get('auctionDetails'))?.vehicleMedia
    ?.videos;
  const tyreThreadDepths = useSelector(state => state.vehicles.get('auctionDetails'))
    ?.tyreThreadDepths;
  const serviceHistory = useSelector(state => state.vehicles.get('auctionDetails'))
    ?.serviceHistory;
  const descriptionAndSettings = useSelector(state => state.vehicles.get('auctionDetails'))
    ?.descriptionAndSettings;
  const status = useSelector(state => state.vehicles.get('auctionDetails')?.status);
  const capData = useSelector(state => state.vehicles.get('auctionDetails')?.capData);
  const newPhoto = useSelector(state => state.vehicles.get('Photo'));
  const newVideo = useSelector(state => state.vehicles.get('Video'));
  const version = useSelector(state => state.vehicles.get('auctionDetails'))?.version;
  const organizationLocations = useSelector(state => state.vehicles.get('auctionDetails'))
    ?.organizationLocations;
  const vehicleFeatures = useSelector(state => state.vehicles.get('auctionDetails'))
    ?.vehicleFeatures;

  const prepareTyreThreadDepths = () => {
    if (tyreThreadDepths) {
      const tyreThreadDepthsObj = {};
      Object.keys(tyreThreadDepths).forEach(key => {
        tyreThreadDepthsObj[key] = tyreThreadDepths[key]
          ? {
              value: tyreThreadDepths[key],
              label: `${tyreThreadDepths[key]}mm`,
            }
          : {};
      });
      return tyreThreadDepthsObj;
    }
    return '';
  };
  let currentOrganizationLocation;
  if (organizationLocations && descriptionAndSettings) {
    currentOrganizationLocation = organizationLocations.find(
      item => item.id === descriptionAndSettings?.locationId,
    );
  }
  const initialValues = {
    id: id || '',
    deletedOn: auctionDetails?.deletedOn,
    launchingCounterSerial: launchingCounterSerial || '',
    status: status
      ? convertSnakeCaseStringToCapitalize(AuctionStatusToVehicleStatus[status])
      : '',
    numberPlate: basicData?.registrationPlate || '',
    dealer: tradeName || '',
    make: prepareSelectValue(basicData?.makeId, basicData?.makeName),
    model: prepareSelectValue(basicData?.modelId, basicData?.modelName),
    year: basicData?.year || '',
    mileage: basicData?.mileage || '',
    derivative: basicData?.derivative || '',
    carType: basicData?.carType || '',
    transmission: basicData?.transmission || '',
    engineSize: basicData?.engineSize || '',
    fuel: basicData?.fuel || '',
    colour: basicData?.colour || '',
    doors: basicData?.doors || '',
    vin: basicData?.vin || '',
    previousOwners:
      basicData?.previousKeepersInTotal !== undefined &&
      basicData?.previousKeepersInTotal !== null
        ? basicData?.previousKeepersInTotal
        : '',
    mot: basicData?.mot || '',
    registrationDate: basicData?.dateOfFirstRegistration || '',
    vehicleCapCapId: basicData?.vehicleCapCapId || '',
    launchDate: auctionDetails?.launchedOn || '',
    seller: seller ? `${seller?.firstName} ${seller?.lastName}` : '',
    capAverage: capData?.capAverage || '',
    capBelow: capData?.capBelow || '',
    capClean: capData?.capClean || '',
    retail: capData?.retail || '',
    organizationLocations: currentOrganizationLocation
      ? prepareSelectValue(
          currentOrganizationLocation?.id,
          currentOrganizationLocation?.postalCode?.postcode,
        )
      : '',
    photos: photos || '',
    photosToDelete: [],
    videos: videos || '',
    videosToDelete: [],
    ...prepareTyreThreadDepths(),
    serviceHistory:
      prepareSelectValue(
        serviceHistory?.type,
        VehicleServiceHistoryName[serviceHistory?.type],
      ) || '',
    publishOption: '',
    description: descriptionAndSettings?.description || '',
    defaultDescription: descriptionAndSettings?.defaultDescription || '',
    descriptionTemplate: '',
    newFeature: '',
    services: serviceHistory?.manualRecords
      ? serviceHistory?.manualRecords
      : [{ date: '', mileage: '', serviceName: '' }],
    serviceHistoryPhotoRecords: serviceHistory?.photoRecords || [],
    newServiceHistoryPhotoRecords: [],
    serviceHistoryPhotoRecordsToDelete: [],
    timeOption: prepareSelectValue(
      descriptionAndSettings?.timeOption,
      AuctionTimeName[descriptionAndSettings?.timeOption],
    ),
    type: descriptionAndSettings?.type
      ? prepareSelectValue(
          descriptionAndSettings?.type,
          AuctionStatusTypeName[descriptionAndSettings?.type],
        )
      : prepareSelectValue(
          AuctionStatusType.MINIMUM_PRICE,
          AuctionStatusTypeName[AuctionStatusType.MINIMUM_PRICE],
        ),
    minimumPriceInPence: descriptionAndSettings?.minimumPriceInPence / 100 || '',
    autoAcceptPriceInPence: descriptionAndSettings?.autoAcceptPriceInPence / 100 || '',
    tryingToDealDeliveryDate: descriptionAndSettings?.tryingToDealDeliveryDate || new Date(),
    publishTarget: descriptionAndSettings?.publishTarget || '',
    newPhoto: newPhoto || [],
    newVideo: newVideo || [],
    version: version || 0,
    vehicleFeaturesDictionaryIds: vehicleFeatures?.vehicleFeaturesDictionaryIds || [],
    vehicleFeaturesCustom: vehicleFeatures?.vehicleFeaturesCustom || [],
    tryingToDealDeliveryDateType: descriptionAndSettings?.tryingToDealDeliveryDateType
      ? prepareSelectValue(
          descriptionAndSettings?.tryingToDealDeliveryDateType,
          DueInDateTypeName[descriptionAndSettings?.tryingToDealDeliveryDateType],
        )
      : null,
  };

  useEffect(() => {
    dispatch(getAuctionDetails(id));
    return () => dispatch(clearAuctionDetails());
  }, [dispatch, id]);

  const validateTryingToDealDeliveryDate = (values, auctionDetails, setErrors) => {
    if (
      status === AuctionStatus.PUBLISHED &&
      descriptionAndSettings.type === AuctionStatusType.TRYING_TO_DEAL &&
      values.tryingToDealDeliveryDateType.value === DueInDateType.CUSTOM_DATE
    ) {
      const isValidDate = isTodayOrFutureDate(new Date(), values?.tryingToDealDeliveryDate);
      if (!isValidDate) {
        setErrors({
          tryingToDealDeliveryDate: 'Date must not be in the past',
        });
      }
      return isValidDate;
    }
    return true;
  };

  const handleSubmit = (values, { setSubmitting, setErrors }) => {
    if (validateTryingToDealDeliveryDate(values, auctionDetails, setErrors)) {
      dispatch(updateVehicle(id, values, auctionDetails))
        .then(() => {
          setSubmitting(false);
        })
        .catch(() => setSubmitting(false));
    }
    setSubmitting(false);
  };

  return (
    <div className="content">
      <Row>
        <Col xs="9">
          <Breadcrumbs items={breadcrumbsItems} />
        </Col>
        {status === AuctionStatus.PUBLISHED && (
          <Col xs="3">
            <Button
              className="float-right"
              color="danger"
              onClick={() =>
                dispatch(cancelAuction({ id, version }, () => dispatch(getAuctionDetails(id))))
              }
            >
              Cancel
            </Button>
          </Col>
        )}
      </Row>
      <Row>
        <Col md={6}>
          <VehicleSectionsNav section="information" />
        </Col>
        {REVOKE_AUCTION_STATUSES?.includes(status) && (
          <Col md={6} className="d-flex justify-content-end">
            <Button color="secondary" onClick={() => setIsRevokeAlertVisible(true)}>
              Revoke the sale
            </Button>
          </Col>
        )}
      </Row>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema(status, descriptionAndSettings?.type)}
        onSubmit={handleSubmit}
        validateOnBlur={true}
        enableReinitialize
      >
        {form => <VehicleForm title="Edit vehicle" form={form} />}
      </Formik>
      <SweetAlert
        warning
        showCancel
        show={isRevokeAlertVisible}
        title="Warning!"
        onConfirm={() => {
          setIsRevokeAlertVisible(false);
          dispatch(revokeAuction(id, version));
        }}
        onCancel={() => setIsRevokeAlertVisible(false)}
        confirmBtnBsStyle="info"
      >
        Are you sure to revoke an auction?
      </SweetAlert>
    </div>
  );
};

export default EditVehiclePage;
