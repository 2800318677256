import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { reverse } from 'named-urls';
import validationSchema from './VehicleSellForm.schema';
import { FormCurrencyInputField } from '../../common/components/formFields';
import DealersSelectField from '../../dealers/forms/DealersSelectField';
import { sellVehicle } from '../../bids/BidsActions';
import { parsePriceFormatToInt } from '../../helpers/parsePriceFormatToInt';
import FormSelectField from '../../common/components/formFields/FormSelectField';
import { getAuctionDetails, getDealersEmployees } from '../VehicleActions';
import { dataToSelect } from '../../common/helpers';
import RouterPaths from '../../constants/RouterPaths';
import CommonLoader from '../../common/components/CommonLoader';

const initialValues = {
  organizationId: '',
  organizationMemberId: '',
  amountInPence: '',
};

let formContext = null;

const VehicleSellForm = ({ history }) => {
  const dispatch = useDispatch();
  const { id: auctionId } = useParams();
  const auctionDetails = useSelector(state => state.vehicles.get('auctionDetails'));
  const [employees, setEmployees] = useState([]);
  const [formValue, setFormValue] = useState({});
  const [loading, setLoading] = useState(false);
  const [attempt, setAttempt] = useState(0);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);

  const isSellAllowed = auctionDetails && auctionDetails.isSellAllowed;

  useEffect(() => {
    if (auctionId) {
      dispatch(getAuctionDetails(auctionId));
    }
  }, [dispatch, auctionId]);
  const handleChangeDealer = employee => {
    if (employee?.value) {
      dispatch(
        getDealersEmployees(employee?.value, employees => {
          setEmployees(dataToSelect(employees, ['id', 'firstName']));
          formContext.setFieldValue('organizationMemberId', {});
        }),
      );
    }
  };

  const handleSubmit = ({ organizationMemberId, amountInPound }, { setSubmitting }) => {
    setSubmitting(false);
    setFormValue({
      id: auctionId,
      version: auctionDetails.version,
      organizationMemberId: organizationMemberId?.value,
      amountInPence: parsePriceFormatToInt(amountInPound, false),
    });
    setShowConfirmAlert(true);
  };

  const handleCancel = () => {
    setShowConfirmAlert(false);
    setFormValue({});
  };

  const onSuccessSold = soldAuctionId => {
    if (attempt > 3) {
      history.push(reverse(RouterPaths.VEHICLES));
    }
    setAttempt(attempt + 1);
    dispatch(getAuctionDetails(soldAuctionId, true))
      .then(data => {
        if (data.status === 'SOLD') {
          setLoading(false);
          history.push(reverse(RouterPaths.EDIT_VEHICLE, { id: data.id }));
        } else {
          setTimeout(() => {
            onSuccessSold(soldAuctionId);
          }, 1000);
        }
      })
      .catch(() => {
        setTimeout(() => {
          onSuccessSold(soldAuctionId);
        }, 1000);
      });
  };

  const handleSell = () => {
    setLoading(true);
    setShowConfirmAlert(false);
    dispatch(
      sellVehicle(
        formValue,
        soldAuctionId => onSuccessSold(soldAuctionId),
        () => {
          setLoading(false);
        },
      ),
    );
  };

  return (
    <>
      {loading ? (
        <CommonLoader />
      ) : (
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validateOnBlur={true}
          >
            {form => {
              formContext = form;
              return (
                <Form autoComplete="off">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md="4">
                          <DealersSelectField
                            name="organizationId"
                            label="Dealer"
                            onChangeHandler={handleChangeDealer}
                            disabled={!isSellAllowed || loading}
                          />
                        </Col>
                        <Col md="4">
                          <FormSelectField
                            name="organizationMemberId"
                            label="Employee"
                            options={employees}
                            disabled={!isSellAllowed || loading}
                          />
                        </Col>
                        <Col md="4">
                          <FormCurrencyInputField
                            name="amountInPound"
                            label="Bid"
                            disabled={!isSellAllowed || loading}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <Button
                            color="primary"
                            className="mt-4 float-right"
                            type="submit"
                            disabled={!isSellAllowed || loading}
                          >
                            Sell
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Form>
              );
            }}
          </Formik>
          <SweetAlert
            warning
            showCancel
            show={showConfirmAlert}
            title="Warning!"
            onConfirm={() => {
              handleSell();
            }}
            onCancel={() => handleCancel()}
            confirmBtnBsStyle="info"
          >
            Are you sure you want to sell this vehicle?
          </SweetAlert>
        </>
      )}
    </>
  );
};

export default withRouter(VehicleSellForm);
