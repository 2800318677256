import { apiClient, handleError } from '../api/ApiClient';
import { prepareSearchData, SORT_DESC } from '../helpers/prepareSearchData';
import {
  ADD_VEHICLE_BID,
  ADD_VEHICLE_BID_FAILURE,
  ADD_VEHICLE_BID_SUCCESS,
  SEARCH_BIDS,
  SEARCH_BIDS_FAILURE,
  SEARCH_BIDS_SUCCESS,
} from './BidsReducer';
import { showAlert } from '../common/components/alert/AlertActions';
import AlertVariant from '../common/components/alert/AlertVariant';

export const BIDS_ENDPOINT = '/auction/bid';
export const SELL_ENDPOINT = '/auction/sell';

export const searchBids = values => dispatch => {
  dispatch({ type: SEARCH_BIDS });
  return apiClient
    .post(`${BIDS_ENDPOINT}/search`, prepareSearchData(values, { serial: SORT_DESC }))
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: SEARCH_BIDS_SUCCESS,
          payload: {
            items: res?.data?.data?.content,
            totalItems: res?.data?.data?.totalElements,
          },
        });
      }
    })
    .catch(err => {
      dispatch({ type: SEARCH_BIDS_FAILURE });
      dispatch(handleError(err));
    });
};

export const addVehicleBid = (values, successCallback) => dispatch => {
  dispatch({ type: ADD_VEHICLE_BID });
  return apiClient
    .post(BIDS_ENDPOINT, values)
    .then(res => {
      if (res.status === 200) {
        dispatch({ type: ADD_VEHICLE_BID_SUCCESS });
        dispatch(showAlert('Bid has added successfully', AlertVariant.SUCCESS));
        if (typeof successCallback === 'function') {
          successCallback();
        }
      }
    })
    .catch(err => {
      dispatch({ type: ADD_VEHICLE_BID_FAILURE });
      dispatch(handleError(err));
    });
};

export const sellVehicle = (values, successCallback, errorCallback) => dispatch => {
  return apiClient
    .post(SELL_ENDPOINT, values)
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('The vehicle has been sold', AlertVariant.SUCCESS));
        if (typeof successCallback === 'function') {
          successCallback(res?.data?.data?.id);
        }
      }
    })
    .catch(err => {
      if (typeof errorCallback === 'function') {
        errorCallback();
      }
      dispatch(handleError(err));
    });
};

export const deleteBid = id => dispatch =>
  apiClient
    .delete(`${BIDS_ENDPOINT}/${id}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Bid removed successfully', AlertVariant.SUCCESS));
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    });
