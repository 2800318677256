import * as Yup from 'yup';

export default () => {
  return Yup.object().shape({
    monthlySubscriptionInPence: Yup.string()
      .required('Field is required')
      .max(14, 'Field can be at most 10 characters'),
    launchInPence: Yup.string()
      .required('Field is required')
      .max(14, 'Field can be at most 10 characters'),
    saleForLessThan1000InPence: Yup.string().max(14, 'Field can be at most 10 characters'),
    saleBetween1001And2500InPence: Yup.string().max(14, 'Field can be at most 10 characters'),
    saleBetween2501And5000InPence: Yup.string().max(14, 'Field can be at most 10 characters'),
    saleBetween5001And10000InPence: Yup.string().max(14, 'Field can be at most 10 characters'),
    saleBetween10001And20000InPence: Yup.string().max(
      14,
      'Field can be at most 10 characters',
    ),
    saleForMoreThan20000InPence: Yup.string().max(14, 'Field can be at most 10 characters'),
    purchaseForLessThan1000InPence: Yup.string().max(14, 'Field can be at most 10 characters'),
    purchaseBetween1001And2500InPence: Yup.string().max(
      14,
      'Field can be at most 10 characters',
    ),
    purchaseBetween2501And5000InPence: Yup.string().max(
      14,
      'Field can be at most 10 characters',
    ),
    purchaseBetween5001And10000InPence: Yup.string().max(
      14,
      'Field can be at most 10 characters',
    ),
    purchaseBetween10001And20000InPence: Yup.string().max(
      14,
      'Field can be at most 10 characters',
    ),
    purchaseForMoreThan20000InPence: Yup.string().max(
      14,
      'Field can be at most 10 characters',
    ),
  });
};
