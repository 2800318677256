import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { Form } from 'formik';
import { useSelector } from 'react-redux';
import { FormCurrencyInputField, FormSelectField } from '../../common/components/formFields';
import { constantToSelect } from '../../common/helpers';
import { AgreeOptions } from '../../constants/AgreeOptions';

const agreeOptions = constantToSelect(AgreeOptions);

const SettingsForm = () => {
  const validationErrors = useSelector(state => state.settings.get('validationErrors'));
  const {
    monthlySubscriptionInPence,
    launchInPence,
    saleForLessThan1000InPence,
    saleBetween1001And2500InPence,
    saleBetween2501And5000InPence,
    saleBetween5001And10000InPence,
    saleBetween10001And20000InPence,
    saleForMoreThan20000InPence,
    purchaseForLessThan1000InPence,
    purchaseBetween1001And2500InPence,
    purchaseBetween2501And5000InPence,
    purchaseBetween5001And10000InPence,
    purchaseBetween10001And20000InPence,
    purchaseForMoreThan20000InPence,
  } = validationErrors;

  return (
    <>
      <Form noValidate>
        <Row>
          <Col md="4">
            <FormSelectField
              name="vehicleMakeVisible"
              label="Vehicle make visible on the home page"
              options={agreeOptions}
            />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <h5>Fees</h5>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <FormCurrencyInputField
              name="monthlySubscriptionInPence"
              label="Monthly subscription"
            />
            {monthlySubscriptionInPence && (
              <label className="error-label">{monthlySubscriptionInPence}</label>
            )}
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <FormCurrencyInputField name="launchInPence" label="Launch" />
            {launchInPence && <label className="error-label">{launchInPence}</label>}
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <FormCurrencyInputField
              name="saleForLessThan1000InPence"
              label="Sale £0 - £1,000"
            />
            {saleForLessThan1000InPence && (
              <label className="error-label">{saleForLessThan1000InPence}</label>
            )}
          </Col>
          <Col md="4">
            <FormCurrencyInputField
              name="saleBetween1001And2500InPence"
              label="Sale £1,001 - £2,500"
            />
            {saleBetween1001And2500InPence && (
              <label className="error-label">{saleBetween1001And2500InPence}</label>
            )}
          </Col>
          <Col md="4">
            <FormCurrencyInputField
              name="saleBetween2501And5000InPence"
              label="Sale £2,501 - £5,000"
            />
            {saleBetween2501And5000InPence && (
              <label className="error-label">{saleBetween2501And5000InPence}</label>
            )}
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <FormCurrencyInputField
              name="saleBetween5001And10000InPence"
              label="Sale £5,001 - £10,000"
            />
            {saleBetween5001And10000InPence && (
              <label className="error-label">{saleBetween5001And10000InPence}</label>
            )}
          </Col>
          <Col md="4">
            <FormCurrencyInputField
              name="saleBetween10001And20000InPence"
              label="Sale £10,001 - £20,000"
            />
            {saleBetween10001And20000InPence && (
              <label className="error-label">{saleBetween10001And20000InPence}</label>
            )}
          </Col>
          <Col md="4">
            <FormCurrencyInputField
              name="saleForMoreThan20000InPence"
              label="Sale for more than £20,000"
            />
            {saleForMoreThan20000InPence && (
              <label className="error-label">{saleForMoreThan20000InPence}</label>
            )}
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <FormCurrencyInputField
              name="purchaseForLessThan1000InPence"
              label="Purchase £0 - £1,000"
            />
            {purchaseForLessThan1000InPence && (
              <label className="error-label">{purchaseForLessThan1000InPence}</label>
            )}
          </Col>
          <Col md="4">
            <FormCurrencyInputField
              name="purchaseBetween1001And2500InPence"
              label="Purchase £1,001 - £2,500"
            />
            {purchaseBetween1001And2500InPence && (
              <label className="error-label">{purchaseBetween1001And2500InPence}</label>
            )}
          </Col>
          <Col md="4">
            <FormCurrencyInputField
              name="purchaseBetween2501And5000InPence"
              label="Purchase £2,501 - £5,000"
            />
            {purchaseBetween2501And5000InPence && (
              <label className="error-label">{purchaseBetween2501And5000InPence}</label>
            )}
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <FormCurrencyInputField
              name="purchaseBetween5001And10000InPence"
              label="Purchase £5,001 - £10,000"
            />
            {purchaseBetween5001And10000InPence && (
              <label className="error-label">{purchaseBetween5001And10000InPence}</label>
            )}
          </Col>
          <Col md="4">
            <FormCurrencyInputField
              name="purchaseBetween10001And20000InPence"
              label="Purchase £10,001 - £20,000"
            />
            {purchaseBetween10001And20000InPence && (
              <label className="error-label">{purchaseBetween10001And20000InPence}</label>
            )}
          </Col>
          <Col md="4">
            <FormCurrencyInputField
              name="purchaseForMoreThan20000InPence"
              label="Purchase for more than £20,000"
            />
            {purchaseForMoreThan20000InPence && (
              <label className="error-label">{purchaseForMoreThan20000InPence}</label>
            )}
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Button color="primary" className="mt-4 float-right" type="submit">
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default SettingsForm;
