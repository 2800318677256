import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import CommonTable from '../../../common/components/CommonTable';
import DealerTransactionsTableRow from './DealerTransactionsTableRow';

const headers = [
  {
    label: 'ID',
  },
  {
    label: 'Title',
  },
  {
    label: 'Transaction date',
  },
  {
    label: 'Fee',
  },
  {
    label: 'Dealer',
  },
];

const DealerTransactionsTable = ({ data, offset, changeOffset }) => {
  return (
    <Row>
      <Col xs="12">
        <CommonTable
          data={data}
          rowComponent={DealerTransactionsTableRow}
          offset={offset}
          onOffsetChange={changeOffset}
          headers={headers}
          title="Transactions"
        />
      </Col>
    </Row>
  );
};

DealerTransactionsTable.propTypes = {
  data: PropTypes.object.isRequired,
  offset: PropTypes.number.isRequired,
  changeOffset: PropTypes.func.isRequired,
};

export default DealerTransactionsTable;
