import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Button } from 'reactstrap';
import { FieldArray } from 'formik';
import { isEmpty } from 'lodash';
import PostcodeSelectField from './postcode/PostcodeSelectField';
import { FormInputField } from '../../../common/components/formFields';
import PendingActionButtons from '../PendingActionButtons';
import { DealerPendingActionTypes } from '../../../constants/DealerPendingActionTypes';

const DealerLocationForm = ({ locations, disabled, preparePendingActionObj }) => {
  const mainLocationDataObj = preparePendingActionObj(
    DealerPendingActionTypes.MAIN_LOCATION_CHANGE,
  );
  const additionalLocationDataObj = preparePendingActionObj(
    DealerPendingActionTypes.ADD_ADDITIONAL_LOCATION,
  );

  const err = (index, arrayHelpers, name) => {
    if (arrayHelpers?.form?.errors?.locations) {
      const errors = arrayHelpers?.form?.errors?.locations?.[index];
      const touched = arrayHelpers?.form?.touched?.locations?.[index];
      if (errors?.[name] && touched?.[name]) {
        return <label className="error-label">{errors?.[name]}</label>;
      }
    }
    return false;
  };

  return (
    <>
      <h6>Main location</h6>
      <Row>
        <Col md="4">
          <PostcodeSelectField
            name="mainLocation.postalCode"
            label="Postal Code"
            disabled={disabled}
            loadRightAway={false}
          />
        </Col>
        <Col md="4">
          <FormInputField name="address" label="Address" disabled={disabled} />
        </Col>
        <Col md="4">
          <FormInputField name="address2" label="Address (line 2)" disabled={disabled} />
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <FormInputField name="city" label="City" disabled={disabled} />
        </Col>
        <Col md="4">
          <FormInputField name="county" label="County" disabled={disabled} />
        </Col>
      </Row>
      {mainLocationDataObj?.id && (
        <PendingActionButtons
          data={mainLocationDataObj}
          type={DealerPendingActionTypes.MAIN_LOCATION_CHANGE}
        />
      )}
      <h6>Additional location</h6>
      <FieldArray
        name="locations"
        render={arrayHelpers => (
          <div>
            {locations.map((location, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Row key={index}>
                <Col md="4">
                  <PostcodeSelectField
                    name={`locations.${index}.postalCode`}
                    label="Postal Code"
                    loadRightAway={false}
                  />
                  {err(index, arrayHelpers, 'postalCode')}
                </Col>
                <Col md="4">
                  <FormInputField name={`locations.${index}.address`} label="Address" />
                  {err(index, arrayHelpers, 'address')}
                </Col>
                <Col md="4">
                  <FormInputField
                    name={`locations.${index}.address2`}
                    label="Address (line 2)"
                  />
                  {err(index, arrayHelpers, 'address2')}
                </Col>
                <Col md="4">
                  <FormInputField name={`locations.${index}.city`} label="City" />
                  {err(index, arrayHelpers, 'city')}
                </Col>
                <Col md="4">
                  <FormInputField name={`locations.${index}.county`} label="County" />
                  {err(index, arrayHelpers, 'county')}
                </Col>
                {!isEmpty(additionalLocationDataObj) && (
                  <Col xs="12">
                    <PendingActionButtons
                      data={additionalLocationDataObj}
                      type={DealerPendingActionTypes.ADD_ADDITIONAL_LOCATION}
                      locationId={location.id}
                    />
                  </Col>
                )}
              </Row>
            ))}
            <Row>
              <Button
                color="neutral"
                type="button"
                onClick={() =>
                  arrayHelpers.push({
                    postalCode: '',
                    address: '',
                    city: '',
                    county: '',
                  })
                }
              >
                Add additional location
              </Button>
            </Row>
          </div>
        )}
      />
    </>
  );
};

DealerLocationForm.defaultProps = {
  disabled: false,
  locations: [],
};

DealerLocationForm.propTypes = {
  disabled: PropTypes.bool,
  preparePendingActionObj: PropTypes.func.isRequired,
  locations: PropTypes.array,
};

export default DealerLocationForm;
