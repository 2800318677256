import { FormRegExp } from '../../constants/FormRegExp';
import { poundsToPences } from './poundsToPences';

export const parseCurrencyToIntIfString = value => {
  if (typeof value === 'string') {
    const result = Number(value.replace(FormRegExp.INT_WITHOUT_PREFIX_AND_SPECIAL_CHARS, ''));
    if (!Number.isNaN(result)) return poundsToPences(result);
  }
  return poundsToPences(value);
};
