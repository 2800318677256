export const AuctionStatusType = {
  PHYSICAL: 'PHYSICAL',
  MINIMUM_PRICE: 'MINIMUM_PRICE',
  TRYING_TO_DEAL: 'TRYING_TO_DEAL',
};

export const AuctionStatusTypeName = {
  PHYSICAL: 'Physical Vehicle',
  MINIMUM_PRICE: 'No Reserve',
  TRYING_TO_DEAL: 'Trying to Deal',
};
