import { fromJS } from 'immutable';
import { LoaderStatus } from '../common/components/CommonLoader';

const initialData = fromJS({
  items: [],
  totalItems: 0,
  loaderStatus: LoaderStatus.IN_PROGRESS,
});

export const SEARCH_TRANSACTIONS = 'SEARCH_TRANSACTIONS';
export const SEARCH_TRANSACTIONS_SUCCESS = 'SEARCH_TRANSACTIONS_SUCCESS';
export const SEARCH_TRANSACTIONS_FAILURE = 'SEARCH_TRANSACTIONS_FAILURE';

export default (state = initialData, { type, payload }) => {
  switch (type) {
    case SEARCH_TRANSACTIONS:
      return state.merge({
        loaderStatus: LoaderStatus.IN_PROGRESS,
      });
    case SEARCH_TRANSACTIONS_SUCCESS:
      return state.merge({
        items: payload.items,
        totalItems: payload.totalItems,
        loaderStatus: LoaderStatus.SUCCESS,
      });
    case SEARCH_TRANSACTIONS_FAILURE:
      return state.merge({
        loaderStatus: LoaderStatus.NOT_PENDING,
      });
    default:
      return state;
  }
};
