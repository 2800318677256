import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge } from 'reactstrap';
import { getPendingChangesCount } from './RequestFormChangesActions';

const RequestForChangesBadge = () => {
  const dispatch = useDispatch();
  const totalItems = useSelector(state => state.requests.get('totalItems'));

  useEffect(() => {
    const interval = setInterval(() => dispatch(getPendingChangesCount()), 10000);
    dispatch(getPendingChangesCount());
    return () => {
      clearInterval(interval);
    };
  }, [dispatch]);

  const getLabel = totalItems => (totalItems < 100 ? totalItems : '99+');

  return (
    <>
      {totalItems > 0 ? (
        <Badge color="danger" pill>
          {getLabel(totalItems)}
        </Badge>
      ) : (
        <></>
      )}
    </>
  );
};

export default RequestForChangesBadge;
