import React, { useState } from 'react';
import { Card, CardBody, CardHeader, CardTitle, Table, Row, Col, Button } from 'reactstrap';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import TablePagination from './TablePagination';

const CommonTable = ({
  offset,
  title,
  onOffsetChange,
  data,
  headers,
  ordinal = false,
  rowComponent: RowComponent,
  rowComponentProps = {},
  pagination = true,
  partial = false,
  partialCount = 5,
  pageSize,
  handleRowClick,
  hasRowPointer,
  rowIdPrefix,
}) => {
  const [showMore, setShowMore] = useState(false);

  const handleClick = () => {
    setShowMore(prev => !prev);
  };
  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle tag="h4">{title}</CardTitle>
        </CardHeader>
        <CardBody>
          <Table responsive>
            <thead className="text-primary">
              <tr>
                {ordinal && <th className="text-center">#</th>}
                {headers.map(header => (
                  <th
                    key={header?.key ? header?.key : header?.label}
                    className={header.className}
                  >
                    {header.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {!isEmpty(data.items) &&
                data.items.map((item, index) =>
                  !partial || showMore || (partial && !showMore && index < partialCount) ? (
                    <tr
                      id={rowIdPrefix ? `${rowIdPrefix}-${item.id}` : null}
                      key={item.id}
                      onClick={() => handleRowClick(item)}
                      className={hasRowPointer ? `common-table__row` : ''}
                    >
                      {ordinal && (
                        <td className="text-center">{offset * pageSize + index + 1}</td>
                      )}
                      <RowComponent item={item} {...rowComponentProps} />
                    </tr>
                  ) : null,
                )}
              {isEmpty(data.items) && (
                <tr>
                  <td colSpan={headers.length}>
                    <span>No data</span>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          {pagination && (
            <div className="mt-2">
              {!isEmpty(data.items) && (
                <TablePagination
                  total={data.total}
                  offset={offset}
                  pageSize={pageSize}
                  onOffsetChange={offset => onOffsetChange(offset)}
                />
              )}
            </div>
          )}

          {partial && (
            <Row>
              <Col md="12">
                <Button
                  color="neutral"
                  className="mt-4 pull-right"
                  type="button"
                  onClick={handleClick}
                >
                  {!showMore ? 'more' : 'less'}
                </Button>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
    </>
  );
};

CommonTable.defaultProps = {
  titleTranslated: false,
  offset: 0,
  onOffsetChange: () => {},
  title: '',
  pageSize: 50,
  handleRowClick: () => {},
  hasRowPointer: true,
  rowIdPrefix: null,
};

CommonTable.propTypes = {
  rowIdPrefix: PropTypes.string,
  offset: PropTypes.number,
  title: PropTypes.string,
  onOffsetChange: PropTypes.func,
  data: PropTypes.object.isRequired,
  headers: PropTypes.array.isRequired,
  rowComponent: PropTypes.any.isRequired,
  titleTranslated: PropTypes.bool,
  pageSize: PropTypes.number,
  handleRowClick: PropTypes.func,
  hasRowPointer: PropTypes.bool,
};

export default CommonTable;
