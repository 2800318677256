import { fromJS } from 'immutable';
import { LoaderStatus } from '../common/components/CommonLoader';

const initialData = fromJS({
  adminDetails: {},
  loaderStatus: LoaderStatus.IN_PROGRESS,
  items: [],
  totalItems: 0,
  validateErrors: {},
  oldEmail: '',
});

export const GET_ADMIN = 'GET_ADMIN';
export const GET_ADMIN_SUCCESS = 'GET_ADMIN_SUCCESS';
export const GET_ADMIN_FAILURE = 'GET_ADMIN_FAILURE';
export const CREATE_ADMIN = 'CREATE_ADMIN';
export const CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS';
export const CREATE_ADMIN_FAILURE = 'CREATE_ADMIN_FAILURE';
export const EDIT_ADMIN = 'EDIT_ADMIN';
export const EDIT_ADMIN_SUCCESS = 'EDIT_ADMIN_SUCCESS';
export const EDIT_ADMIN_FAILURE = 'EDIT_ADMIN_FAILURE';
export const SEARCH_ADMIN = 'SEARCH_ADMIN';
export const SEARCH_ADMIN_SUCCESS = 'SEARCH_ADMIN_SUCCESS';
export const SEARCH_ADMIN_FAILURE = 'SEARCH_ADMIN_FAILURE';
export const CLEAR_SEARCH_DATA = 'CLEAR_SEARCH_DATA';

export default (state = initialData, { type, payload }) => {
  switch (type) {
    case GET_ADMIN:
      return state.merge({
        loaderStatus: LoaderStatus.IN_PROGRESS,
      });
    case GET_ADMIN_SUCCESS:
      return state.merge({
        adminDetails: payload.data,
        loaderStatus: LoaderStatus.SUCCESS,
      });
    case GET_ADMIN_FAILURE:
      return state.merge({
        adminDetails: {},
        loaderStatus: LoaderStatus.NOT_PENDING,
      });
    case CREATE_ADMIN:
      return state.merge({
        loaderStatus: LoaderStatus.IN_PROGRESS,
      });
    case CREATE_ADMIN_SUCCESS:
      return state.merge({
        loaderStatus: LoaderStatus.SUCCESS,
      });
    case CREATE_ADMIN_FAILURE:
      return state.merge({
        loaderStatus: LoaderStatus.NOT_PENDING,
        validateErrors: payload.validateErrors,
        oldEmail: payload.email,
      });
    case SEARCH_ADMIN:
      return state.merge({
        loaderStatus: LoaderStatus.IN_PROGRESS,
      });
    case SEARCH_ADMIN_SUCCESS:
      return state.merge({
        loaderStatus: LoaderStatus.SUCCESS,
        items: payload.items,
        totalItems: payload.totalItems,
      });
    case SEARCH_ADMIN_FAILURE:
      return state.merge({
        loaderStatus: LoaderStatus.NOT_PENDING,
      });
    case CLEAR_SEARCH_DATA:
      return state.merge({
        items: [],
      });
    default:
      return state;
  }
};
