import React from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { Field } from 'formik';
import { get } from 'lodash';
import moment from 'moment';
import {
  DATE_FORMAT,
  dateToString,
  REVERSE_DASH_DATE_FORMAT,
} from '../../utils/date/Date.utils';

const FormDatePickerFieldInner = props => {
  const {
    label,
    placeholder,
    form,
    field,
    onChangeHandler,
    disabled,
    isUpperRange,
    readOnly,
    format,
    timeFormat,
    dateFormat,
  } = props;

  const error = form.errors[field.name];
  const touched = form.touched[field.name];
  const value = form.values[field.name] || get(form.values, field.name, '') || '';

  return (
    <Row>
      <Col style={{ 'text-align': 'left' }}>
        <Label>{label}</Label>
        <FormGroup>
          <Datetime
            closeOnSelect={true}
            timeFormat={timeFormat}
            dateFormat={dateFormat}
            inputProps={{
              readOnly,
              placeholder,
              disabled: form.isSubmitting || disabled,
              className: `form-control ${readOnly ? 'form-control--read-only' : ''}`,
            }}
            value={format(value)}
            onBlur={() => form.setFieldTouched(field.name, true)}
            onChange={option => {
              if (isUpperRange) {
                option.add(1, 'd');
                option.subtract(1, 'ms');
              }
              form.setFieldValue(field.name, moment(option).format(REVERSE_DASH_DATE_FORMAT));
              onChangeHandler(option);
            }}
          />
          {(form.submitCount > 0 || touched) && error && (
            <label style={{ color: 'red ' }}>{error}</label>
          )}
        </FormGroup>
      </Col>
    </Row>
  );
};

const FormDatePickerField = props => {
  return <Field {...props} component={FormDatePickerFieldInner} />;
};
FormDatePickerField.defaultProps = {
  placeholder: '',
  options: [],
  onChangeHandler: () => {},
  disabled: false,
  label: '',
  isUpperRange: false,
  readOnly: false,
  timeFormat: false,
  dateFormat: DATE_FORMAT,
  format: value => dateToString(value),
};

FormDatePickerField.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  onChangeHandler: PropTypes.func,
  disabled: PropTypes.bool,
  isUpperRange: PropTypes.bool,
  readOnly: PropTypes.bool,
  timeFormat: PropTypes.bool,
  dateFormat: PropTypes.string,
  format: PropTypes.func,
};

export default FormDatePickerField;
