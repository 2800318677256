import React from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import validationSchema from './ReportsUserSearch.schema';
import { FormDatePickerField, FormSelectField } from '../../common/components/formFields';
import { UserStatus, DealerUserStatusName } from '../../constants/UserStatus';
import { constantToSelect } from '../../common/helpers/constantToSelect';

const initialValues = {
  status: {
    value: UserStatus.ACTIVE,
    label: DealerUserStatusName.ACTIVE,
  },
  registeredDateFrom: '',
  registeredDateTo: '',
};

const userStatuses = constantToSelect(DealerUserStatusName);

const ReportsUserSearch = ({ handleSearch, handleClear }) => {
  return (
    <Col md="12">
      <Card>
        <CardBody>
          <h6>Search report</h6>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSearch}
            validateOnBlur={true}
          >
            {({ resetForm }) => (
              <Form noValidate>
                <Row>
                  <Col md="4">
                    <FormSelectField name="status" label="Status" options={userStatuses} />
                  </Col>
                  <Col md="4">
                    <FormDatePickerField
                      name="registeredDateFrom"
                      label="registered date from"
                    />
                  </Col>
                  <Col md="4">
                    <FormDatePickerField name="registeredDateTo" label="registered date to" />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Button color="primary" className="mt-4 float-right" type="submit">
                      Search
                    </Button>
                    <Button
                      color="primary"
                      outline
                      className="mt-4 float-right"
                      type="button"
                      onClick={() => {
                        resetForm();
                        handleClear();
                      }}
                    >
                      Clear
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </Col>
  );
};

ReportsUserSearch.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
};
export default ReportsUserSearch;
