import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { dateToString } from '../../../common/utils/date/Date.utils';
import { preparePriceFormat } from '../../../helpers/preparePriceFormat';

const DealerTransactionsTableRow = ({ item }) => {
  return (
    <>
      <td>{item?.serial}</td>
      <td>{item?.title}</td>
      <td>{dateToString(item?.createdOn)}</td>
      <td>{preparePriceFormat(item?.amountInPence) || '-'}</td>
      <td>{item?.organization?.tradeName}</td>
    </>
  );
};

DealerTransactionsTableRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default withRouter(DealerTransactionsTableRow);
