export const scrollToElement = (id, offset = 0) => {
  const element = document.querySelector(`#${id}`);
  if (element) {
    const rect = element.getBoundingClientRect();

    window.scrollTo({
      top: rect.top + window.scrollY + offset,
      left: 0,
      behavior: 'smooth',
    });
  }
};
