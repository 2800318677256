import { fromJS } from 'immutable';

const initialData = fromJS({
  tvDashboardData: [],
});

export const FETCH_TV_DASHBOARD_DATA_SUCCESS = 'FETCH_TV_DASHBOARD_DATA_SUCCESS';

export default (state = initialData, { type, payload }) => {
  switch (type) {
    case FETCH_TV_DASHBOARD_DATA_SUCCESS:
      return state.merge({
        tvDashboardData: payload.content,
      });

    default:
      return state;
  }
};
