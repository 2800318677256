import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { get } from 'lodash';
import { Button, Col, Row } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Breadcrumbs from '../../common/components/Breadcrumbs';
import AdminForm from '../form/AdminForm';
import validationSchema from '../form/AdminForm.schema';
import { deleteAdmin, editAdmin, fetchAdmin } from '../AdminsActions';
import CommonLoader from '../../common/components/CommonLoader';
import { capitalizeString } from '../../helpers/capitalizeString';

const breadcrumbsItems = [
  { id: 1, type: 'home' },
  { id: 2, type: 'link', text: 'Admins', url: '/admins' },
  { id: 3, type: 'span', text: 'Edit Admin' },
];

const EditAdminPage = ({ match, adminDetails, fetchAdmin, loaderStatus, editAdmin }) => {
  const dispatch = useDispatch();
  const id = get(match, 'params.id', null);
  const version = get(adminDetails, 'version', null);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    fetchAdmin(id);
  }, [fetchAdmin, id]);

  const handleSubmit = (values, { setSubmitting }) => {
    editAdmin(values, id, version)
      .then(() => setSubmitting(false))
      .catch(() => setSubmitting(false));
  };
  return (
    <div className="content">
      <Row>
        <Col md="9">
          <Breadcrumbs items={breadcrumbsItems} />
        </Col>
        <Col md="3">
          <Button className="float-right" color="danger" onClick={() => setShowAlert(true)}>
            Delete
          </Button>
        </Col>
      </Row>
      <CommonLoader status={loaderStatus}>
        <Formik
          enableReinitialize
          initialValues={{
            ...adminDetails,
            status: {
              value: adminDetails.status,
              label: capitalizeString(adminDetails.status),
            },
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          validateOnBlur={true}
        >
          {() => <AdminForm title="Edit admin" />}
        </Formik>
      </CommonLoader>
      <SweetAlert
        danger
        showCancel
        show={showAlert}
        title="Warning!"
        onConfirm={() => {
          setShowAlert(false);
          dispatch(deleteAdmin(id));
        }}
        onCancel={() => setShowAlert(false)}
        confirmBtnBsStyle="info"
      >
        Are you sure to remove this dealer?
      </SweetAlert>
    </div>
  );
};

const mapStateToProps = ({ admins }) => {
  return {
    adminDetails: admins.get('adminDetails'),
    loaderStatus: admins.get('loaderStatus'),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({ fetchAdmin, editAdmin }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditAdminPage));
