import React, { useState } from 'react';
import { Button, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import * as PropTypes from 'prop-types';

const ReportsActionButtons = ({ dealerName, handleDownload, handleSend }) => {
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  return (
    <>
      <Col className="d-flex justify-content-start">
        <Button
          color="primary"
          className="mt-4 float-right"
          type="submit"
          onClick={() => setIsAlertVisible(true)}
          disabled={!dealerName}
        >
          Send report to the dealer
        </Button>
      </Col>
      <Col className="d-flex justify-content-end">
        <Button
          color="primary"
          className="mt-4 float-right"
          type="submit"
          disabled={!dealerName}
          onClick={handleDownload}
        >
          Download
        </Button>
      </Col>
      <SweetAlert
        info
        showCancel
        show={isAlertVisible}
        title="Are you sure?"
        onConfirm={() => {
          setIsAlertVisible(false);
          handleSend();
        }}
        onCancel={() => setIsAlertVisible(false)}
        confirmBtnBsStyle="info"
      >
        {`Please confirm sending the report to this dealer: ${dealerName}`}
      </SweetAlert>
    </>
  );
};

ReportsActionButtons.propTypes = {
  dealerName: PropTypes.string.isRequired,
  handleDownload: PropTypes.func.isRequired,
  handleSend: PropTypes.func.isRequired,
};

export default ReportsActionButtons;
