import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Router, Switch } from 'react-router-dom';
import AuthLayout from 'layouts/Auth.js';
import 'bootstrap/dist/css/bootstrap.css';
import 'assets/scss/paper-dashboard.scss';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { Provider } from 'react-redux';
import RouterPaths from './constants/RouterPaths';
import { setUserInSession } from './auth/AuthActions';
import { prepareStore } from './redux/Store';
import Layout from './layouts/MainLayout';
import history from './browserHistory';
import AlertContainer from './common/components/alert/AlertContainer';
import './App.css';
import ForgotPasswordLayout from './layouts/ForgotPassword';
import ConfirmForgotPasswordLayout from './layouts/ConfirmForgotPassword';
import ResetPasswordLayout from './layouts/ResetPassword';
import AuthRefreshToken from './auth/AuthRefreshToken';

import SageRedirect from './settings/SageRedirect';

export const store = prepareStore();
setUserInSession(store.dispatch);

history.listen(() => {
  window.scrollTo(0, 0);
});

ReactDOM.render(
  <Provider store={store}>
    <AuthRefreshToken />
    <div className="wrapper wrapper-full-page">
      <Router history={history}>
        <Switch>
          <Route path={RouterPaths.LOGIN} render={props => <AuthLayout {...props} />} exact />
          <Route
            path={RouterPaths.FORGOT_PASSWORD}
            render={props => <ForgotPasswordLayout {...props} />}
            exact
          />
          <Route
            path={RouterPaths.CONFIRM_FORGOT_PASSWORD}
            render={props => <ConfirmForgotPasswordLayout {...props} />}
            exact
          />
          <Route
            path={RouterPaths.RESET_PASSWORD}
            render={props => <ResetPasswordLayout {...props} />}
            exact
          />
          <Route path={RouterPaths.SAGE_AUTH} render={() => <SageRedirect />} />
          <Route path={RouterPaths.MAIN} render={props => <Layout {...props} />} />u
        </Switch>
        <AlertContainer />
      </Router>
    </div>
  </Provider>,
  document.getElementById('root'),
);
