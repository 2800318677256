import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FormSelectField } from '../../../common/components/formFields';
import { constantToSelect } from '../../../common/helpers';
import { PaymentMethodsName } from '../../../constants/PaymentMethods';
import FeeOption from './fees/FeeOption';

const paymentMethodsName = constantToSelect(PaymentMethodsName);
const FEE_VALIDATION_ERRORS_OBJECT_KEY_PREFIX = 'organizationForm.organizationFees';

const DealerPaymentForm = ({ disabled, values }) => {
  const validationErrors = useSelector(state => state.dealers.get('validationErrors'));

  return (
    <>
      <h6>Fees</h6>
      <Row>
        <Col md="4">
          <FormSelectField
            name="paymentMethod"
            label="Payment Method"
            options={paymentMethodsName}
            disabled={disabled}
          />
        </Col>
      </Row>
      <FeeOption
        name="monthlySubscription"
        label="Monthly Subscription"
        disabled={disabled}
        values={values}
        validationMessage={
          validationErrors?.[
            `${FEE_VALIDATION_ERRORS_OBJECT_KEY_PREFIX}.monthlySubscriptionInPence`
          ]
        }
      />
      <FeeOption
        name="launch"
        label="Launch Fee"
        disabled={disabled}
        values={values}
        validationMessage={
          validationErrors?.[`${FEE_VALIDATION_ERRORS_OBJECT_KEY_PREFIX}.launchInPence`]
        }
      />
      <FeeOption
        name="saleForLessThan1000"
        label="Sale £0 - £1,000"
        disabled={disabled}
        values={values}
        validationMessage={
          validationErrors?.[
            `${FEE_VALIDATION_ERRORS_OBJECT_KEY_PREFIX}.saleForLessThan1000InPence`
          ]
        }
      />
      <FeeOption
        name="saleBetween1001And2500"
        label="Sale £1,001 - £2,500"
        disabled={disabled}
        values={values}
        validationMessage={
          validationErrors?.[
            `${FEE_VALIDATION_ERRORS_OBJECT_KEY_PREFIX}.saleBetween1001And2500InPence`
          ]
        }
      />
      <FeeOption
        name="saleBetween2501And5000"
        label="Sale £2,501 - £5,000"
        disabled={disabled}
        values={values}
        validationMessage={
          validationErrors?.[
            `${FEE_VALIDATION_ERRORS_OBJECT_KEY_PREFIX}.saleBetween2501And5000InPence`
          ]
        }
      />
      <FeeOption
        name="saleBetween5001And10000"
        label="Sale £5,001 - £10,000"
        disabled={disabled}
        values={values}
        validationMessage={
          validationErrors?.[
            `${FEE_VALIDATION_ERRORS_OBJECT_KEY_PREFIX}.saleBetween5001And10000InPence`
          ]
        }
      />
      <FeeOption
        name="saleBetween10001And20000"
        label="Sale £10,001 - £20,000"
        disabled={disabled}
        values={values}
        validationMessage={
          validationErrors?.[
            `${FEE_VALIDATION_ERRORS_OBJECT_KEY_PREFIX}.saleBetween10001And20000InPence`
          ]
        }
      />
      <FeeOption
        name="saleForMoreThan20000"
        label="Sale Over £20,000"
        disabled={disabled}
        values={values}
        validationMessage={
          validationErrors?.[
            `${FEE_VALIDATION_ERRORS_OBJECT_KEY_PREFIX}.saleForMoreThan20000InPence`
          ]
        }
      />
      <FeeOption
        name="purchaseForLessThan1000"
        label="Purchase £0 - £1,000"
        disabled={disabled}
        values={values}
        validationMessage={
          validationErrors?.[
            `${FEE_VALIDATION_ERRORS_OBJECT_KEY_PREFIX}.purchaseForLessThan1000InPence`
          ]
        }
      />
      <FeeOption
        name="purchaseBetween1001And2500"
        label="Purchase £1,001 - £2,500"
        disabled={disabled}
        values={values}
        validationMessage={
          validationErrors?.[
            `${FEE_VALIDATION_ERRORS_OBJECT_KEY_PREFIX}.purchaseBetween1001And2500InPence`
          ]
        }
      />
      <FeeOption
        name="purchaseBetween2501And5000"
        label="Purchase £2,501 - £5,000"
        disabled={disabled}
        values={values}
        validationMessage={
          validationErrors?.[
            `${FEE_VALIDATION_ERRORS_OBJECT_KEY_PREFIX}.purchaseBetween2501And5000InPence`
          ]
        }
      />
      <FeeOption
        name="purchaseBetween5001And10000"
        label="Purchase £5,001 - £10,000"
        disabled={disabled}
        values={values}
        validationMessage={
          validationErrors?.[
            `${FEE_VALIDATION_ERRORS_OBJECT_KEY_PREFIX}.purchaseBetween5001And10000InPence`
          ]
        }
      />
      <FeeOption
        name="purchaseBetween10001And20000"
        label="Purchase £10,001 - £20,000"
        disabled={disabled}
        values={values}
        validationMessage={
          validationErrors?.[
            `${FEE_VALIDATION_ERRORS_OBJECT_KEY_PREFIX}.purchaseBetween10001And20000InPence`
          ]
        }
      />
      <FeeOption
        name="purchaseForMoreThan20000"
        label="Purchase Over £20,000"
        disabled={disabled}
        values={values}
        validationMessage={
          validationErrors?.[
            `${FEE_VALIDATION_ERRORS_OBJECT_KEY_PREFIX}.purchaseForMoreThan20000InPence`
          ]
        }
      />
    </>
  );
};

DealerPaymentForm.defaultProps = {
  disabled: false,
};

DealerPaymentForm.propTypes = {
  disabled: PropTypes.bool,
  values: PropTypes.object.isRequired,
};

export default DealerPaymentForm;
