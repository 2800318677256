export const UserPosition = {
  OWNER: 'OWNER',
  MANAGING_DIRECTOR: 'MANAGING_DIRECTOR',
  OPERATIONS_DIRECTOR: 'OPERATIONS_DIRECTOR',
  DEALER_PRINCIPLE: 'DEALER_PRINCIPLE',
  HEAD_OF_BUSINESS: 'HEAD_OF_BUSINESS',
  GENERAL_MANAGER: 'GENERAL_MANAGER',
  SALES_MANAGER: 'SALES_MANAGER',
  SALES_CONTROLLER: 'SALES_CONTROLLER',
  SALES_EXECUTIVE: 'SALES_EXECUTIVE',
  TRADE_DISPOSALS: 'TRADE_DISPOSALS',
  GROUP_BUYER: 'GROUP_BUYER',
  BUYER: 'BUYER',
};

export const UserPositionName = {
  OWNER: 'Owner',
  MANAGING_DIRECTOR: 'Managing Director',
  OPERATIONS_DIRECTOR: 'Operations Director',
  DEALER_PRINCIPLE: 'Dealer Principle',
  HEAD_OF_BUSINESS: 'Head Of Business',
  GENERAL_MANAGER: 'General Manager',
  SALES_MANAGER: 'Sales Manager',
  SALES_CONTROLLER: 'Sales Controller',
  SALES_EXECUTIVE: 'Sales Executive',
  TRADE_DISPOSALS: 'Trade Disposals',
  GROUP_BUYER: 'Group Buyer',
  BUYER: 'Buyer',
};
