import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label } from 'reactstrap';
import { Field } from 'formik';

const FormGroupRadioFieldInner = props => {
  const { form, field, radios, disabled, formClassName } = props;

  const error = form.errors[field.name];
  const touched = form.touched[field.name];
  const value = form.values[field.name] || '';

  return (
    <>
      {radios.map(radio => (
        <FormGroup
          inline
          disabled={form.isSubmitting}
          key={radio.label}
          className={formClassName}
        >
          <Label check>
            <Input
              type="radio"
              name={field.name}
              value={radio.value}
              onBlur={() => form.setFieldTouched(field.name, true)}
              onChange={() => {
                form.setFieldValue(field.name, radio.value);
              }}
              checked={radio.value === value}
              disabled={form.isSubmitting || disabled}
            />
            {radio.label}
          </Label>
        </FormGroup>
      ))}
      {(form.submitCount > 0 || touched) && error && (
        <label style={{ color: 'red ' }}>{error}</label>
      )}
    </>
  );
};

const FormGroupRadioField = props => {
  return <Field {...props} component={FormGroupRadioFieldInner} />;
};

FormGroupRadioField.defaultProps = {
  radios: PropTypes.array.isRequired,
  formClassName: null,
};

export default FormGroupRadioField;
