import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { FormAsyncSelectField } from '../../../../common/components/formFields';
import { getGroups } from './GroupActions';
import { dataToSelect } from '../../../../common/helpers';

const GroupSelectField = props => {
  const dispatch = useDispatch();

  const loadOptions = (inputValue, callback) => {
    dispatch(
      getGroups(inputValue, groups => {
        callback(dataToSelect(groups.data.content, ['id', 'name']));
      }),
    );
    return true;
  };

  return (
    <FormAsyncSelectField
      {...props}
      loadOptions={loadOptions}
      loadRightAway={props?.loadRightAway}
      defaultOptions
    />
  );
};

GroupSelectField.defaultProps = {
  loadRightAway: true,
};

GroupSelectField.propTypes = {
  loadRightAway: PropTypes.bool,
};

export default GroupSelectField;
