import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';

const GroupFormTableRow = ({ item, showRemoveItemAlert }) => {
  return (
    <>
      <td>{item.tradeName || item.name}</td>
      <td className="text-right">
        <Button
          className="btn-link"
          color="primary"
          id={`tooltip${item.id}`}
          size="sm"
          type="button"
          onClick={() => showRemoveItemAlert(item)}
        >
          <i className="fa fa-trash" />
        </Button>
        <UncontrolledTooltip
          className="uncontrolled-tooltip"
          delay={0}
          target={`tooltip${item.id}`}
        >
          Remove
        </UncontrolledTooltip>
      </td>
    </>
  );
};

export default GroupFormTableRow;
