import * as Yup from 'yup';
import { FormRegExp } from '../../../constants/FormRegExp';
import { ValidationMessage } from '../../../constants/ValidationMessage';

export default () => {
  const {
    FIELD_MAX_8,
    FIELD_MAX_10,
    FIELD_MAX_12,
    FIELD_MAX_15,
    FIELD_MAX_50,
    FIELD_MAX_100,
    FIELD_MAX_200,
  } = ValidationMessage;

  return Yup.object().shape({
    officialCompanyName: Yup.string()
      .required('Field is required')
      .max(50, FIELD_MAX_50),
    tradeName: Yup.string().max(50, FIELD_MAX_50),
    vatNumber: Yup.string()
      .required('Field is required')
      .matches(FormRegExp.INTEGER, 'Type only numbers')
      .min(9, 'Min length equals 9 characters')
      .max(12, FIELD_MAX_12),
    companyRegistrationNumber: Yup.string()
      .matches(FormRegExp.ALPHANUMERIC_WITHOUT_SPACES_UPPERCASE, 'Type correct characters')
      .min(6, 'Min length equals 6 characters')
      .max(8, FIELD_MAX_8)
      .nullable(),
    role: Yup.string().required('Field is required'),
    website: Yup.string()
      .matches(FormRegExp.URL_NO_HTTPS, ValidationMessage.WEBSITE_ADDRESS)
      .max(200, FIELD_MAX_200)
      .nullable(),
    postcode: Yup.string().required('Field is required'),
    address: Yup.string()
      .required('Field is required')
      .max(100, FIELD_MAX_100),
    address2: Yup.string()
      .max(100, FIELD_MAX_100)
      .nullable(),
    city: Yup.string()
      .required('Field is required')
      .max(100, FIELD_MAX_100),
    county: Yup.string()
      .required('Field is required')
      .max(100, FIELD_MAX_100)
      .nullable(),
    firstName: Yup.string()
      .required('Field is required')
      .matches(FormRegExp.STRING_WITH_SPECIAL_CHARS, 'Type correct characters')
      .max(50, FIELD_MAX_50),
    lastName: Yup.string()
      .required('Field is required')
      .matches(FormRegExp.STRING_WITH_SPECIAL_CHARS, 'Type correct characters')
      .max(50, FIELD_MAX_50),
    email: Yup.string()
      .required('Field is required')
      .email('Email has the wrong format')
      .max(50, FIELD_MAX_50),
    landlinePhoneNumber: Yup.string()
      .matches(FormRegExp.INTEGER, 'Type only numbers')
      .nullable()
      .max(15, FIELD_MAX_15),
    phoneNumber: Yup.string()
      .required('Field is required')
      .matches(FormRegExp.INTEGER, 'Type only numbers')
      .max(15, FIELD_MAX_15),
    mailchimpId: Yup.string().nullable(),
    isBuyer: Yup.string().test({
      message: 'Minimum one role is required',
      test(value) {
        const isSeller = !(
          this.parent.isSeller === undefined || this.parent.isSeller === 'false'
        );
        const isBuyer = !(value === undefined || value === 'false');

        return isSeller || isBuyer;
      },
    }),
    isSeller: Yup.string().test({
      message: 'Minimum one role is required',
      test(value) {
        const isSeller = !(value === undefined || value === 'false');
        const isBuyer = !(
          this.parent.isBuyer === undefined || this.parent.isBuyer === 'false'
        );

        return isSeller || isBuyer;
      },
    }),
    paymentMethod: Yup.string().required('Field is required'),
    monthlySubscriptionInPence: Yup.string().max(14, FIELD_MAX_10),
    monthlySubscriptionFeeExemption: Yup.object().shape({
      label: Yup.string().required('Field is required'),
      value: Yup.bool().oneOf([true, false], 'Field is required'),
    }),
    monthlySubscriptionFeeExemptionTo: Yup.string()
      .when('monthlySubscriptionFeeExemption', {
        is: field => field.value === true,
        then: Yup.string().required('Field is required'),
      })
      .nullable(),
    launchInPence: Yup.string().max(14, FIELD_MAX_10),
    launchFeeExemption: Yup.object().shape({
      label: Yup.string().required('Field is required'),
      value: Yup.bool().oneOf([true, false], 'Field is required'),
    }),
    launchFeeExemptionTo: Yup.string()
      .when('launchFeeExemption', {
        is: field => field.value === true,
        then: Yup.string().required('Field is required'),
      })
      .nullable(),
    saleForLessThan1000InPence: Yup.string().max(14, FIELD_MAX_10),
    saleForLessThan1000FeeExemption: Yup.object().shape({
      label: Yup.string().required('Field is required'),
      value: Yup.bool().oneOf([true, false], 'Field is required'),
    }),
    saleForLessThan1000FeeExemptionTo: Yup.string()
      .when('saleForLessThan1000FeeExemption', {
        is: field => field.value === true,
        then: Yup.string().required('Field is required'),
      })
      .nullable(),
    saleBetween1001And2500InPence: Yup.string().max(14, FIELD_MAX_10),
    saleBetween1001And2500FeeExemption: Yup.object().shape({
      label: Yup.string().required('Field is required'),
      value: Yup.bool().oneOf([true, false], 'Field is required'),
    }),
    saleBetween1001And2500FeeExemptionTo: Yup.string()
      .when('saleBetween1001And2500FeeExemption', {
        is: field => field.value === true,
        then: Yup.string().required('Field is required'),
      })
      .nullable(),
    saleBetween2501And5000InPence: Yup.string().max(14, FIELD_MAX_10),
    saleBetween2501And5000FeeExemption: Yup.object().shape({
      label: Yup.string().required('Field is required'),
      value: Yup.bool().oneOf([true, false], 'Field is required'),
    }),
    saleBetween2501And5000FeeExemptionTo: Yup.string()
      .when('saleBetween2501And5000FeeExemption', {
        is: field => field.value === true,
        then: Yup.string().required('Field is required'),
      })
      .nullable(),
    saleBetween5001And10000InPence: Yup.string().max(14, FIELD_MAX_10),
    saleBetween5001And10000FeeExemption: Yup.object().shape({
      label: Yup.string().required('Field is required'),
      value: Yup.bool().oneOf([true, false], 'Field is required'),
    }),
    saleBetween5001And10000FeeExemptionTo: Yup.string()
      .when('saleBetween5001And10000FeeExemption', {
        is: field => field.value === true,
        then: Yup.string().required('Field is required'),
      })
      .nullable(),
    saleBetween10001And20000InPence: Yup.string().max(14, FIELD_MAX_10),
    saleBetween10001And20000FeeExemption: Yup.object().shape({
      label: Yup.string().required('Field is required'),
      value: Yup.bool().oneOf([true, false], 'Field is required'),
    }),
    saleBetween10001And20000FeeExemptionTo: Yup.string()
      .when('saleBetween10001And20000FeeExemption', {
        is: field => field.value === true,
        then: Yup.string().required('Field is required'),
      })
      .nullable(),
    saleForMoreThan20000InPence: Yup.string().max(14, FIELD_MAX_10),
    saleForMoreThan20000FeeExemption: Yup.object().shape({
      label: Yup.string().required('Field is required'),
      value: Yup.bool().oneOf([true, false], 'Field is required'),
    }),
    saleForMoreThan20000FeeExemptionTo: Yup.string()
      .when('saleForMoreThan20000FeeExemption', {
        is: field => field.value === true,
        then: Yup.string().required('Field is required'),
      })
      .nullable(),
    purchaseForLessThan1000InPence: Yup.string().max(14, FIELD_MAX_10),
    purchaseForLessThan1000FeeExemption: Yup.object().shape({
      label: Yup.string().required('Field is required'),
      value: Yup.bool().oneOf([true, false], 'Field is required'),
    }),
    purchaseForLessThan1000FeeExemptionTo: Yup.string()
      .when('purchaseForLessThan1000FeeExemption', {
        is: field => field.value === true,
        then: Yup.string().required('Field is required'),
      })
      .nullable(),
    purchaseBetween1001And2500InPence: Yup.string().max(14, FIELD_MAX_10),
    purchaseBetween1001And2500FeeExemption: Yup.object().shape({
      label: Yup.string().required('Field is required'),
      value: Yup.bool().oneOf([true, false], 'Field is required'),
    }),
    purchaseBetween1001And2500FeeExemptionTo: Yup.string()
      .when('purchaseBetween1001And2500FeeExemption', {
        is: field => field.value === true,
        then: Yup.string().required('Field is required'),
      })
      .nullable(),
    purchaseBetween2501And5000InPence: Yup.string().max(14, FIELD_MAX_10),
    purchaseBetween2501And5000FeeExemption: Yup.object().shape({
      label: Yup.string().required('Field is required'),
      value: Yup.bool().oneOf([true, false], 'Field is required'),
    }),
    purchaseBetween2501And5000FeeExemptionTo: Yup.string()
      .when('purchaseBetween2501And5000FeeExemption', {
        is: field => field.value === true,
        then: Yup.string().required('Field is required'),
      })
      .nullable(),
    purchaseBetween5001And10000InPence: Yup.string().max(14, FIELD_MAX_10),
    purchaseBetween5001And10000FeeExemption: Yup.object().shape({
      label: Yup.string().required('Field is required'),
      value: Yup.bool().oneOf([true, false], 'Field is required'),
    }),
    purchaseBetween5001And10000FeeExemptionTo: Yup.string()
      .when('purchaseBetween5001And10000FeeExemption', {
        is: field => field.value === true,
        then: Yup.string().required('Field is required'),
      })
      .nullable(),
    purchaseBetween10001And20000InPence: Yup.string().max(14, FIELD_MAX_10),
    purchaseBetween10001And20000FeeExemption: Yup.object().shape({
      label: Yup.string().required('Field is required'),
      value: Yup.bool().oneOf([true, false], 'Field is required'),
    }),
    purchaseBetween10001And20000FeeExemptionTo: Yup.string()
      .when('purchaseBetween10001And20000FeeExemption', {
        is: field => field.value === true,
        then: Yup.string().required('Field is required'),
      })
      .nullable(),
    purchaseForMoreThan20000InPence: Yup.string().max(14, FIELD_MAX_10),
    purchaseForMoreThan20000FeeExemption: Yup.object().shape({
      label: Yup.string().required('Field is required'),
      value: Yup.bool().oneOf([true, false], 'Field is required'),
    }),
    purchaseForMoreThan20000FeeExemptionTo: Yup.string()
      .when('purchaseForMoreThan20000FeeExemption', {
        is: field => field.value === true,
        then: Yup.string().required('Field is required'),
      })
      .nullable(),
    mainLocation: Yup.object().shape({
      postalCode: Yup.string()
        .required('Field is required')
        .nullable(),
    }),
    locations: Yup.array().of(
      Yup.object().shape({
        postalCode: Yup.string().required('Field is required'),
        address: Yup.string()
          .required('Field is required')
          .max(100, FIELD_MAX_100),
        address2: Yup.string()
          .max(100, FIELD_MAX_100)
          .nullable(),
        city: Yup.string()
          .required('Field is required')
          .max(100, FIELD_MAX_100),
        county: Yup.string()
          .required('Field is required')
          .max(100, FIELD_MAX_100)
          .nullable(),
      }),
    ),
    additionalEmails: Yup.array().of(
      Yup.object().shape({
        email: Yup.string()
          .required('Field is required')
          .email('Email has the wrong format')
          .max(50, FIELD_MAX_50),
      }),
    ),
    additionalSalesEmails: Yup.array().of(
      Yup.object().shape({
        email: Yup.string()
          .required('Field is required')
          .email('Email has the wrong format')
          .max(50, FIELD_MAX_50),
      }),
    ),
  });
};
