import { get } from 'lodash';
import { prepareSearchData, SORT_DESC } from '../helpers/prepareSearchData';
import { apiClient, handleError } from '../api/ApiClient';

import {
  SEARCH_TRANSACTIONS,
  SEARCH_TRANSACTIONS_FAILURE,
  SEARCH_TRANSACTIONS_SUCCESS,
} from './TransactionsReducer';

const DEALER_ENDPOINT = '/dealer';

export const searchTransactions = values => dispatch => {
  const preparedValues = {
    ...values,
    feeInPence: values.feeInPence ? String(Number(values?.feeInPence) * 100) : '',
  };

  return apiClient
    .post(
      `${DEALER_ENDPOINT}/transactions`,
      prepareSearchData(preparedValues, { createdOn: SORT_DESC }),
    )
    .then(res => {
      dispatch({ type: SEARCH_TRANSACTIONS });
      if (res.status === 200) {
        dispatch({
          type: SEARCH_TRANSACTIONS_SUCCESS,
          payload: {
            items: get(res, 'data.data.content', []),
            totalItems: get(res, 'data.data.totalElements', 0),
          },
        });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
      dispatch({ type: SEARCH_TRANSACTIONS_FAILURE });
    });
};
