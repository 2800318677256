import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Player, ControlBar, BigPlayButton } from 'video-react';

const VideoPreviewModal = ({ isOpen, toggleModal, video }) => {
  return (
    video && (
      <Modal
        size="full-page"
        isOpen={isOpen}
        toggle={toggleModal}
        centered={true}
        className="damage-modal video-preview-modal"
      >
        <div className="modal-header justify-content-center video-preview-modal-body">
          <button
            type="button"
            className="photo_gallery_close_button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={toggleModal}
          >
            <span aria-hidden="true">X</span>
          </button>
        </div>
        <ModalBody style={{ backgroundColor: 'black' }}>
          <div className="video-react_popup_slider">
            <Player src={video}>
              <BigPlayButton position="center" />
              <ControlBar autoHide={false} className="my-class" />
            </Player>
          </div>
        </ModalBody>
        <ModalFooter className="video-preview-modal-body" />
      </Modal>
    )
  );
};

VideoPreviewModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  video: PropTypes.string,
};

VideoPreviewModal.defaultProps = {
  video: '',
};
export default VideoPreviewModal;
