import React from 'react';
import * as PropTypes from 'prop-types';

const CommonErrorLabel = ({ value, classNames }) => {
  return <>{value && <label className={`error-label ${classNames}`}>{value}</label>}</>;
};

CommonErrorLabel.defaultProps = {
  value: '',
  classNames: '',
};

CommonErrorLabel.propTypes = {
  value: PropTypes.string,
  classNames: PropTypes.string,
};

export default CommonErrorLabel;
