import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import * as PropTypes from 'prop-types';
import CommonTable from '../../common/components/CommonTable';
import GroupFormTableRow from './GroupFormTableRow';

const headers = [
  {
    label: 'Name',
  },
  {
    label: 'Actions',
    className: 'text-right',
  },
];

let removeDealer;

const GroupFormTable = ({
  data,
  offset,
  changeOffset,
  removeItemHandler,
  isCreateGroupForm,
}) => {
  const [showAlert, setShowAlert] = useState(false);
  const showRemoveItemAlert = dealer => {
    removeDealer = dealer;
    setShowAlert(true);
  };

  return (
    <>
      <Row>
        <Col xs="12">
          <CommonTable
            rowComponent={GroupFormTableRow}
            rowComponentProps={{ showRemoveItemAlert }}
            data={data}
            offset={offset}
            onOffsetChange={changeOffset}
            headers={headers}
            title="Dealers"
            ordinal
            pagination={!isCreateGroupForm}
            hasRowPointer={false}
          />
        </Col>
      </Row>
      <SweetAlert
        danger
        showCancel
        show={showAlert}
        style={{ display: 'block', marginTop: '-100px' }}
        title="Warning!"
        onConfirm={() => {
          setShowAlert(false);
          removeItemHandler(removeDealer);
        }}
        onCancel={() => setShowAlert(false)}
        confirmBtnBsStyle="info"
      >
        Are you sure to remove this dealer?
      </SweetAlert>
    </>
  );
};

GroupFormTable.propTypes = {
  data: PropTypes.object.isRequired,
  offset: PropTypes.number.isRequired,
  changeOffset: PropTypes.func.isRequired,
  removeItemHandler: PropTypes.func.isRequired,
};

export default GroupFormTable;
