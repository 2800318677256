import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { DealerPendingActionTypes } from 'constants/DealerPendingActionTypes';
import { editDealerOrganization } from '../DealersActions';
import { DealerStatus } from '../../constants/DealerStatus';

const PendingActionButtons = ({ data, type, locationId }) => {
  const dispatch = useDispatch();

  if (type === DealerPendingActionTypes.ADD_ADDITIONAL_LOCATION && locationId) {
    data = data.find(item => item.value === locationId);
  }

  const pendingFunction = status => {
    dispatch(
      editDealerOrganization(data.id, {
        status,
        version: data.version,
      }),
    );
  };

  let newValue = '';

  if (
    type === DealerPendingActionTypes.MAIN_LOCATION_CHANGE &&
    typeof data?.value === 'object'
  ) {
    newValue = `${data?.value?.postalCode.postcode} | ${data?.value?.address} | ${
      data?.value?.address2 ? `${data?.value?.address2} |` : '(empty value) |'
    } ${data?.value?.city} | ${data?.value?.county}`;
  }

  if (type === DealerPendingActionTypes.PHONE_NUMBER_CHANGE) {
    newValue = data?.value;
  }

  if (type === DealerPendingActionTypes.LANDLINE_PHONE_NUMBER_CHANGE) {
    newValue = data?.value === null ? '-' : data?.value;
  }

  return (
    <>
      {get(data, 'id', '') && (
        <div className="pending-action-buttons">
          {newValue && <p className="pending-action-value">{newValue}</p>}
          <Button
            className="pending-action-button"
            type="button"
            color="success"
            onClick={() => pendingFunction(DealerStatus.ACTIVE)}
          >
            <i className="fas fa-check" />
          </Button>
          <Button
            className="pending-action-button"
            type="button"
            color="danger"
            onClick={() => pendingFunction(DealerStatus.REJECTED)}
          >
            <i className="fas fa-times" />
          </Button>
        </div>
      )}
    </>
  );
};

PendingActionButtons.defaultProps = {
  data: null,
  type: '',
  locationId: null,
};

PendingActionButtons.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  type: PropTypes.string,
  locationId: PropTypes.string,
};

export default PendingActionButtons;
