import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../../common/components/Breadcrumbs';
import VehicleSectionsNav from '../VehicleSectionsNav';
import AddVehicleNoteForm from './AddVehicleNoteForm';
import VehicleNotesTable from './VehicleNotesTable';
import { useQueryString } from '../../common/helpers/queryString';
import { fetchAuctionNotes } from '../VehicleActions';

const breadcrumbsItems = [
  { id: 1, type: 'home' },
  { id: 2, type: 'link', text: 'Vehicles', url: '/vehicles' },
  { id: 3, type: 'span', text: 'Vehicle notes' },
];

const VehiclesNotesPage = () => {
  const query = useQueryString();
  const dispatch = useDispatch();
  const { id: auctionId } = useParams();
  const auctionNotes = useSelector(state => state.dealers.get('auctionNotes'));
  const itemsNotes = auctionNotes?.items || null;
  const totalItemsNotes = auctionNotes?.totalItems;
  const [searchParams, setSearchParams] = useState({
    offset: query.get('page') ? +query.get('page') - 1 : 0,
  });
  const changeOffsetNotes = value => {
    setSearchParams(prevState => ({ ...prevState, offset: value }));
  };

  useEffect(() => {
    if (auctionId) {
      dispatch(fetchAuctionNotes(searchParams, auctionId));
    }
  }, [dispatch, searchParams]);

  return (
    <div className="content">
      <Breadcrumbs items={breadcrumbsItems} />
      <VehicleSectionsNav section="notes" />
      <Card>
        <CardBody>
          <h6>Vehicle notes</h6>
          <AddVehicleNoteForm searchParams={searchParams} />
          <VehicleNotesTable
            data={{ items: itemsNotes, total: totalItemsNotes }}
            offset={searchParams.offset}
            changeOffset={changeOffsetNotes}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default VehiclesNotesPage;
