import jwt from 'jsonwebtoken';
import history from 'browserHistory.js';
import { apiClient, setAuthorizationHeader, handleError } from '../api/ApiClient';
import { LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT } from './AuthReducer';
import { AUTH_TOKEN } from './AuthConstants';
import RouterPaths from '../constants/RouterPaths';
import { AuthApiEndpoints } from './AuthApiEndpoints';
import { isDateBefore } from '../common/utils/date/Date.utils';

/**
 *
 * @param email
 * @param password
 * @param failureCallback
 * @returns {function(*=): Promise<AxiosResponse<any>>}
 */

export const login = (email, password) => dispatch => {
  clearToken();
  return apiClient
    .post('/auth/login', { email, password })
    .then(res => {
      const { token } = res?.data?.data;
      if (res.status === 200 && token) {
        localStorage.setItem(AUTH_TOKEN, token);
        setUserInSession(dispatch);
        history.push('/');
      } else {
        dispatch({ type: LOGIN_FAILURE });
      }
    })
    .catch(err => dispatch(handleError(err)));
};

/**
 *
 * @returns {function(*): Promise<void>}
 */
export const refreshToken = () => dispatch =>
  apiClient
    .post('/auth/refresh-token', {})
    .then(res => {
      const {
        data: { token },
      } = res.data;
      if (res.status === 200 && token) {
        localStorage.setItem(AUTH_TOKEN, token);
        setUserInSession(dispatch);
      }
    })
    .catch(() => {});

/**
 *
 * @param dispatch
 */
export const setUserInSession = dispatch => {
  const authToken = localStorage.getItem(AUTH_TOKEN);
  if (authToken) {
    const decoded = jwt.decode(authToken);
    const tokenExpDateInMilliseconds = decoded?.exp * 1000;
    const currentDateInMilliseconds = new Date().getTime();
    if (isDateBefore(tokenExpDateInMilliseconds, currentDateInMilliseconds)) {
      dispatch(logout());
    } else {
      dispatch({ type: LOGIN_SUCCESS, payload: decoded });
    }
  }
  setAuthorizationHeader(authToken, () => dispatch(logout()));
};

/**
 *
 */
function deleteTokens() {
  localStorage.removeItem(AUTH_TOKEN);
}

/**
 *
 * @returns {function(...[*]=)}
 */
export const logout = () => dispatch => {
  deleteTokens();
  dispatch({ type: LOGOUT });
  setAuthorizationHeader(null, () => {});
};

export const forgotPassword = ({ email }, failureCallback) => dispatch =>
  apiClient
    .post(AuthApiEndpoints.PASSWORD_RESET_EMAIL, { email })
    .then(res => {
      if (res.status === 200) {
        history.push(RouterPaths.CONFIRM_FORGOT_PASSWORD);
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    })
    .catch(failureCallback());

export const resetPassword = (
  { password, passwordRepeat, token },
  failureCallback,
) => dispatch => {
  apiClient
    .post(AuthApiEndpoints.PASSWORD_RESET_PASSWORD, {
      password,
      passwordRepeat,
      token,
    })
    .then(res => {
      if (res.status === 200) {
        history.push(RouterPaths.LOGIN);
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    })
    .catch(failureCallback());
};

export const clearToken = () => {
  deleteTokens();
  setAuthorizationHeader(null, () => {});
};
