import { PerspectiveType } from '../constants/PerspectiveType';

export const checkPhotoPerspective = photo => {
  switch (photo.perspective) {
    case PerspectiveType.MASTER:
      return 0;
    case PerspectiveType.LEFT_FRONT:
      return 1;
    case PerspectiveType.LEFT_SIDE:
      return 2;
    case PerspectiveType.LEFT_REAR:
      return 3;
    case PerspectiveType.REAR:
      return 4;
    case PerspectiveType.RIGHT_REAR:
      return 5;
    case PerspectiveType.RIGHT_SIDE:
      return 6;
    case PerspectiveType.RIGHT_FRONT:
      return 7;
    case PerspectiveType.INTERIOR_1:
      return 8;
    case PerspectiveType.INTERIOR_2:
      return 9;
    case PerspectiveType.INTERIOR_3:
      return 10;
    case PerspectiveType.INTERIOR_4:
      return 11;
    default:
      return null;
  }
};
