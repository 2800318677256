import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { reverse } from 'named-urls';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CommonTable from '../../common/components/CommonTable';
import VehiclesTableRow from './VehiclesTableRow';
import RouterPaths from '../../constants/RouterPaths';
import { SET_SELECTED_VEHICLE, SET_VEHICLES_SEARCH_PARAMS } from '../VehicleReducer';
import CommonLoader from '../../common/components/CommonLoader';

const headers = [
  {
    label: 'Number plate',
  },
  {
    label: 'Title',
  },
  {
    label: 'Registered date',
  },
  {
    label: 'Launch date',
  },
  {
    label: 'Vehicle status',
  },
  {
    label: 'Buyer',
  },
  {
    label: 'Seller',
  },
  {
    label: 'Author',
  },
  {
    label: 'Status',
  },
  {
    label: 'Auction Batch ID',
  },
  {
    label: 'Actions',
    className: 'text-right',
  },
];

const VehiclesTable = ({ data, offset, setOffset, history, searchParams }) => {
  const loaderStatus = useSelector(state => state.vehicles.get('loaderStatus'));
  const dispatch = useDispatch();
  return (
    <Row>
      <Col xs="12">
        <CommonLoader status={loaderStatus}>
          <CommonTable
            rowIdPrefix="vehicle-row"
            rowComponent={VehiclesTableRow}
            data={data}
            offset={offset}
            onOffsetChange={setOffset}
            headers={headers}
            title="Vehicles"
            handleRowClick={item => {
              dispatch({
                type: SET_SELECTED_VEHICLE,
                payload: item.id,
              });
              dispatch({
                type: SET_VEHICLES_SEARCH_PARAMS,
                payload: searchParams,
              });
              history.push(reverse(RouterPaths.EDIT_VEHICLE, { id: item.id }));
            }}
          />
        </CommonLoader>
      </Col>
    </Row>
  );
};

VehiclesTable.propTypes = {
  searchParams: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  offset: PropTypes.number.isRequired,
  setOffset: PropTypes.func.isRequired,
};

export default withRouter(VehiclesTable);
