export const AuctionStatus = {
  CANCELLED: 'CANCELLED',
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
  REJECTED: 'REJECTED',
  SOLD: 'SOLD',
  TO_ACCEPT: 'TO_ACCEPT',
  WAITING: 'WAITING',
  ENDED: 'ENDED',
};

export const AuctionStatusName = {
  SOLD: 'Sold',
  PUBLISHED: 'Live',
  TO_ACCEPT: 'To accept',
  WAITING: 'Waiting',
  REJECTED: 'Declined Offer',
  ENDED: 'Ended',
  CANCELLED: 'Cancelled',
  DRAFT: 'Draft',
};

export const AuctionSaleStatus = {
  SOLD: 'Sold',
  NOT_SOLD: 'Not Sold',
};

export const AuctionStatusNameNoDraft = () => {
  const list = { ...AuctionStatusName };
  delete list.DRAFT;
  return list;
};
