import React from 'react';
import Breadcrumbs from '../../common/components/Breadcrumbs';
import VehicleSectionsNav from '../VehicleSectionsNav';
import VehicleSellForm from './VehicleSellForm';

const breadcrumbsItems = [
  { id: 1, type: 'home' },
  { id: 2, type: 'link', text: 'Vehicles', url: '/vehicles' },
  { id: 3, type: 'span', text: 'Vehicle sell' },
];

const VehicleSellPage = () => {
  return (
    <div className="content">
      <Breadcrumbs items={breadcrumbsItems} />
      <VehicleSectionsNav section="sell" />
      <VehicleSellForm />
    </div>
  );
};

export default VehicleSellPage;
