import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { AuctionStatusName } from '../../constants/AuctionStatus';
import { dateToString } from '../../common/utils/date/Date.utils';
import { AuctionStatusTypeName } from '../../constants/AuctionStatusType';

const VehiclesTableRow = ({ item }) => {
  return (
    <>
      <td>{item?.vehicle?.registrationPlate}</td>
      <td>
        {item?.vehicle?.vehicleModel?.makeName} {item?.vehicle?.vehicleModel?.name} |{' '}
        {item?.vehicle?.year}
      </td>
      <td>{dateToString(item?.vehicle?.dateOfFirstRegistration)}</td>
      <td>{dateToString(item?.launchedOn)}</td>
      <td>{AuctionStatusTypeName?.[item.type]}</td>
      <td>{item?.buyer?.organizationContact?.tradeName}</td>
      <td>{item?.seller?.organizationContact?.tradeName}</td>
      <td>{`${item?.seller?.firstName} ${item?.seller?.lastName}`}</td>
      <td>{AuctionStatusName?.[item.status].toUpperCase()}</td>
      <td>{item?.launchingCounterSerial}</td>
      <td className="text-right">
        <Button
          className="btn-link"
          color="primary"
          id={`tooltip-edit-${item.id}`}
          size="sm"
          type="button"
        >
          <i className="fa fa-edit" />
        </Button>
        <UncontrolledTooltip
          className="uncontrolled-tooltip"
          delay={0}
          target={`tooltip-edit-${item.id}`}
        >
          Edit
        </UncontrolledTooltip>
      </td>
    </>
  );
};

export default VehiclesTableRow;
