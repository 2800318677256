import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import NotificationTypeSection from './NotificationTypeSection';
import { getNotificationConfig, updateNotificationConfig } from '../UsersActions';
import { NotificationType } from '../../constants/NotificationType';
import { useIsMounted } from '../../helpers/isMounted';

const SEARCH_ALERT_CONFIG = 'SEARCH_ALERT';

const mapToNotificationConfig = configs => {
  return configs.map(item => ({ event: item.event, checked: item.checked }));
};

const NotificationSettingForm = ({ userId, handleCloseAlert }) => {
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const [isLoading, setIsLoading] = useState(true);
  const [updated, setUpdated] = useState(false);
  const [searchAlertConfig, setSearchAlertConfig] = useState({
    [NotificationType.EMAIL]: [],
    [NotificationType.PUSH]: [],
  });

  const [systemConfig, setSystemConfig] = useState({
    [NotificationType.EMAIL]: [],
    [NotificationType.PUSH]: [],
  });

  useEffect(() => {
    if (userId) {
      setIsLoading(true);
      dispatch(getNotificationConfig(userId)).then(data => {
        if (isMounted.current) {
          setNotificationConfig(data);
          setIsLoading(false);
        }
      });
    }
  }, [userId, dispatch, isMounted]);

  const setNotificationConfig = data => {
    if (!data) return;
    const { emailNotifications, pushNotifications } = data;
    setSystemConfig({
      [NotificationType.EMAIL]: emailNotifications.filter(
        item => item.event !== SEARCH_ALERT_CONFIG,
      ),
      [NotificationType.PUSH]: pushNotifications.filter(
        item => item.event !== SEARCH_ALERT_CONFIG,
      ),
    });

    setSearchAlertConfig({
      [NotificationType.EMAIL]: emailNotifications
        .filter(item => item.event === SEARCH_ALERT_CONFIG)
        .map(item => ({ ...item, eventMessage: 'Enabled' })),
      [NotificationType.PUSH]: pushNotifications
        .filter(item => item.event === SEARCH_ALERT_CONFIG)
        .map(item => ({ ...item, eventMessage: 'Enabled' })),
    });
  };

  const onSearchAlertConfigChange = (type, event) => {
    const newSearchAlertConfig = {
      ...searchAlertConfig,
      [type]: searchAlertConfig[type].map(item =>
        item.event === event ? { ...item, checked: !item.checked } : item,
      ),
    };
    setSearchAlertConfig(newSearchAlertConfig);
    setUpdated(true);
  };

  const onSystemConfigChange = (type, event) => {
    const newSystemConfig = {
      ...systemConfig,
      [type]: systemConfig[type].map(item =>
        item.event === event ? { ...item, checked: !item.checked } : item,
      ),
    };
    setSystemConfig(newSystemConfig);
    setUpdated(true);
  };

  const handleUpdate = () => {
    setIsLoading(true);
    const emailNotifications = [
      ...searchAlertConfig[NotificationType.EMAIL],
      ...systemConfig[NotificationType.EMAIL],
    ];
    const pushNotifications = [
      ...searchAlertConfig[NotificationType.PUSH],
      ...systemConfig[NotificationType.PUSH],
    ];
    dispatch(
      updateNotificationConfig(
        userId,
        mapToNotificationConfig(pushNotifications),
        mapToNotificationConfig(emailNotifications),
      ),
    )
      .then(data => {
        setNotificationConfig(data);
      })
      .catch(() => {
        dispatch(getNotificationConfig(userId)).then(data => {
          setNotificationConfig(data);
        });
      })
      .finally(() => {
        if (isMounted.current) {
          setIsLoading(false);
          setUpdated(false);
        }
      });
  };

  return (
    <div>
      <NotificationTypeSection
        title="SYSTEM NOTIFICATIONS"
        emailSettings={systemConfig[NotificationType.EMAIL]}
        pushSettings={systemConfig[NotificationType.PUSH]}
        onChangeConfig={onSystemConfigChange}
      />
      <NotificationTypeSection
        title="SEARCH ALERTS"
        emailSettings={searchAlertConfig[NotificationType.EMAIL]}
        pushSettings={searchAlertConfig[NotificationType.PUSH]}
        onChangeConfig={onSearchAlertConfigChange}
      />
      <div className="notification__buttons-container">
        <Button
          type="submit"
          color="success"
          onClick={() => handleUpdate()}
          disabled={isLoading || !updated}
        >
          Save
        </Button>
        <Button color="danger" onClick={() => handleCloseAlert()}>
          Cancel
        </Button>
      </div>
    </div>
  );
};
export default NotificationSettingForm;

NotificationSettingForm.propTypes = {
  userId: PropTypes.string,
};

NotificationSettingForm.defaultProps = {
  userId: '',
};
