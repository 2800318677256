import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { Field } from 'formik';

const FormCurrencyInputFieldInner = props => {
  const { label, placeholder, form, field, disabled, showEmptyValue } = props;

  const error = form.errors[field.name];
  const touched = form.touched[field.name];
  const value = form.values[field.name] || (showEmptyValue ? '' : 0);

  return (
    <Row>
      <Col style={{ 'text-align': 'left' }}>
        <Label>{label}</Label>
        <FormGroup>
          <CurrencyFormat
            thousandSeparator={true}
            allowNegative={false}
            prefix="£"
            customInput={Input}
            placeholder={placeholder}
            value={value}
            disabled={form.isSubmitting || disabled}
            onBlur={() => form.setFieldTouched(field.name, true)}
            onChange={({ target }) => {
              if (target.value === '£0') {
                form.setFieldValue(field.name, '');
              } else {
                form.setFieldValue(field.name, target.value);
              }
            }}
          />
          {(form.submitCount > 0 || touched) && error && (
            <label style={{ color: 'red ' }}>{error}</label>
          )}
        </FormGroup>
      </Col>
    </Row>
  );
};

const FormCurrencyInputField = props => {
  return <Field {...props} component={FormCurrencyInputFieldInner} />;
};

FormCurrencyInputField.defaultProps = {
  placeholder: '',
  disabled: false,
  showEmptyValue: false,
};

FormCurrencyInputField.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  showEmptyValue: PropTypes.bool,
};

export default FormCurrencyInputField;
