import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import validationSchema from './VehicleAddBidForm.schema';
import { FormCurrencyInputField } from '../../common/components/formFields';
import DealersSelectField from '../../dealers/forms/DealersSelectField';
import { addVehicleBid, searchBids } from '../../bids/BidsActions';
import { parsePriceFormatToInt } from '../../helpers/parsePriceFormatToInt';
import FormSelectField from '../../common/components/formFields/FormSelectField';
import { getAuctionDetails, getDealersEmployees } from '../VehicleActions';
import { dataToSelect } from '../../common/helpers';
import { AuctionStatus } from '../../constants/AuctionStatus';
import { useQueryString } from '../../common/helpers/queryString';

const initialValues = {
  organizationId: '',
  organizationMemberId: '',
  amountInPence: '',
};

let formContext = null;

const VehicleAddBidForm = () => {
  const dispatch = useDispatch();
  const { id: auctionId } = useParams();
  const query = useQueryString();
  const status = useSelector(state => state.vehicles.get('auctionDetails'))?.status;
  const isLoading = useSelector(state => state.bids.get('isLoading'));
  const [employees, setEmployees] = useState([]);

  const [searchParams] = useState({
    offset: query.get('page') ? +query.get('page') - 1 : 0,
    auctionId: auctionId || null,
  });

  useEffect(() => {
    if (auctionId) {
      dispatch(getAuctionDetails(auctionId));
    }
  }, [dispatch, auctionId]);
  const handleChangeDealer = employee => {
    if (employee?.value) {
      dispatch(
        getDealersEmployees(employee?.value, employees => {
          setEmployees(dataToSelect(employees, ['id', 'firstName']));
          formContext.setFieldValue('organizationMemberId', {});
        }),
      );
    }
  };

  const handleSubmit = ({ organizationMemberId, amountInPound }, { setSubmitting }) => {
    dispatch(
      addVehicleBid(
        {
          auctionId,
          organizationMemberId: organizationMemberId?.value,
          amountInPence: parsePriceFormatToInt(amountInPound, false),
        },
        () => dispatch(searchBids(searchParams)),
      ),
    );
    setSubmitting(false);
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnBlur={true}
    >
      {form => {
        formContext = form;
        return (
          <Form autoComplete="off">
            <Card>
              <CardBody>
                <Row>
                  <Col md="4">
                    <DealersSelectField
                      name="organizationId"
                      label="Dealer"
                      onChangeHandler={handleChangeDealer}
                      disabled={status !== AuctionStatus.PUBLISHED || isLoading}
                    />
                  </Col>
                  <Col md="4">
                    <FormSelectField
                      name="organizationMemberId"
                      label="Employee"
                      options={employees}
                      disabled={status !== AuctionStatus.PUBLISHED || isLoading}
                    />
                  </Col>
                  <Col md="4">
                    <FormCurrencyInputField
                      name="amountInPound"
                      label="Bid"
                      disabled={status !== AuctionStatus.PUBLISHED || isLoading}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Button
                      color="primary"
                      className="mt-4 float-right"
                      type="submit"
                      disabled={status !== AuctionStatus.PUBLISHED || isLoading}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
};

export default VehicleAddBidForm;
