import PropTypes from 'prop-types';
import React from 'react';
import { dateTimeToString } from '../../common/utils/date/Date.utils';

const VehicleNoteTableRow = ({ item }) => {
  return (
    <>
      <td>{item?.createdOn && dateTimeToString(item?.createdOn)}</td>
      <td>{item.createdBy}</td>
      <td className="dealer-note-table-content">{item.content}</td>
    </>
  );
};

VehicleNoteTableRow.propTypes = {
  item: PropTypes.object,
};
VehicleNoteTableRow.defaultProps = {
  item: {},
};

export default VehicleNoteTableRow;
