import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Button, Col, Row } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getDealer } from 'dealers/DealersActions';
import UserActivities from 'users/history/UserActivities';
import history from 'browserHistory.js';
import { reverse } from 'named-urls';
import Breadcrumbs from '../../common/components/Breadcrumbs';
import validationSchema from './EditUserForm.schema';
import UserForm from '../form/UserForm';
import { fetchUser, updateUser, deleteUser } from '../UsersActions';
import CommonLoader from '../../common/components/CommonLoader';
import { userInitialValues, prepareUpdateData } from '../UsersUtils';
import ChangePasswordForm from '../form/ChangePasswordForm';
import RouterPaths from '../../constants/RouterPaths';
import NotificationSettingForm from '../form/NotificationSettingForm';

const breadcrumbsItems = [
  { id: 1, type: 'home' },
  { id: 2, type: 'link', text: 'Users', url: '/users' },
  { id: 3, type: 'span', text: 'Edit User' },
];

const EditUserPage = () => {
  const { id } = useParams();

  const [showAlert, setShowAlert] = useState(false);
  const [showOverridePassword, setShowOverridePassword] = useState(false);
  const [showNotificationSettings, setShowNotificationSettings] = useState(false);

  const dispatch = useDispatch();
  const loaderStatus = useSelector(state => state.users.get('loaderStatus'));
  const userDetails = useSelector(state => state.users.get('userDetails'));
  const organizationId = useSelector(
    state => state.users.get('userDetails')?.user?.organization?.id,
  );
  const organization = useSelector(state => state.dealers.get('dealerDetails'));
  useEffect(() => {
    dispatch(fetchUser(id));
  }, [id, dispatch]);

  useEffect(() => {
    if (organizationId) {
      dispatch(getDealer(organizationId));
    }
  }, [organizationId, dispatch]);

  const handleSubmit = (values, { setSubmitting }) => {
    dispatch(
      updateUser(
        userDetails?.user?.id,
        prepareUpdateData({
          ...values,
          id: userDetails?.user?.id,
          version: userDetails?.user?.version,
        }),
        () => {
          setSubmitting(false);
        },
      ),
    );
  };
  const handleRemoveUser = () => {
    dispatch(deleteUser(userDetails?.user?.id));
  };

  return (
    <div className="content">
      <Row className="delete-button-wrapper">
        <Col xs={12} sm="4">
          <Breadcrumbs items={breadcrumbsItems} />
        </Col>
        <Col xs={12} sm="8">
          <div className="edit-user__buttons-wrapper">
            <Button
              className="float-right"
              color="secondary"
              onClick={() => setShowNotificationSettings(true)}
            >
              Notifications
            </Button>
            <Button
              className="float-right"
              color="warning"
              onClick={() => setShowOverridePassword(true)}
            >
              Override password
            </Button>
            <Button className="float-right" color="danger" onClick={() => setShowAlert(true)}>
              Delete user
            </Button>
            <Button
              className="float-right"
              color="success"
              onClick={() =>
                history.push(reverse(RouterPaths.DEALER_INFORMATION, { id: organizationId }))
              }
            >
              Go to dealer
            </Button>
          </div>
        </Col>
      </Row>
      <CommonLoader status={loaderStatus}>
        <Formik
          enableReinitialize
          initialValues={userInitialValues(userDetails)}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          validateOnBlur={true}
        >
          {({ values }) => (
            <UserForm
              title="Edit user"
              locations={userDetails?.organizationLocations}
              organizationRole={userDetails?.organizationRole}
              isOwner={userDetails?.user?.isOwner}
              values={values}
              organizationStatus={
                organizationId === organization?.organization?.id
                  ? organization?.mainProfile?.status
                  : ''
              }
            />
          )}
        </Formik>
      </CommonLoader>

      <UserActivities id={id} />

      <SweetAlert
        danger
        showCancel
        show={showAlert}
        style={{ display: 'block', marginTop: '-100px' }}
        title="Warning!"
        onConfirm={() => {
          setShowAlert(false);
          handleRemoveUser();
        }}
        onCancel={() => setShowAlert(false)}
        confirmBtnBsStyle="info"
      >
        Are you sure to remove a user?
      </SweetAlert>
      <SweetAlert
        onConfirm={() => {}}
        title={false}
        showConfirm={false}
        onCancel={() => setShowOverridePassword(false)}
        show={showOverridePassword}
        style={{ display: 'block', marginTop: '-100px' }}
      >
        <ChangePasswordForm
          handleCloseAlert={() => setShowOverridePassword(false)}
          userId={id}
        />
      </SweetAlert>
      <SweetAlert
        onConfirm={() => {}}
        btnSize="lg"
        title={false}
        style={{ minWidth: 550, paddingLeft: 32, paddingRight: 32 }}
        showConfirm={false}
        onCancel={() => setShowNotificationSettings(false)}
        show={showNotificationSettings}
      >
        <NotificationSettingForm
          handleCloseAlert={() => setShowNotificationSettings(false)}
          userId={id}
        />
      </SweetAlert>
    </div>
  );
};

EditUserPage.propTypes = {};

export default EditUserPage;
