import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import DealersSelectField from '../forms/DealersSelectField';
import { createBlacklistDealer, searchBlacklistDealers } from '../DealersActions';

const initialValues = {
  dealer: '',
};

const DealerBlacklistForm = ({ searchParams, dealers }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [currentDealerOption, setCurrentDealerOption] = useState('');
  const [addButtonDisabled, setAddButtonDisabled] = useState(false);
  const [items, setItems] = useState(dealers);

  useEffect(() => {
    setItems(dealers);
  }, [dealers]);

  useEffect(() => {
    const dealersIds = items.map(dealer => dealer?.blockedOrganization?.id);
    if (
      (isEmpty(dealersIds) && !currentDealerOption) ||
      dealersIds.includes(currentDealerOption?.value)
    ) {
      return setAddButtonDisabled(true);
    }
    return setAddButtonDisabled(false);
  }, [items, currentDealerOption]);

  const handleSubmit = async (values, { setSubmitting, setFieldValue }) => {
    setSubmitting(true);
    await dispatch(
      createBlacklistDealer(id, values?.dealer?.value, () =>
        dispatch(searchBlacklistDealers(searchParams, id)),
      ),
    );
    setFieldValue('dealer', '');
    setSubmitting(false);
  };
  const onChangeHandler = option => {
    setCurrentDealerOption(option);
  };
  return (
    <Col md="12">
      <Card>
        <CardBody>
          <h6>Blacklist</h6>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {() => (
              <Form autoComplete="off">
                <Row>
                  <Col md="4">
                    <DealersSelectField
                      name="dealer"
                      label="Add dealer"
                      onChangeHandler={onChangeHandler}
                    />
                  </Col>
                  <Col md="4">
                    <Button
                      color="info"
                      type="submit"
                      className="mt-4"
                      disabled={!currentDealerOption || addButtonDisabled}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </Col>
  );
};

export default DealerBlacklistForm;
