import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, withRouter } from 'react-router-dom';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import { reverse } from 'named-urls';
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Formik } from 'formik';
import ReactDatetime from 'react-datetime';
import * as Yup from 'yup';
import RouterPaths from '../constants/RouterPaths';
import {
  acceptAuction,
  getAuctionDetails,
  rejectAuction,
  republishAuction,
} from './VehicleActions';
import {
  DATE_FORMAT,
  dateTimeToString,
  isBeforeOrPresentDate,
  toExtendedDateFormat,
} from '../common/utils/date/Date.utils';
import {
  FormCurrencyInputField,
  FormGroupRadioField,
  FormSelectField,
} from '../common/components/formFields';
import { constantToSelect } from '../common/helpers';
import { VehiclePublishOptionName } from '../constants/VehiclePublishOption';
import { prepareSelectValue } from '../helpers/prepareSelectValue';
import { AuctionStatusType, AuctionStatusTypeName } from '../constants/AuctionStatusType';
import { parseCurrencyToIntIfString } from '../common/helpers/parseCurrencyToIntIfString';

export const validationSchema = Yup.object().shape({
  type: Yup.object({
    label: Yup.string(),
    value: Yup.string(),
  }),
  endsOn: Yup.string()
    .required('Field is required')
    .test('is-future-date', 'The date must be in the future', value => {
      return isBeforeOrPresentDate(value);
    }),
  autoAcceptPriceInPence: Yup.string().when('type', {
    is: value => value?.value && value.value === AuctionStatusType.PHYSICAL,
    then: Yup.string()
      .required('Field is required')
      .max(10, 'Reserve Price must be between 1 and 10 characters')
      .test('minValue', 'Reserve Price must be greater or equal to 100', value => {
        const formattedValue = value?.replaceAll('£', '');
        if (formattedValue?.startsWith('0')) return false;
        return value ? Number(formattedValue?.replaceAll(',', '')) >= 100 : false;
      }),
  }),
  minimumPriceInPence: Yup.string().when('type', {
    is: value => value?.value && value.value === AuctionStatusType.MINIMUM_PRICE,
    then: Yup.string()
      .required('Field is required')
      .max(10, 'No Reserve Price must be between 1 and 10 characters')
      .test('minValue', 'No Reserve Price must be greater or equal to 100', value => {
        const formattedValue = value?.replaceAll('£', '');
        if (formattedValue?.startsWith('0')) return false;
        return value ? Number(formattedValue?.replaceAll(',', '')) >= 100 : false;
      }),
  }),
});

export const initialValues = {
  type: '',
  publishTarget: '',
  endsOn: dateTimeToString(new Date().getTime()),
  minimumPriceInPence: '',
  autoAcceptPriceInPence: '',
};

const VehicleSectionsNav = ({ history, section }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const auctionDetails = useSelector(state => state.vehicles.get('auctionDetails'));
  const isSellAllowed = auctionDetails && auctionDetails.isSellAllowed;
  const [isRelaunchButtonEnabled, setIsRelaunchButtonEnabled] = useState(false);
  const [areRejectAndAcceptButtonsEnabled, setAreRejectAndAcceptButtonsEnabled] = useState(
    false,
  );
  const [isRejectionAlertVisible, setIsRejectionAlertVisible] = useState(false);
  const [isAcceptanceAlertVisible, setIsAcceptanceAlertVisible] = useState(false);
  const [isRelaunchPerformed, setIsRelaunchPerformed] = useState(false);
  const [isRelaunchAlertVisible, setIsRelaunchAlertVisible] = useState(false);
  const [reloadAuction, setReloadAuction] = useState(false);
  delete AuctionStatusTypeName.TRYING_TO_DEAL;
  const auctionTypes = constantToSelect(AuctionStatusTypeName);
  const [datePickerValue, setDatePickerValue] = useState('');

  useEffect(() => {
    dispatch(getAuctionDetails(auctionDetails?.id || id));
  }, [reloadAuction]);

  useEffect(() => {
    if (auctionDetails) {
      setDatePickerValue(dateTimeToString(new Date().getTime()));
      setIsRelaunchButtonEnabled(isSellAllowed && !isRelaunchPerformed);
      setAreRejectAndAcceptButtonsEnabled(auctionDetails.isSellerAcceptanceAllowed);
    }
  }, [auctionDetails]);

  const renderInputDependsOnType = (form, type) => {
    if (type === AuctionStatusType.PHYSICAL)
      return (
        <FormCurrencyInputField
          labelToLeft
          name="autoAcceptPriceInPence"
          label="Reserve Price"
          invalid={!!form.errors.autoAcceptPriceInPence}
        />
      );
    if (type === AuctionStatusType.MINIMUM_PRICE)
      return (
        <FormCurrencyInputField
          labelToLeft
          name="minimumPriceInPence"
          label="No Reserve Price"
          invalid={!!form.errors.minimumPriceInPence}
        />
      );
    return null;
  };

  const handleTypeChange = (form, type) => {
    if (type?.value === AuctionStatusType.PHYSICAL) {
      form.setFieldValue('minimumPriceInPence', '£0');
    } else {
      form.setFieldValue('autoAcceptPriceInPence', '£0');
    }
  };

  const handleRelaunchFormSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    await dispatch(
      republishAuction(
        auctionDetails.id,
        {
          id: auctionDetails.id,
          version: auctionDetails.version,
          autoAcceptPriceInPence:
            parseCurrencyToIntIfString(values.autoAcceptPriceInPence) || null,
          minimumPriceInPence: parseCurrencyToIntIfString(values.minimumPriceInPence) || null,
          type: values.type.value,
          publishTarget: values.publishTarget,
          endsOn: toExtendedDateFormat(values.endsOn),
        },
        () => {
          triggerReloadAuctionDetails();
          setIsRelaunchButtonEnabled(false);
          setIsRelaunchPerformed(true);
          setSubmitting(false);
        },
      ),
    );
  };

  const handleRejectAuction = () => {
    dispatch(
      rejectAuction({ id: auctionDetails.id, version: auctionDetails.version }, () => {
        triggerReloadAuctionDetails();
        setAreRejectAndAcceptButtonsEnabled(false);
      }),
    );
  };

  const handleAcceptAuction = () => {
    dispatch(
      acceptAuction({ id: auctionDetails.id, version: auctionDetails.version }, () => {
        triggerReloadAuctionDetails();
        setAreRejectAndAcceptButtonsEnabled(false);
      }),
    );
  };

  const triggerReloadAuctionDetails = () => {
    setTimeout(() => {
      setReloadAuction(prevState => !prevState);
    }, 3000);
  };

  const dynamicInitialValues = auctionDetails
    ? {
        endsOn: dateTimeToString(new Date().getTime()),
        publishTarget: auctionDetails?.descriptionAndSettings?.publishTarget || '',
        minimumPriceInPence:
          auctionDetails?.descriptionAndSettings?.minimumPriceInPence / 100 || '',
        autoAcceptPriceInPence:
          auctionDetails?.descriptionAndSettings?.autoAcceptPriceInPence / 100 || '',
        type:
          prepareSelectValue(
            auctionDetails?.descriptionAndSettings?.type,
            AuctionStatusTypeName[auctionDetails?.descriptionAndSettings?.type],
          ) || '',
      }
    : initialValues;

  return (
    <Row className="vehicle-bids-nav-buttons">
      <Col>
        <Button
          color="primary"
          size="sm"
          outline={section !== 'information'}
          className={`${section === 'information' ? 'button-primary-not-outline' : ''}`}
          onClick={() => history.push(reverse(RouterPaths.EDIT_VEHICLE, { id }))}
        >
          Information
        </Button>
        <Button
          color="primary"
          size="sm"
          className={`${section === 'bids' ? 'button-primary-not-outline' : ''}`}
          outline={section !== 'bids'}
          onClick={() => history.push(reverse(RouterPaths.VEHICLE_BIDS, { id }))}
        >
          Bids
        </Button>
        <Button
          color="primary"
          disabled={!isSellAllowed}
          size="sm"
          className={`${section === 'sell' ? 'button-primary-not-outline' : ''}`}
          outline={section !== 'sell'}
          onClick={() => history.push(reverse(RouterPaths.VEHICLE_SELL, { id }))}
        >
          Sell
        </Button>
        <Button
          color="primary"
          size="sm"
          className={`${section === 'notes' ? 'button-primary-not-outline' : ''}`}
          outline={section !== 'notes'}
          onClick={() => history.push(reverse(RouterPaths.VEHICLES_NOTES, { id }))}
        >
          Notes
        </Button>
      </Col>
      {section === 'bids' && (
        <Col className="vehicle-bids-nav-auction-buttons">
          <Button
            color="warning"
            onClick={() => {
              setIsRelaunchAlertVisible(true);
            }}
            disabled={!isRelaunchButtonEnabled}
          >
            Relaunch
          </Button>
          <Button
            color="danger"
            onClick={() => {
              setIsRejectionAlertVisible(true);
            }}
            disabled={!areRejectAndAcceptButtonsEnabled}
          >
            Reject
          </Button>
          <Button
            color="success"
            onClick={() => {
              setIsAcceptanceAlertVisible(true);
            }}
            disabled={!areRejectAndAcceptButtonsEnabled}
          >
            Accept
          </Button>
          <SweetAlert
            showCancel
            show={isRejectionAlertVisible}
            title="Are you sure?"
            onConfirm={() => {
              setIsRejectionAlertVisible(false);
              handleRejectAuction();
            }}
            onCancel={() => {
              setIsRejectionAlertVisible(false);
            }}
          >
            Please confirm your rejection of the auction
          </SweetAlert>
          <SweetAlert
            showCancel
            show={isAcceptanceAlertVisible}
            title="Are you sure?"
            onConfirm={() => {
              setIsAcceptanceAlertVisible(false);
              handleAcceptAuction();
            }}
            onCancel={() => {
              setIsAcceptanceAlertVisible(false);
            }}
          >
            Please confirm your acceptance of the auction
          </SweetAlert>

          {auctionDetails && !isRelaunchPerformed && (
            <Formik
              initialValues={dynamicInitialValues}
              validationSchema={validationSchema}
              onSubmit={handleRelaunchFormSubmit}
              enableReinitialize
            >
              {form => (
                <SweetAlert
                  showCancel
                  inputType="submit"
                  customClass="relaunch-vehicle-alert"
                  confirmBtnCssClass="relaunch-vehicle-alert-confirm-button"
                  show={isRelaunchAlertVisible}
                  title="Relaunch vehicle"
                  onConfirm={async () => {
                    await form.submitForm();
                  }}
                  confirmBtnText="Relaunch"
                  onCancel={() => {
                    form.resetForm();
                    setIsRelaunchAlertVisible(false);
                  }}
                >
                  <div className="relaunch-vehicle-form">
                    <Row className="relaunch-vehicle-form-buttons">
                      <Col
                        className="relaunch-vehicle-form-button"
                        style={{ textAlign: 'left' }}
                      >
                        <FormGroup>
                          <Label>Time</Label>
                          <ReactDatetime
                            inputProps={{
                              onKeyDown: e => {
                                e.preventDefault();
                              },
                              className: 'form-control',
                            }}
                            dateFormat={DATE_FORMAT}
                            value={datePickerValue}
                            onChange={value => {
                              if (typeof value === 'object') {
                                setDatePickerValue(value);
                                const dateTime = dateTimeToString(value);
                                form.setFieldValue('endsOn', dateTime);
                              }
                            }}
                          />
                          {form.errors.endsOn && (
                            <label className="error error-label">{form.errors.endsOn}</label>
                          )}
                        </FormGroup>
                      </Col>
                      <Col className="relaunch-vehicle-form-button">
                        <FormSelectField
                          name="type"
                          label="Type"
                          options={auctionTypes}
                          labelToLeft
                          onChangeHandler={value => handleTypeChange(form, value)}
                        />
                      </Col>
                      <Col className="relaunch-vehicle-form-button">
                        {renderInputDependsOnType(form, form.values?.type?.value)}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroupRadioField
                          name="publishTarget"
                          formClassName="relaunch-vehicle-radios"
                          radios={constantToSelect(VehiclePublishOptionName)}
                        />
                      </Col>
                    </Row>
                  </div>
                </SweetAlert>
              )}
            </Formik>
          )}
        </Col>
      )}
    </Row>
  );
};

VehicleSectionsNav.propTypes = {
  section: PropTypes.string.isRequired,
};

export default withRouter(VehicleSectionsNav);
