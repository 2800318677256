export const dataToSelect = (data, [valueField, labelField]) => {
  if (!data) {
    return [];
  }

  return data
    .filter(item => {
      return (
        Object.prototype.hasOwnProperty.call(item, valueField) &&
        item[valueField] &&
        Object.prototype.hasOwnProperty.call(item, labelField) &&
        item[labelField]
      );
    })
    .map(item => {
      return {
        value: item[valueField],
        label: item[labelField],
      };
    });
};
