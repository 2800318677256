import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import NotFoundImg from 'assets/img/404.png';
import RouterPaths from '../constants/RouterPaths';

const NotFound = () => {
  return (
    <div className="not-found">
      <img src={NotFoundImg} alt="Logo not found" />
      <p className="not-found-text">Houston, we have a problem</p>
      <NavLink
        to={RouterPaths.HOMEPAGE}
        className="btn btn-primary"
        activeClassName="auth-button--active"
      >
        Go to home page
      </NavLink>
    </div>
  );
};
export default withRouter(NotFound);
