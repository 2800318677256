import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { Form, Formik } from 'formik';
import validationSchema from './TransactionsSearch.schema';
import { FormDatePickerField, FormInputField } from '../../common/components/formFields';
import DealersSelectField from '../../dealers/forms/DealersSelectField';

const initialValues = {
  search: '',
  feeInPence: '',
  dealerId: '',
  transactionDateFrom: '',
  transactionDateTo: '',
};

const TransactionsSearch = ({ handleSearch }) => {
  return (
    <Row>
      <Col md="12">
        <Card>
          <CardBody>
            <h6>Search transactions</h6>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSearch}
              validateOnBlur={true}
            >
              {({ resetForm, submitForm }) => (
                <Form noValidate>
                  <Row>
                    <Col md="4">
                      <FormInputField name="search" label="Title" />
                    </Col>
                    <Col md="4">
                      <DealersSelectField name="dealerId" label="Dealer" />
                    </Col>
                    <Col md="4">
                      <FormInputField name="feeInPence" label="Fee" />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <FormDatePickerField
                        name="transactionDateFrom"
                        label="Transaction date from"
                      />
                    </Col>
                    <Col md="4">
                      <FormDatePickerField
                        name="transactionDateTo"
                        label="Transaction date to"
                        isUpperRange={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Button color="primary" className="mt-4 float-right" type="submit">
                        Search
                      </Button>
                      <Button
                        color="primary"
                        outline={true}
                        className="mt-4 float-right"
                        type="button"
                        onClick={() => {
                          resetForm();
                          submitForm();
                        }}
                      >
                        Clear
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

TransactionsSearch.propTypes = {
  handleSearch: PropTypes.func.isRequired,
};

export default TransactionsSearch;
