import { DealerStatus } from '../constants/DealerStatus';

export const SORT_ASC = 'ASC';
export const SORT_DESC = 'DESC';

export const prepareSearchData = (
  values,
  sortOptions = {},
  isDealersSearch,
  pageSize = 50,
) => {
  const page = {
    pageNumber: values.offset,
    pageSize,
  };

  const criteria = {};
  Object.keys(values).forEach(key => {
    if (values[key] || (typeof values[key] === 'boolean' && !values[key])) {
      if (
        typeof values[key] === 'object' &&
        Object.prototype.hasOwnProperty.call(values[key], 'value')
      ) {
        criteria[key] = values[key].value;
      } else if (isDealersSearch && values?.tradeName) {
        criteria.tradeName = values[key];
      } else {
        criteria[key] = values[key];
      }
    }
  });

  if (criteria.offset) {
    delete criteria.offset;
  }

  if (Object.keys(sortOptions).length) {
    page.sort = {
      orders: [],
    };

    Object.keys(sortOptions).forEach(key => {
      page.sort.orders.push({
        direction: sortOptions[key],
        property: key,
      });
    });
  }

  return { criteria, page };
};
export const prepareSortOption = values => {
  if (values?.status === DealerStatus.BLOCKED) {
    return { lastModifiedOn: SORT_DESC };
  }
  return { serial: SORT_DESC };
};
