import React, { useState } from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { BidStatus, BidStatusName } from '../constants/BidStatus';
import { SET_SELECTED_BID } from './BidsReducer';

const BidsStatusFilter = ({ handleChangeStatus }) => {
  const dispatch = useDispatch();
  const previousSearchParams = useSelector(state => state.bids.get('bidsSearchParams'));
  const [selected, setSelected] = useState(previousSearchParams?.status || '');

  const handleClick = status => {
    dispatch({
      type: SET_SELECTED_BID,
    });
    setSelected(status);
    handleChangeStatus(status);
  };

  return (
    <Row>
      <Col md="12">
        <Card>
          <CardBody>
            <div className="d-flex flex-row">
              <div className="p-2">
                <Button
                  color="primary"
                  outline={selected !== ''}
                  onClick={() => handleClick('')}
                >
                  All
                </Button>
              </div>
              {Object.keys(BidStatus).map(status => (
                <div className="p-2" key={status}>
                  <Button
                    color="primary"
                    outline={selected !== BidStatus[status]}
                    onClick={() => handleClick(status)}
                  >
                    {BidStatusName[status]}
                  </Button>
                </div>
              ))}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

BidsStatusFilter.propTypes = {
  handleChangeStatus: PropTypes.func.isRequired,
};

export default BidsStatusFilter;
