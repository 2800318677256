export const prepareSageQueryParams = (sageSettings, sageStateId) => {
  return {
    filter: 'apiv3.1',
    response_type: 'code',
    client_id: sageSettings.clientId,
    redirect_uri: sageSettings.redirectUri,
    scope: 'full_access',
    state: sageStateId,
  };
};
