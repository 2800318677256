/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import classnames from 'classnames';
// reactstrap components
import { Container, Navbar } from 'reactstrap';
import { Link } from 'react-router-dom';
import RouterPaths from '../../../constants/RouterPaths';

class AuthNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: 'navbar-transparent',
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateColor);
  }

  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    const newState = {
      collapseOpen: !this.state.collapseOpen,
    };
    if (!this.state.collapseOpen) {
      newState.color = 'bg-white';
    } else {
      newState.color = 'navbar-transparent';
    }
    this.setState(newState);
  };

  render() {
    return (
      <Navbar
        className={classnames('navbar-absolute fixed-top', this.state.color)}
        expand="lg"
      >
        <Container>
          <div className="navbar-wrapper">
            <Link to={RouterPaths.LOGIN} className="navbar-brand">
              Auction4Dealers Administration Panel
            </Link>
          </div>
        </Container>
      </Navbar>
    );
  }
}

export default AuthNavbar;
