import { fromJS } from 'immutable';
import { LoaderStatus } from '../common/components/CommonLoader';

const initialData = fromJS({
  items: [],
  loaderStatus: LoaderStatus.NOT_PENDING,
  totalItems: 0,
  siteDetails: {},
});

export const SEARCH_SITE = 'SEARCH_SITE';
export const SEARCH_SITE_SUCCESS = 'SEARCH_SITE_SUCCESS';
export const SEARCH_SITE_FAILURE = 'SEARCH_SITE_FAILURE';
export const GET_SITE = 'GET_SITE';
export const GET_SITE_SUCCESS = 'GET_SITE_SUCCESS';
export const GET_SITE_FAILURE = 'GET_SITE_FAILURE';

export default (state = initialData, { type, payload }) => {
  switch (type) {
    case SEARCH_SITE:
      return state.merge({
        loaderStatus: LoaderStatus.IN_PROGRESS,
      });
    case SEARCH_SITE_SUCCESS:
      return state.merge({
        loaderStatus: LoaderStatus.SUCCESS,
        items: payload.items,
        totalItems: payload.totalItems,
      });
    case SEARCH_SITE_FAILURE:
      return state.merge({
        loaderStatus: LoaderStatus.NOT_PENDING,
      });
    case GET_SITE:
      return state.merge({
        siteDetails: {},
        loaderStatus: LoaderStatus.IN_PROGRESS,
      });
    case GET_SITE_SUCCESS:
      return state.merge({
        siteDetails: payload.data,
        loaderStatus: LoaderStatus.SUCCESS,
      });
    case GET_SITE_FAILURE:
      return state.merge({
        siteDetails: {},
        loaderStatus: LoaderStatus.NOT_PENDING,
      });
    default:
      return state;
  }
};
