import * as Yup from 'yup';

export default () => {
  return Yup.object().shape({
    password: Yup.string()
      .required('Field is required')
      .min(6, 'Min length equals 6 characters')
      .max(50, 'Max length equals 50 characters'),
    passwordRepeat: Yup.string()
      .required('Field is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must be the same'),
  });
};
