import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import { Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  FormDatePickerField,
  FormInputField,
  FormSelectField,
} from '../../../common/components/formFields';
import DealerLocationForm from './DealerLocationForm';
import DealerUserForm from './DealerUserForm';
import DealerPaymentForm from './DealerPaymentForm';
import EditDealerNotesTable from '../EditDealerNotesTable';
import EditDealerChangesTable from '../EditDealerChangesTable';
import { DealerPendingActionTypes } from '../../../constants/DealerPendingActionTypes';
import { useQueryString } from '../../../common/helpers/queryString';
import { fetchDealerChanges, fetchDealerNotes } from '../../DealersActions';
import { constantToSelect } from '../../../common/helpers';
import { DealerRoleName } from '../../../constants/DealerRole';
import GroupSelectField from './group/GroupSelectField';
import { AgreeOptions } from '../../../constants/AgreeOptions';
import CommonErrorLabel from '../../../common/components/CommonErrorLabel';
import DealerAdditionalEmailsForm from './DealerAdditionalEmailsForm';
import DealerAdditionalSalesEmailsForm from './DealerAdditionalSalesEmailsForm';

const { YES, NO } = AgreeOptions;
const dealerRoleName = constantToSelect(DealerRoleName);
const WEBSITE_VALIDATION_ERRORS_OBJECT_KEY_PREFIX = 'organizationForm.website';

const DealerForm = ({
  values,
  errors,
  showNotes,
  searchParamsNotes,
  changeOffsetNotes,
  dealerNotesRef,
  isDisabled,
}) => {
  const query = useQueryString();
  const dispatch = useDispatch();
  const data = useSelector(state => state.dealers.get('dealerDetails'));
  const dealerChanges = useSelector(state => state.dealers.get('dealerChanges'));
  const itemsChanges = dealerChanges?.items;
  const totalItemsChanges = dealerChanges?.totalItems;
  const dealerNotes = useSelector(state => state.dealers.get('dealerNotes'));
  const itemsNotes = dealerNotes?.items || null;
  const totalItemsNotes = dealerNotes?.totalItems;
  const validationErrors = useSelector(state => state.dealers.get('validationErrors'));

  const [searchParamsChanges, setSearchParamsChanges] = useState({
    offset: query.get('page') ? +query.get('page') - 1 : 0,
  });

  useEffect(() => {
    if (data?.organization?.id) {
      dispatch(fetchDealerChanges(searchParamsChanges, data?.organization?.id));
    }
  }, [dispatch, searchParamsChanges, data]);

  useEffect(() => {
    if (data?.organization?.id) {
      dispatch(fetchDealerNotes(searchParamsNotes, data?.organization?.id));
    }
  }, [dispatch, searchParamsNotes, data]);

  const changeOffsetChanges = value => {
    setSearchParamsChanges(prevState => ({ ...prevState, offset: value }));
  };

  const preparePendingActionObj = type => {
    const pendingActions = data?.pendingActions;
    let action = null;

    if (type === DealerPendingActionTypes.ADD_ADDITIONAL_LOCATION && pendingActions) {
      const tabReturn = [];
      action = pendingActions.filter(item => item.type === type);
      action.map(item =>
        tabReturn.push({
          id: item?.id,
          version: item?.version,
          value: item?.value,
        }),
      );
      return tabReturn;
    }
    if (pendingActions) {
      action = pendingActions.find(item => item.type === type);
      return {
        id: action?.id,
        version: action?.version,
        value: action?.value,
      };
    }
    return null;
  };

  return (
    <Form autoComplete="off">
      <Row>
        <Col>
          <FormInputField
            name="officialCompanyName"
            label="Company name"
            disabled={isDisabled}
          />
        </Col>
        <Col>
          <FormInputField name="tradeName" label="Trade name" disabled={isDisabled} />
        </Col>
        <Col>
          <Label>Verified in Companies House</Label>
          <FormGroup className=" mt-2">
            <span className="text-uppercase">
              {data?.organization?.isVerifiedInCompanyHouse ? YES : NO}
            </span>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <GroupSelectField name="organizationGroup" label="Group" disabled={isDisabled} />
        </Col>
        <Col>
          <FormInputField name="vatNumber" label="VAT number" disabled={isDisabled} />
        </Col>
        <Col>
          <FormInputField
            name="companyRegistrationNumber"
            label="Company registration number"
            disabled={isDisabled}
          />
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <FormDatePickerField
            name="companyRegistrationDate"
            label="Registration date"
            disabled={true}
          />
        </Col>
        <Col md="4">
          <FormSelectField
            name="role"
            label="Type"
            disabled={isDisabled}
            options={dealerRoleName}
          />
        </Col>
        <Col md="4">
          <FormInputField name="website" label="Website address" disabled={isDisabled} />
          <CommonErrorLabel
            value={validationErrors?.[WEBSITE_VALIDATION_ERRORS_OBJECT_KEY_PREFIX]}
          />
        </Col>
        <Col md="4">
          <FormInputField name="mailchimpId" label="MailchimpId" disabled={true} />
        </Col>
      </Row>
      <DealerLocationForm
        locations={values.locations}
        disabled={isDisabled}
        preparePendingActionObj={preparePendingActionObj}
        organizationId={values?.organizationId}
      />
      <DealerAdditionalEmailsForm additionalEmails={values.additionalEmails} />
      <DealerAdditionalSalesEmailsForm additionalSalesEmails={values.additionalSalesEmails} />
      <DealerUserForm
        errors={errors}
        disabled={isDisabled}
        preparePendingActionObj={preparePendingActionObj}
      />
      <DealerPaymentForm disabled={isDisabled} values={values} />
      <div ref={dealerNotesRef}>
        <EditDealerNotesTable
          data={{ items: itemsNotes, total: totalItemsNotes }}
          forceShow={showNotes}
          offset={searchParamsNotes.offset}
          changeOffset={changeOffsetNotes}
        />
      </div>
      <EditDealerChangesTable
        data={{ items: itemsChanges, total: totalItemsChanges }}
        offset={searchParamsChanges.offset}
        changeOffset={changeOffsetChanges}
      />
      <Button color="primary" className="mt-4 float-right" type="submit" disabled={isDisabled}>
        Save
      </Button>
    </Form>
  );
};

DealerForm.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

export default withRouter(DealerForm);
