import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { dateToString } from '../../common/utils/date/Date.utils';
import { preparePriceFormat } from '../../helpers/preparePriceFormat';
import { BidStatus, BidStatusName } from '../../constants/BidStatus';
import { pencesToPounds } from '../../common/helpers/pencesToPounds';
import { getPriceColor } from '../../helpers/auctionColorHelper';

const BidsTableRow = ({ item }) => {
  const { firstName, lastName } = item?.bidder;
  const { vehicleMakeName, vehicleModelName } = item;
  const bidderFullName = item ? `${firstName} ${lastName}` : '-';
  const carFullName = item ? `${vehicleMakeName} ${vehicleModelName}` : '-';

  return (
    <>
      <td>{item.serial}</td>
      <td>{item?.vehicleRegistrationPlate}</td>
      <td>{carFullName}</td>
      <td>{dateToString(item?.auctionLaunchedOn)}</td>
      <td>{dateToString(item?.createdOn)}</td>
      <td>
        {item?.autoAcceptPriceInPence
          ? preparePriceFormat(pencesToPounds(item?.autoAcceptPriceInPence))
          : '-'}
      </td>
      <td>
        {item?.minPriceInPence
          ? preparePriceFormat(pencesToPounds(item?.minPriceInPence))
          : '-'}
      </td>
      <td style={getPriceColor(item?.shouldBidAmountBeGreen)}>
        <CurrencyFormat
          value={item?.amountInPence / 100}
          thousandSeparator={true}
          allowNegative={false}
          prefix="£"
          displayType="text"
        />
      </td>
      <td>
        <span id={`seller-tooltip${item.id}`}>{item?.seller?.organization?.tradeName}</span>
        <UncontrolledTooltip
          className="uncontrolled-tooltip"
          delay={0}
          target={`seller-tooltip${item.id}`}
        >
          {item?.seller.firstName} {item?.seller.lastName} <br />
          {item?.seller.phoneNumber} <br />
          {item?.seller.landingPhoneNumber && item?.seller.landingPhoneNumber}
        </UncontrolledTooltip>
      </td>
      <td>
        <span id={`bidder-tooltip${item.id}`}>{item?.bidder?.organization?.tradeName}</span>
        <UncontrolledTooltip
          className="uncontrolled-tooltip"
          delay={0}
          target={`bidder-tooltip${item.id}`}
        >
          {item?.bidder.firstName} {item?.bidder.lastName} <br />
          {item?.bidder.phoneNumber} <br />
          {item?.bidder.landingPhoneNumber && item?.bidder.landingPhoneNumber}
        </UncontrolledTooltip>
      </td>
      <td>{bidderFullName}</td>
      <td>
        {item?.status === BidStatus.REJECTED
          ? BidStatusName.OFFER_DECLINED.toUpperCase()
          : item?.status}
      </td>
      <td className="text-right">
        <Button
          className="btn-link"
          color="primary"
          id={`tooltip${item.id}`}
          size="sm"
          type="button"
        >
          <i className="fa fa-info-circle" id={`tooltipIcon${item.id}`} />
        </Button>
        <UncontrolledTooltip
          className="uncontrolled-tooltip"
          delay={0}
          target={`tooltip${item.id}`}
        >
          more details
        </UncontrolledTooltip>
      </td>
    </>
  );
};

export default BidsTableRow;
