import { apiClient, handleError } from '../api/ApiClient';
import { prepareSearchData, SORT_DESC } from '../helpers/prepareSearchData';
import { FETCH_TV_DASHBOARD_DATA_SUCCESS } from './TvDashboardReducer';

const TV_DASHBOARD_SEARCH_ENDPOINT = '/tv-dashboard/search';

export const fetchTvDashboardData = () => dispatch => {
  apiClient
    .post(
      TV_DASHBOARD_SEARCH_ENDPOINT,
      prepareSearchData('', { soldOn: SORT_DESC }, false, 100),
    )
    .then(res => {
      if (res.status === 200) {
        dispatch({ type: FETCH_TV_DASHBOARD_DATA_SUCCESS, payload: res?.data?.data });
      }
    })
    .catch(res => dispatch(handleError(res)));
};
