import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';

const GroupsTableRow = ({ item }) => {
  return (
    <>
      <td>{item.serial}</td>
      <td>{item.name}</td>
      <td className="text-right">
        <Button
          className="btn-link"
          color="primary"
          id={`tooltip${item.id}`}
          size="sm"
          type="button"
        >
          <i className="fa fa-edit" />
        </Button>
        <UncontrolledTooltip
          className="uncontrolled-tooltip"
          delay={0}
          target={`tooltip${item.id}`}
        >
          Edit
        </UncontrolledTooltip>
      </td>
    </>
  );
};

export default GroupsTableRow;
