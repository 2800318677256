import React from 'react';
import PropTypes from 'prop-types';
import { dateToString } from '../../common/utils/date/Date.utils';

const EditDealerChangesTableRow = ({ item }) => {
  return (
    <>
      <td>{item?.createdOn && dateToString(item?.createdOn)}</td>
      <td>{item?.createdBy}</td>
      <td>
        {item?.changes.map(item => (
          <p className="mt-0 mb-0" key={item.id}>
            {item.description}
          </p>
        ))}
      </td>
    </>
  );
};

EditDealerChangesTableRow.propTypes = {
  item: PropTypes.object,
};
EditDealerChangesTableRow.defaultProps = {
  item: {},
};

export default EditDealerChangesTableRow;
