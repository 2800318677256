import { UserRole } from '../constants/UserRole';
import { DealerUserStatusName } from '../constants/UserStatus';
import { UserPositionName } from '../constants/UserPosition';

export const prepareSearchParams = ({
  firstName,
  lastName,
  email,
  dealer,
  registeredDateFrom,
  registeredDateTo,
  role,
  mailchimpId,
}) => {
  const params = {
    firstName,
    lastName,
    email,
    tradeName: dealer,
    mailchimpId,
  };
  if (registeredDateFrom) {
    params.registeredDateFrom = registeredDateFrom.format();
  }
  if (registeredDateTo) {
    params.registeredDateTo = registeredDateTo.format();
  }
  params.isBuyer = role.includes(UserRole.BUYER);
  params.isSeller = role.includes(UserRole.SELLER);
  return params;
};

export const userInitialValues = ({ user }) => {
  const initialValues = {
    role: [],
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    phone: user?.phoneNumber,
    mailchimpId: user && user.mailchimpId ? user.mailchimpId : '',
    dealer: user && user.organization?.tradeName ? user.organization.tradeName : '',
    status:
      user && user.status
        ? { value: user.status, label: DealerUserStatusName?.[user.status] }
        : {},
    location:
      user && user.location ? { value: user.location?.id, label: user.location?.address } : {},
    position:
      user && user.position
        ? { value: user.position, label: UserPositionName?.[user.position] }
        : {},
  };

  if (user) {
    if (user.isBuyer) {
      initialValues.role.push(UserRole.BUYER);
    }
    if (user.isSeller) {
      initialValues.role.push(UserRole.SELLER);
    }
    if (user.isTransactionAcceptor) {
      initialValues.role.push(UserRole.TRANSACTION_MANAGEMENT);
    }
  }
  return initialValues;
};

export const prepareUpdateData = values => {
  return {
    id: values.id,
    firstName: values.firstName,
    lastName: values.lastName,
    mailchimpId: values.mailchimpId,
    email: values.email,
    phoneNumber: values.phone,
    status: values.status?.value,
    isBuyer: values.role.includes(UserRole.BUYER),
    isSeller: values.role.includes(UserRole.SELLER),
    isTransactionAcceptor: values.role.includes(UserRole.TRANSACTION_MANAGEMENT),
    position: values.position?.value,
    locationId: values.location?.value,
    version: values.version,
  };
};
