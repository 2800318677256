import React, { useEffect, useState, useRef } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import Breadcrumbs from '../../common/components/Breadcrumbs';
import validationSchema from './EditGroupForm.schema';
import GroupForm from '../form/GroupForm';

import {
  deleteGroup,
  editGroup,
  fetchGroup,
  searchOrganizationDealers,
} from '../GroupsActions';
import { useQueryString, setQueryString } from '../../common/helpers/queryString';

const breadcrumbsItems = [
  { id: 1, type: 'home' },
  { id: 2, type: 'link', text: 'Groups', url: '/groups' },
  { id: 3, type: 'span', text: 'Edit Group' },
];

const EditGroupPage = ({ history, location }) => {
  const groupDetails = useSelector(state => state.groups.get('groupDetails'));
  const organizationDealersItems = useSelector(state =>
    state.groups.get('organizationDealersItems'),
  );
  const initialValues = {
    id: groupDetails?.id || '',
    name: groupDetails?.name || '',
    version: groupDetails?.version,
  };
  const formRef = useRef(null);
  const { id } = useParams();
  const dispatch = useDispatch();
  const query = useQueryString();
  const [showAlert, setShowAlert] = useState(false);
  const [savedName, setSavedName] = useState('');
  const [searchParams, setSearchParams] = useState({
    offset: query.get('page') ? +query.get('page') - 1 : 0,
  });

  useEffect(() => {
    dispatch(fetchGroup(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(searchOrganizationDealers(id, searchParams));
  }, [dispatch, id, searchParams]);

  useEffect(() => {
    setSavedName(groupDetails?.name);
  }, [groupDetails]);

  const changeOffset = value => {
    setSearchParams(prevState => ({ ...prevState, offset: value }));
    history.push(setQueryString(location, 'page', value + 1));
  };

  const handleDeleteGroup = () => {
    dispatch(deleteGroup(id));
  };

  const handleNameChange = () => {
    dispatch(editGroup(id, formRef.current.values, [], [])).then(() => {
      dispatch(fetchGroup(id));
    });
  };

  const handleDealerAdd = dealerId => {
    const newValues = { ...formRef.current.values, name: savedName };
    dispatch(editGroup(id, newValues, [dealerId], [])).then(() => {
      dispatch(searchOrganizationDealers(id, searchParams));
    });
  };

  const handleDealerDelete = dealerId => {
    const newValues = { ...formRef.current.values, name: savedName };
    dispatch(editGroup(id, newValues, [], [dealerId])).then(() => {
      dispatch(searchOrganizationDealers(id, searchParams));
    });
  };

  return (
    <div className="content">
      <Row className="delete-button-wrapper">
        <Col xs="9">
          <Breadcrumbs items={breadcrumbsItems} />
        </Col>
        <Col xs="3">
          <Button className="float-right" color="danger" onClick={() => setShowAlert(true)}>
            Delete group
          </Button>
        </Col>
      </Row>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={true}
        innerRef={formRef}
        enableReinitialize
      >
        {form => (
          <GroupForm
            title="Edit group"
            form={form}
            dealers={organizationDealersItems}
            addDealerHandler={handleDealerAdd}
            deleteDealerHandler={handleDealerDelete}
            changeNameHandler={handleNameChange}
            changeOffset={changeOffset}
            searchParams={searchParams}
          />
        )}
      </Formik>
      <SweetAlert
        danger
        showCancel
        show={showAlert}
        style={{ display: 'block', marginTop: '-100px' }}
        title="Warning!"
        onConfirm={() => {
          setShowAlert(false);
          handleDeleteGroup();
        }}
        onCancel={() => setShowAlert(false)}
        confirmBtnBsStyle="info"
      >
        Are you sure to remove a group?
      </SweetAlert>
    </div>
  );
};

EditGroupPage.propTypes = {};

export default withRouter(EditGroupPage);
