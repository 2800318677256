import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'formik';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import VehicleDetailsForm from './VehicleDetailsForm';
import VehicleMediaImageForm from './VehicleMediaImageForm';
import VehicleMediaVideoForm from './VehicleMediaVideoForm';
import VehicleTiresForm from './VehicleTiresForm';
import VehicleServiceHistoryForm from './VehicleServiceHistoryForm';
import VehicleFeaturesForm from './VehicleFeaturesForm';
import VehicleDescriptionForm from './VehicleDescriptionForm';
import CommonLoader from '../../common/components/CommonLoader';

const VehicleForm = ({ title, form }) => {
  const basicData = useSelector(state => state.vehicles.get('auctionDetails'));

  return (
    <>
      {basicData ? (
        <Form autoComplete="off">
          <Card>
            <CardBody>
              <h6>{title}</h6>
              <VehicleDetailsForm form={form} />
              <VehicleMediaImageForm form={form} />
              <VehicleMediaVideoForm form={form} />
              <VehicleTiresForm />
              <VehicleServiceHistoryForm form={form} services={form.values.services} />
              <VehicleFeaturesForm
                feature={form.values.newFeature}
                form={form}
                values={form?.values}
              />
              <VehicleDescriptionForm form={form} auction={basicData} />
              <Row>
                <Col md="12">
                  <Button color="primary" className="mt-4 float-right" type="submit">
                    Save
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      ) : (
        <CommonLoader />
      )}
    </>
  );
};

VehicleForm.propTypes = {
  title: PropTypes.string,
};
VehicleForm.defaultProps = {
  title: '',
};

export default VehicleForm;
