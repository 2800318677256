import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { reverse } from 'named-urls';
import RouterPaths from '../constants/RouterPaths';
import { DealerNavSections } from '../constants/DealerNavSections';

const DealerSectionsNav = ({ section, id, history }) => {
  return (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <Row className="equal-height-buttons-nav">
              <Col xs="auto">
                <Button
                  block
                  color="primary"
                  outline={!!section}
                  onClick={() => history.push(reverse(RouterPaths.DEALER_INFORMATION, { id }))}
                >
                  Information
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  block
                  color="primary"
                  outline={section !== DealerNavSections.USERS}
                  onClick={() =>
                    history.push(reverse(RouterPaths.USERS_DEALER, { dealerId: id }))
                  }
                >
                  Users
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  block
                  color="primary"
                  outline={section !== DealerNavSections.VEHICLES}
                  onClick={() =>
                    history.push(reverse(RouterPaths.VEHICLES_DEALER, { dealerId: id }))
                  }
                >
                  Vehicles
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  block
                  color="primary"
                  outline={section !== DealerNavSections.BIDS}
                  onClick={() =>
                    history.push(reverse(RouterPaths.BIDS_DEALER, { dealerId: id }))
                  }
                >
                  Bids
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  block
                  color="primary"
                  outline={section !== DealerNavSections.TRANSACTIONS}
                  onClick={() =>
                    history.push(reverse(RouterPaths.TRANSACTIONS_DEALER, { dealerId: id }))
                  }
                >
                  Transactions
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  block
                  color="primary"
                  outline={section !== DealerNavSections.BLACKLIST}
                  onClick={() => history.push(reverse(RouterPaths.DEALER_BLACKLIST, { id }))}
                >
                  Blacklist
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

DealerSectionsNav.propTypes = {
  section: PropTypes.string,
  id: PropTypes.string.isRequired,
};
DealerSectionsNav.defaultProps = {
  section: null,
};
export default withRouter(DealerSectionsNav);
