import React from 'react';
import { Button, Card, CardBody, Col, FormGroup, Label, Row } from 'reactstrap';
import { Form, Formik } from 'formik';
import validationSchema from './UsersSearch.schema';
import {
  FormDatePickerField,
  FormGroupCheckboxField,
  FormInputField,
} from '../../common/components/formFields';
import { DealerUserRoleName } from '../../constants/UserRole';
import { constantToSelect } from '../../common/helpers';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  tradeName: '',
  registeredDateFrom: '',
  registeredDateTo: '',
  role: [],
  mailchimpId: '',
};

const DealerUserSearch = ({ handleSearch }) => {
  const dealerUserRoles = constantToSelect(DealerUserRoleName);

  return (
    <Row>
      <Col md="12">
        <Card>
          <CardBody>
            <h6>Search user</h6>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSearch}
              validateOnBlur={true}
            >
              {({ resetForm, submitForm }) => (
                <Form noValidate>
                  <Row>
                    <Col md="4">
                      <FormInputField name="firstName" label="First name" />
                    </Col>
                    <Col md="4">
                      <FormInputField name="lastName" label="Last name" />
                    </Col>
                    <Col md="4">
                      <FormInputField name="email" label="Email" />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <FormInputField name="tradeName" label="Dealer" />
                    </Col>
                    <Col md="4">
                      <FormDatePickerField
                        name="registeredDateFrom"
                        label="Registered date from"
                      />
                    </Col>
                    <Col md="4">
                      <FormDatePickerField
                        name="registeredDateTo"
                        label="to"
                        isUpperRange={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <Label>Role</Label>
                      </FormGroup>
                      <FormGroupCheckboxField
                        inline={true}
                        name="role"
                        label="Role"
                        checkboxes={dealerUserRoles}
                      />
                    </Col>
                    <Col md="4">
                      <FormInputField name="mailchimpId" label="Mailchimp ID" />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Button color="primary" className="mt-4 float-right" type="submit">
                        Search
                      </Button>
                      <Button
                        color="primary"
                        outline={true}
                        className="mt-4 float-right"
                        type="button"
                        onClick={() => {
                          resetForm();
                          submitForm();
                        }}
                      >
                        Clear
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

DealerUserSearch.propTypes = {};

export default DealerUserSearch;
