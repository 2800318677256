import { fromJS } from 'immutable';

const initialData = fromJS({
  mainSettings: {},
  sageSettings: {},
  sageConected: {},
  validationErrors: {},
});

export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const FETCH_SAGE_SETTINGS_SUCCESS = 'FETCH_SAGE_SETTINGS_SUCCESS';
export const FETCH_SAGE_STATUS_SUCCESS = 'FETCH_SAGE_STATUS_SUCCESS';
export const EDIT_SETTINGS_FAILURE = 'EDIT_SETTINGS_FAILURE';
export const CLEAR_FEES_VALIDATION_ERRORS_SUCCESS = 'CLEAR_FEES_VALIDATION_ERRORS_SUCCESS';

export default (state = initialData, { type, payload }) => {
  switch (type) {
    case GET_SETTINGS_SUCCESS:
      return state.merge({
        mainSettings: payload.data,
      });
    case FETCH_SAGE_SETTINGS_SUCCESS:
      return state.merge({
        sageSettings: payload.data,
      });
    case FETCH_SAGE_STATUS_SUCCESS:
      return state.merge({
        sageStatus: payload.data,
      });
    case EDIT_SETTINGS_FAILURE:
      return state.merge({
        validationErrors: payload,
      });
    case CLEAR_FEES_VALIDATION_ERRORS_SUCCESS:
      return state.merge({
        validationErrors: {},
      });
    default:
      return state;
  }
};
