import React from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Button, Row, Col } from 'reactstrap';
import FormInputField, {
  FormInputFieldType,
} from '../common/components/formFields/FormInputField';
import {
  sampleSuccess,
  sampleExceptionBusiness,
  sampleExceptionServer,
  sampleExceptionValidation,
} from './RestTestActions';

const initialValues = { name: '', id: '' };

const validationSchema = () => {
  return Yup.object().shape({
    name: Yup.string().required(),
    id: Yup.number().required(),
  });
};

const RestTest = () => {
  const dispatch = useDispatch();

  const onSubmitSuccess = (values, form) => {
    dispatch(sampleSuccess(values, form)).then(() => form.setSubmitting(false));
  };

  const onSubmitExceptionValidation = (values, form) => {
    dispatch(sampleExceptionValidation(values, form));
  };

  const onSubmitExceptionServer = (values, form) => {
    dispatch(sampleExceptionServer(values, form));
  };

  const onSubmitExceptionBusiness = (values, form) => {
    dispatch(sampleExceptionBusiness(values, form));
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="4">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmitSuccess}
            >
              {() => (
                <Form noValidate>
                  <FormInputField name="name" label="Name" type={FormInputFieldType.TEXT} />
                  <FormInputField name="id" label="ID" type={FormInputFieldType.NUMBER} />
                  <Row>
                    <Col md="12">
                      <Button className="btn" color="info" type="submit">
                        Send (Success)
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
          <Col md="4">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmitExceptionValidation}
            >
              {() => (
                <Form noValidate>
                  <FormInputField name="name" label="Name" type={FormInputFieldType.TEXT} />
                  <FormInputField name="id" label="ID" type={FormInputFieldType.NUMBER} />
                  <Row>
                    <Col md="12">
                      <Button className="btn" color="info" type="submit">
                        Send (ExceptionValidation)
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmitExceptionServer}
            >
              {() => (
                <Form noValidate>
                  <FormInputField name="name" label="Name" type={FormInputFieldType.TEXT} />
                  <FormInputField name="id" label="ID" type={FormInputFieldType.NUMBER} />
                  <Row>
                    <Col md="12">
                      <Button className="btn" color="info" type="submit">
                        Send (ExceptionServer)
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
          <Col md="4">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmitExceptionBusiness}
            >
              {() => (
                <Form noValidate>
                  <FormInputField name="name" label="Name" type={FormInputFieldType.TEXT} />
                  <FormInputField name="id" label="ID" type={FormInputFieldType.NUMBER} />
                  <Row>
                    <Col md="12">
                      <Button className="btn" color="info" type="submit">
                        Send (ExceptionBusiness)
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </div>
    </>
  );
};

RestTest.propTypes = {};

export default RestTest;
