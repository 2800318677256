import React, { useState } from 'react';
import { Button, Row, Col, Card, CardBody } from 'reactstrap';
import { DealerStatus, DealerStatusName } from '../constants/DealerStatus';

const DealersStatusFilter = ({ handleChangeStatus }) => {
  const [selected, setSelected] = useState(null);

  const handleClick = status => {
    setSelected(status);
    handleChangeStatus(status);
  };

  return (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <div className="d-flex flex-row">
              <div className="p-2">
                <Button
                  color="primary"
                  outline={selected !== null}
                  onClick={() => handleClick(null)}
                >
                  All
                </Button>
              </div>
              {Object.keys(DealerStatus).map(status => (
                <div className="p-2" key={status}>
                  <Button
                    color="primary"
                    outline={selected !== DealerStatus[status]}
                    onClick={() => handleClick(DealerStatus[status])}
                  >
                    {DealerStatusName[status]}
                  </Button>
                </div>
              ))}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

DealersStatusFilter.propTypes = {};

export default DealersStatusFilter;
