import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const DragAndDropPhoto = ({ title, onDrop }) => {
  const onDropPhoto = useCallback(acceptedFiles => {
    acceptedFiles.map(async file => {
      onDrop(file);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: onDropPhoto,
    noDrag: true,
  });

  return (
    <aside className="thumbs-container">
      <div {...getRootProps()} className="vehicle_details__edit_photo_drag">
        <input {...getInputProps()} />
        <span>{title}</span>
      </div>
    </aside>
  );
};

DragAndDropPhoto.propTypes = {
  title: PropTypes.string,
  onDrop: PropTypes.func,
};
DragAndDropPhoto.defaultProps = {
  title: '',
  onDrop: () => {},
};

export default DragAndDropPhoto;
