import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumbs from '../common/components/Breadcrumbs';
import RouterPaths from '../constants/RouterPaths';
import AdminsTable from './AdminsTable';
import AdminsSearch from './search/AdminsSearch';
import AdminsStatusFilter from './AdminsStatusFilter';
import { searchAdmin } from './AdminsActions';
import { setQueryString, useQueryString } from '../common/helpers/queryString';

const breadcrumbsItems = [
  { id: 1, type: 'home' },
  { id: 2, type: 'span', text: 'Admins' },
];

const AdminsPage = ({ history, location }) => {
  const query = useQueryString();
  const dispatch = useDispatch();

  const items = useSelector(state => state.admins.get('items'));
  const totalItems = useSelector(state => state.admins.get('totalItems'));

  const [searchParams, setSearchParams] = useState({
    offset: query.get('page') ? +query.get('page') - 1 : 0,
  });

  useEffect(() => {
    dispatch(searchAdmin(searchParams));
  }, [dispatch, searchParams]);

  const handleChangeStatus = status => {
    setSearchParams(prevState => ({ ...prevState, status, offset: 0 }));
  };

  const handleSearch = (values, { setSubmitting }) => {
    setSearchParams(prevState => ({ ...prevState, ...values, offset: 0 }));
    setSubmitting(false);
  };

  const changeOffset = value => {
    setSearchParams(prevState => ({ ...prevState, offset: value }));
    history.push(setQueryString(location, 'page', value + 1));
  };

  return (
    <div className="content">
      <Row>
        <Col xs="9">
          <Breadcrumbs items={breadcrumbsItems} />
        </Col>
        <Col xs="3">
          <Button
            className="float-right"
            color="primary"
            onClick={() => history.push(RouterPaths.CREATE_ADMIN)}
          >
            Add admin
          </Button>
        </Col>
      </Row>
      <Row>
        <AdminsSearch handleSearch={handleSearch} />
      </Row>
      <Row>
        <AdminsStatusFilter handleChangeStatus={handleChangeStatus} />
      </Row>
      <Row>
        <AdminsTable
          data={{ items, total: totalItems }}
          offset={searchParams.offset}
          changeOffset={changeOffset}
        />
      </Row>
    </div>
  );
};

export default withRouter(AdminsPage);
