import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  CardBody,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';

import ForgotPasswordSchema from './ForgotPasswordForm.schema';
import { forgotPassword } from '../AuthActions';
import CommonAuthLayoutWrapper from '../../common/components/CommonAuthLayoutWrapper';

const ForgotPassword = () => {
  useEffect(() => {
    document.body.classList.toggle('login-page');
    return () => {
      document.body.classList.toggle('login-page');
    };
  }, []);

  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const handleRecoverPassword = () => {
    setErrors({});
    ForgotPasswordSchema.validate(
      {
        email,
      },
      { abortEarly: false },
    )
      .then(() => {
        dispatch(
          forgotPassword({ email }, () => {
            setEmail('');
          }),
        );
      })
      .catch(validation => {
        const err = {};
        validation.inner.forEach(e => {
          err[e.path] = e.message;
        });

        setErrors({
          ...err,
        });
      });
  };

  return (
    <CommonAuthLayoutWrapper
      title="Forgot your password"
      submitButtonTitle="Send email"
      submitAction={() => handleRecoverPassword(email, forgotPassword)}
    >
      <CardBody>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="nc-icon nc-single-02" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Email"
            type="text"
            value={email}
            invalid={!!errors.email}
            onChange={e => setEmail(e.target.value)}
          />
          {errors && errors.email && <FormFeedback>{errors.email}</FormFeedback>}
        </InputGroup>
        <p className="forgot-your-password__info-text">Only for superadmins</p>
      </CardBody>
    </CommonAuthLayoutWrapper>
  );
};

export default ForgotPassword;
