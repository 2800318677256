import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { DealerUserStatusName, UserStatus } from '../../constants/UserStatus';
import { dateToString } from '../../common/utils/date/Date.utils';
import { getImpersonate } from '../UsersActions';

const UsersTableRow = ({ item }) => {
  const dispatch = useDispatch();

  const handleLoginClick = (id, event) => {
    event.stopPropagation();
    dispatch(
      getImpersonate(id, token => {
        const DEALER_URL = process.env.REACT_APP_DEALER_URL || 'http://localhost:3000';
        window.open(`${DEALER_URL}/impersonate?om=${id}&t=${token}`, '_blank');
      }),
    );
  };

  return (
    <>
      <td>{item.serial}</td>
      <td>{item.firstName}</td>
      <td>{item.lastName}</td>
      <td>{item.email}</td>
      <td>{item.mailchimpId}</td>
      <td>{item?.registrationDate && dateToString(item?.registrationDate)}</td>
      <td>{item.organization?.tradeName}</td>
      <td>{DealerUserStatusName?.[item.status]}</td>
      <td className="text-right">
        {item.status !== UserStatus.PENDING && item.status !== UserStatus.REJECTED && (
          <>
            <Button
              className="btn-link"
              color="primary"
              id={`tooltip_login${item.id}`}
              size="sm"
              type="button"
              onClick={event => handleLoginClick(item.id, event)}
            >
              <i className="fas fa-sign-in-alt" />
            </Button>
            <UncontrolledTooltip
              className="uncontrolled-tooltip"
              delay={0}
              target={`tooltip_login${item.id}`}
            >
              Log In
            </UncontrolledTooltip>
          </>
        )}
        <Button
          className="btn-link"
          color="primary"
          id={`tooltip${item.id}`}
          size="sm"
          type="button"
        >
          <i className="fa fa-edit" />
        </Button>
        <UncontrolledTooltip
          className="uncontrolled-tooltip"
          delay={0}
          target={`tooltip${item.id}`}
        >
          Edit
        </UncontrolledTooltip>
      </td>
    </>
  );
};
export default UsersTableRow;
