import React, { useState } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import ReactDatetime from 'react-datetime';
import {
  FormCurrencyInputField,
  FormDatePickerField,
  FormGroupRadioField,
  FormInputField,
  FormSelectField,
} from '../../common/components/formFields';
import { constantToSelect } from '../../common/helpers';
import { VehiclePublishOptionName } from '../../constants/VehiclePublishOption';
import { AuctionStatusType, AuctionStatusTypeName } from '../../constants/AuctionStatusType';
import { AuctionStatus } from '../../constants/AuctionStatus';
import { DueInDateType, DueInDateTypeName } from '../../constants/DueInDateType';
import { DATE_FORMAT, dateTimeToString } from '../../common/utils/date/Date.utils';

const modifiedAuctionTypes = AuctionStatusTypeName;
delete modifiedAuctionTypes.TRYING_TO_DEAL;
const auctionTypes = constantToSelect(modifiedAuctionTypes);
const dueInDateTypes = constantToSelect(DueInDateTypeName);

const VehicleDescriptionForm = ({ form, auction }) => {
  const [ref, setRef] = useState(null);
  const isDraftAuction = auction.status === AuctionStatus.DRAFT;
  const isLiveAuction = auction.status === AuctionStatus.PUBLISHED;
  const [datePickerValue, setDatePickerValue] = useState('');

  const renderInputDependsOnType = type => {
    if (type === AuctionStatusType.PHYSICAL)
      return (
        <FormCurrencyInputField
          showEmptyValue
          name="autoAcceptPriceInPence"
          label="Reserve price"
          invalid={!!form.errors.autoAcceptPriceInPence}
          disabled={!(isDraftAuction || isLiveAuction)}
        />
      );
    if (type === AuctionStatusType.MINIMUM_PRICE)
      return (
        <FormCurrencyInputField
          name="minimumPriceInPence"
          label="Price"
          invalid={!!form.errors.minimumPriceInPence}
          disabled={!(isDraftAuction || isLiveAuction) || !auction.isMinimumPriceChangeAllowed}
        />
      );
    if (type === AuctionStatusType.TRYING_TO_DEAL)
      return (
        <>
          <FormSelectField
            name="tryingToDealDeliveryDateType"
            label="Due in date"
            placeholder="Due in date"
            options={dueInDateTypes}
            disabled={!(isDraftAuction || isLiveAuction)}
          />
          {form?.values?.tryingToDealDeliveryDateType?.value === DueInDateType.CUSTOM_DATE && (
            <FormDatePickerField
              name="tryingToDealDeliveryDate"
              label="Date"
              disabled={!(isDraftAuction || isLiveAuction)}
            />
          )}
        </>
      );
    return null;
  };

  return (
    <>
      <Row>
        <Col md="12" className="vehicle-description__description-column">
          <FormInputField
            inputClassName="vehicle-description__textarea"
            label="Description"
            name="description"
            type="textarea"
            setRef={setRef}
          />
        </Col>
      </Row>
      <Row>
        <Col md="12" className="vehicle-description__description-column">
          <FormInputField
            label="Default description"
            inputClassName="vehicle-description__textarea"
            name="defaultDescription"
            type="textarea"
          />
        </Col>
      </Row>
      <Row>
        {auction.status === AuctionStatus.PUBLISHED && (
          <Col lg={12} className="current-ends-time">
            Current Ends Time: {dateTimeToString(auction.endsOn)}
          </Col>
        )}
        {isLiveAuction && (
          <Col md="4">
            <FormGroup>
              <Label>Time</Label>
              <ReactDatetime
                inputProps={{
                  onKeyDown: e => {
                    e.preventDefault();
                  },
                  className: 'form-control',
                }}
                dateFormat={DATE_FORMAT}
                value={datePickerValue}
                onChange={value => {
                  if (typeof value === 'object') {
                    setDatePickerValue(value);
                    const dateTime = dateTimeToString(value);
                    form.setFieldValue('endsOn', dateTime);
                  }
                }}
              />
              {form.errors.endsOn && (
                <label className="error error-label">{form.errors.endsOn}</label>
              )}
            </FormGroup>
          </Col>
        )}
        <Col md={isLiveAuction ? 4 : 6}>
          <FormSelectField
            name="type"
            label="Type"
            options={auctionTypes}
            disabled={!isDraftAuction}
          />
        </Col>
        <Col md={isLiveAuction ? 4 : 6}>
          {renderInputDependsOnType(form?.values?.type?.value)}
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroupRadioField
            disabled={!isDraftAuction}
            name="publishTarget"
            formClassName="edit-vehicle-radios"
            radios={constantToSelect(VehiclePublishOptionName)}
          />
        </Col>
      </Row>
    </>
  );
};

export default VehicleDescriptionForm;
