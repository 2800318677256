import React from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import FormInputField from '../../../common/components/formFields/FormInputField';
import DealersSelectField from '../../forms/DealersSelectField';
import validationSchema from './DealerTransactionsSearch.schema';
import { FormDatePickerField } from '../../../common/components/formFields';

const initialValues = {
  feeInPence: '',
  dealerId: '',
  transactionDateFrom: '',
  transactionDateTo: '',
  search: '',
};

const DealerTransactionsSearch = ({ handleSearch }) => {
  return (
    <Row>
      <Col md="12">
        <Card>
          <CardBody>
            <h6>Search transaction</h6>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSearch}
              validateOnBlur={true}
            >
              {({ resetForm, submitForm }) => (
                <Form>
                  <Row>
                    <Col md="4">
                      <FormInputField name="feeInPence" label="Fee" />
                    </Col>
                    <Col md="4">
                      <DealersSelectField name="dealerId" label="Dealer" />
                    </Col>
                    <Col md="4">
                      <FormDatePickerField
                        name="transactionDateFrom"
                        label="Transaction date from"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <FormDatePickerField
                        name="transactionDateTo"
                        label="to"
                        isUpperRange={true}
                      />
                    </Col>
                    <Col md="4">
                      <FormInputField name="search" label="Keyword" />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Button color="primary" className="mt-4 float-right" type="submit">
                        Search
                      </Button>
                      <Button
                        color="primary"
                        outline={true}
                        className="mt-4 float-right"
                        type="button"
                        onClick={() => {
                          resetForm();
                          submitForm();
                        }}
                      >
                        Clear
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

DealerTransactionsSearch.propTypes = {
  handleSearch: PropTypes.func.isRequired,
};

export default DealerTransactionsSearch;
