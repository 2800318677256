import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'formik';
import { useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Label, Row } from 'reactstrap';
import { FormInputField, FormSelectField } from '../../common/components/formFields';
import { constantToSelect, dataToSelect } from '../../common/helpers';
import { DealerUserStatusName, UserStatus } from '../../constants/UserStatus';
import FormGroupCheckboxField from '../../common/components/formFields/FormGroupCheckboxField';
import { EmployeeUserRoleName, DealerUserRoleName, UserRole } from '../../constants/UserRole';
import { UserPositionName } from '../../constants/UserPosition';

const dealerUserPositions = constantToSelect(UserPositionName);

const UserForm = ({
  title,
  locations,
  organizationRole,
  isOwner,
  values,
  organizationStatus,
}) => {
  const [dealerUserStatuses, setDealerUserStatuses] = useState([]);
  const locationsData = dataToSelect(locations, ['id', 'address']);
  const userDetails = useSelector(state => state.users.get('userDetails'));
  const userStatus = useSelector(state => state.users.get('userDetails')?.user?.status);
  let dealerUserRoles = constantToSelect(isOwner ? DealerUserRoleName : EmployeeUserRoleName);

  useEffect(() => {
    let statuses = [];
    if (userStatus) {
      switch (userStatus) {
        case UserStatus.PENDING:
          statuses = [UserStatus.ACTIVE, UserStatus.REJECTED];
          break;
        case UserStatus.ACTIVE:
          statuses = [UserStatus.BLOCKED];
          break;
        case UserStatus.BLOCKED:
          statuses = [UserStatus.ACTIVE];
          break;
        case UserStatus.BLOCKED_DUE_TO_PAYMENT:
          setDealerUserStatuses([]);
          return;
        default:
          statuses = [];
      }
    }

    statuses = Object.fromEntries(
      [...statuses].map(item => [item, DealerUserStatusName[item]]),
    );

    setDealerUserStatuses(constantToSelect(statuses));
  }, [userStatus]);

  const checkCheckboxDependencies = (value, remove) => {
    if (!value.includes(UserRole.SELLER) && value.includes(UserRole.TRANSACTION_MANAGEMENT)) {
      const idx = value.indexOf(UserRole.TRANSACTION_MANAGEMENT);
      if (idx !== -1) {
        remove(idx);
      }
    }
  };

  if (!isOwner && organizationRole) {
    dealerUserRoles = dealerUserRoles.map(role => {
      const disabled =
        (role.value === UserRole.BUYER && !organizationRole.isBuyer) ||
        (role.value === UserRole.SELLER && !organizationRole.isSeller) ||
        (role.value === UserRole.TRANSACTION_MANAGEMENT &&
          (!organizationRole.isSeller || !values.role.includes(UserRole.SELLER)));
      return { ...role, disabled };
    });
  }

  return (
    <>
      <Form autoComplete="off">
        <Card>
          <CardBody>
            {userDetails?.user?.isOwner && (
              <Row>
                <Col md="12" className="d-flex flex-column align-items-center">
                  <p className="title">Main profile</p>
                  <p className="alert-warning">Changes affect the dealer&apos;s account</p>
                  {userStatus === UserStatus.BLOCKED_DUE_TO_PAYMENT && (
                    <p className="alert-warning">Blocked due to payment</p>
                  )}
                </Col>
              </Row>
            )}
            {!userDetails?.user?.isOwner &&
              organizationStatus === UserStatus.BLOCKED_DUE_TO_PAYMENT && (
                <Row>
                  <Col md="12" className="d-flex flex-column align-items-center">
                    <p className="alert-warning">Blocked due to payment</p>
                  </Col>
                </Row>
              )}
            <h6>{title}</h6>
            <div className="form-horizontal">
              <Row>
                <Col md="4">
                  <FormInputField name="firstName" label="First name" />
                </Col>
                <Col md="4">
                  <FormInputField name="lastName" label="Last name" />
                </Col>
                <Col md="4">
                  <FormInputField name="email" label="Email" type="email" />
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <FormInputField name="phone" label="Phone" />
                </Col>
                <Col md="4">
                  <FormInputField name="mailchimpId" label="Mailchimp ID" />
                </Col>
                <Col md="4">
                  <FormInputField name="dealer" label="Dealer" disabled={true} />
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <FormSelectField
                    name="status"
                    label="Status"
                    options={dealerUserStatuses}
                    disabled={
                      userStatus === UserStatus.REJECTED ||
                      userStatus === UserStatus.BLOCKED_DUE_TO_PAYMENT
                    }
                  />
                </Col>
                <Col md="4">
                  <FormSelectField
                    name="location"
                    label="Location"
                    options={locationsData}
                    disabled={userDetails?.user?.isOwner}
                  />
                </Col>
                <Col md="4">
                  <FormSelectField
                    name="position"
                    label="Position"
                    options={dealerUserPositions}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Label>Role</Label>
                  <FormGroupCheckboxField
                    name="role"
                    checkboxes={dealerUserRoles}
                    checkDependencies={checkCheckboxDependencies}
                    isRoleGroupCheckbox
                  />
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Button color="primary" className="mt-4 float-right" type="submit">
                    Save
                  </Button>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Form>
    </>
  );
};

UserForm.propTypes = {
  title: PropTypes.string,
  isOwner: PropTypes.bool,
  values: PropTypes.object,
  locations: PropTypes.array,
  organizationRole: PropTypes.object,
  organizationStatus: PropTypes.string,
};
UserForm.defaultProps = {
  title: '',
  isOwner: false,
  values: {},
  locations: [],
  organizationRole: {},
  organizationStatus: '',
};

export default UserForm;
