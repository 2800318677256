import { PerspectiveType } from '../constants/PerspectiveType';

export const checkInteriorPerspective = perspective => {
  return (
    perspective === PerspectiveType.INTERIOR_1 ||
    perspective === PerspectiveType.INTERIOR_2 ||
    perspective === PerspectiveType.INTERIOR_3 ||
    perspective === PerspectiveType.INTERIOR_4
  );
};
