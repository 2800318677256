import React from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CommonTable from '../common/components/CommonTable';
import AdminsTableRow from './AdminsTableRow';
import CommonLoader from '../common/components/CommonLoader';
import { PrepareUrl } from '../common/helpers/PrepareUrl';
import RouterPaths from '../constants/RouterPaths';

const headers = [
  {
    label: 'ID',
  },
  {
    label: 'First name',
  },
  {
    label: 'Last name',
  },
  {
    label: 'Email',
  },
  {
    label: 'Status',
  },
  {
    label: 'Action',
  },
];

const AdminsTable = ({ data, offset, changeOffset, history }) => {
  const loaderStatus = useSelector(state => state.admins.get('loaderStatus'));
  return (
    <>
      <Col xs="12">
        <CommonLoader status={loaderStatus}>
          <CommonTable
            rowComponent={AdminsTableRow}
            data={data}
            offset={offset}
            onOffsetChange={changeOffset}
            headers={headers}
            title="Admins"
            handleRowClick={item =>
              history.push(PrepareUrl(RouterPaths.EDIT_ADMIN, { id: item.id }))
            }
          />
        </CommonLoader>
      </Col>
    </>
  );
};

AdminsTable.propTypes = {
  data: PropTypes.object.isRequired,
  offset: PropTypes.number.isRequired,
  changeOffset: PropTypes.func.isRequired,
};

export default withRouter(AdminsTable);
