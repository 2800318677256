import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';
import { FieldArray } from 'formik';
import { FormInputField } from '../../../common/components/formFields';

const DealerAdditionalEmailsForm = ({ additionalSalesEmails }) => {
  const err = (index, arrayHelpers, name) => {
    if (arrayHelpers?.form?.errors?.additionalSalesEmails) {
      const errors = arrayHelpers?.form?.errors?.additionalSalesEmails?.[index];
      const touched = arrayHelpers?.form?.touched?.additionalSalesEmails?.[index];
      if (errors?.[name] && (touched || touched?.[name])) {
        return <label className="error-label">{errors?.[name]}</label>;
      }
    }
    return false;
  };

  return (
    <>
      <h6>Additional sales emails</h6>
      <FieldArray
        name="additionalSalesEmails"
        render={arrayHelpers => (
          <div>
            {additionalSalesEmails.map((additionalEmail, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Row key={index}>
                <Col md="6" sm="8" xs="8">
                  <FormInputField
                    name={`additionalSalesEmails.${index}.email`}
                    label="Email"
                  />
                  {err(index, arrayHelpers, 'email')}
                </Col>
                <Col>
                  <Button
                    className="dealer-additional-emails-delete-btn"
                    type="button"
                    onClick={() => arrayHelpers.remove(index)}
                  >
                    <i className="fas fa-times" />
                  </Button>
                </Col>
              </Row>
            ))}
            <Row>
              <Button color="neutral" type="button" onClick={() => arrayHelpers.push('')}>
                Add additional sales email
              </Button>
            </Row>
          </div>
        )}
      />
    </>
  );
};

DealerAdditionalEmailsForm.defaultProps = {
  additionalSalesEmails: [],
};

DealerAdditionalEmailsForm.propTypes = {
  additionalSalesEmails: PropTypes.array,
};

export default DealerAdditionalEmailsForm;
