import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import validationSchema from './BidsSearch.schema';
import {
  FormDatePickerField,
  FormInputField,
  FormSelectField,
} from '../../common/components/formFields';
import DealersSelectField from '../../dealers/forms/DealersSelectField';
import { getVehicleMakeList, getVehicleModelsList } from '../../vehicles/VehicleActions';
import { dataToSelect } from '../../common/helpers';
import { SET_BIDS_SEARCH_PARAMS, SET_SELECTED_BID } from '../BidsReducer';

const initialValues = {
  registrationPlate: '',
  sellerOrganizationId: '',
  bidderOrganizationId: '',
  makeId: '',
  modelId: '',
  year: '',
  bidDateFrom: '',
  bidDateTo: '',
  bidderId: '',
  launchDateFrom: '',
  launchDateTo: '',
  keyword: '',
};

let formContext = null;

const BidsSearch = ({ handleSearch }) => {
  const previousSearchParams = useSelector(state => state.bids.get('bidsSearchParams'));
  const dispatch = useDispatch();

  const formRef = useRef();
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);

  useEffect(() => {
    if (previousSearchParams) {
      formRef.current.setValues(previousSearchParams);
    }
  }, []);

  useEffect(() => {
    dispatch(
      getVehicleMakeList(makes => {
        setMakes(dataToSelect(makes.data, ['id', 'name']));
      }),
    );
  }, [dispatch]);

  const handlerMakeChange = make => {
    if (make?.value) {
      formContext.setFieldValue('modelId', '');
      dispatch(
        getVehicleModelsList(make.value, models => {
          setModels(dataToSelect(models.data, ['id', 'name']));
        }),
      );
    }
  };

  const prepareSearchCriteria = (values, { setSubmitting }) => {
    const params = { ...values };
    if (params?.makeId?.value) {
      params.makeId = params.makeId.value;
    }
    if (params?.modelId?.value) {
      params.modelId = params.modelId.value;
    }
    handleSearch(params, setSubmitting);
  };

  return (
    <Row>
      <Col md="12">
        <Card>
          <CardBody>
            <h6>Search bid</h6>
            <Formik
              innerRef={formRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={prepareSearchCriteria}
              validateOnBlur={true}
            >
              {form => {
                formContext = form;
                return (
                  <Form>
                    <Row>
                      <Col md="4">
                        <FormInputField name="registrationPlate" label="Number plate" />
                      </Col>
                      <Col md="4">
                        <DealersSelectField name="sellerOrganizationId" label="Seller" />
                      </Col>
                      <Col md="4">
                        <DealersSelectField name="bidderOrganizationId" label="Buyer" />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <FormSelectField
                          name="makeId"
                          label="Make"
                          options={makes}
                          onChangeHandler={handlerMakeChange}
                        />
                      </Col>
                      <Col md="4">
                        <FormSelectField name="modelId" label="Model" options={models} />
                      </Col>
                      <Col md="4">
                        <FormInputField name="year" label="Year" type="number" />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <FormDatePickerField name="bidDateFrom" label="Bid date from" />
                      </Col>
                      <Col md="4">
                        <FormDatePickerField
                          name="bidDateTo"
                          label="Bid date to"
                          isUpperRange={true}
                        />
                      </Col>
                      <Col md="4">
                        <FormDatePickerField name="launchDateFrom" label="Launch date from" />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <FormDatePickerField
                          name="launchDateTo"
                          label="Launch date to"
                          isUpperRange={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <Button color="primary" className="mt-4 float-right" type="submit">
                          Search
                        </Button>
                        <Button
                          color="primary"
                          outline={true}
                          className="mt-4 float-right"
                          type="button"
                          onClick={() => {
                            dispatch({
                              type: SET_SELECTED_BID,
                            });
                            dispatch({
                              type: SET_BIDS_SEARCH_PARAMS,
                              payload: {
                                status: previousSearchParams.status,
                              },
                            });
                            form.resetForm();
                            form.submitForm();
                          }}
                        >
                          Clear
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

BidsSearch.propTypes = {
  handleSearch: PropTypes.func.isRequired,
};

export default BidsSearch;
