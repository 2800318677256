import React, { createRef, useEffect, useState } from 'react';
import { Button, ButtonGroup, Card, CardBody, Col, Row } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import Breadcrumbs from '../../common/components/Breadcrumbs';
import DealerSectionsNav from '../DealerSectionsNav';
import validationSchema from './forms/DealerForm.schema';
import DealerForm from './forms/DealerForm';
import {
  clearDealerDetails,
  clearDealerFeesValidationErrors,
  deleteDealer,
  editDealer,
  getDealer,
} from '../DealersActions';
import { DealerRoleName } from '../../constants/DealerRole';
import { DealerPositionName } from '../../constants/DealerPosition';
import { PaymentMethodsName } from '../../constants/PaymentMethods';
import DealerNotesForm from './forms/DealerNotesForm';
import { DealerStatus, DealerStatusActionName } from '../../constants/DealerStatus';
import { useQueryString } from '../../common/helpers/queryString';
import { pencesToPounds } from '../../common/helpers/pencesToPounds';
import { DealerUserStatusName } from '../../constants/UserStatus';

const breadcrumbsItems = [
  { id: 1, type: 'home' },
  { id: 2, type: 'link', text: 'Dealers', url: '/dealers' },
  { id: 3, type: 'span', text: 'Edit Dealer' },
];

const EditDealerPage = () => {
  const query = useQueryString();
  const { id } = useParams();
  const dispatch = useDispatch();
  const dealerNotesRef = createRef();

  useEffect(() => {
    dispatch(getDealer(id));
  }, [dispatch, id]);

  useEffect(
    () => () => {
      dispatch(clearDealerFeesValidationErrors());
      dispatch(clearDealerDetails());
    },
    [dispatch],
  );

  const data = useSelector(state => state.dealers.get('dealerDetails'));
  const [statusValue, setStatusValue] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [newStatusValue, setNewStatusValue] = useState(false);
  const [showNotes, setShowNotes] = useState({ show: false });
  const dealerNotes = useSelector(state => state.dealers.get('dealerNotes'));
  const itemsNotes = dealerNotes?.items || null;

  const [searchParamsNotes, setSearchParamsNotes] = useState({
    offset: query.get('page') ? +query.get('page') - 1 : 0,
  });

  const changeOffsetNotes = value => {
    setSearchParamsNotes(prevState => ({ ...prevState, offset: value }));
  };

  const mapAdditionalEmails = additionalEmails => {
    return additionalEmails.map(item => ({
      email: item,
    }));
  };

  const mainProfile = {
    profileId: get(data, 'mainProfile.id', '') || '',
    profileSerial: get(data, 'mainProfile.serial', '') || '',
    profileVersion: get(data, 'mainProfile.version', null),
    status: get(data, 'mainProfile.status', '') || '',
    firstName: get(data, 'mainProfile.firstName', '') || '',
    lastName: get(data, 'mainProfile.lastName', '') || '',
    email: get(data, 'mainProfile.email', '') || '',
    landlinePhoneNumber: get(data, 'mainProfile.landlinePhoneNumber', '') || '',
    phoneNumber: get(data, 'mainProfile.phoneNumber', '') || '',
    mailchimpId: get(data, 'mainProfile.mailchimpId', '') || '',
    position: {
      label: DealerPositionName[get(data, 'mainProfile.position', '')] || '',
      value: get(data, 'mainProfile.position', '') || '',
    },
    isBuyer: get(data, 'mainProfile.isBuyer', '') || false,
    isSeller: get(data, 'mainProfile.isSeller', '') || false,
    isOwner: get(data, 'mainProfile.isOwner', '') || '',
    acceptNewsletter: get(data, 'mainProfile.acceptNewsletter', '') || '',
    acceptTos: get(data, 'mainProfile.acceptTos', '') || '',
  };
  const organization = {
    additionalEmails: mapAdditionalEmails(
      get(data, 'organization.additionalEmails', []) || [],
    ),
    additionalSalesEmails: mapAdditionalEmails(
      get(data, 'organization.additionalSalesEmails', []) || [],
    ),
    organizationId: get(data, 'organization.id', '') || '',
    organizationVersion: get(data, 'organization.version', null),
    organizationSerial: get(data, 'organization.serial', '') || '',
    organizationFeesVersion: get(data, 'organization.organizationFees.version', null),
    organizationFeesSerial: get(data, 'organization.organizationFees.serial', '') || '',
    organizationGroup: get(data, 'organization.group', '') || '',
    officialCompanyName: get(data, 'organization.officialCompanyName', '') || '',
    tradeName: get(data, 'organization.tradeName', '') || '',
    vatNumber: get(data, 'organization.vatNumber', '') || '',
    companyRegistrationNumber: get(data, 'organization.companyRegistrationNumber', '') || null,
    companyRegistrationDate: get(data, 'mainProfile.registrationDate', '') || '',
    role: {
      label: DealerRoleName[get(data, 'organization.role', '')] || '',
      value: get(data, 'organization.role', '') || '',
    },
    website: get(data, 'organization.website', '') || '',
    paymentMethod: {
      label:
        PaymentMethodsName[get(data, 'organization.organizationFees.paymentMethod', '')] || '',
      value: get(data, 'organization.organizationFees.paymentMethod', '') || '',
    },
    monthlySubscriptionInPence: pencesToPounds(
      get(data, 'organization.organizationFees.monthlySubscriptionInPence', 0) || 0,
    ),
    monthlySubscriptionFeeExemption: {
      label: get(data, 'organization.organizationFees.monthlySubscriptionFeeExemption', '')
        ? 'Yes'
        : 'No',
      value:
        get(data, 'organization.organizationFees.monthlySubscriptionFeeExemption', '') || '',
    },
    monthlySubscriptionFeeExemptionTo:
      get(data, 'organization.organizationFees.monthlySubscriptionFeeExemptionTo', '') || '',
    launchInPence:
      pencesToPounds(get(data, 'organization.organizationFees.launchInPence', '')) || '',
    launchFeeExemption:
      {
        label: get(data, 'organization.organizationFees.launchFeeExemption', '')
          ? 'Yes'
          : 'No',
        value: get(data, 'organization.organizationFees.launchFeeExemption', ''),
      } || '',
    launchFeeExemptionTo:
      get(data, 'organization.organizationFees.launchFeeExemptionTo', '') || '',
    saleForLessThan1000InPence:
      pencesToPounds(
        get(data, 'organization.organizationFees.saleForLessThan1000InPence', 0),
      ) || 0,
    saleForLessThan1000FeeExemption: {
      label: get(data, 'organization.organizationFees.saleForLessThan1000FeeExemption', '')
        ? 'Yes'
        : 'No',
      value:
        get(data, 'organization.organizationFees.saleForLessThan1000FeeExemption', '') || '',
    },
    saleForLessThan1000FeeExemptionTo:
      get(data, 'organization.organizationFees.saleForLessThan1000FeeExemptionTo', '') || '',
    saleBetween1001And2500InPence:
      pencesToPounds(
        get(data, 'organization.organizationFees.saleBetween1001And2500InPence', 0),
      ) || 0,
    saleBetween1001And2500FeeExemption: {
      label: get(data, 'organization.organizationFees.saleBetween1001And2500FeeExemption', '')
        ? 'Yes'
        : 'No',
      value:
        get(data, 'organization.organizationFees.saleBetween1001And2500FeeExemption', '') ||
        '',
    },
    saleBetween1001And2500FeeExemptionTo:
      get(data, 'organization.organizationFees.saleBetween1001And2500FeeExemptionTo', '') ||
      '',
    saleBetween2501And5000InPence:
      pencesToPounds(
        get(data, 'organization.organizationFees.saleBetween2501And5000InPence', 0),
      ) || 0,
    saleBetween2501And5000FeeExemption: {
      label: get(data, 'organization.organizationFees.saleBetween2501And5000FeeExemption', '')
        ? 'Yes'
        : 'No',
      value:
        get(data, 'organization.organizationFees.saleForLessThan1000FeeExemption', '') || '',
    },
    saleBetween2501And5000FeeExemptionTo:
      get(data, 'organization.organizationFees.saleBetween2501And5000FeeExemptionTo', '') ||
      '',
    saleBetween5001And10000InPence:
      pencesToPounds(
        get(data, 'organization.organizationFees.saleBetween5001And10000InPence', 0),
      ) || 0,
    saleBetween5001And10000FeeExemption: {
      label: get(data, 'organization.organizationFees.saleBetween5001And10000FeeExemption', '')
        ? 'Yes'
        : 'No',
      value:
        get(data, 'organization.organizationFees.saleForLessThan1000FeeExemption', '') || '',
    },
    saleBetween5001And10000FeeExemptionTo:
      get(data, 'organization.organizationFees.saleBetween5001And10000FeeExemptionTo', '') ||
      '',
    saleBetween10001And20000InPence:
      pencesToPounds(
        get(data, 'organization.organizationFees.saleBetween10001And20000InPence', 0),
      ) || 0,
    saleBetween10001And20000FeeExemption: {
      label: get(
        data,
        'organization.organizationFees.saleBetween10001And20000FeeExemption',
        '',
      )
        ? 'Yes'
        : 'No',
      value:
        get(data, 'organization.organizationFees.saleForLessThan1000FeeExemption', '') || '',
    },
    saleBetween10001And20000FeeExemptionTo:
      get(data, 'organization.organizationFees.saleBetween10001And20000FeeExemptionTo', '') ||
      '',
    saleForMoreThan20000InPence:
      pencesToPounds(
        get(data, 'organization.organizationFees.saleForMoreThan20000InPence', 0),
      ) || 0,
    saleForMoreThan20000FeeExemption: {
      label: get(data, 'organization.organizationFees.saleForMoreThan20000FeeExemption', '')
        ? 'Yes'
        : 'No',
      value:
        get(data, 'organization.organizationFees.saleForLessThan1000FeeExemption', '') || '',
    },
    saleForMoreThan20000FeeExemptionTo:
      get(data, 'organization.organizationFees.saleForMoreThan20000FeeExemptionTo', '') || '',
    purchaseForLessThan1000InPence:
      pencesToPounds(
        get(data, 'organization.organizationFees.purchaseForLessThan1000InPence', 0),
      ) || 0,
    purchaseForLessThan1000FeeExemption: {
      label: get(data, 'organization.organizationFees.purchaseForLessThan1000FeeExemption', '')
        ? 'Yes'
        : 'No',
      value:
        get(data, 'organization.organizationFees.saleForLessThan1000FeeExemption', '') || '',
    },
    purchaseForLessThan1000FeeExemptionTo:
      get(data, 'organization.organizationFees.purchaseForLessThan1000FeeExemptionTo', '') ||
      '',
    purchaseBetween1001And2500InPence:
      pencesToPounds(
        get(data, 'organization.organizationFees.purchaseBetween1001And2500InPence', 0),
      ) || 0,
    purchaseBetween1001And2500FeeExemption: {
      label: get(
        data,
        'organization.organizationFees.purchaseBetween1001And2500FeeExemption',
        '',
      )
        ? 'Yes'
        : 'No',
      value:
        get(data, 'organization.organizationFees.saleForLessThan1000FeeExemption', '') || '',
    },
    purchaseBetween1001And2500FeeExemptionTo:
      get(
        data,
        'organization.organizationFees.purchaseBetween1001And2500FeeExemptionTo',
        '',
      ) || '',
    purchaseBetween2501And5000InPence:
      pencesToPounds(
        get(data, 'organization.organizationFees.purchaseBetween2501And5000InPence', 0),
      ) || 0,
    purchaseBetween2501And5000FeeExemption: {
      label: get(
        data,
        'organization.organizationFees.purchaseBetween2501And5000FeeExemption',
        '',
      )
        ? 'Yes'
        : 'No',
      value:
        get(data, 'organization.organizationFees.saleForLessThan1000FeeExemption', '') || '',
    },
    purchaseBetween2501And5000FeeExemptionTo:
      get(
        data,
        'organization.organizationFees.purchaseBetween2501And5000FeeExemptionTo',
        '',
      ) || '',
    purchaseBetween5001And10000InPence:
      pencesToPounds(
        get(data, 'organization.organizationFees.purchaseBetween5001And10000InPence', 0),
      ) || 0,
    purchaseBetween5001And10000FeeExemption: {
      label: get(
        data,
        'organization.organizationFees.purchaseBetween5001And10000FeeExemption',
        '',
      )
        ? 'Yes'
        : 'No',
      value:
        get(data, 'organization.organizationFees.saleForLessThan1000FeeExemption', '') || '',
    },
    purchaseBetween5001And10000FeeExemptionTo:
      get(
        data,
        'organization.organizationFees.purchaseBetween5001And10000FeeExemptionTo',
        '',
      ) || '',
    purchaseBetween10001And20000InPence:
      pencesToPounds(
        get(data, 'organization.organizationFees.purchaseBetween10001And20000InPence', 0),
      ) || 0,
    purchaseBetween10001And20000FeeExemption: {
      label: get(
        data,
        'organization.organizationFees.purchaseBetween10001And20000FeeExemption',
        '',
      )
        ? 'Yes'
        : 'No',
      value:
        get(data, 'organization.organizationFees.saleForLessThan1000FeeExemption', '') || '',
    },
    purchaseBetween10001And20000FeeExemptionTo:
      get(
        data,
        'organization.organizationFees.purchaseBetween10001And20000FeeExemptionTo',
        '',
      ) || '',

    purchaseForMoreThan20000InPence:
      pencesToPounds(
        get(data, 'organization.organizationFees.purchaseForMoreThan20000InPence', 0),
      ) || 0,
    purchaseForMoreThan20000FeeExemption: {
      label: get(
        data,
        'organization.organizationFees.purchaseForMoreThan20000FeeExemption',
        '',
      )
        ? 'Yes'
        : 'No',
      value:
        get(data, 'organization.organizationFees.saleForLessThan1000FeeExemption', '') || '',
    },
    purchaseForMoreThan20000FeeExemptionTo:
      get(data, 'organization.organizationFees.purchaseForMoreThan20000FeeExemptionTo', '') ||
      '',
    mainLocation: get(data, 'organization.mainLocation', '') || '',
    postalCodeId: get(data, 'organization.mainLocation.postalCode.id', '') || '',
    postcode: {
      label: get(data, 'organization.mainLocation.postalCode.postcode', '') || '',
      value: get(data, 'organization.mainLocation.postalCode.id', '') || '',
    },
    address: get(data, 'organization.mainLocation.address', '') || '',
    address2: get(data, 'organization.mainLocation.address2', '') || '',
    city: get(data, 'organization.mainLocation.city', '') || '',
    county: get(data, 'organization.mainLocation.county', '') || '',
    additionalLocation: get(data, 'organization.additionalLocation', []) || [],
    locations: get(data, 'organization.additionalLocation', [
      { postalCode: {}, address: '', address2: '', city: '', county: '' },
    ]) || [{ postalCode: {}, address: '', address2: '', city: '', county: '' }],
    deletedOn: get(data, 'organization.deletedOn', null),
  };

  if (organization.organizationGroup) {
    organization.organizationGroup.label = organization.organizationGroup.name;
    organization.organizationGroup.value = organization.organizationGroup.id;
  }

  const values = {
    ...mainProfile,
    ...organization,
  };

  const isDisabled = values.deletedOn;

  const handleSubmit = (values, { setSubmitting }) => {
    dispatch(editDealer(id, values, statusValue))
      .then(() => {
        setSubmitting(false);
      })
      .catch(() => setSubmitting(false));
  };

  const showMoreNotes = () => {
    if (dealerNotesRef.current) {
      setShowNotes({ show: true });
      dealerNotesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const changeStatus = value => {
    if (statusValue === newStatusValue) {
      setNewStatusValue(false);
      setStatusValue(null);
    } else {
      setNewStatusValue(value);
      setStatusValue(value);
    }
  };

  const renderStatusButtons = () => {
    switch (values.status) {
      case DealerStatus.PENDING:
        return (
          <ButtonGroup>
            {newStatusValue && (
              <span className="dealer-status-button">Click SAVE to confirm this change.</span>
            )}
            <Button
              type="button"
              className={
                newStatusValue === DealerStatus.ACTIVE ? 'active-button' : 'dealer-form-button'
              }
              onClick={() => changeStatus(DealerStatus.ACTIVE)}
            >
              {DealerStatusActionName.ACTIVATE}
            </Button>
            <Button
              type="button"
              className={
                newStatusValue === DealerStatus.REJECTED
                  ? 'reject-button'
                  : 'dealer-form-button'
              }
              onClick={() => changeStatus(DealerStatus.REJECTED)}
            >
              {DealerStatusActionName.REJECT}
            </Button>
          </ButtonGroup>
        );
      case DealerStatus.ACTIVE:
        return (
          <ButtonGroup>
            {newStatusValue && (
              <span className="dealer-status-button">Click SAVE to confirm this change.</span>
            )}
            <Button
              type="button"
              className={
                newStatusValue === DealerStatus.BLOCKED
                  ? 'blocked-button'
                  : 'dealer-form-button'
              }
              onClick={() => changeStatus(DealerStatus.BLOCKED)}
            >
              {DealerStatusActionName.BLOCK}
            </Button>
          </ButtonGroup>
        );
      case DealerStatus.BLOCKED:
        return (
          <ButtonGroup>
            {newStatusValue && (
              <span className="dealer-status-button">Click SAVE to confirm this change.</span>
            )}
            <Button
              type="button"
              className={
                newStatusValue === DealerStatus.ACTIVE ? 'active-button' : 'dealer-form-button'
              }
              onClick={() => changeStatus(DealerStatus.ACTIVE)}
            >
              {DealerStatusActionName.ACTIVATE}
            </Button>
          </ButtonGroup>
        );
      default:
        return null;
    }
  };

  return (
    <div className="content">
      <Row>
        <Col xs="9">
          <Breadcrumbs items={breadcrumbsItems} />
        </Col>
        <Col md="3">
          {isDisabled ? (
            <p className="dealer-form-delete-status">COMPANY DELETED</p>
          ) : (
            <Button className="float-right" color="danger" onClick={() => setShowAlert(true)}>
              Delete
            </Button>
          )}
        </Col>
      </Row>
      <DealerSectionsNav id={id} />
      <Card>
        <CardBody>
          <div className="dealer-form-header">
            <h6>
              Dealer details{' '}
              <span className="dealer-status">
                Status: {DealerUserStatusName?.[values.status]}
              </span>
            </h6>
            {renderStatusButtons()}
          </div>
          <DealerNotesForm
            data={itemsNotes}
            showMoreNotes={showMoreNotes}
            disabled={isDisabled}
            searchParams={searchParamsNotes}
          />
          <Formik
            initialValues={values}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validateOnBlur={true}
            enableReinitialize
          >
            {({ values, errors }) => (
              <DealerForm
                values={values}
                errors={errors}
                setStatusValue={setStatusValue}
                statusValue={statusValue}
                setShowNotes={setShowNotes}
                showNotes={showNotes}
                searchParamsNotes={searchParamsNotes}
                changeOffsetNotes={changeOffsetNotes}
                dealerNotesRef={dealerNotesRef}
                isDisabled={isDisabled}
              />
            )}
          </Formik>
        </CardBody>
      </Card>
      <SweetAlert
        danger
        showCancel
        show={showAlert}
        title="Warning!"
        onConfirm={() => {
          setShowAlert(false);
          dispatch(deleteDealer(id));
        }}
        onCancel={() => setShowAlert(false)}
        confirmBtnBsStyle="info"
      >
        Are you sure to remove this dealer?
      </SweetAlert>
    </div>
  );
};

export default EditDealerPage;
