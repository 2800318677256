import React from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CommonTable from '../common/components/CommonTable';
import ReportsTableRow from './ReportsTableRow';
import CommonLoader from '../common/components/CommonLoader';

const headers = [
  {
    label: 'Auction No.',
  },
  {
    label: 'Registration Number',
  },
  {
    label: 'Make',
  },
  {
    label: 'Model',
  },
  {
    label: 'Car Type',
  },
  {
    label: 'Fuel',
  },
  {
    label: 'Colour',
  },
  {
    label: 'Transmission',
  },
  {
    label: 'D.O.R',
  },
  {
    label: 'Mileage',
  },
  {
    label: 'V5 Present',
  },
  {
    label: 'Sale Price',
  },
  {
    label: 'Cap Below',
  },
  {
    label: 'Cap Retail',
  },
  {
    label: 'Cap Clean',
  },
  {
    label: 'Cap Average',
  },
  {
    label: 'Status',
  },
  {
    label: 'Auction Type',
  },
  {
    label: 'Listing End',
  },
  {
    label: 'Cancelled',
  },
  {
    key: 'Status2',
    label: 'Status',
  },
];

const ReportsTable = ({ data, offset, changeOffset }) => {
  const loaderStatus = useSelector(state => state.reports.get('loaderStatus'));
  return (
    <Col xs="12">
      <CommonLoader status={loaderStatus}>
        <CommonTable
          rowComponent={ReportsTableRow}
          data={data}
          offset={offset}
          onOffsetChange={changeOffset}
          headers={headers}
          title="Reports"
          hasRowPointer={false}
        />
      </CommonLoader>
    </Col>
  );
};

ReportsTable.propTypes = {
  data: PropTypes.object.isRequired,
  offset: PropTypes.number.isRequired,
  changeOffset: PropTypes.func.isRequired,
};

export default ReportsTable;
