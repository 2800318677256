import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../../common/components/Breadcrumbs';
import VehicleSectionsNav from '../VehicleSectionsNav';
import { deleteBid, searchBids } from '../../bids/BidsActions';
import { useQueryString } from '../../common/helpers/queryString';
import VehicleInfoAndBidsLaunch from './VehicleInfoAndBidsLaunch';

const breadcrumbsItems = [
  { id: 1, type: 'home' },
  { id: 2, type: 'link', text: 'Vehicles', url: '/vehicles' },
  { id: 3, type: 'span', text: 'Vehicle bids' },
];

const VehicleBidsPage = () => {
  const dispatch = useDispatch();
  const query = useQueryString();
  const { id: auctionId } = useParams();
  const items = useSelector(state => state.bids.get('items'));
  const auctionDetails = useSelector(state => state.vehicles.get('auctionDetails'));
  const totalItems = useSelector(state => state.bids.get('totalItems'));
  const [vehicleTitle, setVehicleTitle] = useState(null);

  useEffect(() => {
    if (auctionDetails?.basicData) {
      const { makeName } = auctionDetails.basicData;
      const { modelName } = auctionDetails.basicData;
      const { registrationPlate } = auctionDetails.basicData;
      setVehicleTitle(`${makeName} ${modelName}: ${registrationPlate}`);
    }
  }, [auctionDetails]);

  const [searchParams] = useState({
    offset: query.get('page') ? +query.get('page') - 1 : 0,
    auctionId: auctionId || null,
  });

  useEffect(() => {
    dispatch(searchBids(searchParams));
  }, [dispatch, searchParams]);

  const removeItemHandler = bid => {
    if (bid) {
      dispatch(deleteBid(bid.id)).then(() => {
        dispatch(searchBids(searchParams));
      });
    }
  };

  return (
    <div className="content">
      <Breadcrumbs items={breadcrumbsItems} />
      <VehicleSectionsNav section="bids" />
      <VehicleInfoAndBidsLaunch
        bids={{ items, total: totalItems }}
        removeItemHandler={removeItemHandler}
        vehicleTitle={vehicleTitle}
      />
    </div>
  );
};

export default VehicleBidsPage;
