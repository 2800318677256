export const PaymentMethods = {
  CREDIT_CARD: 'CREDIT_CARD',
  DIRECT_DEBIT: 'DIRECT_DEBIT',
  BASED_ON_THE_INVOICE: 'BASED_ON_THE_INVOICE',
};

export const PaymentMethodsName = {
  CREDIT_CARD: 'Credit card',
  DIRECT_DEBIT: 'Direct debit',
  BASED_ON_THE_INVOICE: 'Base on the invoice',
};
