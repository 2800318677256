import { fromJS } from 'immutable';
import { LoaderStatus } from '../common/components/CommonLoader';

const initialData = fromJS({
  userDetails: {},
  loaderStatus: LoaderStatus.IN_PROGRESS,
  items: [],
  totalItems: 0,
});

export const FETCH_CHANGES = 'FETCH_CHANGES';
export const FETCH_CHANGES_SUCCESS = 'FETCH_CHANGES_SUCCESS';
export const FETCH_CHANGES_FAILURE = 'FETCH_CHANGES_FAILURE';
export const FETCH_CHANGES_COUNT_SUCCESS = 'FETCH_CHANGES_COUNT_SUCCESS';

export default (state = initialData, { type, payload }) => {
  switch (type) {
    case FETCH_CHANGES:
      return state.merge({
        loaderStatus: LoaderStatus.IN_PROGRESS,
      });
    case FETCH_CHANGES_SUCCESS:
      return state.merge({
        loaderStatus: LoaderStatus.SUCCESS,
        items: payload.items,
        totalItems: payload.totalItems,
      });
    case FETCH_CHANGES_FAILURE:
      return state.merge({
        loaderStatus: LoaderStatus.NOT_PENDING,
      });
    case FETCH_CHANGES_COUNT_SUCCESS:
      return state.merge({
        totalItems: payload.totalItems,
      });
    default:
      return state;
  }
};
