import React from 'react';
import TvDashboard from 'tvdashboard/TvDashboard';
import TransactionsPage from './transactions/TransactionsPage';
import RouterPaths from './constants/RouterPaths';
import Homepage from './homepage/Homepage';
import RestTest from './resttest/RestTest';
import GroupsPage from './groups/GroupsPage';
import EditGroupPage from './groups/edit/EditGroupPage';
import CreateGroupPage from './groups/create/CreateGroupPage';
import DealersPage from './dealers/DealersPage';
import EditDealerPage from './dealers/information/EditDealerPage';
import UsersPage from './users/UsersPage';
import EditUserPage from './users/edit/EditUserPage';
import AdminsPage from './admins/AdminsPage';
import CreateAdminPage from './admins/create/CreateAdminPage';
import VehiclesPage from './vehicles/VehiclesPage';
import EditVehiclePage from './vehicles/edit/EditVehiclePage';
import EditAdminPage from './admins/edit/EditAdminPage';
import SiteCreatorPage from './sitecreator/SiteCreatorPage';
import CreateSitePage from './sitecreator/create/CreateSitePage';
import EditSitePage from './sitecreator/edit/EditSitePage';
import DealerBlacklistPage from './dealers/blacklist/DealerBlacklistPage';
import BidsPage from './bids/BidsPage';
import VehicleBidsPage from './vehicles/bids/VehicleBidsPage';
import VehicleSellPage from './vehicles/sell/VehicleSellPage';
import SettingsPage from './settings/SettingsPage';
import RequestForChangesPage from './requestforchanges/RequestForChangesPage';
import RequestForChangesBadge from './requestforchanges/RequestForChangesBadge';
import ReportsPage from './reports/ReportsPage';
import ReportsUserPage from './reports/ReportsUserPage';
import { AdminRoleName } from './constants/AdminRole';
import DealerTransactionsPage from './dealers/transactions/DealerTransactionsPage';
import VehicleNotesPage from './vehicles/notes/VehicleNotesPage';

const routes = [
  {
    path: RouterPaths.HOMEPAGE,
    exact: true,
    name: 'Home',
    icon: 'fa fa-home',
    component: Homepage,
    layout: '/user',
    access: AdminRoleName.ADMIN,
  },
  {
    path: RouterPaths.HOMEPAGE,
    exact: true,
    name: 'Home',
    icon: 'fa fa-home',
    component: TvDashboard,
    layout: '/user',
    access: AdminRoleName.DASHBOARD,
  },
  {
    path: RouterPaths.REQUEST_FOR_CHANGES,
    exact: true,
    name: 'Request for changes',
    icon: 'fas fa-edit',
    component: RequestForChangesPage,
    access: AdminRoleName.ADMIN,
    badge: <RequestForChangesBadge />,
  },
  {
    path: RouterPaths.USERS,
    exact: true,
    name: 'Users',
    icon: 'fa fa-users',
    component: UsersPage,
    access: AdminRoleName.ADMIN,
  },
  {
    path: RouterPaths.DEALERS,
    exact: true,
    name: 'Dealers',
    icon: 'fa fa-address-card',
    component: DealersPage,
    access: AdminRoleName.ADMIN,
  },
  {
    path: RouterPaths.GROUPS,
    exact: true,
    name: 'Groups',
    icon: 'nc-icon nc-tile-56',
    component: GroupsPage,
    access: AdminRoleName.ADMIN,
  },
  {
    path: RouterPaths.VEHICLES,
    exact: true,
    name: 'Vehicles',
    icon: 'fa fa-car',
    component: VehiclesPage,
    access: AdminRoleName.ADMIN,
  },
  {
    path: RouterPaths.BIDS,
    exact: true,
    name: 'Bids',
    icon: 'fa fa-money-bill',
    component: BidsPage,
    access: AdminRoleName.ADMIN,
  },
  {
    path: RouterPaths.TRANSACTIONS,
    exact: true,
    name: 'Transactions',
    icon: 'fa fa-credit-card',
    component: TransactionsPage,
    access: AdminRoleName.ADMIN,
  },
  {
    path: RouterPaths.SETTINGS,
    name: 'Settings',
    icon: 'fa fa-cog',
    component: SettingsPage,
    access: AdminRoleName.SUPER_ADMIN,
  },
  {
    path: RouterPaths.ADMINS,
    exact: false,
    name: 'Admins',
    icon: 'nc-icon nc-single-02',
    component: AdminsPage,
    access: AdminRoleName.SUPER_ADMIN,
  },

  {
    exact: true,
    name: 'Reports',
    icon: 'fas fa-list-ul',
    access: AdminRoleName.SUPER_ADMIN,
    collapse: true,
    views: [
      {
        path: RouterPaths.REPORTS,
        exact: true,
        name: 'Reports of Dealers',
        icon: 'fa fa-address-card',
        component: ReportsPage,
        access: AdminRoleName.SUPER_ADMIN,
      },
      {
        path: RouterPaths.REPORTS_USER,
        exact: true,
        name: 'Reports of Users',
        icon: 'fa fa-users',
        component: ReportsUserPage,
        access: AdminRoleName.SUPER_ADMIN,
      },
    ],
  },

  {
    path: RouterPaths.SITE_CREATOR,
    exact: true,
    name: 'Site creator',
    icon: 'fa fa-book',
    component: SiteCreatorPage,
    access: AdminRoleName.SUPER_ADMIN,
  },
  {
    path: RouterPaths.CREATE_SITE,
    exact: true,
    component: CreateSitePage,
    access: AdminRoleName.ADMIN,
  },
  {
    path: RouterPaths.EDIT_SITE,
    exact: true,
    component: EditSitePage,
    access: AdminRoleName.SUPER_ADMIN,
  },
  {
    path: RouterPaths.USERS_DEALER,
    component: UsersPage,
    access: AdminRoleName.ADMIN,
  },
  {
    path: RouterPaths.EDIT_USER,
    component: EditUserPage,
    access: AdminRoleName.ADMIN,
  },
  {
    path: RouterPaths.DEALER_INFORMATION,
    exact: true,
    component: EditDealerPage,
    access: AdminRoleName.ADMIN,
  },
  {
    path: RouterPaths.EDIT_GROUP,
    component: EditGroupPage,
    access: AdminRoleName.ADMIN,
  },
  {
    path: RouterPaths.CREATE_GROUP,
    component: CreateGroupPage,
    access: AdminRoleName.ADMIN,
  },
  {
    path: RouterPaths.EDIT_VEHICLE,
    component: EditVehiclePage,
    exact: true,
    access: AdminRoleName.ADMIN,
  },
  {
    path: RouterPaths.VEHICLE_BIDS,
    component: VehicleBidsPage,
    access: AdminRoleName.ADMIN,
  },
  {
    path: RouterPaths.VEHICLE_SELL,
    component: VehicleSellPage,
    access: AdminRoleName.ADMIN,
  },
  {
    path: RouterPaths.VEHICLES_NOTES,
    component: VehicleNotesPage,
    access: AdminRoleName.ADMIN,
  },
  {
    path: RouterPaths.VEHICLES_DEALER,
    component: VehiclesPage,
    access: AdminRoleName.ADMIN,
  },
  {
    path: RouterPaths.BIDS_DEALER,
    component: BidsPage,
    access: AdminRoleName.ADMIN,
  },
  {
    path: RouterPaths.CREATE_ADMIN,
    component: CreateAdminPage,
    exact: true,
    access: AdminRoleName.ADMIN,
  },
  {
    path: RouterPaths.EDIT_ADMIN,
    component: EditAdminPage,
    exact: true,
    access: AdminRoleName.ADMIN,
  },
  {
    path: RouterPaths.DEALER_BLACKLIST,
    component: DealerBlacklistPage,
    access: AdminRoleName.ADMIN,
  },
  {
    path: RouterPaths.REST_TEST,
    component: RestTest,
    access: AdminRoleName.ADMIN,
  },
  {
    path: RouterPaths.DEALER_TRANSACTIONS,
    component: DealerTransactionsPage,
    access: AdminRoleName.ADMIN,
  },
  {
    path: RouterPaths.TRANSACTIONS_DEALER,
    component: TransactionsPage,
    access: AdminRoleName.ADMIN,
  },
];

export default routes;
