export const UserRole = {
  SELLER: 'SELLER',
  BUYER: 'BUYER',
  TRANSACTION_MANAGEMENT: 'TRANSACTION_MANAGEMENT',
};

export const DealerUserRoleName = {
  BUYER: 'Buyer',
  SELLER: 'Seller',
};

export const EmployeeUserRoleName = {
  BUYER: 'Buyer',
  SELLER: 'Seller',
  TRANSACTION_MANAGEMENT: 'Transaction management',
};
