import React from 'react';
import { Button, Col } from 'reactstrap';
import * as PropTypes from 'prop-types';

const ReportsUserActionButtons = ({ handleDownload }) => {
  return (
    <>
      <Col className="d-flex justify-content-end">
        <Button
          color="primary"
          className="mt-4 float-right"
          type="submit"
          onClick={handleDownload}
        >
          Download
        </Button>
      </Col>
    </>
  );
};

ReportsUserActionButtons.propTypes = {
  handleDownload: PropTypes.func.isRequired,
};

export default ReportsUserActionButtons;
