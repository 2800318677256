import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CommonTable from '../../common/components/CommonTable';
import UserActivitiesTableRow from './UserActivitiesTableRow';
import CommonLoader from '../../common/components/CommonLoader';

const headers = [
  {
    label: 'Date',
  },
  {
    label: 'Description',
  },
  {
    label: 'Device',
  },
];

const UserActivitiesTable = ({ data, offset, changeOffset }) => {
  const loaderUserActivities = useSelector(state => state.users.get('loaderUserActivities'));
  return (
    <Row>
      <Col xs="12">
        <CommonLoader status={loaderUserActivities}>
          <CommonTable
            rowComponent={UserActivitiesTableRow}
            data={data}
            offset={offset}
            onOffsetChange={changeOffset}
            headers={headers}
            title="History"
          />
        </CommonLoader>
      </Col>
    </Row>
  );
};

UserActivitiesTable.propTypes = {
  changeOffset: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  offset: PropTypes.number.isRequired,
};

export default withRouter(UserActivitiesTable);
