import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FieldArray, getIn } from 'formik';
import { Button, Col, Row } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import { cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import uuid from 'react-uuid';
import {
  FormDatePickerField,
  FormInputField,
  FormSelectField,
} from '../../common/components/formFields';
import {
  VehicleServiceHistory,
  VehicleServiceHistoryName,
} from '../../constants/VehicleServiceHistory';
import { constantToSelect } from '../../common/helpers';
import { UploadFileTypes } from '../../constants/UploadFileTypes';
import { addPhoto } from '../VehicleActions';

const vehicleServiceHistories = constantToSelect(VehicleServiceHistoryName);

const VehicleServiceHistoryForm = ({ form, services }) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [showRemoveServiceAlert, setShowRemoveServiceAlert] = useState(false);
  const [removeServiceIndex, setRemoveServiceIndex] = useState(null);

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.map(async file => {
      setPreview(file);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.values.newServiceHistoryPhotoRecords = files;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const changeServiceHistory = serviceHistory => {
    if (serviceHistory?.value === VehicleServiceHistory.NONE) {
      for (let i = 0; i < form.values.serviceHistoryPhotoRecords.length; i++) {
        form.values.serviceHistoryPhotoRecordsToDelete.push(
          form.values.serviceHistoryPhotoRecords[i].mediaMetadataId,
        );
      }
      form.setFieldValue('serviceHistoryPhotoRecords', []);
      form.setFieldValue('services', []);
      setFiles([]);
    } else if (!form?.values?.services || form?.values?.services?.length === 0) {
      form.setFieldValue('services', [
        {
          date: '',
          mileage: '',
          serviceName: '',
        },
      ]);
    }
  };

  const setPreview = async file => {
    if (file && file?.type.includes(UploadFileTypes.IMAGE)) {
      const photo = await dispatch(addPhoto(file));
      if (photo) {
        file = {
          ...photo,
        };
        file.preview = photo.fileUrl;

        setFiles(prevState => {
          const files = cloneDeep(prevState);
          files.push(file);
          return files;
        });
      }
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop,
    noDrag: true,
  });

  const removeImage = (event, fileId) => {
    setFiles(prevState => {
      return prevState.filter(item => item.id !== fileId);
    });
    form.values.serviceHistoryPhotoRecords = form.values.serviceHistoryPhotoRecords.filter(
      item => item.mediaMetadataId !== fileId,
    );
    form.values.serviceHistoryPhotoRecordsToDelete = [
      ...form.values.serviceHistoryPhotoRecordsToDelete,
      fileId,
    ];
    event.stopPropagation();
  };

  const err = name => {
    const error = getIn(form.errors, name);
    const touched = getIn(form.touched, name);
    if ((form.submitCount > 0 || touched) && error) {
      return <label style={{ color: 'red ' }}>{error}</label>;
    }
    return null;
  };

  const thumbs = files.map(file => (
    <div className="thumb" key={file.id}>
      <div className="thumb-inner" role="button" tabIndex="0">
        <img src={file.preview} className="img" alt="preview" />
        <div
          className="remove-image"
          role="presentation"
          onClick={e => removeImage(e, file.id)}
        >
          <i className="fa fa-times" />
        </div>
      </div>
    </div>
  ));

  return (
    <>
      <Row>
        <Col>
          <h6>Service history</h6>
        </Col>
      </Row>

      <Row>
        <Col md="4">
          <FormSelectField
            name="serviceHistory"
            label="Service history"
            options={vehicleServiceHistories}
            onChangeHandler={changeServiceHistory}
          />
        </Col>
      </Row>
      <Row className="media-upload">
        <Col>
          <aside className="thumbs-container">
            {form?.values?.serviceHistoryPhotoRecords &&
              form?.values?.serviceHistoryPhotoRecords.map(photo => (
                <div className="thumb" key={photo.mediaMetadataId}>
                  <div className="thumb-inner">
                    <div
                      className="remove-image"
                      role="presentation"
                      onClick={e => removeImage(e, photo.mediaMetadataId)}
                    >
                      <i className="fa fa-times" />
                    </div>
                    <img
                      key={photo.mediaMetadataId}
                      src={`${photo.fileUrl}-thumbnail`}
                      alt="Vehicle thumbnail"
                      className="img"
                    />
                  </div>
                </div>
              ))}
          </aside>
        </Col>
      </Row>
      {form?.values?.serviceHistory?.value !== VehicleServiceHistory.NONE && (
        <>
          <Row className="media-upload">
            <Col col="4">
              <aside className="thumbs-container">
                {thumbs}
                <div {...getRootProps()} className="add-button">
                  <input {...getInputProps()} />
                  <i className="fa fa-plus-circle" aria-hidden="true" />
                </div>
              </aside>
            </Col>
          </Row>
          <FieldArray
            name="services"
            render={arrayHelpers => (
              <div>
                {services.map((service, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={`services.${index}`}>
                    {/* eslint-disable-next-line react/no-array-index-key */}
                    <Row key={index} className="position-relative">
                      <Col
                        key={uuid()}
                        md="12"
                        className="d-flex justify-content-end remove-service-history"
                      >
                        <Button
                          key={uuid()}
                          className="btn-link"
                          color="primary"
                          size="sm"
                          type="button"
                          onClick={() => {
                            setRemoveServiceIndex(index);
                            setShowRemoveServiceAlert(true);
                          }}
                        >
                          <i className="fa fa-trash" />
                        </Button>
                      </Col>
                      <Col md="4">
                        <FormDatePickerField
                          name={`services.${index}.date`}
                          label="Service date"
                        />
                        {err(`services.${index}.date`)}
                      </Col>
                      <Col md="4">
                        <FormInputField name={`services.${index}.mileage`} label="Mileage" />
                        {err(`services.${index}.mileage`)}
                      </Col>
                      <Col md="4">
                        <FormInputField
                          name={`services.${index}.serviceName`}
                          label="Service name"
                        />
                        {err(`services.${index}.serviceName`)}
                      </Col>
                    </Row>
                    <SweetAlert
                      danger
                      key={uuid()}
                      showCancel
                      show={removeServiceIndex === index && showRemoveServiceAlert}
                      title="Warning!"
                      onConfirm={() => {
                        setShowRemoveServiceAlert(false);
                        arrayHelpers.remove(index);
                      }}
                      onCancel={() => setShowRemoveServiceAlert(false)}
                      confirmBtnBsStyle="info"
                    >
                      Are you sure to remove a service?
                    </SweetAlert>
                  </div>
                ))}
                <Row>
                  <Button
                    color="neutral"
                    type="button"
                    onClick={() =>
                      arrayHelpers.push({
                        date: '',
                        mileage: '',
                        serviceName: '',
                      })
                    }
                  >
                    Add another service
                  </Button>
                </Row>
              </div>
            )}
          />
        </>
      )}
    </>
  );
};

VehicleServiceHistoryForm.propTypes = {
  form: PropTypes.object.isRequired,
  services: PropTypes.array.isRequired,
};

export default VehicleServiceHistoryForm;
