import React from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import DealersSelectField from 'dealers/forms/DealersSelectField';
import validationSchema from './DealerSearch.schema';
import {
  FormDatePickerField,
  FormInputField,
  FormSelectField,
} from '../../common/components/formFields';
import { constantToSelect } from '../../common/helpers';
import { DealerRoleName } from '../../constants/DealerRole';

const initialValues = {
  serial: '',
  tradeName: '',
  mainEmail: '',
  registeredDateFrom: '',
  registeredDateTo: '',
};
const organizationRoles = constantToSelect(DealerRoleName);
const DealerSearch = ({ handleSearch }) => {
  return (
    <Row>
      <Col md="12">
        <Card>
          <CardBody>
            <h6>Search dealer</h6>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSearch}
              validateOnBlur={true}
            >
              {({ resetForm, submitForm }) => (
                <Form noValidate>
                  <Row>
                    <Col className="pr-1" md="4">
                      <FormInputField name="serial" label="ID" defaultValue="" />
                    </Col>
                    <Col className="px-1" md="4">
                      <DealersSelectField name="tradeName" label="Dealer" />
                    </Col>
                    <Col className="px-1" md="4">
                      <FormInputField name="mainEmail" label="Email" />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="4">
                      <FormDatePickerField
                        name="registeredDateFrom"
                        label="Registered date from"
                      />
                    </Col>
                    <Col className="px-1" md="4">
                      <FormDatePickerField
                        name="registeredDateTo"
                        label="Registered date to"
                        isUpperRange={true}
                      />
                    </Col>
                    <Col md="4">
                      <FormSelectField name="role" label="Role" options={organizationRoles} />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Button color="primary" className="mt-4 float-right" type="submit">
                        Search
                      </Button>
                      <Button
                        color="primary"
                        outline={true}
                        className="mt-4 float-right"
                        type="button"
                        onClick={() => {
                          resetForm();
                          submitForm();
                        }}
                      >
                        Clear
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

DealerSearch.propTypes = {
  handleSearch: PropTypes.func.isRequired,
};

export default DealerSearch;
