import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Button } from 'reactstrap';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { FormInputField, FormInputFieldType } from '../../../common/components/formFields';
import { addDealerNote, fetchDealerNotes } from '../../DealersActions';
import { dateToString } from '../../../common/utils/date/Date.utils';
import CommonErrorLabel from '../../../common/components/CommonErrorLabel';

const initialValues = {
  contentNote: '',
};

const errorGrid = { size: 6, offset: 6 };

const DealerNotesForm = ({ showMoreNotes, disabled, searchParams }) => {
  const dispatch = useDispatch();
  const [heightNote, setHeightNote] = useState(125);
  const dealerDetails = useSelector(state => state.dealers.get('dealerDetails'));
  const [contentNoteError, setContentNoteError] = useState('');

  const validateFormField = (value, required = true, maxLength = null) => {
    let error = '';
    if (required && !value) {
      error = 'Field is required';
      return error;
    }
    if (maxLength && value.length > maxLength) {
      error = `Max length equals ${maxLength} characters`;
      return error;
    }
    return null;
  };

  const validateContentNote = values =>
    new Promise((resolve, reject) => {
      const { contentNote } = values;
      const contentNoteErrorMessage = validateFormField(contentNote, true, 1000);

      if (contentNoteErrorMessage) {
        setContentNoteError(contentNoteErrorMessage);
        reject();
      }
      resolve();
    });

  const handleSubmit = (values, { setSubmitting, setFieldValue }) => {
    setFieldValue('contentNote', values.contentNote.replace(/ /, 's'));
    validateContentNote(values)
      .then(() => {
        setContentNoteError('');
        dispatch(
          addDealerNote(values, dealerDetails?.organization?.id, () => {
            dispatch(fetchDealerNotes(searchParams, dealerDetails?.organization?.id));
            setFieldValue('contentNote', '');
          }),
        );
      })
      .catch(() => {});
    setSubmitting(false);
  };

  const dealerNotes = useSelector(state => state.dealers.get('dealerNotes'));
  const itemsNotes = dealerNotes?.items || null;
  const note = itemsNotes ? itemsNotes[0] : null;

  useEffect(() => {
    if (!note) {
      setHeightNote(125);
    }
  }, [note]);

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form noValidate>
          <div className="dealer-note-form">
            {note && (
              <Col md="6" className="note-display">
                <Row>
                  <Col>Last note</Col>
                  <Col>
                    <Button
                      color="neutral"
                      type="button"
                      className="float-right"
                      onClick={() => showMoreNotes()}
                    >
                      More
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      ref={el => {
                        if (!el) return;
                        setHeightNote(el.getBoundingClientRect().height + 50);
                      }}
                    >
                      {note?.content}
                    </div>
                    <small className="float-right">
                      {note?.createdBy}, {note?.createdOn && dateToString(note?.createdOn)}
                    </small>
                  </Col>
                </Row>
              </Col>
            )}
            <Col md={note ? 6 : null} className="note-editor">
              <Row>
                <Col>Add note</Col>
                <Col>
                  <Button color="neutral" type="submit" className="float-right">
                    Add
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormInputField
                    noStyling={true}
                    style={{ height: `${heightNote}px` }}
                    name="contentNote"
                    type={FormInputFieldType.TEXTAREA}
                    disabled={disabled}
                  />
                </Col>
              </Row>
            </Col>
          </div>
          {contentNoteError ? (
            <Row>
              <Col md={note ? errorGrid : null}>
                <CommonErrorLabel value={contentNoteError} />
              </Col>
            </Row>
          ) : null}
        </Form>
      </Formik>
    </div>
  );
};

DealerNotesForm.defaultProps = {
  disabled: false,
};

DealerNotesForm.propTypes = {
  showMoreNotes: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default DealerNotesForm;
