import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { SiteStatusName } from 'constants/SiteCreator';

const SiteCreatorTableRow = ({ item }) => {
  return (
    <>
      <td>{item.serial}</td>
      <td>{item.title}</td>
      <td>{item.isActive ? SiteStatusName.ACTIVE : SiteStatusName.INACTIVE}</td>
      <td>
        <Button
          className="btn-link"
          color="primary"
          id={`tooltip${item.id}`}
          size="sm"
          type="button"
        >
          <i className="fa fa-edit" />
        </Button>
        <UncontrolledTooltip
          className="uncontrolled-tooltip"
          delay={0}
          target={`tooltip${item.id}`}
        >
          Edit
        </UncontrolledTooltip>
      </td>
    </>
  );
};

export default SiteCreatorTableRow;
