/* eslint-disable react/no-array-index-key */
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Switch, Route } from 'react-router-dom';
import AdminNavbar from 'common/components/Navbars/LoggedNavbar.js';
import Footer from 'common/components/Footer/Footer.js';
import Sidebar from 'common/components/Sidebar/Sidebar.js';
import jwt from 'jsonwebtoken';
import { get } from 'lodash';
import { AdminRole } from 'constants/AdminRole';
import NotFound from 'notfound/NotFound';
import history from '../browserHistory';
import RouterPaths from '../constants/RouterPaths';
import ProtectedRoute from '../common/hoc/ProtectedRoute';
import { AUTH_TOKEN } from '../auth/AuthConstants';
import { accessRoutes } from '../helpers/accessRoutes';

class MainLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountType: '',
    };
  }

  componentDidMount() {
    const authToken = localStorage.getItem(AUTH_TOKEN);
    const decode = jwt.decode(authToken);
    const accountType = get(decode, 'accountType', '');
    this.setState({ accountType });
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      return (
        <ProtectedRoute
          path={prop.path}
          component={prop.component}
          key={key}
          exact={prop.exact}
        />
      );
    });
  };

  handleMiniClick = () => {
    document.body.classList.toggle('sidebar-mini');
  };

  render() {
    const { accountType } = this.state;
    if (this.props.isAuthenticated) {
      return (
        <div>
          {accountType !== AdminRole.DASHBOARD && (
            <Sidebar
              {...this.props}
              bgColor="white"
              activeColor="info"
              routes={accessRoutes(accountType)}
            />
          )}
          <div
            className={`main-panel ${accountType === AdminRole.DASHBOARD && 'tv-dashboard'}`}
          >
            <AdminNavbar {...this.props} handleMiniClick={this.handleMiniClick} />
            <Switch>
              {history.location.pathname === '/' && <Redirect to={RouterPaths.HOMEPAGE} />}
              {this.getRoutes(accessRoutes(accountType))}
              <Route component={NotFound} />
            </Switch>
            {// we don't want the footer to be rendered on full screen maps page
            this.props.location.pathname.indexOf('full-screen-map') !== -1 ? null : (
              <Footer fluid />
            )}
          </div>
        </div>
      );
    }
    return (
      <>
        <Switch>
          <Redirect to={RouterPaths.LOGIN} />
        </Switch>
      </>
    );
  }
}

const mapStateToProps = ({ auth }) => ({
  isAuthenticated: auth.get('isAuthenticated'),
});

export default connect(mapStateToProps)(MainLayout);
