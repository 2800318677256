import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  CardBody,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';

import { get } from 'lodash';
import { withRouter } from 'react-router-dom';
import ResetPasswordSchema from './ResetPasswordForm.schema';
import { resetPassword } from '../AuthActions';
import CommonAuthLayoutWrapper from '../../common/components/CommonAuthLayoutWrapper';

const ResetPasswordForm = ({ match }) => {
  useEffect(() => {
    document.body.classList.toggle('login-page');
    return () => {
      document.body.classList.toggle('login-page');
    };
  }, []);

  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [errors, setErrors] = useState({});
  const token = get(match, 'params.token', null);

  const dispatch = useDispatch();

  const handleRecoverPassword = () => {
    setErrors({});
    ResetPasswordSchema.validate(
      {
        password,
        passwordRepeat,
      },
      { abortEarly: false },
    )
      .then(() => {
        dispatch(
          resetPassword({ password, passwordRepeat, token }, () => {
            setPassword('');
            setPasswordRepeat('');
          }),
        );
      })
      .catch(validation => {
        const err = {};
        validation.inner.forEach(e => {
          err[e.path] = e.message;
        });

        setErrors({
          ...err,
        });
      });
  };

  return (
    <CommonAuthLayoutWrapper
      title="Reset your password"
      submitButtonTitle="Reset password"
      submitAction={() => handleRecoverPassword(password, token, resetPassword)}
    >
      <CardBody>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="nc-icon nc-key-25" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Password"
            type="password"
            autoComplete="off"
            value={password}
            invalid={!!errors.password}
            onChange={e => setPassword(e.target.value)}
          />
          {errors && errors.password && <FormFeedback>{errors.password}</FormFeedback>}
        </InputGroup>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="nc-icon nc-key-25" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder="Repeat password"
            type="password"
            autoComplete="off"
            value={passwordRepeat}
            invalid={!!errors.passwordRepeat}
            onChange={e => setPasswordRepeat(e.target.value)}
          />
          {errors && errors.passwordRepeat && (
            <FormFeedback>{errors.passwordRepeat}</FormFeedback>
          )}
        </InputGroup>
      </CardBody>
    </CommonAuthLayoutWrapper>
  );
};

export default withRouter(ResetPasswordForm);
