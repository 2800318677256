import * as Yup from 'yup';

export default () => {
  return Yup.object().shape({
    dealer: Yup.string().required('Field is required'),
    createdOnFrom: Yup.date().required('Field is required'),
    createdOnTo: Yup.date()
      .required('Field is required')
      .when('createdOnFrom', (createdOnFrom, schema) => {
        const date = new Date(createdOnFrom);
        date.setMonth(date.getMonth() + 24);
        return createdOnFrom && schema.max(date, 'The data range cannot exceed 24 months');
      }),
  });
};
