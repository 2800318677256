import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumbs from '../common/components/Breadcrumbs';
import DealerSearch from './search/DealerSearch';
import DealersTable from './DealersTable';
import DealersStatusFilter from './DealersStatusFilter';
import { setQueryString, useQueryString } from '../common/helpers/queryString';
import { searchDealer } from './DealersActions';

const breadcrumbsItems = [
  { id: 1, type: 'home' },
  { id: 2, type: 'span', text: 'Dealers' },
];

const DealersPage = ({ history, location }) => {
  const query = useQueryString();
  const dispatch = useDispatch();

  const items = useSelector(state => state.dealers.get('items'));
  const totalItems = useSelector(state => state.dealers.get('totalItems'));

  const [searchParams, setSearchParams] = useState({
    offset: query.get('page') ? +query.get('page') - 1 : 0,
  });

  useEffect(() => {
    dispatch(searchDealer(searchParams));
  }, [dispatch, searchParams]);

  const handleChangeStatus = status => {
    setSearchParams(prevState => ({ ...prevState, status, offset: 0 }));
  };

  const handleSearch = (values, { setSubmitting }) => {
    setSearchParams(prevState => ({
      ...prevState,
      ...values,
      tradeName: values?.tradeName?.label,
      offset: 0,
    }));
    setSubmitting(false);
  };

  const changeOffset = value => {
    setSearchParams(prevState => ({ ...prevState, offset: value }));
    history.push(setQueryString(location, 'page', value + 1));
  };

  return (
    <div className="content">
      <Breadcrumbs items={breadcrumbsItems} />
      <DealerSearch handleSearch={handleSearch} />
      <DealersStatusFilter handleChangeStatus={handleChangeStatus} />
      <DealersTable
        data={{ items, total: totalItems }}
        offset={searchParams.offset}
        changeOffset={changeOffset}
      />
    </div>
  );
};

export default withRouter(DealersPage);
