import * as Yup from 'yup';
import { FormRegExp } from '../../constants/FormRegExp';

export default () => {
  return Yup.object().shape({
    firstName: Yup.string()
      .matches(FormRegExp.STRING_WITH_SPECIAL_CHARS, 'Type correct characters')
      .max(50, 'First name is too long - should be 50 chars maximum'),
    lastName: Yup.string()
      .matches(FormRegExp.STRING_WITH_SPECIAL_CHARS, 'Type correct characters')
      .max(50, 'Last name is too long - should be 50 chars maximum'),
  });
};
