import React from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import validationSchema from './AdminsSearch.schema';
import { FormInputField, FormSelectField } from '../../common/components/formFields';
import { constantToSelect } from '../../common/helpers/constantToSelect';
import { AdminRoleName } from '../../constants/AdminRole';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  role: '',
};

const adminRoles = constantToSelect(AdminRoleName);

const AdminsSearch = ({ handleSearch }) => {
  return (
    <>
      <Col md="12">
        <Card>
          <CardBody>
            <h6>Search admin</h6>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSearch}
              validateOnBlur={true}
            >
              {({ resetForm, submitForm }) => (
                <Form noValidate>
                  <Row>
                    <Col md="4">
                      <FormInputField name="firstName" label="First name" />
                    </Col>
                    <Col md="4">
                      <FormInputField name="lastName" label="Last name" />
                    </Col>
                    <Col md="4">
                      <FormInputField name="email" label="Email" type="email" />
                    </Col>
                    <Col md="4">
                      <FormSelectField name="role" label="Role" options={adminRoles} />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Button color="primary" className="mt-4 float-right" type="submit">
                        Search
                      </Button>
                      <Button
                        color="primary"
                        outline={true}
                        className="mt-4 float-right"
                        type="button"
                        onClick={() => {
                          resetForm();
                          submitForm();
                        }}
                      >
                        Clear
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

AdminsSearch.propTypes = {
  handleSearch: PropTypes.func.isRequired,
};
export default AdminsSearch;
