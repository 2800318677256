const yup = require('yup');

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid Email Format')
    .required('Field required'),
  password: yup.string().required('Field required'),
});

export default schema;
