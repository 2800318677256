import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { feeAgreeOptions } from '../../../../constants/feeAgreeOptions';
import {
  FormSelectField,
  FormDatePickerField,
  FormCurrencyInputField,
} from '../../../../common/components/formFields';
import { AgreeOptions } from '../../../../constants/AgreeOptions';

const FeeOption = ({ disabled, values, name, label, validationMessage }) => {
  const [isBlockedFeeExemptionTo, setBlockedFeeExemptionTo] = useState(true);

  useEffect(() => {
    if (values[`${name}FeeExemption`]?.label === AgreeOptions.YES) {
      setBlockedFeeExemptionTo(false);
    } else {
      setBlockedFeeExemptionTo(true);
    }
  }, [label, name, values]);

  return (
    <Row>
      <Col md="4">
        <FormCurrencyInputField name={`${name}InPence`} label={label} disabled={disabled} />
        {validationMessage && <label className="error-label">{validationMessage}</label>}
      </Col>
      <Col md="4">
        <FormSelectField
          name={`${name}FeeExemption`}
          label="Fee exemption"
          options={feeAgreeOptions}
          disabled={disabled}
        />
      </Col>
      <Col md="4">
        <FormDatePickerField
          name={`${name}FeeExemptionTo`}
          label="to"
          disabled={disabled || isBlockedFeeExemptionTo}
          readOnly={!disabled && !isBlockedFeeExemptionTo}
        />
      </Col>
    </Row>
  );
};

FeeOption.defaultProps = {
  disabled: false,
  validationMessage: '',
};

FeeOption.propTypes = {
  disabled: PropTypes.bool,
  values: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  validationMessage: PropTypes.string,
};

export default FeeOption;
