import React from 'react';
import PropTypes from 'prop-types';
import { DealerUserStatusName } from '../constants/UserStatus';
import { UserPositionName } from '../constants/UserPosition';
import { AgreeOptions } from '../constants/AgreeOptions';

const ReportsUserTableRow = ({ item }) => {
  return (
    <>
      <td>{item.organizationName}</td>
      <td>{item.isOwner ? AgreeOptions.YES : AgreeOptions.NO}</td>
      <td>{item.firstName}</td>
      <td>{item.lastName}</td>
      <td>{item.email}</td>
      <td>{item.phoneNumber}</td>
      <td>{item.status ? DealerUserStatusName[item.status] : ''}</td>
      <td>{item.location}</td>
      <td>{item.position ? UserPositionName[item.position] : ''}</td>
    </>
  );
};

ReportsUserTableRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ReportsUserTableRow;
