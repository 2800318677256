import { fromJS } from 'immutable';
import { LoaderStatus } from '../common/components/CommonLoader';

const initialData = fromJS({
  items: [],
  totalItems: null,
  isLoading: null,
  loaderStatus: LoaderStatus.IN_PROGRESS,
  bidsSearchParams: null,
  selectedBidId: null,
});

export const SEARCH_BIDS = 'SEARCH_BIDS';
export const SEARCH_BIDS_SUCCESS = 'SEARCH_BIDS_SUCCESS';
export const SEARCH_BIDS_FAILURE = 'SEARCH_BIDS_FAILURE';
export const ADD_VEHICLE_BID = 'ADD_VEHICLE_BID';
export const ADD_VEHICLE_BID_SUCCESS = 'ADD_VEHICLE_BID_SUCCESS';
export const ADD_VEHICLE_BID_FAILURE = 'ADD_VEHICLE_BID_FAILURE';
export const SET_BIDS_SEARCH_PARAMS = 'SET_BIDS_SEARCH_PARAMS';
export const SET_SELECTED_BID = 'SET_SELECTED_BID';

export default (state = initialData, { type, payload }) => {
  switch (type) {
    case SEARCH_BIDS:
      return state.merge({
        loaderStatus: LoaderStatus.IN_PROGRESS,
      });
    case SEARCH_BIDS_SUCCESS: {
      const { items, totalItems } = payload;
      return state.merge({
        items,
        totalItems,
        loaderStatus: LoaderStatus.SUCCESS,
      });
    }
    case SEARCH_BIDS_FAILURE: {
      const items = payload?.items;
      const totalItems = payload?.totalItems;
      return state.merge({
        items,
        totalItems,
        loaderStatus: LoaderStatus.NOT_PENDING,
      });
    }
    case ADD_VEHICLE_BID: {
      return state.merge({
        isLoading: true,
      });
    }
    case ADD_VEHICLE_BID_SUCCESS: {
      return state.merge({
        isLoading: false,
      });
    }
    case ADD_VEHICLE_BID_FAILURE: {
      return state.merge({
        isLoading: false,
      });
    }
    case SET_BIDS_SEARCH_PARAMS: {
      return state.merge({
        bidsSearchParams: payload,
      });
    }
    case SET_SELECTED_BID: {
      return state.merge({
        selectedBidId: payload,
      });
    }
    default:
      return state;
  }
};
