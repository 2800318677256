import React from 'react';
import Footer from '../common/components/Footer/Footer';
import AuthNavbar from '../common/components/Navbars/AuthNavbar';
import ResetPasswordForm from '../auth/resetpassword/ResetPasswordForm';

const ResetPassword = () => {
  return (
    <>
      <AuthNavbar />
      <div className="full-page section-image">
        <ResetPasswordForm />
        <Footer fluid />
      </div>
    </>
  );
};

export default ResetPassword;
