import React from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { Formik, Form } from 'formik';
import validationSchema from './SiteCreator.schema';
import { FormInputField, FormSelectField } from '../../common/components/formFields';
import { constantToSelect } from '../../common/helpers/constantToSelect';
import { SiteStatusName } from '../../constants/SiteCreator';

const initialValues = {
  title: '',
  status: '',
};

const siteCreatorStatuses = constantToSelect(SiteStatusName);

const SiteCreatorSearch = ({ handleSearch }) => {
  return (
    <>
      <Col md="12">
        <Card>
          <CardBody>
            <h6>Search site</h6>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSearch}
              validateOnBlur={true}
            >
              {() => (
                <Form noValidate>
                  <Row>
                    <Col md="4">
                      <FormInputField name="title" label="Name" />
                    </Col>
                    <Col md="4">
                      <FormSelectField
                        name="status"
                        label="Status"
                        options={siteCreatorStatuses}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Button color="primary" className="mt-4 float-right" type="submit">
                        Search
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default SiteCreatorSearch;
