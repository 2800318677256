import React, { useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import CommonTable from '../../common/components/CommonTable';
import EditDealerChangesTableRow from './EditDealerChangesTableRow';

const headers = [
  {
    label: 'Date',
  },
  {
    label: 'Author',
  },
  {
    label: 'Change',
  },
];

const EditDealerChangesTable = ({ data, offset, changeOffset }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Row>
        <Button color="neutral" type="button" onClick={() => setShow(prev => !prev)}>
          Changes {show ? '-' : '+'}
        </Button>
      </Row>
      {show && (
        <Row>
          <Col xs="12">
            <CommonTable
              rowComponent={EditDealerChangesTableRow}
              data={data}
              offset={offset}
              onOffsetChange={changeOffset}
              headers={headers}
              hasRowPointer={false}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

EditDealerChangesTable.propTypes = {
  changeOffset: PropTypes.func.isRequired,
};

export default EditDealerChangesTable;
