import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { reverse } from 'named-urls';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CommonTable from '../../common/components/CommonTable';
import DashboardTableRow from './DashboardTableRow';
import RouterPaths from '../../constants/RouterPaths';
import { AdminRole } from '../../constants/AdminRole';

const DashboardTable = ({ data, offset, changeOffset, history }) => {
  const accountType = useSelector(state => state.auth.get('accountType'));
  const isDashboardAccount = accountType === AdminRole.DASHBOARD;

  const headers = [
    {
      label: 'ID',
    },
    {
      label: 'Plate',
    },
    {
      label: 'Title',
    },
    {
      label: 'Sold Date',
    },
    {
      label: 'Transaction amount',
    },
    {
      label: 'Seller',
    },
    {
      label: 'Buyer',
    },
    !isDashboardAccount && {
      label: 'Actions',
    },
  ];

  return (
    <Row>
      <Col xs="12">
        <CommonTable
          rowComponent={DashboardTableRow}
          rowComponentProps={{ isDashboardAccount }}
          data={data}
          offset={offset}
          onOffsetChange={changeOffset}
          headers={headers}
          pagination={false}
          handleRowClick={item => {
            if (!isDashboardAccount) {
              history.push(reverse(RouterPaths.EDIT_VEHICLE, { id: item.id }));
            }
          }}
          hasRowPointer={!isDashboardAccount}
        />
      </Col>
    </Row>
  );
};

DashboardTable.defaultProps = {
  offset: 0,
  changeOffset: () => {},
};

DashboardTable.propTypes = {
  offset: PropTypes.number,
  changeOffset: PropTypes.func,
  data: PropTypes.object.isRequired,
};

export default withRouter(DashboardTable);
