import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';

import { SageStatus } from '../constants/SageStatus';
import { SageBaseUrl } from '../constants/SageBaseUrl';
import { prepareSageQueryParams } from '../helpers/prepareSageQueryParams';

import { fetchSageStatus, fetchSageSettings } from './SettingsActions';
import { PrepareUrlWithQueryParams } from '../common/helpers/PrepareUrl';
import { SAGE_STATE_ID } from '../constants/SageStateId';

const SagaConnectPanel = () => {
  const [isSageConnected, setIsSageConnected] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const sageStatus = useSelector(state => state.settings.get('sageStatus'));
  const sageSettings = useSelector(state => state.settings.get('sageSettings'));

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSageStatus());
  }, [dispatch]);

  useEffect(() => {
    setIsSageConnected(sageStatus?.sageConnected || false);
  }, [sageStatus]);

  useEffect(() => {
    if (sageSettings.clientId && !isSageConnected) {
      const sageStateId = uuid();
      localStorage.setItem(SAGE_STATE_ID, sageStateId);
      window.location.href = PrepareUrlWithQueryParams(
        SageBaseUrl,
        prepareSageQueryParams(sageSettings, sageStateId),
      );
    }
  }, [sageSettings, isSageConnected]);

  const handleClick = () => {
    dispatch(fetchSageSettings());
    setButtonDisabled(true);
  };

  return (
    <>
      <Row>
        <Col md="12">
          <h5 className="settings__sage-title">Sage</h5>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <h6
            className={
              isSageConnected ? 'settigs__sage-status--connected' : 'settings__sage-status'
            }
          >
            {isSageConnected ? SageStatus.CONNECTED : SageStatus.DISCONNECTED}
          </h6>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          {!isSageConnected && (
            <Button
              color="primary"
              onClick={handleClick}
              className="mt-4 float-right"
              type="button"
              disabled={buttonDisabled}
            >
              Connect
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
};

export default SagaConnectPanel;
