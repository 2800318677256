import axios from 'axios';
import jwt from 'jsonwebtoken';
import AlertVariant from '../common/components/alert/AlertVariant';
import { showAlert } from '../common/components/alert/AlertActions';
import { errorCodes } from '../constants/ErrorCodes';
import { isDateBefore } from '../common/utils/date/Date.utils';
import { AUTH_TOKEN } from '../auth/AuthConstants';
import { logout } from '../auth/AuthActions';
import { store } from '../index';

let authAuthInterceptor = null;

const BACKEND_URL =
  process.env.REACT_APP_BACKEND_URL || 'https://adminbackend.itradestaging.link';

export const apiClient = axios.create({
  baseURL: `${BACKEND_URL}`,
  timeout: 50000,
});

apiClient.interceptors.request.use(req => {
  const authToken = jwt.decode(localStorage.getItem(AUTH_TOKEN));
  const tokenExpInSeconds = authToken?.exp;
  if (!authToken || isDateBefore(tokenExpInSeconds * 1000, new Date())) {
    store.dispatch(logout());
  }
  return req;
});

apiClient.interceptors.response.use(res => {
  if (res && res.data && res.data.errorCode) {
    throw res.data.errorCode;
  }
  return res;
});

const addAuthInterceptor = logoutAction => {
  authAuthInterceptor = apiClient.interceptors.response.use(
    res => res,
    error => {
      if (error && error.response && error.response.status === 401) {
        logoutAction();
      }
      throw error;
    },
  );
};

const removeAuthInterceptor = () => {
  if (authAuthInterceptor) {
    apiClient.interceptors.response.eject(authAuthInterceptor);
  }
};

export const setAuthorizationHeader = (token, logoutAction) => {
  if (token) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`;
    addAuthInterceptor(logoutAction);
  } else {
    removeAuthInterceptor();
    delete apiClient.defaults.headers.common.Authorization;
  }
};

export const handleError = (err, form = null) => dispatch => {
  let errorMessage;
  if (err?.response) {
    switch (err?.response?.status) {
      case 404:
        dispatch(showAlert('Not Found', AlertVariant.DANGER));
        break;

      case 401:
      case 403:
        dispatch(showAlert('Unauthorized', AlertVariant.DANGER));
        break;

      case 400:
      case 418:
      case 500:
        errorMessage = err?.response?.data?.message || err?.response?.data?.code;
        dispatch(showAlert(`${errorMessage}`, AlertVariant.DANGER));
        break;

      case 412:
        if (form) {
          form.setErrors(err?.response?.data?.data);
        }
        break;

      default:
        break;
    }
  } else {
    let errorCode;
    switch (err?.code) {
      case errorCodes.ECONNABORTED:
        errorCode =
          'There was a problem with the network. Please check your internet connection and try again.';
        break;
      default:
        errorCode = err?.response?.data?.message || err;
    }
    dispatch(showAlert(errorCode, AlertVariant.DANGER));
  }

  if (form) {
    form.setSubmitting(false);
  }
};
