import React, { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumbs from '../../common/components/Breadcrumbs';
import DealerBlacklistForm from './DealerBlacklistForm';
import DealerBlacklistTable from './DealerBlacklistTable';
import RouterPaths from '../../constants/RouterPaths';
import { PrepareUrl } from '../../common/helpers/PrepareUrl';
import { setQueryString, useQueryString } from '../../common/helpers/queryString';
import { searchBlacklistDealers } from '../DealersActions';

const DealerBlacklistPage = ({ history, location }) => {
  const { id } = useParams();
  const breadcrumbsItems = [
    { id: 1, type: 'home' },
    { id: 2, type: 'link', text: 'Dealers', url: '/dealers' },
    {
      id: 3,
      type: 'link',
      text: 'Edit Dealer',
      url: PrepareUrl(RouterPaths.DEALER_INFORMATION, { id }),
    },
    { id: 4, type: 'span', text: 'Blacklist' },
  ];
  const query = useQueryString();
  const dispatch = useDispatch();

  const items = useSelector(state => state.dealers.get('items'));
  const totalItems = useSelector(state => state.dealers.get('totalItems'));

  const [searchParams, setSearchParams] = useState({
    offset: query.get('page') ? +query.get('page') - 1 : 0,
  });

  useEffect(() => {
    dispatch(searchBlacklistDealers(searchParams, id));
  }, [dispatch, searchParams, id]);

  const changeOffset = value => {
    setSearchParams(prevState => ({ ...prevState, offset: value }));
    history.push(setQueryString(location, 'page', value + 1));
  };

  return (
    <div className="content">
      <Breadcrumbs items={breadcrumbsItems} />
      <DealerBlacklistForm searchParams={searchParams} dealers={items} />
      <DealerBlacklistTable
        data={{ items, total: totalItems }}
        offset={searchParams.offset}
        changeOffset={changeOffset}
        searchParams={searchParams}
      />
    </div>
  );
};

export default withRouter(DealerBlacklistPage);
