import { Col, Row } from 'reactstrap';
import React from 'react';
import CommonTable from '../../common/components/CommonTable';
import VehicleNoteTableRow from './VehicleNoteTableRow';

const headers = [
  {
    label: 'Date',
  },
  {
    label: 'Author',
  },
  {
    label: 'Note',
  },
];

const VehicleNotesTable = ({ data, offset, changeOffset }) => {
  return (
    <Row>
      <Col xs="12">
        <CommonTable
          rowComponent={VehicleNoteTableRow}
          data={data}
          offset={offset}
          onOffsetChange={changeOffset}
          headers={headers}
          pagination={true}
          hasRowPointer={false}
        />
      </Col>
    </Row>
  );
};

export default VehicleNotesTable;
