import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Col, CustomInput, Form, FormGroup, Label, Row } from 'reactstrap';
import { NotificationType } from '../../constants/NotificationType';

const NotificationTypeSection = ({ title, emailSettings, pushSettings, onChangeConfig }) => {
  return (
    <div>
      <h5 className="text-center font-weight-bold mt-3">{title}</h5>
      <Row>
        <Col xs="6">
          <Form className="text-left ">
            <FormGroup>
              <Label for="email">EMAIL</Label>
              <div>
                {emailSettings &&
                  emailSettings.map(item => (
                    <CustomInput
                      key={`${item.event}email`}
                      type="switch"
                      label={item.eventMessage}
                      checked={item.checked}
                      onChange={() => onChangeConfig(NotificationType.EMAIL, item.event)}
                      id={`${item.eventMessage}email`}
                    />
                  ))}
              </div>
            </FormGroup>
          </Form>
        </Col>
        <Col xs="6">
          <Form className="text-left">
            <FormGroup>
              <Label for="push">PUSH</Label>
              <div>
                {pushSettings &&
                  pushSettings.map(item => (
                    <CustomInput
                      key={`${item.event}push`}
                      type="switch"
                      label={item.eventMessage}
                      checked={item.checked}
                      onChange={() => onChangeConfig(NotificationType.PUSH, item.event)}
                      id={`${item.eventMessage}push`}
                    />
                  ))}
              </div>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </div>
  );
};
NotificationTypeSection.defaultProps = {
  emailSettings: [],
  pushSettings: [],
};

NotificationTypeSection.propTypes = {
  title: PropTypes.string.isRequired,
  emailSettings: PropTypes.array,
  pushSettings: PropTypes.array,
  onChangeConfig: PropTypes.func.isRequired,
};

export default withRouter(NotificationTypeSection);
