import { get } from 'lodash';
import history from 'browserHistory.js';
import { apiClient, handleError } from '../api/ApiClient';
import { prepareSearchData, SORT_DESC } from '../helpers/prepareSearchData';
import {
  GET_USER,
  GET_USER_ACTIVITIES,
  GET_USER_ACTIVITIES_FAILURE,
  GET_USER_ACTIVITIES_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_SUCCESS,
  SEARCH_USERS,
  SEARCH_USERS_FAILURE,
  SEARCH_USERS_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
} from './UsersReducer';
import RouterPaths from '../constants/RouterPaths';
import { showAlert } from '../common/components/alert/AlertActions';
import AlertVariant from '../common/components/alert/AlertVariant';

const USER_ENDPOINT = '/user';

export const searchUsers = values => dispatch => {
  return apiClient
    .post(`${USER_ENDPOINT}/search`, prepareSearchData(values, { serial: SORT_DESC }))
    .then(res => {
      dispatch({ type: SEARCH_USERS });
      if (res.status === 200) {
        dispatch({
          type: SEARCH_USERS_SUCCESS,
          payload: {
            items: get(res, 'data.data.content', []),
            totalItems: get(res, 'data.data.totalElements', 0),
          },
        });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
      dispatch({ type: SEARCH_USERS_FAILURE });
    });
};

export const fetchUser = id => dispatch =>
  apiClient
    .get(`${USER_ENDPOINT}/${id}`)
    .then(res => {
      dispatch({ type: GET_USER });
      if (res.status === 200) {
        dispatch({ type: GET_USER_SUCCESS, payload: res.data });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
      dispatch({ type: GET_USER_FAILURE });
      history.push(RouterPaths.USERS);
    });

export const fetchUserActivities = (id, values) => dispatch =>
  apiClient
    .post(
      `${USER_ENDPOINT}/${id}/activities`,
      prepareSearchData(values, { createdOn: SORT_DESC }),
    )
    .then(res => {
      dispatch({ type: GET_USER_ACTIVITIES });
      if (res.status === 200) {
        dispatch({
          type: GET_USER_ACTIVITIES_SUCCESS,
          payload: {
            items: get(res, 'data.data.content', []).map((item, i) => ({ id: i, ...item })),
            totalItems: get(res, 'data.data.totalElements', 0),
          },
        });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
      dispatch({ type: GET_USER_ACTIVITIES_FAILURE });
    });

export const updateUser = (id, values, callback) => dispatch =>
  apiClient
    .put(`${USER_ENDPOINT}/${id}`, values)
    .then(res => {
      dispatch({ type: UPDATE_USER });
      if (res.status === 200) {
        dispatch(showAlert('User has updated successfully', AlertVariant.SUCCESS));
        dispatch({ type: UPDATE_USER_SUCCESS });
        history.push(RouterPaths.USERS);
      }
      callback();
    })
    .catch(err => {
      dispatch(handleError(err));
      dispatch({ type: UPDATE_USER_FAILURE });
      callback();
    });

export const deleteUser = id => dispatch => {
  apiClient
    .delete(`${USER_ENDPOINT}/${id}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('User has removed successfully', AlertVariant.SUCCESS));
        history.push(RouterPaths.USERS);
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    });
};

export const overrideUserPassword = (id, values) => dispatch => {
  apiClient
    .post(`${USER_ENDPOINT}/${id}/reset-password`, values)
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Changes saved successfully', AlertVariant.SUCCESS));
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    });
};

export const getImpersonate = (id, callback) => dispatch => {
  apiClient
    .post(`${USER_ENDPOINT}/${id}/impersonate/`, {})
    .then(res => callback(res?.data?.data?.impersonateToken))
    .catch(err => {
      dispatch(handleError(err));
    });
};

export const getNotificationConfig = id => dispatch => {
  return apiClient
    .get(`${USER_ENDPOINT}/${id}/notifications-config`)
    .then(res => res?.data?.data)
    .catch(err => {
      dispatch(handleError(err));
    });
};

export const updateNotificationConfig = (
  id,
  pushNotifications,
  emailNotifications,
) => dispatch => {
  return apiClient
    .put(`${USER_ENDPOINT}/${id}/notifications-config`, {
      pushNotifications,
      emailNotifications,
    })
    .then(res => res?.data?.data)
    .catch(err => {
      dispatch(handleError(err));
      return Promise.reject(err?.response);
    });
};
