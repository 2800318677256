import { get } from 'lodash';
import { apiClient, handleError } from '../api/ApiClient';
import { prepareSearchData, SORT_ASC, SORT_DESC } from '../helpers/prepareSearchData';
import history from '../browserHistory';
import RouterPaths from '../constants/RouterPaths';
import AlertVariant from '../common/components/alert/AlertVariant';
import { showAlert } from '../common/components/alert/AlertActions';
import {
  CLEAR_AUCTION_DETAILS_SUCCESS,
  FETCH_VEHICLE_FEATURES_SUCCESS,
  GET_AUCTION_DETAILS_SUCCESS,
  SEARCH_AUCTIONS,
  SEARCH_AUCTIONS_SUCCESS,
} from './VehicleReducer';
import { PerspectiveType } from '../constants/PerspectiveType';
import { AuctionStatusType } from '../constants/AuctionStatusType';
import { reverseDateToString, toExtendedDateFormat } from '../common/utils/date/Date.utils';
import { DueInDateType } from '../constants/DueInDateType';
import { AuctionStatus } from '../constants/AuctionStatus';
import { FormRegExp } from '../constants/FormRegExp';
import {
  FETCH_AUCTION_NOTES_FAILURE,
  FETCH_AUCTION_NOTES_SUCCESS,
} from '../dealers/DealersReducer';

export const AUCTION_DETAILS_ENDPOINT = '/auction';
export const DEALERS_ENDPOINT = '/dealer';
const MEDIA_API_ENDPOINT = '/media';
export const VEHICLE_FEATURES_ENDPOINT = '/vehicle-specification';

export const getVehicleMakeList = successCallback => dispatch => {
  return apiClient
    .get('/vehicle-make')
    .then(res => successCallback(res.data))
    .catch(err => dispatch(handleError(err)));
};

export const getVehicleModelsList = (makeId, successCallback) => dispatch => {
  return apiClient
    .get(`/vehicle-make/${makeId}`)
    .then(res => successCallback(res.data))
    .catch(err => dispatch(handleError(err)));
};

const prepareSortForVehicleLive = item => {
  if (item.status.length === 1 && item.status[0] === AuctionStatus.PUBLISHED) {
    return { endsOn: SORT_ASC };
  }
  return { createdOn: SORT_DESC };
};

export const searchAuctions = values => dispatch => {
  dispatch({ type: SEARCH_AUCTIONS });
  return apiClient
    .post('/auction/search', prepareSearchData(values, prepareSortForVehicleLive(values)))
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: SEARCH_AUCTIONS_SUCCESS,
          payload: {
            items: res?.data?.data?.content,
            totalItems: res?.data?.data?.totalElements,
          },
        });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    });
};

export const getAuctionDetails = (auctionId, skipErrHandler = false) => dispatch => {
  return apiClient
    .get(`${AUCTION_DETAILS_ENDPOINT}/${auctionId}`)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: GET_AUCTION_DETAILS_SUCCESS,
          payload: res?.data?.data,
        });
      }
      return res?.data?.data;
    })
    .catch(err => {
      if (!skipErrHandler) {
        dispatch(handleError(err));
      }
      return err;
    });
};

export const clearAuctionDetails = () => dispatch => {
  dispatch({
    type: CLEAR_AUCTION_DETAILS_SUCCESS,
  });
};

export const getDealersEmployees = (organizationId, successCallback) => dispatch => {
  return apiClient
    .post(
      `${DEALERS_ENDPOINT}/${organizationId}/users`,
      prepareSearchData({ organizationId }, {}, true),
    )
    .then(res => {
      successCallback(res.data?.data?.content);
    })
    .catch(err => dispatch(handleError(err)));
};
export const updateVehicle = (id, values) => dispatch => {
  const prepareBasicData = () => {
    const {
      numberPlate,
      make,
      model,
      mileage,
      year,
      derivative,
      carType,
      transmission,
      engineSize,
      fuel,
      doors,
      colour,
      previousOwners,
      mot,
      registrationDate,
      vin,
      vehicleCapCapId,
    } = values;
    return {
      registrationPlate: numberPlate,
      makeId: make.value,
      modelId: model.value,
      mileage,
      year,
      derivative,
      colour,
      doors,
      mot: mot || null,
      dateOfFirstRegistration: registrationDate,
      engineSize: fuel === 'Electric' ? null : engineSize,
      fuel,
      previousKeepersInTotal: previousOwners,
      carType,
      transmission,
      vin: vin || null,
      vehicleCapCapId,
    };
  };

  const prepareVehicleMedia = () => {
    const { photos, newPhoto, videos, newVideo, photosToDelete, videosToDelete } = values;

    const preparedVideos =
      newVideo &&
      newVideo.map(item => {
        return {
          fileUrl: item?.fileUrl,
          mediaMetadataId: item?.id,
          previewUrl: item?.previewUrl,
          previewMediaMetadataId: item?.previewId,
        };
      });

    const preparedPhotos =
      newPhoto &&
      newPhoto.map(item => {
        return {
          fileUrl: item?.fileUrl,
          mediaMetadataId: item?.id,
          perspective: item?.mediaFile?.description || PerspectiveType.OTHER,
          damages:
            item?.damages &&
            item?.damages.map(item => {
              return {
                fileUrl: item?.data?.fileUrl,
                mediaMetadataId: item?.data?.id,
                damageType: item?.damageType,
              };
            }),
          damagesToDelete: null,
        };
      });

    return {
      photos: [...photos, ...preparedPhotos],
      photosToDelete,
      videos: [...videos, ...preparedVideos],
      videosToDelete,
    };
  };

  const prepareServiceHistory = () => {
    const {
      serviceHistory,
      services,
      serviceHistoryPhotoRecords,
      newServiceHistoryPhotoRecords,
      serviceHistoryPhotoRecordsToDelete,
    } = values;

    const preparedManualRecords = services.map(({ date, mileage, serviceName }) => {
      return {
        date: reverseDateToString(date),
        mileage,
        serviceName,
      };
    });

    const preparedNewServiceHistoryPhotoRecords = newServiceHistoryPhotoRecords.map(
      ({ fileUrl, id }) => {
        return {
          fileUrl,
          mediaMetadataId: id,
        };
      },
    );

    return {
      type: serviceHistory.value,
      manualRecords: preparedManualRecords,
      photoRecords: [...serviceHistoryPhotoRecords, ...preparedNewServiceHistoryPhotoRecords],
      photoRecordsToDelete: serviceHistoryPhotoRecordsToDelete,
    };
  };

  const parsePriceToPences = value => {
    if (typeof value === 'string') {
      const result = Number(
        value.replace(FormRegExp.INT_WITHOUT_PREFIX_AND_SPECIAL_CHARS, ''),
      );
      if (!Number.isNaN(result)) return result * 100;
    }
    return value;
  };

  const prepareDescriptionAndSettings = () => {
    const {
      minimumPriceInPence,
      autoAcceptPriceInPence,
      description,
      defaultDescription,
      timeOption,
      type,
      publishTarget,
      organizationLocations,
      tryingToDealDeliveryDate,
      tryingToDealDeliveryDateType,
    } = values;
    return {
      description: description?.trim(),
      defaultDescription: defaultDescription?.trim(),
      type: type?.value,
      autoAcceptPriceInPence: prepareAutoAcceptPriceInPence(type, autoAcceptPriceInPence),
      minimumPriceInPence: prepareMinimumPriceInPence(type, minimumPriceInPence),
      timeOption: timeOption?.value,
      locationId: organizationLocations.value,
      tryingToDealDeliveryDate:
        type.value === AuctionStatusType.TRYING_TO_DEAL &&
        tryingToDealDeliveryDateType?.value === DueInDateType.CUSTOM_DATE
          ? reverseDateToString(tryingToDealDeliveryDate)
          : null,
      tryingToDealDeliveryDateType:
        type?.value === AuctionStatusType.TRYING_TO_DEAL
          ? tryingToDealDeliveryDateType?.value
          : null,
      publishTarget,
    };
  };

  const prepareAutoAcceptPriceInPence = (type, autoAcceptPriceInPence) => {
    if (type.value === AuctionStatusType.PHYSICAL) {
      if (typeof autoAcceptPriceInPence === 'number') {
        autoAcceptPriceInPence *= 100;
      }
      return parsePriceToPences(autoAcceptPriceInPence);
    }
    return null;
  };

  const prepareMinimumPriceInPence = (type, minimumPriceInPence) => {
    if (type.value === AuctionStatusType.MINIMUM_PRICE) {
      if (typeof minimumPriceInPence === 'number') {
        minimumPriceInPence *= 100;
      }
      return parsePriceToPences(minimumPriceInPence);
    }
    return null;
  };

  const preparedTyreThreadDepths = () => {
    const { nearsideFront, nearsideRear, offsideFront, offsideRear } = values;
    return {
      nearsideFront: nearsideFront?.value,
      nearsideRear: nearsideRear?.value,
      offsideFront: offsideFront?.value,
      offsideRear: offsideRear?.value,
    };
  };
  const prepareVehicleFeatures = () => {
    const { vehicleFeaturesDictionaryIds, vehicleFeaturesCustom } = values;
    return {
      vehicleFeaturesDictionaryIds,
      vehicleFeaturesCustom,
    };
  };

  const { launchDate, version, endsOn } = values;
  const data = {
    id,
    version,
    launchedOn: launchDate,
    endsOn: toExtendedDateFormat(endsOn),
    basicData: prepareBasicData(),
    tyreThreadDepths: preparedTyreThreadDepths(),
    vehicleMedia: prepareVehicleMedia(),
    serviceHistory: prepareServiceHistory(),
    descriptionAndSettings: prepareDescriptionAndSettings(),
    vehicleFeatures: prepareVehicleFeatures(),
  };

  return apiClient
    .put(`${AUCTION_DETAILS_ENDPOINT}/${id}`, data)
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Vehicle updated successfully', AlertVariant.SUCCESS));
        history.push(RouterPaths.VEHICLES);
      }
    })
    .catch(err => {
      if (err.response.status === 412) {
        const payload = err.response.data;
        const errors = Object.values(payload.data);
        if (errors) {
          dispatch(showAlert(errors[0], AlertVariant.DANGER));
        }
      }
      dispatch(handleError(err));
    });
};

export const addPhoto = mediaFile => dispatch => {
  const formData = new FormData();
  formData.append('file', mediaFile);
  return apiClient
    .post(MEDIA_API_ENDPOINT, formData, {
      timeout: 60000,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      accept: '/',
    })
    .then(res => {
      if (res.status === 200) {
        return res?.data?.data;
      }
      return null;
    })
    .catch(err => dispatch(handleError(err)));
};

export const addVideo = (mediaFile, thumbnail, callback) => dispatch => {
  const formData = new FormData();
  formData.append('file', mediaFile);
  return apiClient
    .post(MEDIA_API_ENDPOINT, formData, {
      timeout: 60000,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      accept: '/',
    })
    .then(res => {
      if (res.status === 200) {
        dispatch(
          addVehicleVideoThumbnail(thumbnail, preview => {
            callback({ video: res?.data?.data, thumbnail: preview });
          }),
        );
      }
      return null;
    })
    .catch(err => dispatch(handleError(err)));
};

const addVehicleVideoThumbnail = (mediaFile, callback) => dispatch => {
  return apiClient
    .post(MEDIA_API_ENDPOINT, mediaFile, {
      timeout: 60000,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      accept: '/',
    })
    .then(res => {
      if (res.status === 200) {
        if (typeof callback === 'function') {
          callback(res?.data?.data);
        }
      }
    })
    .catch(err => dispatch(handleError(err)));
};

export const deleteVideo = id => dispatch => {
  return apiClient
    .delete(`${MEDIA_API_ENDPOINT}/${id}`, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      accept: '/',
    })
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Video removed successfully', AlertVariant.SUCCESS));
      }
      return true;
    })
    .catch(err => {
      dispatch(handleError(err));
    });
};

export const fetchVehicleFeatures = () => dispatch => {
  return apiClient
    .get(VEHICLE_FEATURES_ENDPOINT)
    .then(res => {
      if (res.status === 200) {
        dispatch({ type: FETCH_VEHICLE_FEATURES_SUCCESS, payload: res?.data?.data });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    });
};

export const prepareThumbnailFile = async mediaFile => {
  const res = await fetch(mediaFile);
  const blob = await res.blob();
  const file = new File([blob], 'thumbnail', { type: 'image/jpeg', lastModified: Date.now() });
  const formData = new FormData();
  formData.append('file', file);
  return formData;
};

export const cancelAuction = ({ id, version }, successCallback) => dispatch => {
  return apiClient
    .post(`${AUCTION_DETAILS_ENDPOINT}/${id}/cancel`, { id, version })
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Auction cancelled successfully', AlertVariant.SUCCESS));
        if (typeof successCallback === 'function') {
          successCallback();
        }
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    });
};

export const revokeAuction = (id, version) => dispatch => {
  return apiClient
    .post(`${AUCTION_DETAILS_ENDPOINT}/${id}/revoke`, { id, version })
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Auction revoked successfully', AlertVariant.SUCCESS));
        history.push(RouterPaths.VEHICLES);
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    });
};

export const acceptAuction = ({ id, version }, callback) => dispatch => {
  return apiClient
    .post(`/auction/${id}/acceptance`, { id, version })
    .then(res => {
      if (res.status === 200) {
        dispatch(
          showAlert('The auction has been successfully accepted', AlertVariant.SUCCESS),
        );
        if (typeof callback === 'function') {
          callback();
        }
      }
    })
    .catch(err => dispatch(handleError(err)));
};

export const rejectAuction = ({ id, version }, callback) => dispatch => {
  return apiClient
    .post(`/auction/${id}/rejection`, { id, version })
    .then(res => {
      if (res.status === 200) {
        dispatch(
          showAlert('The auction has been successfully rejected', AlertVariant.SUCCESS),
        );
        if (typeof callback === 'function') {
          callback();
        }
      }
    })
    .catch(err => dispatch(handleError(err)));
};

export const republishAuction = (id, payload, callback) => dispatch => {
  return apiClient
    .post(`/auction/${id}/republish`, payload)
    .then(res => {
      if (res.status === 200) {
        dispatch(
          showAlert('The auction has been successfully relaunched', AlertVariant.SUCCESS),
        );
        if (typeof callback === 'function') {
          callback();
        }
      }
    })
    .catch(err => {
      return dispatch(handleError(err));
    });
};

export const fetchAuctionNotes = (values, auctionId) => dispatch => {
  return apiClient
    .post(`/auction/${auctionId}/notes`, prepareSearchData(values, { createdOn: SORT_DESC }))
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: FETCH_AUCTION_NOTES_SUCCESS,
          payload: {
            auctionNotes: {
              items: get(res, 'data.data.content', []),
              totalItems: get(res, 'data.data.totalElements', 0),
            },
          },
        });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
      dispatch({ type: FETCH_AUCTION_NOTES_FAILURE });
    });
};

export const saveAuctionNote = (values, auctionId, successCallback) => dispatch => {
  const data = {
    content: values?.content,
  };
  return apiClient
    .post(`/auction/${auctionId}/notes/save`, data)
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Note added to vehicle successfully', AlertVariant.SUCCESS));
        successCallback();
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    });
};
