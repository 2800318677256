export const AdminRole = {
  ADMIN: 'ADMIN',
  SUPER_ADMIN: 'SUPER_ADMIN',
  DASHBOARD: 'DASHBOARD',
};

export const AdminRoleName = {
  ADMIN: 'Admin',
  SUPER_ADMIN: 'Superadmin',
  DASHBOARD: 'Dashboard',
};
