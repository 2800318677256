import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { FormInputField } from '../../common/components/formFields';
import { fetchVehicleFeatures } from '../VehicleActions';

const VehicleFeaturesForm = ({
  feature,
  form,
  values: { vehicleFeaturesDictionaryIds, vehicleFeaturesCustom },
}) => {
  const dispatch = useDispatch();
  const [features, setFeatures] = useState([]);
  const vehicleFeatures = useSelector(state => state.vehicles.get('vehicleFeatures'));

  useEffect(() => {
    dispatch(fetchVehicleFeatures());
  }, [dispatch]);

  useEffect(() => {
    if (vehicleFeatures && vehicleFeaturesCustom) {
      const clonedVehicleFeatures = cloneDeep(vehicleFeatures);
      const preparedVehicleFeatures = clonedVehicleFeatures.map(item => {
        if (vehicleFeaturesDictionaryIds.includes(item.id)) {
          return {
            ...item,
            selected: true,
          };
        }
        return item;
      });
      const preparedCustomFeatures = vehicleFeaturesCustom.map(item => {
        return {
          id: item,
          value: item,
          selected: true,
        };
      });
      setFeatures([...preparedVehicleFeatures, ...preparedCustomFeatures]);
    }
  }, [vehicleFeatures, vehicleFeaturesDictionaryIds, vehicleFeaturesCustom]);

  const handlerClick = id => {
    const index = features.findIndex(obj => obj.id === id);
    if (index !== -1) {
      const _features = [...features];
      _features[index].selected = !_features[index].selected;
      if (_features[index].selected) {
        form.setFieldValue('vehicleFeaturesDictionaryIds', [
          ...vehicleFeaturesDictionaryIds,
          id,
        ]);
      } else {
        form.setFieldValue(
          'vehicleFeaturesDictionaryIds',
          vehicleFeaturesDictionaryIds.filter(item => item !== id),
        );
      }
      setFeatures(_features);
    }
    if (typeof id === 'string') {
      form.setFieldValue(
        'vehicleFeaturesCustom',
        vehicleFeaturesCustom.filter(item => item !== id),
      );
    }
  };

  const handleAddFeature = () => {
    if (feature.length && !features.some(item => item.value === feature)) {
      form.setFieldValue('vehicleFeaturesCustom', [...vehicleFeaturesCustom, feature]);
      form.setFieldValue('newFeature', '');
    }
  };

  return (
    <>
      <Row>
        <Col>
          <h6>Features</h6>
        </Col>
      </Row>
      <Row className="features-row">
        {features.map(feature => (
          <Col xs="auto" className="features-col" key={feature.id}>
            <Button
              className="btn-switch features-button"
              block
              outline={!feature.selected}
              color="primary"
              type="button"
              onClick={() => handlerClick(feature.id)}
            >
              {feature.value}
            </Button>
          </Col>
        ))}
      </Row>
      <Row>
        <Col md="6">
          <Row>
            <Col md="9">
              <FormInputField name="newFeature" label="Add a feature" />
            </Col>
            <Col md="3">
              <Button
                color="primary"
                className="mt-4"
                type="button"
                onClick={handleAddFeature}
              >
                Add
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

VehicleFeaturesForm.propTypes = {
  feature: PropTypes.string,
  form: PropTypes.object.isRequired,
  values: PropTypes.object,
};

VehicleFeaturesForm.defaultProps = {
  feature: '',
  values: {},
};

export default VehicleFeaturesForm;
