/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import AuthNavbar from 'common/components/Navbars/AuthNavbar.js';
import Footer from 'common/components/Footer/Footer.js';
import Login from '../auth/login/Login';

class Pages extends React.Component {
  render() {
    return (
      <>
        <AuthNavbar />
        <div className="full-page section-image">
          <Login />
          <Footer fluid />
        </div>
      </>
    );
  }
}

export default Pages;
