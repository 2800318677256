import React, { useState } from 'react';
import { Button, Col, Card, CardBody } from 'reactstrap';
import { AdminStatus, AdminStatusName } from '../constants/AdminStatus';

const AdminsStatusFilter = ({ handleChangeStatus }) => {
  const [selected, setSelected] = useState(null);

  const handleClick = status => {
    setSelected(status);
    handleChangeStatus(status);
  };

  return (
    <Col md="12">
      <Card>
        <CardBody>
          <div className="d-flex flex-row">
            <div className="p-2">
              <Button
                color="primary"
                outline={
                  selected !== null ||
                  selected === AdminStatus.ACTIVE ||
                  selected === AdminStatus.INACTIVE
                }
                onClick={() => handleClick(null)}
              >
                All
              </Button>
            </div>
            {Object.keys(AdminStatus).map(status => (
              <div className="p-2" key={status}>
                <Button
                  color="primary"
                  outline={selected !== AdminStatus[status]}
                  onClick={() => handleClick(AdminStatus[status])}
                >
                  {AdminStatusName[status]}
                </Button>
              </div>
            ))}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default AdminsStatusFilter;
