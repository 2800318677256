import { fromJS } from 'immutable';
import { LoaderStatus } from '../common/components/CommonLoader';

const initialData = fromJS({
  items: [],
  totalItems: 0,
  groupDetails: {},
  organizationDealersItems: [],
  organizationDealersTotalItems: 0,
  loaderStatus: LoaderStatus.IN_PROGRESS,
});

export const SEARCH_GROUP = 'SEARCH_GROUP';
export const SEARCH_GROUP_SUCCESS = 'SEARCH_GROUP_SUCCESS';
export const SEARCH_GROUP_FAILURE = 'SEARCH_GROUP_FAILURE';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const FECTH_GROUP_SUCCESS = 'FECTH_GROUP_SUCCESS';
export const EDIT_GROUP_SUCCESS = 'EDIT_GROUP_SUCCESS';
export const FETCH_ORGANIZATION_DEALERS_SUCCESS = 'FETCH_ORGANIZATION_DEALERS_SUCCESS';

export default (state = initialData, { type, payload }) => {
  switch (type) {
    case SEARCH_GROUP:
      return state.merge({
        loaderStatus: LoaderStatus.IN_PROGRESS,
      });
    case SEARCH_GROUP_SUCCESS:
      return state.merge({
        items: payload.items,
        totalItems: payload.totalItems,
        loaderStatus: LoaderStatus.SUCCESS,
      });
    case SEARCH_GROUP_FAILURE:
      return state.merge({
        loaderStatus: LoaderStatus.NOT_PENDING,
      });

    case FECTH_GROUP_SUCCESS:
      return state.merge({
        groupDetails: payload.groupDetails,
      });
    case FETCH_ORGANIZATION_DEALERS_SUCCESS:
      return state.merge({
        organizationDealersItems: payload.items,
        organizationDealersTotalItems: payload.totalItems,
      });
    default:
      return state;
  }
};
