import moment from 'moment';

export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';
export const EXTENDED_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
export const REVERSE_DASH_DATE_FORMAT = 'YYYY-MM-DD';

export function dateToString(date) {
  return date ? moment(date).format(DATE_FORMAT) : '';
}

export function dateTimeToString(date) {
  return date ? moment(date).format(DATE_TIME_FORMAT) : '';
}

export function toExtendedDateFormat(date) {
  return date ? moment(date, DATE_TIME_FORMAT).format(EXTENDED_DATE_TIME_FORMAT) : '';
}

export function reverseDateToString(date) {
  return date ? moment(date).format(REVERSE_DASH_DATE_FORMAT) : '';
}

export function isDateValid(dateTime) {
  return moment(dateTime, DATE_FORMAT, true).isValid();
}

export function isDateBefore(firstDate, secondDate) {
  const newFirstDate = new Date(firstDate);
  const newSecondDate = new Date(secondDate);

  return moment(newFirstDate).isBefore(newSecondDate);
}

export function isDateAfter(firstDate, secondDate) {
  const newFirstDate = new Date(firstDate);
  const newSecondDate = new Date(secondDate);

  return moment(newFirstDate).isAfter(newSecondDate);
}

export function isTodayOrFutureDate(firstDate, secondDate) {
  const newFirstDate = new Date(firstDate);
  const newSecondDate = new Date(secondDate);
  return moment(newFirstDate)
    .startOf('day')
    .isSameOrBefore(moment(newSecondDate).startOf('day'));
}

export function isBeforeOrPresentDate(value) {
  const [day, month, year, hour, minute] = value
    .match(/(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2})/)
    .slice(1);
  const date = new Date(year, month - 1, day, hour, minute);
  const now = new Date();
  return moment(now).isSameOrBefore(date);
}
