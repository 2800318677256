import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumbs from '../common/components/Breadcrumbs';
import GroupsSearch from './GroupsSearch';
import GroupsTable from './GroupsTable';
import RouterPaths from '../constants/RouterPaths';
import { setQueryString, useQueryString } from '../common/helpers/queryString';
import { searchGroups } from './GroupsActions';

const breadcrumbsItems = [
  { id: 1, type: 'home' },
  { id: 2, type: 'span', text: 'Groups' },
];

const GroupsPage = ({ history, location }) => {
  const query = useQueryString();
  const dispatch = useDispatch();

  const items = useSelector(state => state.groups.get('items'));
  const totalItems = useSelector(state => state.groups.get('totalItems'));

  const [searchParams, setSearchParams] = useState({
    offset: query.get('page') ? +query.get('page') - 1 : 0,
  });

  useEffect(() => {
    dispatch(searchGroups(searchParams));
  }, [dispatch, searchParams]);

  const handleSearch = (values, { setSubmitting }) => {
    setSearchParams(prevState => ({ ...prevState, ...values, offset: 0 }));
    setSubmitting(false);
  };

  const changeOffset = value => {
    setSearchParams(prevState => ({ ...prevState, offset: value }));
    history.push(setQueryString(location, 'page', value + 1));
  };

  return (
    <div className="content">
      <Row>
        <Col xs="9">
          <Breadcrumbs items={breadcrumbsItems} />
        </Col>
        <Col xs="3">
          <Button
            className="float-right"
            color="primary"
            onClick={() => history.push(RouterPaths.CREATE_GROUP)}
          >
            Add group
          </Button>
        </Col>
      </Row>
      <GroupsSearch handleSearch={handleSearch} />
      <GroupsTable
        data={{ items, total: totalItems }}
        offset={searchParams.offset}
        changeOffset={changeOffset}
      />
    </div>
  );
};

export default withRouter(GroupsPage);
