import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { dateTimeToString } from 'common/utils/date/Date.utils';
import { dataToSelect } from '../../common/helpers';
import {
  FormDatePickerField,
  FormInputField,
  FormInputFieldType,
  FormSelectField,
} from '../../common/components/formFields';
import { getVehicleMakeList, getVehicleModelsList } from '../VehicleActions';

const VehicleDetailsForm = ({ form }) => {
  const organizationLocations = useSelector(state => state.vehicles.get('auctionDetails'))
    ?.organizationLocations;
  const dispatch = useDispatch();
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [location, setLocation] = useState([]);

  useEffect(() => {
    dispatch(
      getVehicleMakeList(makes => {
        setMakes(dataToSelect(makes.data, ['id', 'name']));
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (organizationLocations?.length > 0) {
      const preparedOrganizationLocations = organizationLocations.map(item => {
        return {
          label: item?.postalCode?.postcode,
          value: item.id,
        };
      });
      setLocation(preparedOrganizationLocations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlerMakeChange = make => {
    if (make?.value) {
      form.setFieldValue('model', '');
      dispatch(
        getVehicleModelsList(make.value, models => {
          setModels(dataToSelect(models.data, ['id', 'name']));
        }),
      );
    }
  };

  return (
    <>
      <Row>
        <Col md="4">
          <FormInputField name="launchingCounterSerial" label="Auction Batch ID" disabled />
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <FormInputField name="status" label="Status" disabled />
        </Col>
        <Col md="4">
          <FormInputField name="numberPlate" label="Number plate" />
        </Col>
        <Col md="4">
          <FormInputField name="dealer" label="Seller" disabled />
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <FormSelectField
            name="make"
            label="Make"
            onChangeHandler={handlerMakeChange}
            options={makes}
          />
        </Col>
        <Col md="4">
          <FormSelectField name="model" label="Model" options={models} />
        </Col>
        <Col md="4">
          <FormInputField name="year" label="Year" type={FormInputFieldType.NUMBER} />
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <FormInputField name="mileage" label="Mileage" type={FormInputFieldType.NUMBER} />
        </Col>
        <Col md="4">
          <FormInputField name="derivative" label="Derivative" upperCase />
        </Col>
        <Col md="4">
          <FormInputField name="carType" label="Body type" />
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <FormInputField name="transmission" label="Transmission" />
        </Col>
        <Col md="4">
          <FormInputField name="engineSize" label="Engine size" />
        </Col>
        <Col md="4">
          <FormInputField name="fuel" label="Fuel" />
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <FormInputField name="colour" label="Colour" />
        </Col>
        <Col md="4">
          <FormInputField name="doors" label="Doors" />
        </Col>
        <Col md="4">
          <FormInputField name="previousOwners" label="Number of previous owners" />
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <FormDatePickerField name="mot" label="MOT" readOnly />
        </Col>
        <Col md="4">
          <FormDatePickerField name="registrationDate" label="Registration date" />
        </Col>
        <Col md="4">
          <FormDatePickerField
            name="launchDate"
            label="Launch date"
            disabled
            format={value => dateTimeToString(value)}
          />
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <FormInputField name="vin" label="VIN/Chassis No." />
        </Col>
        <Col md="4">
          <FormInputField name="capAverage" label="Cap average" disabled />
        </Col>
        <Col md="4">
          <FormInputField name="capBelow" label="Cap below" disabled />
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <FormInputField name="capClean" label="Cap clean" disabled />
        </Col>
        <Col md="4">
          <FormInputField name="retail" label="Retail" disabled />
        </Col>
        <Col md="4">
          <FormInputField name="seller" label="Author" disabled />
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <FormSelectField name="organizationLocations" label="Location" options={location} />
        </Col>
        <Col md="4">
          <FormDatePickerField
            name="deletedOn"
            label="Deleted on"
            disabled
            format={value => dateTimeToString(value)}
          />
        </Col>
      </Row>
    </>
  );
};

VehicleDetailsForm.propTypes = {
  form: PropTypes.object.isRequired,
};

export default VehicleDetailsForm;
