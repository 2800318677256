import React from 'react';
import Footer from '../common/components/Footer/Footer';
import AuthNavbar from '../common/components/Navbars/AuthNavbar';
import ForgotPasswordForm from '../auth/forgotpassword/ForgotPasswordForm';

const ForgotPassword = () => {
  return (
    <>
      <AuthNavbar />
      <div className="full-page section-image">
        <ForgotPasswordForm />
        <Footer fluid />
      </div>
    </>
  );
};

export default ForgotPassword;
