import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useQueryString } from 'common/helpers/queryString';
import { fetchUserActivities } from '../UsersActions';
import CommonLoader from '../../common/components/CommonLoader';
import UserActivitiesTable from './UserActivitiesTable';

const UserActivities = ({ id }) => {
  const dispatch = useDispatch();
  const query = useQueryString();
  const loaderUserActivities = useSelector(state => state.users.get('loaderUserActivities'));
  const userActivities = useSelector(state => state.users.get('userActivities'));
  const totalItemsActivities = useSelector(state => state.users.get('totalItemsActivities'));
  const [searchParams, setSearchParams] = useState({
    offset: query.get('page') ? +query.get('page') - 1 : 0,
  });

  useEffect(() => {
    dispatch(fetchUserActivities(id, searchParams));
  }, [id, searchParams, dispatch]);

  const changeOffset = value => {
    setSearchParams(() => ({
      offset: value,
    }));
  };

  return (
    <>
      <CommonLoader status={loaderUserActivities}>
        <UserActivitiesTable
          data={{ items: userActivities, total: totalItemsActivities }}
          offset={searchParams.offset}
          changeOffset={changeOffset}
        />
      </CommonLoader>
    </>
  );
};

UserActivities.propTypes = {
  id: PropTypes.string.isRequired,
};

export default UserActivities;
