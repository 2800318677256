import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { reverse } from 'named-urls';
import { withRouter } from 'react-router-dom';
import CommonTable from '../common/components/CommonTable';
import DealersTableRow from './DealersTableRow';
import CommonLoader from '../common/components/CommonLoader';
import RouterPaths from '../constants/RouterPaths';

const headers = [
  {
    label: 'Company name',
  },
  {
    label: 'Trade name',
  },
  {
    label: 'Email',
  },
  {
    label: 'Mobile phone',
  },
  {
    label: 'Password Reset Link Active',
  },
  {
    label: 'Payment method added',
  },
  {
    label: 'Status',
  },
  {
    label: 'Actions',
    className: 'text-right',
  },
];

const DealersTable = ({ data, offset, changeOffset, history }) => {
  const loaderStatus = useSelector(state => state.dealers.get('loaderStatus'));
  return (
    <Row>
      <Col xs="12">
        <CommonLoader status={loaderStatus}>
          <CommonTable
            rowComponent={DealersTableRow}
            data={data}
            offset={offset}
            onOffsetChange={changeOffset}
            headers={headers}
            title="Dealers"
            handleRowClick={item =>
              history.push(reverse(RouterPaths.DEALER_INFORMATION, { id: item.id }))
            }
          />
        </CommonLoader>
      </Col>
    </Row>
  );
};

DealersTable.propTypes = {
  data: PropTypes.object.isRequired,
  offset: PropTypes.number.isRequired,
  changeOffset: PropTypes.func.isRequired,
};

export default withRouter(DealersTable);
