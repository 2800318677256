import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { Form } from 'formik';
import { isEmpty } from 'lodash';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormInputField } from '../../common/components/formFields';
import GroupFormTable from './GroupFormTable';
import DealersSelectField from '../../dealers/forms/DealersSelectField';

const GroupForm = ({
  title,
  dealers,
  form,
  isCreateGroupForm,
  addDealerHandler,
  deleteDealerHandler,
  changeNameHandler,
  changeOffset,
  searchParams,
}) => {
  const organizationDealersTotalItems = useSelector(state =>
    state.groups.get('organizationDealersTotalItems'),
  );
  const [addButtonDisabled, setAddButtonDisabled] = useState(true);
  const [data, setData] = useState({
    items: dealers,
    total: dealers.length,
  });
  const [currentDealerOption, setCurrentDealerOption] = useState('');

  useEffect(() => {
    if (!isCreateGroupForm) {
      setData({ items: dealers, total: dealers.length });
    }
  }, [isCreateGroupForm, dealers]);

  useEffect(() => {
    const dealersIds = data.items.map(dealer => dealer.id);
    if (
      (isEmpty(dealersIds) && !currentDealerOption) ||
      dealersIds.includes(currentDealerOption?.value)
    ) {
      return setAddButtonDisabled(true);
    }
    return setAddButtonDisabled(false);
  }, [data.items, currentDealerOption]);

  const onChangeDealerHandler = option => {
    setCurrentDealerOption(option);
  };

  const saveButtonOnClickHandler = () => {
    changeNameHandler(form?.values?.name);
  };

  const addButtonOnClickHandler = () => {
    const dealer = form?.values?.dealer;
    if (dealer) {
      if (addDealerHandler) {
        addDealerHandler(dealer?.value);
      }
      form.setFieldValue('dealer', '');
      setCurrentDealerOption('');
    }
  };

  const removeItemHandler = dealer => {
    if (dealer) {
      deleteDealerHandler(dealer.id);
    }
  };

  return (
    <>
      <Form autoComplete="off">
        <Card>
          <CardBody>
            <h6>{title}</h6>
            <Row>
              <Col md="10">
                <FormInputField name="name" label="Name" />
              </Col>
              <Col>
                <Button color="primary" className="mt-4" onClick={saveButtonOnClickHandler}>
                  Save
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <DealersSelectField
                  onChangeHandler={onChangeDealerHandler}
                  name="dealer"
                  label="Add dealer"
                  disabled={isCreateGroupForm}
                />
              </Col>
              <Col md="4">
                <Button
                  color="info"
                  type="button"
                  className="mt-4"
                  disabled={addButtonDisabled || !currentDealerOption}
                  onClick={addButtonOnClickHandler}
                >
                  Add
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <GroupFormTable
                  data={{
                    items: data.items,
                    total: organizationDealersTotalItems,
                  }}
                  offset={searchParams.offset}
                  changeOffset={changeOffset}
                  removeItemHandler={removeItemHandler}
                  isCreateGroupForm={isCreateGroupForm}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Form>
    </>
  );
};

GroupForm.defaultProps = {
  isCreateGroupForm: false,
  dealers: [],
  addDealerHandler: null,
  deleteDealerHandler: null,
  changeNameHandler: null,
  changeOffset: () => {},
  searchParams: {},
};

GroupForm.propTypes = {
  title: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  dealers: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isCreateGroupForm: PropTypes.bool,
  addDealerHandler: PropTypes.func,
  deleteDealerHandler: PropTypes.func,
  changeNameHandler: PropTypes.func,
  changeOffset: PropTypes.func,
  searchParams: PropTypes.object,
};

export default withRouter(GroupForm);
