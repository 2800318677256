export const ValidationMessage = {
  WEBSITE_ADDRESS: 'Website address has the wrong format. E.g.: http://www.your-site.com',
  FIELD_MAX_8: 'Max length equals 8 characters',
  FIELD_MAX_10: 'Max length equals 10 characters',
  FIELD_MAX_11: 'Max length equals 11 characters',
  FIELD_MAX_12: 'Max length equals 12 characters',
  FIELD_MAX_15: 'Max length equals 15 characters',
  FIELD_MAX_50: 'Max length equals 50 characters',
  FIELD_MAX_100: 'Max length equals 100 characters',
  FIELD_MAX_200: 'Max length equals 200 characters',
};
