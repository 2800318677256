import { get } from 'lodash';
import { apiClient, handleError } from '../api/ApiClient';
import {
  CREATE_ADMIN,
  CREATE_ADMIN_FAILURE,
  CREATE_ADMIN_SUCCESS,
  EDIT_ADMIN,
  EDIT_ADMIN_FAILURE,
  EDIT_ADMIN_SUCCESS,
  GET_ADMIN,
  GET_ADMIN_FAILURE,
  GET_ADMIN_SUCCESS,
  SEARCH_ADMIN,
  SEARCH_ADMIN_FAILURE,
  SEARCH_ADMIN_SUCCESS,
} from './AdminsReducer';
import history from '../browserHistory';
import RouterPaths from '../constants/RouterPaths';
import AlertVariant from '../common/components/alert/AlertVariant';
import { showAlert } from '../common/components/alert/AlertActions';
import { prepareSearchData, SORT_DESC } from '../helpers/prepareSearchData';

const ADMIN_ENDPOINT = '/admin';

export const fetchAdmin = id => dispatch =>
  apiClient
    .get(`${ADMIN_ENDPOINT}/${id}`)
    .then(res => {
      dispatch({ type: GET_ADMIN });
      if (res.status === 200) {
        dispatch({ type: GET_ADMIN_SUCCESS, payload: res.data });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
      dispatch({ type: GET_ADMIN_FAILURE });
    });

export const deleteAdmin = id => dispatch =>
  apiClient
    .delete(`${ADMIN_ENDPOINT}/${id}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Admin removed successfully', AlertVariant.SUCCESS));
        history.push(RouterPaths.ADMINS);
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    });

export const createAdmin = values => dispatch =>
  apiClient
    .post(`${ADMIN_ENDPOINT}`, { ...values, status: values.status.value })
    .then(res => {
      dispatch({ type: CREATE_ADMIN });
      if (res.status === 200) {
        dispatch(showAlert('Admin added successfully', AlertVariant.SUCCESS));
        dispatch({ type: CREATE_ADMIN_SUCCESS });
        history.push(RouterPaths.ADMINS);
      }
    })
    .catch(err => {
      if (err?.response?.status) {
        dispatch({
          type: CREATE_ADMIN_FAILURE,
          payload: {
            validateErrors: err?.response?.data?.data,
            email: values?.email,
          },
        });
        dispatch(showAlert('Invalid values', AlertVariant.DANGER));
      } else {
        dispatch(handleError(err));
      }
    });

export const editAdmin = (values, id, version) => dispatch =>
  apiClient
    .put(`${ADMIN_ENDPOINT}/${id}`, {
      id,
      ...values,
      status: values.status.value,
      version,
    })
    .then(res => {
      dispatch({ type: EDIT_ADMIN });
      if (res.status === 200) {
        dispatch(showAlert('Admin updated successfully', AlertVariant.SUCCESS));
        dispatch({ type: EDIT_ADMIN_SUCCESS });
        history.push(RouterPaths.ADMINS);
      }
    })
    .catch(err => {
      dispatch(handleError(err));
      dispatch({ type: EDIT_ADMIN_FAILURE });
    });

export const searchAdmin = values => dispatch => {
  return apiClient
    .post(`${ADMIN_ENDPOINT}/search`, prepareSearchData(values, { createdOn: SORT_DESC }))
    .then(res => {
      dispatch({ type: SEARCH_ADMIN });
      if (res.status === 200) {
        dispatch({
          type: SEARCH_ADMIN_SUCCESS,
          payload: {
            items: get(res, 'data.data.content', []),
            totalItems: get(res, 'data.data.totalElements', 0),
          },
        });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
      dispatch({ type: SEARCH_ADMIN_FAILURE });
    });
};
