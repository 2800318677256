import { fromJS } from 'immutable';
import { LoaderStatus } from '../common/components/CommonLoader';

const initialData = fromJS({
  dealerDetails: {},
  loaderStatus: LoaderStatus.IN_PROGRESS,
  items: [],
  totalItems: 0,
  dealerChanges: {},
  dealerNotes: {},
  auctionNotes: {},
  validationErrors: {},
});

export const GET_DEALER = 'GET_DEALER';
export const GET_DEALER_SUCCESS = 'GET_DEALER_SUCCESS';
export const GET_DEALER_FAILURE = 'GET_DEALER_FAILURE';
export const SEARCH_DEALER = 'SEARCH_DEALER';
export const SEARCH_DEALER_SUCCESS = 'SEARCH_DEALER_SUCCESS';
export const SEARCH_DEALER_FAILURE = 'SEARCH_DEALER_FAILURE';
export const CLEAR_SEARCH_DATA = 'CLEAR_SEARCH_DATA';
export const FETCH_DEALER_CHANGES = 'FETCH_DEALER_CHANGES';
export const FETCH_DEALER_CHANGES_SUCCESS = 'FETCH_DEALER_CHANGES_SUCCESS';
export const FETCH_DEALER_CHANGES_FAILURE = 'FETCH_DEALER_CHANGES_FAILURE';
export const FETCH_DEALER_NOTES_SUCCESS = 'FETCH_DEALER_NOTES_SUCCESS';
export const FETCH_DEALER_NOTES_FAILURE = 'FETCH_DEALER_NOTES_FAILURE';
export const FETCH_AUCTION_NOTES_SUCCESS = 'FETCH_AUCTION_NOTES_SUCCESS';
export const FETCH_AUCTION_NOTES_FAILURE = 'FETCH_AUCTION_NOTES_FAILURE';
export const SEARCH_BLACKLIST_DEALERS_SUCCESS = 'SEARCH_BLACKLIST_DEALERS_SUCCESS';
export const CLEAR_DEALER_VALIDATION_ERRORS_SUCCESS = 'CLEAR_DEALER_VALIDATION_ERRORS_SUCCESS';
export const EDIT_DEALER_FAILURE = 'EDIT_DEALER_FAILURE';
export const CLEAR_DEALER_DETAILS = 'CLEAR_DEALER_DETAILS';

export default (state = initialData, { type, payload }) => {
  switch (type) {
    case GET_DEALER:
      return state.merge({
        loaderStatus: LoaderStatus.IN_PROGRESS,
      });
    case GET_DEALER_SUCCESS:
      return state.merge({
        dealerDetails: payload.data,
        loaderStatus: LoaderStatus.SUCCESS,
      });
    case GET_DEALER_FAILURE:
      return state.merge({
        dealerDetails: {},
        loaderStatus: LoaderStatus.NOT_PENDING,
      });
    case SEARCH_DEALER:
      return state.merge({
        loaderStatus: LoaderStatus.IN_PROGRESS,
      });
    case SEARCH_DEALER_SUCCESS:
      return state.merge({
        loaderStatus: LoaderStatus.SUCCESS,
        items: payload.items,
        totalItems: payload.totalItems,
      });
    case SEARCH_DEALER_FAILURE:
      return state.merge({
        loaderStatus: LoaderStatus.NOT_PENDING,
      });
    case CLEAR_SEARCH_DATA:
      return state.merge({
        items: [],
      });
    case FETCH_DEALER_CHANGES:
      return state.merge({
        loaderStatus: LoaderStatus.IN_PROGRESS,
      });
    case FETCH_DEALER_CHANGES_SUCCESS:
      return state.merge({
        loaderStatus: LoaderStatus.SUCCESS,
        dealerChanges: payload.dealerChanges,
      });
    case FETCH_DEALER_CHANGES_FAILURE:
      return state.merge({
        loaderStatus: LoaderStatus.NOT_PENDING,
      });
    case FETCH_DEALER_NOTES_SUCCESS:
      return state.merge({
        loaderStatus: LoaderStatus.SUCCESS,
        dealerNotes: payload.dealerNotes,
      });
    case FETCH_DEALER_NOTES_FAILURE:
      return state.merge({
        loaderStatus: LoaderStatus.NOT_PENDING,
      });
    case FETCH_AUCTION_NOTES_SUCCESS:
      return state.merge({
        loaderStatus: LoaderStatus.SUCCESS,
        auctionNotes: payload.auctionNotes,
      });
    case FETCH_AUCTION_NOTES_FAILURE:
      return state.merge({
        loaderStatus: LoaderStatus.NOT_PENDING,
      });
    case SEARCH_BLACKLIST_DEALERS_SUCCESS:
      return state.merge({
        items: payload.items,
        totalItems: payload.totalItems,
      });
    case EDIT_DEALER_FAILURE:
      return state.merge({
        validationErrors: payload,
      });
    case CLEAR_DEALER_VALIDATION_ERRORS_SUCCESS:
      return state.merge({
        validationErrors: {},
      });
    case CLEAR_DEALER_DETAILS:
      return state.merge({
        dealerDetails: {},
      });
    default:
      return state;
  }
};
