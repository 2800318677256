import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { withRouter, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { deleteBlacklistDealer, searchBlacklistDealers } from '../DealersActions';

const DealerBlacklistTableRow = ({ item, searchParams }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  return (
    <>
      <td>{item?.blockedOrganization?.serial}</td>
      <td>{item?.blockedOrganization?.tradeName}</td>
      <td style={{ display: 'flex' }}>
        <Button
          className="btn-link"
          color="primary"
          id={`tooltip${item.id}`}
          size="sm"
          type="button"
          onClick={() =>
            dispatch(
              deleteBlacklistDealer(id, item?.blockedOrganization?.id, () =>
                dispatch(searchBlacklistDealers(searchParams, id)),
              ),
            )
          }
        >
          <i className="far fa-thumbs-up" />
        </Button>
        <UncontrolledTooltip
          className="uncontrolled-tooltip"
          delay={0}
          target={`tooltip${item.id}`}
        >
          Unblock
        </UncontrolledTooltip>
      </td>
    </>
  );
};

DealerBlacklistTableRow.propTypes = {
  searchParams: PropTypes.object.isRequired,
};

export default withRouter(DealerBlacklistTableRow);
