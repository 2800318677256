import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Breadcrumbs from '../common/components/Breadcrumbs';
import { setQueryString, useQueryString } from '../common/helpers/queryString';
import { searchUserReports, clearReports, generateUserReport } from './ReportsActions';
import ReportsUserActionButtons from './ReportsUserActionButtons';
import ReportsUserTable from './ReportsUserTable';
import ReportsUserSearch from './search/ReportsUserSearch';

const breadcrumbsItems = [
  { id: 1, type: 'home' },
  { id: 2, type: 'span', text: 'Reports' },
];

const ReportsPage = ({ history, location }) => {
  const query = useQueryString();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useState({
    offset: query.get('page') ? +query.get('page') - 1 : 0,
  });

  const [isTableVisible, setIsTableVisible] = useState(false);
  const items = useSelector(state => state.reports.get('reportsItems'));
  const totalItems = useSelector(state => state.reports.get('totalReportsItems'));

  useEffect(() => {
    if (searchParams?.status) {
      dispatch(searchUserReports(searchParams));
    }
  }, [dispatch, searchParams]);

  const handleDownload = () => {
    dispatch(generateUserReport(searchParams));
  };

  const changeOffset = value => {
    setSearchParams(prevState => ({ ...prevState, offset: value }));
    history.push(setQueryString(location, 'page', value + 1));
  };

  const handleSearch = (values, { setSubmitting }) => {
    setIsTableVisible(true);
    setSearchParams(prevState => ({
      ...prevState,
      ...values,
      organizationId: values?.dealer?.value,
      offset: 0,
    }));
    setSubmitting(false);
  };

  const handleClear = () => {
    dispatch(clearReports());
    setIsTableVisible(false);
  };

  return (
    <div className="content">
      <Row>
        <Col xs="9">
          <Breadcrumbs items={breadcrumbsItems} />
        </Col>
      </Row>
      <Row>
        <ReportsUserSearch handleSearch={handleSearch} handleClear={handleClear} />
      </Row>

      {isTableVisible && (
        <>
          <Row>
            <ReportsUserTable
              data={{ items, total: totalItems }}
              offset={searchParams.offset}
              changeOffset={changeOffset}
            />
          </Row>
          <Row>
            <ReportsUserActionButtons handleDownload={handleDownload} />
          </Row>
        </>
      )}
    </div>
  );
};

export default withRouter(ReportsPage);
