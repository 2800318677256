import { apiClient, handleError } from '../api/ApiClient';
import {
  CLEAR_FEES_VALIDATION_ERRORS_SUCCESS,
  EDIT_SETTINGS_FAILURE,
  FETCH_SAGE_SETTINGS_SUCCESS,
  FETCH_SAGE_STATUS_SUCCESS,
  GET_SETTINGS_SUCCESS,
} from './SettingsReducer';
import { showAlert } from '../common/components/alert/AlertActions';
import AlertVariant from '../common/components/alert/AlertVariant';
import { AgreeOptions } from '../constants/AgreeOptions';
import { parseCurrencyToIntIfString } from '../common/helpers/parseCurrencyToIntIfString';

const SETTINGS_ENDPOINT = '/settings';
const SAGE_STATUS_ENDPOINT = '/sage/auth/status';
const SAGE_SETTINGS_ENDPOINT = '/sage/auth/settings';
const SAGE_AUTH_CODE_ENDPOINT = '/sage/auth/code';

export const fetchSettings = () => dispatch =>
  apiClient
    .get(`${SETTINGS_ENDPOINT}`)
    .then(res => {
      if (res.status === 200) {
        dispatch({ type: GET_SETTINGS_SUCCESS, payload: res.data });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    });

export const editSettings = values => dispatch => {
  const {
    vehicleMakeVisible,
    monthlySubscriptionInPence,
    launchInPence,
    saleForLessThan1000InPence,
    saleBetween1001And2500InPence,
    saleBetween2501And5000InPence,
    saleBetween5001And10000InPence,
    saleBetween10001And20000InPence,
    saleForMoreThan20000InPence,
    purchaseForLessThan1000InPence,
    purchaseBetween1001And2500InPence,
    purchaseBetween2501And5000InPence,
    purchaseBetween5001And10000InPence,
    purchaseBetween10001And20000InPence,
    purchaseForMoreThan20000InPence,
    version,
  } = values;

  const data = {
    vehicleMakeVisible: vehicleMakeVisible?.label === AgreeOptions.YES,
    monthlySubscriptionInPence: parseCurrencyToIntIfString(monthlySubscriptionInPence),
    launchInPence: parseCurrencyToIntIfString(launchInPence),
    saleForLessThan1000InPence: parseCurrencyToIntIfString(saleForLessThan1000InPence),
    saleBetween1001And2500InPence: parseCurrencyToIntIfString(saleBetween1001And2500InPence),
    saleBetween2501And5000InPence: parseCurrencyToIntIfString(saleBetween2501And5000InPence),
    saleBetween5001And10000InPence: parseCurrencyToIntIfString(saleBetween5001And10000InPence),
    saleBetween10001And20000InPence: parseCurrencyToIntIfString(
      saleBetween10001And20000InPence,
    ),
    saleForMoreThan20000InPence: parseCurrencyToIntIfString(saleForMoreThan20000InPence),
    purchaseForLessThan1000InPence: parseCurrencyToIntIfString(purchaseForLessThan1000InPence),
    purchaseBetween1001And2500InPence: parseCurrencyToIntIfString(
      purchaseBetween1001And2500InPence,
    ),
    purchaseBetween2501And5000InPence: parseCurrencyToIntIfString(
      purchaseBetween2501And5000InPence,
    ),
    purchaseBetween5001And10000InPence: parseCurrencyToIntIfString(
      purchaseBetween5001And10000InPence,
    ),
    purchaseBetween10001And20000InPence: parseCurrencyToIntIfString(
      purchaseBetween10001And20000InPence,
    ),
    purchaseForMoreThan20000InPence: parseCurrencyToIntIfString(
      purchaseForMoreThan20000InPence,
    ),
    version,
  };

  return apiClient
    .put(`${SETTINGS_ENDPOINT}`, data)
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Settings updated successfully', AlertVariant.SUCCESS));
      }
    })
    .catch(err => {
      if (err?.response?.status === 412) {
        return dispatch({ type: EDIT_SETTINGS_FAILURE, payload: err?.response?.data?.data });
      }
      return dispatch(handleError(err));
    });
};

export const fetchSageStatus = () => dispatch => {
  apiClient
    .get(SAGE_STATUS_ENDPOINT)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: FETCH_SAGE_STATUS_SUCCESS,
          payload: res?.data,
        });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    });
};

export const fetchSageSettings = () => dispatch => {
  apiClient
    .get(SAGE_SETTINGS_ENDPOINT)
    .then(res => {
      if (res.status === 200) {
        dispatch({ type: FETCH_SAGE_SETTINGS_SUCCESS, payload: res?.data });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    });
};

export const sendSageAuthParams = (code, successCallback) => dispatch => {
  apiClient
    .post(SAGE_AUTH_CODE_ENDPOINT, code)
    .catch(err => {
      dispatch(handleError(err));
    })
    .finally(() => {
      if (typeof successCallback === 'function') {
        successCallback();
      }
    });
};
export const clearFeesValidationErrors = () => dispatch => {
  dispatch({ type: CLEAR_FEES_VALIDATION_ERRORS_SUCCESS });
};
