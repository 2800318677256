import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { sendSageAuthParams } from './SettingsActions';
import history from '../browserHistory';
import RouterPaths from '../constants/RouterPaths';
import Rolling from '../assets/img/Rolling.gif';
import { SAGE_STATE_ID } from '../constants/SageStateId';

const SageRedirect = () => {
  const dispatch = useDispatch();
  const myParam = useLocation().search;
  const urlParams = new URLSearchParams(myParam);
  const code = urlParams.get('code');
  const state = urlParams.get('state');
  const localStateId = localStorage.getItem(SAGE_STATE_ID);

  const redirectOnCallback = () => {
    history.push(RouterPaths.SETTINGS);
  };

  useEffect(() => {
    if (state === localStateId) {
      dispatch(sendSageAuthParams({ code }, redirectOnCallback));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <div className="main-panel sage-redirect">
        <div className="content">
          <Row className="text-center flex-column">
            <Row>
              <Col>
                <h3>Connecting to SAGE ...</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <img className="sage-redurect__loader" src={Rolling} alt="loader" />
              </Col>
            </Row>
          </Row>
        </div>
      </div>
    </>
  );
};

export default SageRedirect;
