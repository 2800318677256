import React from 'react';
import { Form, Formik } from 'formik';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormInputField } from '../../common/components/formFields';
import validationSchema from './ChangePasswordValidation.schema';
import { overrideUserPassword } from '../UsersActions';

const ChangePasswordForm = ({ handleCloseAlert, userId }) => {
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{ password: '', passwordRepeat: '' }}
      validationSchema={validationSchema}
      onSubmit={values => {
        dispatch(overrideUserPassword(userId, values));
        handleCloseAlert();
      }}
    >
      {() => {
        return (
          <Form autoComplete="off">
            <div className="change-password_form-wrapper">
              <h5>Override Password</h5>
              <FormInputField name="password" label="Password" type="password" />
              <FormInputField name="passwordRepeat" label="Confirm Password" type="password" />
              <div className="change-password__buttons-container">
                <Button type="submit" color="success">
                  Save
                </Button>
                <Button color="danger" onClick={() => handleCloseAlert()}>
                  Cancel
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChangePasswordForm;

ChangePasswordForm.propTypes = {
  handleCloseAlert: PropTypes.func,
  userId: PropTypes.string,
};

ChangePasswordForm.defaultProps = {
  handleCloseAlert: () => {},
  userId: '',
};
