import { fromJS } from 'immutable';
import { LoaderStatus } from '../common/components/CommonLoader';

const initialData = fromJS({
  reportsItems: [],
  totalReportsItems: null,
  loaderStatus: LoaderStatus.IN_PROGRESS,
});

export const REPORTS_SEARCH = 'REPORTS_SEARCH';
export const REPORTS_SEARCH_SUCCESS = 'REPORTS_SEARCH_SUCCESS';
export const REPORTS_SEARCH_FAILURE = 'REPORTS_SEARCH_FAILURE';
export const CLEAR_REPORTS = 'CLEAR_REPORTS';

export default (state = initialData, { type, payload }) => {
  switch (type) {
    case REPORTS_SEARCH:
      return state.merge({
        loaderStatus: LoaderStatus.IN_PROGRESS,
      });

    case REPORTS_SEARCH_SUCCESS: {
      const { items, totalItems } = payload;
      return state.merge({
        reportsItems: items,
        totalReportsItems: totalItems,
        loaderStatus: LoaderStatus.SUCCESS,
      });
    }
    case REPORTS_SEARCH_FAILURE:
      return state.merge({
        loaderStatus: LoaderStatus.NOT_PENDING,
      });

    case CLEAR_REPORTS: {
      return state.merge({
        reportsItems: [],
        totalReportsItems: null,
      });
    }
    default:
      return state;
  }
};
