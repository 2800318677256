import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';

import { Link } from 'react-router-dom';
import { login } from '../AuthActions';
import LoginSchema from './Login.schema';
import RouterPaths from '../../constants/RouterPaths';

const Login = () => {
  useEffect(() => {
    document.body.classList.toggle('login-page');
    return () => {
      document.body.classList.toggle('login-page');
    };
  }, []);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();

  const authenticate = () => {
    setErrors({});
    LoginSchema.validate(
      {
        email,
        password,
      },
      { abortEarly: false },
    )
      .then(() => {
        dispatch(login(email, password));
      })
      .catch(validation => {
        const err = {};
        validation.inner.forEach(e => {
          err[e.path] = e.message;
        });

        setErrors({
          ...err,
        });
      });
  };

  return (
    <div className="login-page">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form action="" className="form" method="">
              <Card className="card-login">
                <CardHeader>
                  <CardHeader>
                    <h3 className="header text-center">Login</h3>
                  </CardHeader>
                </CardHeader>
                <CardBody>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="text"
                      value={email}
                      invalid={!!errors.email}
                      onChange={e => setEmail(e.target.value)}
                    />
                    {errors && errors.email && <FormFeedback>{errors.email}</FormFeedback>}
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-key-25" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      type="password"
                      autoComplete="off"
                      value={password}
                      invalid={!!errors.password}
                      onChange={e => setPassword(e.target.value)}
                    />
                    {errors && errors.password && (
                      <FormFeedback>{errors.password}</FormFeedback>
                    )}
                  </InputGroup>
                  <Link to={RouterPaths.FORGOT_PASSWORD}>Forgot password?</Link>
                </CardBody>

                <CardFooter>
                  <Button
                    block
                    className="btn-round mb-3"
                    color="warning"
                    onClick={() => authenticate(email, password, login)}
                  >
                    Login
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${require('assets/img/bg/ilya-yakover.jpg')})`,
        }}
      />
    </div>
  );
};

export default Login;
