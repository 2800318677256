import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumbs from '../common/components/Breadcrumbs';
import { setQueryString, useQueryString } from '../common/helpers/queryString';
import { fetchChanges } from './RequestFormChangesActions';
import RequestForChangesTable from './table/RequestForChangesTable';

const breadcrumbsItems = [
  { id: 1, type: 'home' },
  { id: 2, type: 'span', text: 'Requests' },
];

const RequestForChangesPage = ({ history, location }) => {
  const query = useQueryString();
  const dispatch = useDispatch();

  const items = useSelector(state => state.requests.get('items'));
  const totalItems = useSelector(state => state.requests.get('totalItems'));

  const [searchParams, setSearchParams] = useState({
    offset: query.get('page') ? +query.get('page') - 1 : 0,
  });

  useEffect(() => {
    dispatch(fetchChanges(searchParams));
  }, [dispatch, searchParams]);

  const changeOffset = value => {
    setSearchParams(prevState => ({ ...prevState, offset: value }));
    history.push(setQueryString(location, 'page', value + 1));
  };

  return (
    <div className="content">
      <Breadcrumbs items={breadcrumbsItems} />
      <RequestForChangesTable
        data={{ items, total: totalItems }}
        offset={searchParams.offset}
        changeOffset={changeOffset}
      />
    </div>
  );
};

export default withRouter(RequestForChangesPage);
