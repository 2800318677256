import React, { useState, useEffect } from 'react';
import { Col, Row, Button } from 'reactstrap';
import CommonTable from '../../common/components/CommonTable';
import EditDealerNotesTableRow from './EditDealerNotesTableRow';

const headers = [
  {
    label: 'Date',
  },
  {
    label: 'Author',
  },
  {
    label: 'Note',
  },
];

const EditDealerNotesTable = ({ data, offset, changeOffset = () => {}, forceShow }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (forceShow) {
      setShow(forceShow.show);
    }
  }, [forceShow]);

  return (
    <>
      <Row>
        <Button color="neutral" type="button" onClick={() => setShow(prev => !prev)}>
          Notes {show ? '-' : '+'}
        </Button>
      </Row>
      {show && (
        <Row>
          <Col xs="12">
            <CommonTable
              rowComponent={EditDealerNotesTableRow}
              data={data}
              offset={offset}
              onOffsetChange={changeOffset}
              headers={headers}
              title=""
              pagination={true}
              hasRowPointer={false}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default EditDealerNotesTable;
