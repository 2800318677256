import React from 'react';
import { Card, Col, Row, CardBody, Label, Button } from 'reactstrap';
import { Form } from 'formik';
import { useSelector } from 'react-redux';
import {
  FormGroupRadioField,
  FormInputField,
  FormInputFieldType,
  FormSelectField,
} from '../../common/components/formFields';
import { constantToSelect } from '../../common/helpers/constantToSelect';
import { AdminRoleName } from '../../constants/AdminRole';
import { AdminStatusName } from '../../constants/AdminStatus';
import CommonErrorLabel from '../../common/components/CommonErrorLabel';

const adminStatuses = constantToSelect(AdminStatusName);
const adminRoles = constantToSelect(AdminRoleName);

const AdminForm = ({ title, values }) => {
  const validateErrors = useSelector(state => state.admins.get('validateErrors'));
  const oldEmail = useSelector(state => state.admins.get('oldEmail'));

  return (
    <Form noValidate>
      <Card>
        <CardBody>
          <h6>{title}</h6>
          <Row>
            <Col md="4">
              <FormInputField name="firstName" label="First name" />
            </Col>
            <Col md="4">
              <FormInputField name="lastName" label="Last name" />
            </Col>
            <Col md="4">
              <FormInputField name="email" label="Email" type="email" />
              {oldEmail === values?.email && (
                <CommonErrorLabel
                  value={validateErrors?.email}
                  classNames="custom-email-error-label"
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <FormInputField
                name="password"
                label="Password"
                type={FormInputFieldType.PASSWORD}
              />
            </Col>
            <Col md="4">
              <FormInputField
                name="passwordRepeat"
                label="Repeat password"
                type={FormInputFieldType.PASSWORD}
              />
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <FormSelectField name="status" label="Status" options={adminStatuses} />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <Label>Role</Label>
              <FormGroupRadioField name="role" label="role" radios={adminRoles} />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Button color="primary" className="mt-4 float-right" type="submit">
                Save
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Form>
  );
};

export default AdminForm;
