import { combineReducers } from 'redux';
import authReducer from '../auth/AuthReducer';
import alertReducer from '../common/components/alert/AlertReducer';
import adminsReducer from '../admins/AdminsReducer';
import dealersReducer from '../dealers/DealersReducer';
import usersReducer from '../users/UsersReducer';
import requestsReducer from '../requestforchanges/RequestFormChangesReducer';
import groupsReducer from '../groups/GroupsReducer';
import settingsReducer from '../settings/SettingsReducer';
import vehicleReducer from '../vehicles/VehicleReducer';
import reportsReducer from '../reports/ReportsReducer';
import siteCreatorReducer from '../sitecreator/SiteCreatorReducer';
import bidsReducer from '../bids/BidsReducer';
import TransactionsReducer from '../transactions/TransactionsReducer';
import tvDashboardReducer from '../tvdashboard/TvDashboardReducer';

export default combineReducers({
  auth: authReducer,
  alert: alertReducer,
  admins: adminsReducer,
  dealers: dealersReducer,
  users: usersReducer,
  requests: requestsReducer,
  groups: groupsReducer,
  settings: settingsReducer,
  vehicles: vehicleReducer,
  reports: reportsReducer,
  siteCreator: siteCreatorReducer,
  bids: bidsReducer,
  transactions: TransactionsReducer,
  tvDashboard: tvDashboardReducer,
});
