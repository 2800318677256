import React from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import validationSchema from './ReportsSearch.schema';
import { FormDatePickerField } from '../../common/components/formFields';
import DealersSelectField from '../../dealers/forms/DealersSelectField';

const initialValues = {
  dealer: '',
  createdOnFrom: '',
  createdOnTo: '',
};

const ReportsSearch = ({ handleSearch, handleDealerChange, handleClear }) => {
  return (
    <Col md="12">
      <Card>
        <CardBody>
          <h6>Search report</h6>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSearch}
            validateOnBlur={true}
          >
            {({ resetForm }) => (
              <Form noValidate>
                <Row>
                  <Col md="4">
                    <DealersSelectField
                      name="dealer"
                      label="Dealer"
                      onChangeHandler={values => handleDealerChange(values)}
                    />
                  </Col>
                  <Col md="4">
                    <FormDatePickerField name="createdOnFrom" label="Report date from" />
                  </Col>
                  <Col md="4">
                    <FormDatePickerField
                      name="createdOnTo"
                      label="Report date to"
                      isUpperRange={true}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Button color="primary" className="mt-4 float-right" type="submit">
                      Search
                    </Button>
                    <Button
                      color="primary"
                      outline={true}
                      className="mt-4 float-right"
                      type="button"
                      onClick={() => {
                        resetForm();
                        handleClear();
                      }}
                    >
                      Clear
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </Col>
  );
};

ReportsSearch.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  handleDealerChange: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
};
export default ReportsSearch;
