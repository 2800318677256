const yup = require('yup');

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email format')
    .required('Field required'),
});

export default schema;
