export const VehiclePublishOption = {
  EVERYWHERE: 'EVERYWHERE',
  GROUP_ONLY: 'GROUP_ONLY',
  GROUP_FIRST: 'GROUP_FIRST',
};

export const VehiclePublishOptionShortName = {
  EVERYWHERE: 'Everywhere',
  GROUP: 'Group',
  GROUP_EVERYWHERE: 'Group everywhere',
};

export const VehiclePublishOptionName = {
  EVERYWHERE: 'Publish everywhere',
  GROUP_ONLY: 'Publish in my group',
  GROUP_FIRST:
    'Publish in my group. If there are no offers from my group, make this offer available to everyone.',
};
