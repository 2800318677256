import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

const AdminsTableRow = ({ item }) => {
  return (
    <>
      <td>{item.serial}</td>
      <td>{item.firstName}</td>
      <td>{item.lastName}</td>
      <td>{item.email}</td>
      <td>{item.status}</td>
      <td style={{ display: 'flex' }}>
        <Button
          className="btn-link"
          color="primary"
          id={`tooltip${item.id}`}
          size="sm"
          type="button"
        >
          <i className="fa fa-edit" />
        </Button>
      </td>
    </>
  );
};

AdminsTableRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default AdminsTableRow;
