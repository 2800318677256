import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import { getPhotoDescription } from '../../helpers/getPhotoDescription';
import { UploadFileTypes } from '../../constants/UploadFileTypes';
import bucketIcon from '../../assets/img/bucket.svg';
import { addPhoto } from '../VehicleActions';
import DragAndDropPhoto from './drag/DragAndDropPhoto';
import AddDamageDragPreview from './drag/AddDamageDragPreview';
import CommonLoader from '../../common/components/CommonLoader';
import { checkInteriorPerspective } from '../../helpers/checkInteriorPerspective';
import { PerspectiveType } from '../../constants/PerspectiveType';
import { DamageTypes } from '../../constants/DamageTypes';

const VehiclePhotoEditForm = ({ handleCloseAlert, photos, handleSave, photoEditIndex }) => {
  const dispatch = useDispatch();

  const [editingPhoto, setEditingPhoto] = useState({});
  const [photosToDelete, setPhotosToDelete] = useState([]);
  const [damageLoading, setDamageLoading] = useState(false);
  const [photoLoading, setPhotoLoading] = useState(false);

  const isNewPhoto = photoEditIndex === -1;

  useEffect(() => {
    const newPhoto = getPhotoToEditOrCreateNew(photoEditIndex);
    setEditingPhoto(cloneDeep(newPhoto));
    setPhotosToDelete([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photoEditIndex]);

  const getPhotoToEditOrCreateNew = photoEditIndex => {
    if (photoEditIndex === null) return null;
    if (photoEditIndex === -1) {
      return {
        fileUrl: null,
        mediaMetadataId: null,
        damages: [],
        damagesToDelete: [],
        sortNumber: photos?.length + 1 || 1,
        perspective: getPhotoDescription(photos?.length + 1 || 1).PERSPECTIVE,
      };
    }
    if (photoEditIndex < photos?.length) {
      return photos[photoEditIndex];
    }
    return null;
  };

  const setPhoto = async file => {
    if (file && file?.type.includes(UploadFileTypes.IMAGE)) {
      setPhotoLoading(true);
      const photo = await dispatch(addPhoto(file));
      setEditingPhoto(prevState => ({
        ...prevState,
        mediaMetadataId: photo.id,
        fileUrl: photo.fileUrl,
        urlTemp: URL.createObjectURL(file),
      }));
      setPhotoLoading(false);
    }
  };

  const savePhotos = () => {
    handleSave(editingPhoto, photosToDelete);
  };

  const setDamage = async file => {
    if (file && file?.type.includes(UploadFileTypes.IMAGE)) {
      setDamageLoading(true);
      const photo = await dispatch(addPhoto(file));
      const damage = {
        damageType: checkInteriorPerspective(editingPhoto.perspective)
          ? DamageTypes.OTHER
          : null,
        fileUrl: photo.fileUrl,
        mediaMetadataId: photo.id,
      };
      setEditingPhoto(prevState => ({
        ...prevState,
        damages: [...(prevState.damages || []), damage],
      }));
      setDamageLoading(false);
    }
  };

  const allDamagesHasSelectedTypes = photo => {
    if (!photo) return false;
    if (!photo.damages) return true;
    if (checkInteriorPerspective(photo.perspective)) return true;
    if (photo.damages.length === 0) return true;
    return !photo.damages.find(item => !item.damageType);
  };

  const deletePhoto = () => {
    setPhotosToDelete(prevState => [...prevState, editingPhoto.mediaMetadataId]);
    setEditingPhoto(prev => ({
      ...prev,
      fileUrl: null,
      mediaMetadataId: null,
    }));
  };

  return (
    <>
      <Container className="d-flex flex-column justify-content-center text-center pt-3">
        <button
          type="button"
          className="photo_gallery_close_button"
          onClick={handleCloseAlert}
        >
          X
        </button>
        <Row className=" d-flex justify-content-center">
          <Col lg="6" md="8" sm="12">
            <Row>
              <Col className="vehicle_details_edit_photo__labels">
                {isNewPhoto ? <h6>NEW PHOTO</h6> : <h6>EDIT PHOTO</h6>}
                <p>{getPhotoDescription(editingPhoto.sortNumber).DESCRIPTION}</p>
              </Col>
            </Row>
            <Row className="mb-4">
              {editingPhoto.fileUrl ? (
                <Col className="vehicle_details__added-photo-container">
                  <img alt="Preview car" src={editingPhoto.fileUrl} className="added-photo" />
                  <button
                    type="button"
                    className="vehicle_details__edit_photo_delete_icon"
                    onClick={deletePhoto}
                  >
                    <img src={bucketIcon} height={18} width={15} alt="alt" />
                  </button>
                </Col>
              ) : (
                <Col>
                  {photoLoading ? (
                    <CommonLoader />
                  ) : (
                    <DragAndDropPhoto
                      title="Click here to upload vehicle images"
                      onDrop={setPhoto}
                    />
                  )}
                </Col>
              )}
            </Row>
            {editingPhoto.fileUrl && editingPhoto.perspective !== PerspectiveType.MASTER && (
              <>
                <Row>
                  <Col className="vehicle_details_edit_photo__labels">
                    <h6>ADD DAMAGE</h6>
                  </Col>
                </Row>
                <AddDamageDragPreview photo={editingPhoto} updatePhoto={setEditingPhoto} />
                <Row>
                  <Col>
                    {damageLoading ? (
                      <CommonLoader />
                    ) : (
                      <DragAndDropPhoto title="Drag & drop damage" onDrop={setDamage} />
                    )}
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col
                className="d-flex flex-column align-items-md-start align-items-sm-center"
                sm="12"
                md="6"
                lg="6"
              >
                <Button
                  color="primary"
                  className="mt-4 float-right"
                  onClick={handleCloseAlert}
                >
                  Close
                </Button>
              </Col>
              <Col
                className="d-flex flex-column align-items-md-end align-items-sm-center"
                sm="12"
                md="6"
                lg="6"
              >
                <Button
                  color="primary"
                  className="mt-4 float-right"
                  disabled={!allDamagesHasSelectedTypes(editingPhoto)}
                  onClick={savePhotos}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default VehiclePhotoEditForm;

VehiclePhotoEditForm.propTypes = {
  handleCloseAlert: PropTypes.func,
  photos: PropTypes.array,
  photoEditIndex: PropTypes.number,
};

VehiclePhotoEditForm.defaultProps = {
  photos: [],
  handleCloseAlert: () => {},
  photoEditIndex: null,
};
