import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { reverse } from 'named-urls';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CommonTable from '../../common/components/CommonTable';
import UsersTableRow from './UsersTableRow';
import RouterPaths from '../../constants/RouterPaths';
import CommonLoader from '../../common/components/CommonLoader';

const headers = [
  {
    label: 'ID',
  },
  {
    label: 'First name',
  },
  {
    label: 'Last name',
  },
  {
    label: 'Email',
  },
  {
    label: 'Mailchimp ID',
  },
  {
    label: 'Registered date',
  },
  {
    label: 'Dealer',
  },
  {
    label: 'Status',
  },
  {
    label: 'Actions',
    className: 'text-right action-table-th',
  },
];

const UsersTable = ({ data, offset, changeOffset, history }) => {
  const loaderStatus = useSelector(state => state.users.get('loaderStatus'));
  return (
    <Row>
      <Col xs="12">
        <CommonLoader status={loaderStatus}>
          <CommonTable
            rowComponent={UsersTableRow}
            data={data}
            offset={offset}
            onOffsetChange={changeOffset}
            headers={headers}
            title="Users"
            handleRowClick={item =>
              history.push(reverse(RouterPaths.EDIT_USER, { id: item.id }))
            }
          />
        </CommonLoader>
      </Col>
    </Row>
  );
};

UsersTable.propTypes = {
  changeOffset: PropTypes.func.isRequired,
};

export default withRouter(UsersTable);
