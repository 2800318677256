import _, { get } from 'lodash';
import { apiClient, handleError } from '../api/ApiClient';
import { showAlert } from '../common/components/alert/AlertActions';
import AlertVariant from '../common/components/alert/AlertVariant';
import history from '../browserHistory';
import RouterPaths from '../constants/RouterPaths';
import {
  CLEAR_DEALER_DETAILS,
  CLEAR_DEALER_VALIDATION_ERRORS_SUCCESS,
  EDIT_DEALER_FAILURE,
  FETCH_DEALER_CHANGES,
  FETCH_DEALER_CHANGES_FAILURE,
  FETCH_DEALER_CHANGES_SUCCESS,
  FETCH_DEALER_NOTES_FAILURE,
  FETCH_DEALER_NOTES_SUCCESS,
  GET_DEALER,
  GET_DEALER_FAILURE,
  GET_DEALER_SUCCESS,
  SEARCH_BLACKLIST_DEALERS_SUCCESS,
  SEARCH_DEALER,
  SEARCH_DEALER_FAILURE,
  SEARCH_DEALER_SUCCESS,
} from './DealersReducer';
import { DealerStatus } from '../constants/DealerStatus';
import { prepareSearchData, prepareSortOption, SORT_DESC } from '../helpers/prepareSearchData';
import { FormRegExp } from '../constants/FormRegExp';
import { AgreeOptions } from '../constants/AgreeOptions';
import { poundsToPences } from '../common/helpers/poundsToPences';
import {
  clearDealerEmptyValues,
  clearDealersEmptyValues,
} from '../common/helpers/clearDealerEmptyValues';

const DEALERS_ENDPOINT = '/dealer';

export const getDealer = id => dispatch =>
  apiClient
    .get(`${DEALERS_ENDPOINT}/${id}`)
    .then(res => {
      dispatch({ type: GET_DEALER });
      if (res.status === 200) {
        const data = res?.data?.data;
        if (data.mainProfile) {
          data.mainProfile = clearDealerEmptyValues(data.mainProfile);
        }
        if (data?.organization?.mainLocation) {
          data.organization.mainLocation = clearDealerEmptyValues(
            data.organization.mainLocation,
          );
        }
        dispatch({ type: GET_DEALER_SUCCESS, payload: { data } });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
      dispatch({ type: GET_DEALER_FAILURE });
    });

export const deleteDealer = id => dispatch =>
  apiClient
    .delete(`${DEALERS_ENDPOINT}/${id}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Dealer removed successfully', AlertVariant.SUCCESS));
        history.push(RouterPaths.DEALERS);
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    });

export const editDealer = (id, values, statusValue) => dispatch => {
  const prepareMainProfile = () => {
    const {
      profileId,
      firstName,
      lastName,
      email,
      mailchimpId,
      status,
      isOwner,
      isBuyer,
      isSeller,
      phoneNumber,
      landlinePhoneNumber,
      position,
      acceptTos,
      acceptNewsletter,
      profileVersion,
      profileSerial,
    } = values;
    return {
      id: profileId,
      firstName,
      lastName,
      email,
      mailchimpId,
      status: statusValue || status,
      isOwner,
      isBuyer,
      isSeller,
      phoneNumber,
      landlinePhoneNumber: landlinePhoneNumber || null,
      position: position.value,
      acceptTos,
      acceptNewsletter,
      version: profileVersion,
      serial: profileSerial,
    };
  };
  const prepareOrganization = () => {
    const {
      additionalEmails,
      additionalSalesEmails,
      organizationId,
      vatNumber,
      officialCompanyName,
      tradeName,
      companyRegistrationNumber,
      role,
      website,
      organizationVersion,
      organizationSerial,
      deletedOn,
      organizationFeesVersion,
      organizationFeesSerial,
      mainLocation,
      address,
      address2,
      city,
      county,
      locations,
      paymentMethod,
      monthlySubscriptionInPence,
      monthlySubscriptionFeeExemption,
      monthlySubscriptionFeeExemptionTo,
      organizationGroup,
      launchInPence,
      launchFeeExemption,
      launchFeeExemptionTo,
      saleForLessThan1000InPence,
      saleForLessThan1000FeeExemption,
      saleForLessThan1000FeeExemptionTo,
      saleBetween1001And2500InPence,
      saleBetween1001And2500FeeExemption,
      saleBetween1001And2500FeeExemptionTo,
      saleBetween2501And5000InPence,
      saleBetween2501And5000FeeExemption,
      saleBetween2501And5000FeeExemptionTo,
      saleBetween5001And10000InPence,
      saleBetween5001And10000FeeExemption,
      saleBetween5001And10000FeeExemptionTo,
      saleBetween10001And20000InPence,
      saleBetween10001And20000FeeExemption,
      saleBetween10001And20000FeeExemptionTo,
      saleForMoreThan20000InPence,
      saleForMoreThan20000FeeExemption,
      saleForMoreThan20000FeeExemptionTo,
      purchaseForLessThan1000InPence,
      purchaseForLessThan1000FeeExemption,
      purchaseForLessThan1000FeeExemptionTo,
      purchaseBetween1001And2500InPence,
      purchaseBetween1001And2500FeeExemption,
      purchaseBetween1001And2500FeeExemptionTo,
      purchaseBetween2501And5000InPence,
      purchaseBetween2501And5000FeeExemption,
      purchaseBetween2501And5000FeeExemptionTo,
      purchaseBetween5001And10000InPence,
      purchaseBetween5001And10000FeeExemption,
      purchaseBetween5001And10000FeeExemptionTo,
      purchaseBetween10001And20000InPence,
      purchaseBetween10001And20000FeeExemption,
      purchaseBetween10001And20000FeeExemptionTo,
      purchaseForMoreThan20000InPence,
      purchaseForMoreThan20000FeeExemption,
      purchaseForMoreThan20000FeeExemptionTo,
    } = values;

    const parseCurrencyToIntIfString = value => {
      if (typeof value === 'string') {
        const result = Number(
          value.replace(FormRegExp.INT_WITHOUT_PREFIX_AND_SPECIAL_CHARS, ''),
        );
        if (!Number.isNaN(result)) return poundsToPences(result);
      }
      return poundsToPences(value);
    };

    const locationsData = locations.map(item => {
      item.address2 = item?.address2?.trim() || null;
      item.postalCodeId = item.postalCode.value ? item.postalCode.value : item.postalCode.id;
      return item;
    });
    return {
      id: organizationId,
      vatNumber,
      officialCompanyName,
      tradeName: tradeName?.trim() || null,
      companyRegistrationNumber: companyRegistrationNumber || null,
      groupId: get(organizationGroup, 'value'),
      organizationFees: {
        id,
        version: organizationFeesVersion,
        serial: organizationFeesSerial,
        paymentMethod: paymentMethod.value,
        monthlySubscriptionInPence: parseCurrencyToIntIfString(monthlySubscriptionInPence),
        monthlySubscriptionFeeExemption: monthlySubscriptionFeeExemption.value === true,
        monthlySubscriptionFeeExemptionTo:
          monthlySubscriptionFeeExemption.value === true
            ? monthlySubscriptionFeeExemptionTo
            : null,
        launchInPence: parseCurrencyToIntIfString(launchInPence),
        launchFeeExemption: launchFeeExemption.value === true,
        launchFeeExemptionTo: launchFeeExemption.value === true ? launchFeeExemptionTo : null,
        saleForLessThan1000InPence: parseCurrencyToIntIfString(saleForLessThan1000InPence),
        saleForLessThan1000FeeExemption: saleForLessThan1000FeeExemption.value === true,
        saleForLessThan1000FeeExemptionTo:
          saleForLessThan1000FeeExemption.value === true
            ? saleForLessThan1000FeeExemptionTo
            : null,
        saleBetween1001And2500InPence: parseCurrencyToIntIfString(
          saleBetween1001And2500InPence,
        ),
        saleBetween1001And2500FeeExemption: saleBetween1001And2500FeeExemption.value === true,
        saleBetween1001And2500FeeExemptionTo:
          saleBetween1001And2500FeeExemption.value === true
            ? saleBetween1001And2500FeeExemptionTo
            : null,
        saleBetween2501And5000InPence: parseCurrencyToIntIfString(
          saleBetween2501And5000InPence,
        ),
        saleBetween2501And5000FeeExemption: saleBetween2501And5000FeeExemption.value === true,
        saleBetween2501And5000FeeExemptionTo:
          saleBetween2501And5000FeeExemption.value === true
            ? saleBetween2501And5000FeeExemptionTo
            : null,
        saleBetween5001And10000InPence: parseCurrencyToIntIfString(
          saleBetween5001And10000InPence,
        ),
        saleBetween5001And10000FeeExemption:
          saleBetween5001And10000FeeExemption.value === true,
        saleBetween5001And10000FeeExemptionTo:
          saleBetween5001And10000FeeExemption.value === true
            ? saleBetween5001And10000FeeExemptionTo
            : null,
        saleBetween10001And20000InPence: parseCurrencyToIntIfString(
          saleBetween10001And20000InPence,
        ),
        saleBetween10001And20000FeeExemption:
          saleBetween10001And20000FeeExemption.value === true,
        saleBetween10001And20000FeeExemptionTo:
          saleBetween10001And20000FeeExemption.value === true
            ? saleBetween10001And20000FeeExemptionTo
            : null,
        saleForMoreThan20000InPence: parseCurrencyToIntIfString(saleForMoreThan20000InPence),
        saleForMoreThan20000FeeExemption: saleForMoreThan20000FeeExemption.value === true,
        saleForMoreThan20000FeeExemptionTo:
          saleForMoreThan20000FeeExemption.value === true
            ? saleForMoreThan20000FeeExemptionTo
            : null,
        purchaseForLessThan1000InPence: parseCurrencyToIntIfString(
          purchaseForLessThan1000InPence,
        ),
        purchaseForLessThan1000FeeExemption:
          purchaseForLessThan1000FeeExemption.value === true,
        purchaseForLessThan1000FeeExemptionTo:
          purchaseForLessThan1000FeeExemption.value === true
            ? purchaseForLessThan1000FeeExemptionTo
            : null,
        purchaseBetween1001And2500InPence: parseCurrencyToIntIfString(
          purchaseBetween1001And2500InPence,
        ),
        purchaseBetween1001And2500FeeExemption:
          purchaseBetween1001And2500FeeExemption.value === true,
        purchaseBetween1001And2500FeeExemptionTo:
          purchaseBetween1001And2500FeeExemption.value === true
            ? purchaseBetween1001And2500FeeExemptionTo
            : null,
        purchaseBetween2501And5000InPence: parseCurrencyToIntIfString(
          purchaseBetween2501And5000InPence,
        ),
        purchaseBetween2501And5000FeeExemption:
          purchaseBetween2501And5000FeeExemption.value === true,
        purchaseBetween2501And5000FeeExemptionTo:
          purchaseBetween2501And5000FeeExemption.value === true
            ? purchaseBetween2501And5000FeeExemptionTo
            : null,
        purchaseBetween5001And10000InPence: parseCurrencyToIntIfString(
          purchaseBetween5001And10000InPence,
        ),
        purchaseBetween5001And10000FeeExemption:
          purchaseBetween5001And10000FeeExemption.value === true,
        purchaseBetween5001And10000FeeExemptionTo:
          purchaseBetween5001And10000FeeExemption.value === true
            ? purchaseBetween5001And10000FeeExemptionTo
            : null,
        purchaseBetween10001And20000InPence: parseCurrencyToIntIfString(
          purchaseBetween10001And20000InPence,
        ),
        purchaseBetween10001And20000FeeExemption:
          purchaseBetween10001And20000FeeExemption.value === true,
        purchaseBetween10001And20000FeeExemptionTo:
          purchaseBetween10001And20000FeeExemption.value === true
            ? purchaseBetween10001And20000FeeExemptionTo
            : null,
        purchaseForMoreThan20000InPence: parseCurrencyToIntIfString(
          purchaseForMoreThan20000InPence,
        ),
        purchaseForMoreThan20000FeeExemption:
          purchaseForMoreThan20000FeeExemption.value === true,
        purchaseForMoreThan20000FeeExemptionTo:
          purchaseForMoreThan20000FeeExemption.value === true
            ? purchaseForMoreThan20000FeeExemptionTo
            : null,
      },
      mainLocation: {
        postalCodeId: mainLocation.postalCode.value
          ? mainLocation.postalCode.value
          : mainLocation.postalCode.id,
        address,
        address2: address2?.trim() || null,
        city,
        county,
        version: mainLocation.version,
      },
      additionalLocations: locationsData,
      additionalEmails: prepareAdditionalEmail(additionalEmails),
      additionalSalesEmails: prepareAdditionalEmail(additionalSalesEmails),
      role: role.value,
      website: website?.trim() || null,
      version: organizationVersion,
      serial: organizationSerial,
      deletedOn,
    };
  };

  const prepareAdditionalEmail = additionalEmails => {
    if (_.isEmpty(additionalEmails)) return null;
    return additionalEmails.map(item => item.email);
  };

  const data = {
    mainProfileForm: prepareMainProfile(),
    organizationForm: prepareOrganization(),
  };

  return apiClient
    .put(`${DEALERS_ENDPOINT}/${id}`, data)
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Dealer updated successfully', AlertVariant.SUCCESS));
        history.push(RouterPaths.DEALERS);
      }
    })
    .catch(err => {
      if (err?.response?.status === 412) {
        return dispatch({ type: EDIT_DEALER_FAILURE, payload: err?.response?.data?.data });
      }
      return dispatch(handleError(err));
    });
};

export const editDealerOrganization = (id, { status, version }) => (dispatch, getState) => {
  const organizationId = getState().dealers?.toJS().dealerDetails?.organization?.id;
  return apiClient
    .post(`${DEALERS_ENDPOINT}/organization-change/${id}`, { status, version })
    .then(res => {
      if (res.status === 200) {
        dispatch(
          showAlert(
            status === DealerStatus.ACTIVE
              ? 'Changes approved successfully'
              : 'Changes rejected successfully',
            AlertVariant.SUCCESS,
          ),
        );
        dispatch(getDealer(organizationId));
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    });
};

export const searchDealer = values => dispatch => {
  return apiClient
    .post(`${DEALERS_ENDPOINT}/search`, prepareSearchData(values, prepareSortOption(values)))
    .then(res => {
      dispatch({ type: SEARCH_DEALER });
      if (res.status === 200) {
        let content = get(res, 'data.data.content', []);
        content = clearDealersEmptyValues(content);

        dispatch({
          type: SEARCH_DEALER_SUCCESS,
          payload: {
            items: content,
            totalItems: get(res, 'data.data.totalElements', 0),
          },
        });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
      dispatch({ type: SEARCH_DEALER_FAILURE });
    });
};

export const fetchDealerChanges = (values, organizationId) => dispatch => {
  return apiClient
    .post(
      `${DEALERS_ENDPOINT}/${organizationId}/changelog`,
      prepareSearchData(values, { createdOn: SORT_DESC }),
    )
    .then(res => {
      dispatch({ type: FETCH_DEALER_CHANGES });
      if (res.status === 200) {
        dispatch({
          type: FETCH_DEALER_CHANGES_SUCCESS,
          payload: {
            dealerChanges: {
              items: get(res, 'data.data.content', []),
              totalItems: get(res, 'data.data.totalElements', 0),
            },
          },
        });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
      dispatch({ type: FETCH_DEALER_CHANGES_FAILURE });
    });
};

export const fetchDealerNotes = (values, organizationId) => dispatch => {
  return apiClient
    .post(
      `${DEALERS_ENDPOINT}/${organizationId}/notes`,
      prepareSearchData(values, { createdOn: SORT_DESC }),
    )
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: FETCH_DEALER_NOTES_SUCCESS,
          payload: {
            dealerNotes: {
              items: get(res, 'data.data.content', []),
              totalItems: get(res, 'data.data.totalElements', 0),
            },
          },
        });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
      dispatch({ type: FETCH_DEALER_NOTES_FAILURE });
    });
};

export const addDealerNote = (values, organizationId, successCallback) => dispatch => {
  const data = {
    content: values?.contentNote,
  };
  return apiClient
    .post(`${DEALERS_ENDPOINT}/${organizationId}/add-note`, data)
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Note added to dealer successfully', AlertVariant.SUCCESS));
        successCallback();
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    });
};

export const getDealers = (tradeName, successCallback) => dispatch => {
  return apiClient
    .post(
      `${DEALERS_ENDPOINT}/search`,
      prepareSearchData({ tradeName }, { serial: SORT_DESC }, true),
    )
    .then(res => {
      successCallback(res.data);
    })
    .catch(err => dispatch(handleError(err)));
};

export const searchBlacklistDealers = (values, organizationId) => dispatch => {
  return apiClient
    .post(
      `${DEALERS_ENDPOINT}/${organizationId}/blacklist`,
      prepareSearchData(values, { serial: SORT_DESC }),
    )
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: SEARCH_BLACKLIST_DEALERS_SUCCESS,
          payload: {
            items: get(res, 'data.data.content', []),
            totalItems: get(res, 'data.data.totalElements', 0),
          },
        });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    });
};

export const createBlacklistDealer = (
  organizationId,
  blockedOrganizationId,
  successCallback,
) => dispatch => {
  return apiClient
    .post(`${DEALERS_ENDPOINT}/${organizationId}/blacklist/${blockedOrganizationId}`, {})
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Dealer added to blacklist successfully', AlertVariant.SUCCESS));
        successCallback();
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    });
};

export const deleteBlacklistDealer = (
  organizationId,
  blockedOrganizationId,
  successCallback,
) => dispatch => {
  return apiClient
    .delete(`${DEALERS_ENDPOINT}/${organizationId}/blacklist/${blockedOrganizationId}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(
          showAlert('Dealer removed from blacklist successfully', AlertVariant.SUCCESS),
        );
        successCallback();
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    });
};

export const clearDealerFeesValidationErrors = () => dispatch => {
  dispatch({ type: CLEAR_DEALER_VALIDATION_ERRORS_SUCCESS });
};

export const clearDealerDetails = () => dispatch => {
  dispatch({ type: CLEAR_DEALER_DETAILS });
};
