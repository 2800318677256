import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import VideoSnapshot from 'video-snapshot';
import { addVideo, prepareThumbnailFile } from '../VehicleActions';
import { UploadFileTypes } from '../../constants/UploadFileTypes';
import VideoPreviewModal from './videoPreviewModal/VideoPreviewModal';

const VehicleMediaVideoForm = ({ form }) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [videoPreview, setVideoPreview] = useState(null);

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.map(async file => {
      const preview = await getThumbnail(file);
      setPreview(preview, file);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getThumbnail = async file => {
    const snapshoter = new VideoSnapshot(file);
    const snap = await snapshoter.takeSnapshot();
    const thumbnail = await prepareThumbnailFile(snap);
    return thumbnail;
  };

  const setPreview = async (preview, file) => {
    if (file && file?.type.includes(UploadFileTypes.VIDEO)) {
      dispatch(
        addVideo(file, preview, data => {
          file = {
            fileUrl: data?.video?.fileUrl,
            id: data?.video?.id,
            previewUrl: data?.thumbnail?.fileUrl,
            previewId: data?.thumbnail?.id,
          };
          setFiles(prevState => {
            const files = cloneDeep(prevState);
            files.push(file);
            return files;
          });
        }),
      );
    }
  };

  useEffect(() => {
    form.values.newVideo = files;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'video/*',
    onDrop,
    noDrag: true,
  });

  const showVideoModal = video => {
    setVideoPreview(video);
    setIsOpen(true);
  };

  const removeVideo = (event, fileId) => {
    setFiles(prevState => {
      return prevState.filter(item => item.id !== fileId);
    });
    form.values.videos = form.values.videos.filter(item => item.mediaMetadataId !== fileId);
    form.values.videosToDelete = [...form.values.videosToDelete, fileId];
    event.stopPropagation();
  };

  const thumbs = files.map(file => (
    <div className="thumb" key={file.id}>
      <div className="thumb-inner">
        <img src={file.previewUrl} className="img" alt="preview" />
        <div
          className="remove-image"
          role="presentation"
          onClick={e => removeVideo(e, file.id)}
        >
          <i className="fa fa-times" />
        </div>
      </div>
    </div>
  ));

  return (
    <>
      <h6>Add video</h6>
      <Row className="media-upload">
        <Col>
          <aside className="thumbs-container">
            {form?.values?.videos &&
              form?.values?.videos.map(({ mediaMetadataId, previewUrl, fileUrl }) => (
                <div className="thumb" key={mediaMetadataId}>
                  <div
                    className="thumb-inner"
                    role="button"
                    tabIndex="0"
                    onClick={() => showVideoModal(fileUrl)}
                  >
                    <img
                      key={mediaMetadataId}
                      src={previewUrl}
                      alt="Vehicle thumbnail"
                      className="img"
                    />
                    <div
                      className="remove-image"
                      role="presentation"
                      onClick={e => removeVideo(e, mediaMetadataId)}
                    >
                      <i className="fa fa-times" />
                    </div>
                  </div>
                </div>
              ))}
          </aside>
        </Col>
      </Row>
      <Row className="media-upload">
        <Col>
          <aside className="thumbs-container">
            {thumbs}
            <div {...getRootProps()} className="add-button">
              <input {...getInputProps()} />
              <i className="fa fa-plus-circle" aria-hidden="true" />
            </div>
          </aside>
        </Col>
      </Row>
      <VideoPreviewModal
        isOpen={isOpen}
        toggleModal={() => setIsOpen(prev => !prev)}
        video={videoPreview}
      />
    </>
  );
};

VehicleMediaVideoForm.propTypes = {
  form: PropTypes.object.isRequired,
};

export default VehicleMediaVideoForm;
