import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import PropTypes from 'prop-types';

const RequestForChangesTableRow = ({ item }) => {
  return (
    <>
      <td>{item.serial}</td>
      <td>{item?.organization?.tradeName}</td>
      <td>{item.description}</td>
      <td className="text-right">
        <Button
          className="btn-link"
          color="primary"
          id={`tooltip${item.id}`}
          size="sm"
          type="button"
        >
          <i className="fa fa-edit" />
        </Button>
        <UncontrolledTooltip delay={0} target={`tooltip${item.id}`}>
          View
        </UncontrolledTooltip>
      </td>
    </>
  );
};

RequestForChangesTableRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default RequestForChangesTableRow;
