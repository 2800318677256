import { AdminRole, AdminRoleName } from 'constants/AdminRole';
import routes from '../routes';

const SUPER_ADMIN_ROUTES = routes.filter(
  item => !item.access.includes(AdminRoleName.DASHBOARD),
);
const ADMIN_ROUTES = routes.filter(item => item.access.includes(AdminRoleName.ADMIN));
const DASHBOARD_ROUTES = routes.filter(item => item.access.includes(AdminRoleName.DASHBOARD));
export const accessRoutes = accountType => {
  switch (accountType) {
    case AdminRole.SUPER_ADMIN:
      return SUPER_ADMIN_ROUTES;
    case AdminRole.ADMIN:
      return ADMIN_ROUTES;
    case AdminRole.DASHBOARD:
      return DASHBOARD_ROUTES;
    default:
      return DASHBOARD_ROUTES;
  }
};
