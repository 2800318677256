import { get, isEmpty } from 'lodash';
import { apiClient, handleError } from '../api/ApiClient';
import { prepareSearchData, SORT_ASC, SORT_DESC } from '../helpers/prepareSearchData';
import {
  CREATE_GROUP_SUCCESS,
  DELETE_GROUP_SUCCESS,
  EDIT_GROUP_SUCCESS,
  FECTH_GROUP_SUCCESS,
  FETCH_ORGANIZATION_DEALERS_SUCCESS,
  SEARCH_GROUP,
  SEARCH_GROUP_FAILURE,
  SEARCH_GROUP_SUCCESS,
} from './GroupsReducer';
import RouterPaths from '../constants/RouterPaths';
import history from '../browserHistory';
import { PrepareUrl } from '../common/helpers/PrepareUrl';
import { showAlert } from '../common/components/alert/AlertActions';
import AlertVariant from '../common/components/alert/AlertVariant';

const GROUP_ENDPOINT = '/organization-group';

export const searchGroups = values => dispatch => {
  dispatch({ type: SEARCH_GROUP });
  return apiClient
    .post(`${GROUP_ENDPOINT}/search`, prepareSearchData(values, { serial: SORT_DESC }))
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: SEARCH_GROUP_SUCCESS,
          payload: {
            items: get(res, 'data.data.content', []),
            totalItems: get(res, 'data.data.totalElements', 0),
          },
        });
      }
    })
    .catch(err => {
      dispatch({ type: SEARCH_GROUP_FAILURE });
      dispatch(handleError(err));
    });
};

export const createGroup = values => dispatch => {
  const { name, dealersIdsToAdd } = values;
  const data = {
    name,
    dealersIdsToAdd,
  };
  return apiClient
    .post(GROUP_ENDPOINT, data)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: CREATE_GROUP_SUCCESS,
        });
        dispatch(showAlert('Group created successfully', AlertVariant.SUCCESS));
        const id = get(res, 'data.data.id', '');
        history.push(PrepareUrl(RouterPaths.EDIT_GROUP, { id }));
      }
    })
    .catch(err => {
      const errorMessage =
        err?.response?.data?.message || Object.values(err?.response?.data?.data)[0];
      dispatch(showAlert(errorMessage, AlertVariant.DANGER));
    });
};

export const deleteGroup = id => dispatch => {
  return apiClient
    .delete(`${GROUP_ENDPOINT}/${id}`)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: DELETE_GROUP_SUCCESS,
        });
        dispatch(showAlert('Group removed successfully', AlertVariant.SUCCESS));
        history.push(RouterPaths.GROUPS);
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    });
};

export const fetchGroup = id => dispatch => {
  return apiClient
    .get(`${GROUP_ENDPOINT}/${id}`)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: FECTH_GROUP_SUCCESS,
          payload: {
            groupDetails: res?.data?.data,
          },
        });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    });
};

export const editGroup = (id, values, dealersIdsToAdd, dealersIdsToDelete) => dispatch => {
  const data = {
    ...values,
    dealersIdsToAdd,
    dealersIdsToDelete,
  };
  return apiClient
    .put(`${GROUP_ENDPOINT}/${id}`, data)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: EDIT_GROUP_SUCCESS,
        });
        if (!isEmpty(dealersIdsToAdd))
          dispatch(showAlert('Dealer added successfully to group.', AlertVariant.SUCCESS));
        else if (!isEmpty(dealersIdsToDelete))
          dispatch(showAlert('Dealer removed successfully from group.', AlertVariant.SUCCESS));
        else dispatch(showAlert('Group updated successfully', AlertVariant.SUCCESS));
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    });
};

export const searchOrganizationDealers = (organizationGroupId, values) => dispatch => {
  return apiClient
    .post(
      `${GROUP_ENDPOINT}/${organizationGroupId}/members`,
      prepareSearchData(values, { serial: SORT_ASC }, true),
    )
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: FETCH_ORGANIZATION_DEALERS_SUCCESS,
          payload: {
            items: get(res, 'data.data.content', []),
            totalItems: get(res, 'data.data.totalElements', 0),
          },
        });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    });
};
