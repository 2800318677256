import React from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import FormInputField from '../common/components/formFields/FormInputField';

const initialValues = {
  name: '',
};

const GroupsSearch = ({ handleSearch }) => {
  return (
    <Card>
      <CardBody>
        <h6>Search groups</h6>
        <Formik initialValues={initialValues} onSubmit={handleSearch} validateOnBlur={true}>
          {({ resetForm, submitForm }) => (
            <Form noValidate>
              <Row>
                <Col md="4">
                  <FormInputField name="name" label="Name" />
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <Button color="primary" className="mt-4 float-right" type="submit">
                    Search
                  </Button>
                  <Button
                    color="primary"
                    outline={true}
                    className="mt-4 float-right"
                    type="button"
                    onClick={() => {
                      resetForm();
                      submitForm();
                    }}
                  >
                    Clear
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
};

GroupsSearch.propTypes = {
  handleSearch: PropTypes.func.isRequired,
};
export default GroupsSearch;
