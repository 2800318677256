import React from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import Select from 'react-select';

const FormSelectFieldInner = props => {
  const {
    label,
    placeholder,
    form,
    field,
    options,
    onChangeHandler,
    onInputChange = () => {},
    disabled,
  } = props;

  const error = form.errors[field.name];
  const touched = form.touched[field.name];
  const value = form.values[field.name] || '';

  return (
    <Row>
      <Col style={{ 'text-align': 'left' }}>
        <Label>{label}</Label>
        <FormGroup>
          <Select
            options={options}
            placeholder={placeholder}
            value={value}
            isDisabled={form.isSubmitting || disabled}
            onBlur={() => form.setFieldTouched(field.name, true)}
            onInputChange={onInputChange}
            onChange={option => {
              form.setFieldValue(field.name, option);
              onChangeHandler(option);
            }}
          />
          {(form.submitCount > 0 || touched) && error && (
            <label style={{ color: 'red ' }}>{error?.value ? error.value : error}</label>
          )}
        </FormGroup>
      </Col>
    </Row>
  );
};

const FormSelectField = props => {
  return <Field {...props} component={FormSelectFieldInner} />;
};

FormSelectField.defaultProps = {
  placeholder: '',
  options: [],
  onChangeHandler: () => {},
  disabled: false,
};

FormSelectField.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  onChangeHandler: PropTypes.func,
  disabled: PropTypes.bool,
};

export default FormSelectField;
