import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CommonTable from '../../common/components/CommonTable';
import TransactionsTableRow from './TransactionsTableRow';
import CommonLoader from '../../common/components/CommonLoader';

const headers = [
  {
    label: 'Serial',
  },
  {
    label: 'Fee',
  },
  {
    label: 'Title',
  },
  {
    label: 'Created on',
  },
  {
    label: 'Dealer',
  },
  {
    label: 'Payment status',
  },
];

const TransactionsTable = ({ data, offset, changeOffset }) => {
  const loaderStatus = useSelector(state => state.transactions.get('loaderStatus'));
  return (
    <Row>
      <Col xs="12">
        <CommonLoader status={loaderStatus}>
          <CommonTable
            rowComponent={TransactionsTableRow}
            data={data}
            offset={offset}
            onOffsetChange={changeOffset}
            headers={headers}
            title="Transactions"
            hasRowPointer={false}
          />
        </CommonLoader>
      </Col>
    </Row>
  );
};

TransactionsTable.propTypes = {
  data: PropTypes.object.isRequired,
  offset: PropTypes.number.isRequired,
  changeOffset: PropTypes.func.isRequired,
};

export default TransactionsTable;
