import React from 'react';
import { Col, Row } from 'reactstrap';
import { FormSelectField } from '../../common/components/formFields';

const getTiresSize = () => {
  return Array.from(Array(10), (_, i) => ({ value: i + 1, label: `${i + 1} mm` }));
};

const VehicleTiresForm = () => {
  return (
    <>
      <Row>
        <Col>
          <h6>Tyres depth</h6>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <FormSelectField
            name="nearsideFront"
            label="Nearside front"
            options={getTiresSize()}
          />
        </Col>
        <Col md="4">
          <FormSelectField
            name="nearsideRear"
            label="Nearside rear"
            options={getTiresSize()}
          />
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <FormSelectField
            name="offsideFront"
            label="Offside front"
            options={getTiresSize()}
          />
        </Col>
        <Col md="4">
          <FormSelectField name="offsideRear" label="Offside rear" options={getTiresSize()} />
        </Col>
      </Row>
    </>
  );
};

VehicleTiresForm.propTypes = {};

export default VehicleTiresForm;
