import React from 'react';
import PropTypes from 'prop-types';
import { dateToString } from '../../common/utils/date/Date.utils';

const EditDealerNotesTableRow = ({ item }) => {
  return (
    <>
      <td>{item?.createdOn && dateToString(item?.createdOn)}</td>
      <td>{item.createdBy}</td>
      <td className="dealer-note-table-content">{item.content}</td>
    </>
  );
};

EditDealerNotesTableRow.propTypes = {
  item: PropTypes.object,
};
EditDealerNotesTableRow.defaultProps = {
  item: {},
};

export default EditDealerNotesTableRow;
