import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import SweetAlert from 'react-bootstrap-sweetalert';
import CommonTable from '../../common/components/CommonTable';
import VehicleBidsLaunchTableRow from './VehicleBidsLaunchTableRow';
import VehicleAddBidForm from './VehicleAddBidForm';

const headers = [
  {
    label: 'Buyer',
  },
  {
    label: 'Bidder',
  },
  {
    label: 'Bid',
  },
  {
    label: 'Date',
  },
  {
    label: 'Actions',
    className: 'text-right',
  },
];

let removeBid;

const VehicleBidsLaunchTable = ({ data, includeForm, removeItemHandler }) => {
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [sortedData, setSortedData] = useState([]);

  useEffect(() => {
    if (data.items) {
      setSortedData(
        data.items.sort((a, b) => {
          if (a.amountInPence !== b.amountInPence) {
            return b.amountInPence - a.amountInPence;
          }
          return Date.parse(a.createdOn) - Date.parse(b.createdOn);
        }),
      );
    }
  }, [data.items]);

  const showRemoveItemAlert = bid => {
    removeBid = bid;
    setIsAlertVisible(true);
  };
  return (
    <>
      <Row>
        <Col xs="12">
          <CommonTable
            rowComponent={VehicleBidsLaunchTableRow}
            rowComponentProps={{ showRemoveItemAlert }}
            data={{ items: sortedData, total: data?.totalItems }}
            headers={headers}
            pagination={false}
            ordinal={true}
            partial={true}
            hasRowPointer={false}
          />
        </Col>
        <SweetAlert
          danger
          showCancel
          show={isAlertVisible}
          title="Warning!"
          onConfirm={() => {
            setIsAlertVisible(false);
            removeItemHandler(removeBid);
          }}
          onCancel={() => setIsAlertVisible(false)}
          confirmBtnBsStyle="info"
        >
          Are you sure to remove this bid?
        </SweetAlert>
      </Row>
      {includeForm ? <VehicleAddBidForm /> : null}
    </>
  );
};

VehicleBidsLaunchTable.propTypes = {
  data: PropTypes.array.isRequired,
};

export default VehicleBidsLaunchTable;
