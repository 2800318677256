import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { SET_SELECTED_VEHICLE } from '../../vehicles/VehicleReducer';

const FilterButtons = ({ data, handlerChange, isVehicleFilters }) => {
  const dispatch = useDispatch();
  const previousSearchParams = useSelector(state => state.vehicles.get('searchParams'));
  const [selected, setSelected] = useState(
    previousSearchParams?.status?.length === 1 && isVehicleFilters
      ? previousSearchParams.status[0]
      : '',
  );

  const handleClick = status => {
    if (isVehicleFilters) {
      dispatch({
        type: SET_SELECTED_VEHICLE,
      });
    }
    setSelected(status);
    handlerChange(status);
  };

  return (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <div className="d-flex flex-row">
              <div className="p-2">
                <Button
                  color="primary"
                  outline={selected !== ''}
                  onClick={() => handleClick('')}
                >
                  All
                </Button>
              </div>
              {Object.keys(data).map(status => (
                <div className="p-2" key={status}>
                  <Button
                    color="primary"
                    outline={selected !== status}
                    onClick={() => handleClick(status)}
                  >
                    {data[status]}
                  </Button>
                </div>
              ))}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

FilterButtons.defaultProps = {
  isVehicleFilters: false,
};

FilterButtons.propTypes = {
  isVehicleFilters: PropTypes.bool,
  data: PropTypes.object.isRequired,
  handlerChange: PropTypes.func.isRequired,
};

export default FilterButtons;
