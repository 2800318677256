import { apiClient, handleError } from '../../../../api/ApiClient';

export const getGroups = (search, successCallback) => dispatch => {
  const params = {
    criteria: {
      name: search,
    },
    page: {
      pageNumber: 0,
      pageSize: 50,
      sort: {
        orders: [
          {
            direction: 'ASC',
            property: 'name',
          },
        ],
      },
    },
  };
  return apiClient
    .post('/organization-group/search', params)
    .then(res => successCallback(res.data))
    .catch(err => dispatch(handleError(err)));
};
