import { Form, Formik } from 'formik';
import { Button, Col, Row } from 'reactstrap';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FormInputField, FormInputFieldType } from '../../common/components/formFields';
import { fetchAuctionNotes, saveAuctionNote } from '../VehicleActions';
import CommonErrorLabel from '../../common/components/CommonErrorLabel';

const MAX_NOTE_CHARACTERS = 1000;

const AddVehicleNoteForm = ({ searchParams }) => {
  const [noteContentError, setNoteContentError] = useState(null);
  const { id: auctionId } = useParams();
  const dispatch = useDispatch();

  const handleSubmit = (values, { setSubmitting, setFieldValue }) => {
    setSubmitting(true);
    const error = validateNoteContent(values.content);
    if (error) {
      setNoteContentError(error);
      setSubmitting(false);
      return;
    }
    setNoteContentError(null);
    dispatch(
      saveAuctionNote(values, auctionId, () => {
        setFieldValue('content', '');
        dispatch(fetchAuctionNotes(searchParams, auctionId));
      }),
    );
    setSubmitting(false);
  };

  const validateNoteContent = value => {
    if (!value) return 'Field is required';
    if (value.length >= MAX_NOTE_CHARACTERS)
      return `Max length equals ${MAX_NOTE_CHARACTERS} characters`;
    return null;
  };

  return (
    <div>
      <Formik
        initialValues={{
          content: '',
        }}
        onSubmit={handleSubmit}
      >
        <Form noValidate>
          <div className="dealer-note-form">
            <Col className="note-editor">
              <Row>
                <Col>Add note</Col>
                <Col>
                  <Button color="neutral" type="submit" className="float-right">
                    Add
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormInputField
                    noStyling={true}
                    style={{ height: `${125}px` }}
                    name="content"
                    type={FormInputFieldType.TEXTAREA}
                  />
                </Col>
              </Row>
            </Col>
          </div>
          {noteContentError ? (
            <p>
              <CommonErrorLabel value={noteContentError} />
            </p>
          ) : null}
        </Form>
      </Formik>
    </div>
  );
};

export default AddVehicleNoteForm;
