import React from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import PropTypes from 'prop-types';
import { DealerUserStatusName } from '../constants/UserStatus';

const DealersTableRow = ({ item }) => {
  return (
    <>
      <td>{item.officialCompanyName}</td>
      <td>{item.tradeName}</td>
      <td>{item.mainEmail}</td>
      <td>{item?.phoneNumber}</td>
      <td>{item?.activePasswordResetLink ? 'YES' : 'NO'}</td>
      <td>{item?.paymentMethodAdded ? 'YES' : 'NO'}</td>
      <td>{DealerUserStatusName?.[item.status]}</td>
      <td className="text-right">
        <Button
          className="btn-link"
          color="primary"
          id={`tooltip_edit${item.id}`}
          size="sm"
          type="button"
        >
          <i className="fa fa-edit" />
        </Button>
        <UncontrolledTooltip
          delay={0}
          className="uncontrolled-tooltip"
          target={`tooltip_edit${item.id}`}
        >
          Edit
        </UncontrolledTooltip>
      </td>
    </>
  );
};

DealersTableRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default DealersTableRow;
