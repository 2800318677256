import * as Yup from 'yup';
import { isDateBefore } from '../../common/utils/date/Date.utils';

export default () => {
  return Yup.object().shape({
    id: Yup.number(),
    name: Yup.string(),
    dateFrom: Yup.date()
      .typeError('Wrong date format')
      .test({
        message: 'Wrong date range',
        test(dateFrom) {
          return this.parent.dateTo && this.parent.dateFrom
            ? isDateBefore(dateFrom, this.parent.dateTo)
            : true;
        },
      }),
    dateTo: Yup.date().typeError('Wrong date format'),
  });
};
