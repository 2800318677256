import React from 'react';
import { Col, Row, Label } from 'reactstrap';
import * as PropTypes from 'prop-types';
import {
  FormSelectField,
  FormInputField,
  FormCheckboxField,
} from '../../../common/components/formFields';
import { DealerPendingActionTypes } from '../../../constants/DealerPendingActionTypes';
import PendingActionButtons from '../PendingActionButtons';
import { constantToSelect } from '../../../common/helpers';
import { UserPositionName } from '../../../constants/UserPosition';

const dealerUserPositions = constantToSelect(UserPositionName);

const DealerUserForm = ({ disabled, preparePendingActionObj, errors }) => {
  const phoneNumberDataObj = preparePendingActionObj(
    DealerPendingActionTypes.PHONE_NUMBER_CHANGE,
  );

  const landlinePhoneNumberDataObj = preparePendingActionObj(
    DealerPendingActionTypes.LANDLINE_PHONE_NUMBER_CHANGE,
  );

  const errorRole = errors?.isBuyer ? errors?.isBuyer : errors?.isSeller || '';

  return (
    <>
      <h6>Dealer details</h6>
      <Row>
        <Col md="4">
          <FormInputField name="firstName" label="First name" disabled={disabled} />
        </Col>
        <Col md="4">
          <FormInputField name="lastName" label="Last name" disabled={disabled} />
        </Col>
        <Col md="4">
          <FormInputField name="email" label="Email" disabled={disabled} />
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <FormInputField
            name="landlinePhoneNumber"
            label="Landline phone number"
            disabled={disabled}
          />
          <PendingActionButtons
            data={landlinePhoneNumberDataObj}
            type={DealerPendingActionTypes.LANDLINE_PHONE_NUMBER_CHANGE}
          />
        </Col>
        <Col md="4">
          <FormInputField name="phoneNumber" label="Mobile phone number" disabled={disabled} />
          <PendingActionButtons
            data={phoneNumberDataObj}
            type={DealerPendingActionTypes.PHONE_NUMBER_CHANGE}
          />
        </Col>
        <Col md="4">
          <FormInputField name="mailchimpId" label="Mailchimp ID" disabled={disabled} />
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <FormSelectField
            name="position"
            label="Position"
            options={dealerUserPositions}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <Label>Role</Label>
          <FormCheckboxField
            invalid={false}
            label="Buyer"
            name="isBuyer"
            disabled={disabled}
          />
          <FormCheckboxField
            invalid={false}
            label="Seller"
            name="isSeller"
            disabled={disabled}
          />
          <p>
            <Label style={{ color: 'red' }}>{errorRole}</Label>
          </p>
        </Col>
      </Row>
    </>
  );
};
DealerUserForm.defaultProps = {
  disabled: false,
};

DealerUserForm.propTypes = {
  preparePendingActionObj: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  errors: PropTypes.object.isRequired,
};

export default DealerUserForm;
