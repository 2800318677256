import RouterPaths from 'constants/RouterPaths';
import { get } from 'lodash';
import history from '../browserHistory';
import { apiClient, handleError } from '../api/ApiClient';
import { prepareSearchData, SORT_DESC } from '../helpers/prepareSearchData';
import {
  SEARCH_SITE,
  SEARCH_SITE_FAILURE,
  SEARCH_SITE_SUCCESS,
  GET_SITE,
  GET_SITE_SUCCESS,
  GET_SITE_FAILURE,
} from './SiteCreatorReducer';
import { showAlert } from '../common/components/alert/AlertActions';
import AlertVariant from '../common/components/alert/AlertVariant';

const SITE_CREATOR_ENDPOINT = '/site-creator';

export const getSite = id => dispatch => {
  dispatch({ type: GET_SITE });
  return apiClient
    .get(`${SITE_CREATOR_ENDPOINT}/${id}`)
    .then(res => {
      if (res.status === 200) {
        dispatch({ type: GET_SITE_SUCCESS, payload: res?.data });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
      dispatch({ type: GET_SITE_FAILURE });
    });
};

export const searchSite = values => dispatch => {
  dispatch({ type: SEARCH_SITE });
  apiClient
    .post(`${SITE_CREATOR_ENDPOINT}/search`, prepareSearchData(values, { serial: SORT_DESC }))
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: SEARCH_SITE_SUCCESS,
          payload: {
            items: get(res, 'data.data.content', []),
            totalItems: get(res, 'data.data.totalElements', 0),
          },
        });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
      dispatch({ type: SEARCH_SITE_FAILURE });
    });
};

export const editSite = (id, version, values, form) => dispatch => {
  return apiClient
    .put(`${SITE_CREATOR_ENDPOINT}/${id}`, { ...values, id, version })
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Site updated successfully', AlertVariant.SUCCESS));
        history.push(RouterPaths.SITE_CREATOR);
      }
    })
    .catch(err => {
      dispatch(handleError(err, form));
    });
};

export const createSite = (values, form) => dispatch => {
  return apiClient
    .post(`${SITE_CREATOR_ENDPOINT}`, values)
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Site created successfully', AlertVariant.SUCCESS));
        history.push(RouterPaths.SITE_CREATOR);
      }
    })
    .catch(err => {
      dispatch(handleError(err, form));
    });
};

export const deleteSite = id => dispatch => {
  return apiClient
    .delete(`${SITE_CREATOR_ENDPOINT}/${id}`)
    .then(res => {
      if (res.status === 200) {
        dispatch(showAlert('Site deleted successfully', AlertVariant.SUCCESS));
        history.push(RouterPaths.SITE_CREATOR);
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    });
};
