import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { dateToString } from '../../common/utils/date/Date.utils';
import { preparePriceFormat } from '../../helpers/preparePriceFormat';

const TransactionsTable = ({ item }) => {
  return (
    <>
      <td>{item.serial}</td>
      <td>{preparePriceFormat(item?.amountInPence, true) || '-'}</td>
      <td>{item.title}</td>
      <td>{item?.createdOn && dateToString(item?.createdOn)}</td>
      <td>{item.organization?.tradeName}</td>
      <td>{item.paymentStatus}</td>
    </>
  );
};

TransactionsTable.propTypes = {
  item: PropTypes.object.isRequired,
};

export default withRouter(TransactionsTable);
