import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PrepareUrl } from '../../common/helpers/PrepareUrl';
import RouterPaths from '../../constants/RouterPaths';
import Breadcrumbs from '../../common/components/Breadcrumbs';
import DealerTransactionsSearch from './search/DealerTransactionsSearch';
import DealerTransactionsTable from './table/DealerTransactionsTable';
import { setQueryString, useQueryString } from '../../common/helpers/queryString';
import { searchTransactions } from '../../transactions/TransactionsActions';

const DealerTransactionsPage = ({ history, location }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const query = useQueryString();

  const breadcrumbsItems = [
    { id: 1, type: 'home' },
    { id: 2, type: 'link', text: 'Dealers', url: '/dealers' },
    {
      id: 3,
      type: 'link',
      text: 'Edit Dealer',
      url: PrepareUrl(RouterPaths.DEALER_INFORMATION, { id }),
    },
    { id: 4, type: 'span', text: 'Transactions' },
  ];

  const items = useSelector(state => state.transactions.get('items'));
  const totalItems = useSelector(state => state.transactions.get('totalItems'));

  const [searchParams, setSearchParams] = useState({
    offset: query.get('page') ? +query.get('page') - 1 : 0,
    dealerId: id || null,
  });

  useEffect(() => {
    dispatch(searchTransactions(searchParams));
  }, [dispatch, searchParams]);

  const changeOffset = value => {
    setSearchParams(prevState => ({ ...prevState, offset: value }));
    history.push(setQueryString(location, 'page', value + 1));
  };

  const handleSearch = (values, { setSubmitting }) => {
    setSearchParams(prevState => ({ ...prevState, ...values, offset: 0 }));
    setSubmitting(false);
  };

  return (
    <div className="content">
      <Breadcrumbs items={breadcrumbsItems} />
      <DealerTransactionsSearch handleSearch={handleSearch} />
      <DealerTransactionsTable
        changeOffset={changeOffset}
        offset={searchParams.offset}
        data={{ items, total: totalItems }}
      />
    </div>
  );
};

export default DealerTransactionsPage;
