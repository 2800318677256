import React from 'react';
import PropTypes from 'prop-types';
import CurrencyFormat from 'react-currency-format';
import { AgreeOptions } from 'constants/AgreeOptions';
import { AuctionSaleStatus } from 'constants/AuctionStatus';
import { AuctionStatusTypeName } from 'constants/AuctionStatusType';
import { VehiclePublishOptionShortName } from 'constants/VehiclePublishOption';
import { dateToString } from '../common/utils/date/Date.utils';

const ReportsTableRow = ({ item }) => {
  return (
    <>
      <td>{item.serial}</td>
      <td>{item.vehicleRegistrationPlate}</td>
      <td>{item.vehicleMakeName}</td>
      <td>{item.vehicleModelName}</td>
      <td>{item.vehicleCarType}</td>
      <td>{item.vehicleFuel}</td>
      <td>{item.vehicleColour}</td>
      <td>{item.vehicleTransmission}</td>
      <td>{dateToString(item.vehicleDateOfFirstRegistration)}</td>
      <td>{item.vehicleMileage}</td>
      <td>{item.vehicleVehicleFeaturesV5Present ? AgreeOptions.YES : AgreeOptions.NO}</td>
      <td>
        {item?.salePrice && (
          <CurrencyFormat
            value={item.salePrice}
            thousandSeparator={true}
            allowNegative={false}
            prefix="£"
            displayType="text"
          />
        )}
      </td>
      <td>{item.capBelow}</td>
      <td>{item.capRetail}</td>
      <td>{item.capClean}</td>
      <td>{item.capAverage}</td>
      <td>{AuctionStatusTypeName[item.auctionType]}</td>
      <td>{VehiclePublishOptionShortName[item.publishTarget]}</td>
      <td>{dateToString(item.endsOn)}</td>
      <td>{dateToString(item.cancelledOn)}</td>
      <td>{item.salePrice ? AuctionSaleStatus.SOLD : AuctionSaleStatus.NOT_SOLD}</td>
    </>
  );
};

ReportsTableRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default ReportsTableRow;
