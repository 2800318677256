import React, { useState, useEffect, useRef } from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { useParams } from 'react-router-dom';
import validationSchema from './VehiclesSearch.schema';
import {
  FormInputField,
  FormDatePickerField,
  FormSelectField,
  FormInputFieldType,
} from '../../common/components/formFields';
import { dealersDataMockup } from '../../dealers/mockup/DealersMockup';
import { getVehicleMakeList, getVehicleModelsList } from '../VehicleActions';
import { dataToSelect } from '../../common/helpers';
import DealersSelectField from '../../dealers/forms/DealersSelectField';
import { SET_SELECTED_VEHICLE, SET_VEHICLES_SEARCH_PARAMS } from '../VehicleReducer';

const initialValues = {
  registrationPlate: '',
  buyerOrganizationId: '',
  make: {},
  model: {},
  year: '',
  registeredDateFrom: '',
  registeredDateTo: '',
  sellerOrganizationId: '',
  launchDateFrom: '',
  launchDateTo: '',
  search: '',
  launchingCounterSerial: '',
};
const dealers = dealersDataMockup.map(dealer => ({ value: dealer.id, label: dealer.name }));
let formContext = null;

const VehiclesSearch = ({ handleSearch }) => {
  const previousSearchParams = useSelector(state => state.vehicles.get('searchParams'));
  const { dealerId } = useParams();
  const dispatch = useDispatch();

  const formRef = useRef();
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);

  if (dealerId) {
    const dealer = dealers.filter(item => item.value === +dealerId);
    if (dealer.length) {
      [initialValues.dealer] = dealer;
    }
  } else {
    initialValues.dealer = {};
  }

  useEffect(() => {
    if (previousSearchParams?.searchCriteria) {
      formRef.current.setValues(previousSearchParams.searchCriteria);
    }
  }, []);

  useEffect(() => {
    dispatch(
      getVehicleMakeList(makes => {
        setMakes(dataToSelect(makes.data, ['id', 'name']));
      }),
    );
  }, [dispatch]);

  const handlerMakeChange = make => {
    if (make?.value) {
      formContext.setFieldValue('model', '');
      dispatch(
        getVehicleModelsList(make.value, models => {
          setModels(dataToSelect(models.data, ['id', 'name']));
        }),
      );
    }
  };

  const prepareSearchCriteria = (values, { setSubmitting }) => {
    const params = { ...values };
    if (params?.make?.value) {
      params.makeId = params.make.value;
    }
    if (params?.model?.value) {
      params.modelId = params.model.value;
    }
    handleSearch(params, setSubmitting);
  };

  return (
    <Row>
      <Col md="12">
        <Card>
          <CardBody>
            <h6>Search vehicle</h6>
            <Formik
              innerRef={formRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={prepareSearchCriteria}
              validateOnBlur={true}
            >
              {form => {
                formContext = form;
                return (
                  <Form>
                    <Row>
                      <Col md="4">
                        <FormInputField name="registrationPlate" label="Number plate" />
                      </Col>
                      <Col md="4">
                        <DealersSelectField name="buyerOrganizationId" label="Buyer" />
                      </Col>
                      <Col md="4">
                        <DealersSelectField name="sellerOrganizationId" label="Seller" />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <FormSelectField
                          name="make"
                          label="Make"
                          options={makes}
                          onChangeHandler={handlerMakeChange}
                        />
                      </Col>
                      <Col md="4">
                        <FormSelectField name="model" label="Model" options={models} />
                      </Col>
                      <Col md="4">
                        <FormInputField
                          name="year"
                          label="Year"
                          type={FormInputFieldType.NUMBER}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <FormDatePickerField
                          name="registeredDateFrom"
                          label="Registration date from"
                        />
                      </Col>
                      <Col md="4">
                        <FormDatePickerField
                          name="registeredDateTo"
                          label="to"
                          isUpperRange={true}
                        />
                      </Col>
                      <Col md="4">
                        <FormInputField
                          name="launchingCounterSerial"
                          label="Auction Batch ID"
                          defaultValue=""
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <FormDatePickerField name="launchDateFrom" label="Launch date from" />
                      </Col>
                      <Col md="4">
                        <FormDatePickerField
                          name="launchDateTo"
                          label="to"
                          isUpperRange={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <Button color="primary" className="mt-4 float-right" type="submit">
                          Search
                        </Button>
                        <Button
                          color="primary"
                          outline={true}
                          className="mt-4 float-right"
                          type="button"
                          onClick={() => {
                            if (previousSearchParams) {
                              dispatch({
                                type: SET_SELECTED_VEHICLE,
                              });
                              dispatch({
                                type: SET_VEHICLES_SEARCH_PARAMS,
                                payload: {
                                  status: previousSearchParams.status,
                                },
                              });
                            }
                            form.resetForm();
                            form.submitForm();
                          }}
                        >
                          Clear
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default VehiclesSearch;
