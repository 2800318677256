import React from 'react';
import PropTypes from 'prop-types';
import { Button, UncontrolledTooltip } from 'reactstrap';
import { dateToString } from '../../common/utils/date/Date.utils';
import { preparePriceFormat } from '../../helpers/preparePriceFormat';

const DashboardTableRow = ({ item, isDashboardAccount }) => {
  return (
    <>
      <td>{item?.serial}</td>
      <td>{item?.registrationPlate}</td>
      <td>
        {`${item?.vehicleMakeName} ${item.vehicleModelName} ${
          item.vehicleDerivative
        } ${`| ${item?.vehicleYear}`} ${`| 
          ${item?.locationCity}`}`}
      </td>
      <td>{dateToString(item?.soldOn)}</td>
      <td>{item?.salePriceInPence ? preparePriceFormat(item.salePriceInPence, true) : '-'}</td>
      <td>{item?.sellerOrganizationName}</td>
      <td>{item?.buyerOrganizationName}</td>
      {!isDashboardAccount && (
        <td className="text-right">
          <Button
            className="btn-link"
            color="primary"
            id={`tooltip_edit${item.id}`}
            size="sm"
            type="button"
          >
            <i className="fa fa-edit" />
          </Button>
          <UncontrolledTooltip
            delay={0}
            className="uncontrolled-tooltip"
            target={`tooltip_edit${item.id}`}
          >
            Edit
          </UncontrolledTooltip>
        </td>
      )}
    </>
  );
};

export default DashboardTableRow;

DashboardTableRow.propTypes = {
  item: PropTypes.object.isRequired,
  isDashboardAccount: PropTypes.bool.isRequired,
};
