import React from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Col, Row } from 'reactstrap';
import Breadcrumbs from '../../common/components/Breadcrumbs';
import AdminForm from '../form/AdminForm';
import validationSchema from '../form/AdminForm.schema';
import { createAdmin, searchAdmin } from '../AdminsActions';

const breadcrumbsItems = [
  { id: 1, type: 'home' },
  { id: 2, type: 'link', text: 'Admins', url: '/admins' },
  { id: 3, type: 'span', text: 'Add Admin' },
];

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  passwordRepeat: '',
  status: '',
  role: '',
};

const CreateAdminPage = ({ createAdmin }) => {
  const handleSubmit = (values, { setSubmitting }) => {
    createAdmin(values)
      .then(() => {
        setSubmitting(false);
        searchAdmin();
      })
      .catch(() => setSubmitting(false));
  };

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Breadcrumbs items={breadcrumbsItems} />
        </Col>
        <Col md="12">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validateOnBlur={true}
          >
            {({ values }) => <AdminForm title="Create a new admin" values={values} />}
          </Formik>
        </Col>
      </Row>
    </div>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ createAdmin, searchAdmin }, dispatch);

export default connect(null, mapDispatchToProps)(CreateAdminPage);
