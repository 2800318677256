export const AuctionStatusToVehicleStatus = {
  PUBLISHED: 'LIVE',
  REJECTED: 'OFFER_DECLINED',
  CANCELLED: 'CANCELLED',
  DRAFT: 'PARKED',
  SOLD: 'SOLD',
  TO_ACCEPT: 'TO_ACCEPT',
  WAITING: 'WAITING',
  ENDED: 'ENDED',
};
