import { apiClient, handleError } from '../api/ApiClient';

/**
 *
 * @param name
 * @param id
 * @param form
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export const sampleSuccess = ({ name, id }, form) => dispatch =>
  apiClient
    .post('/sample-success', { name, id })
    .then(() => {})
    .catch(err => dispatch(handleError(err, form)));

/**
 *
 * @param name
 * @param id
 * @param form
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export const sampleExceptionValidation = ({ name, id }, form) => dispatch =>
  apiClient
    .post('/sample-exception-validation', { name, id })
    .then(() => {})
    .catch(err => dispatch(handleError(err, form)));

/**
 *
 * @param name
 * @param id
 * @param form
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export const sampleExceptionServer = ({ name, id }, form) => dispatch =>
  apiClient
    .post('/sample-exception-server', { name, id })
    .then(() => {})
    .catch(err => dispatch(handleError(err, form)));

/**
 *
 * @param name
 * @param id
 * @param form
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export const sampleExceptionBusiness = ({ name, id }, form) => dispatch =>
  apiClient
    .post('/sample-exception-business', { name, id })
    .then(() => {})
    .catch(err => dispatch(handleError(err, form)));
