import { DealerStatus } from '../../constants/DealerStatus';
import { UserStatus } from '../../constants/UserStatus';

export const dealersDataMockup = [
  {
    id: 1,
    name: 'JS Car Dealer #1',
    email: 'j@smith.com',
    registeredDate: '09/12/2020',
    status: DealerStatus.ACTIVE,
  },
  {
    id: 2,
    name: 'JS Car Dealer #2',
    email: 'j@smith.com',
    registeredDate: '19/12/2020',
    status: DealerStatus.ACTIVE,
  },
  {
    id: 3,
    name: 'JS Car Dealer #3',
    email: 'j@smith.com',
    registeredDate: '09/12/2020',
    status: DealerStatus.ACTIVE,
  },
  {
    id: 4,
    name: 'JS Car Dealer #4',
    email: 'j@smith.com',
    registeredDate: '09/12/2020',
    status: DealerStatus.ACTIVE,
  },
  {
    id: 5,
    name: 'JS Car Dealer #5',
    email: 'j@smith.com',
    registeredDate: '09/12/2020',
    status: DealerStatus.DELETED,
  },
  {
    id: 6,
    name: 'JS Car Dealer #6',
    email: 'j@smith.com',
    registeredDate: '09/12/2020',
    status: DealerStatus.ACTIVE,
  },
  {
    id: 7,
    name: 'JS Car Dealer #7',
    email: 'j@smith.com',
    registeredDate: '11/11/2011',
    status: DealerStatus.INACTIVE,
  },
  {
    id: 8,
    name: 'JS Car Dealer #8',
    email: 'j@smith.com',
    registeredDate: '09/12/2020',
    status: DealerStatus.PENDING,
  },
  {
    id: 9,
    name: 'JS Car Dealer #9',
    email: 'j@smith.com',
    registeredDate: '09/12/2020',
    status: DealerStatus.PENDING,
  },
  {
    id: 10,
    name: 'JS Car Dealer #10',
    email: 'j@smith.com',
    registeredDate: '09/12/2020',
    status: DealerStatus.ACTIVE,
  },
  {
    id: 11,
    name: 'JS Car Dealer #11',
    email: 'j@smith.com',
    registeredDate: '09/12/2020',
    status: DealerStatus.ACTIVE,
  },
  {
    id: 12,
    name: 'JS Car Dealer #12',
    email: 'j@smith.com',
    registeredDate: '09/12/2020',
    status: DealerStatus.ACTIVE,
  },
];

export const dealerNotesMockup = [
  { id: 1, date: '07/07/2020', author: 'John Doe', note: 'This is a test note.' },
  { id: 2, date: '02/07/2020', author: 'Johnna Done', note: 'Nope.' },
  { id: 3, date: '22/06/2020', author: 'Johnny Do', note: "Ups. It's working" },
  { id: 4, date: '20/06/2020', author: 'Johan Does', note: 'First note :)' },
];

export const dealerChangesMockup = [
  {
    id: 1,
    date: '07/07/2020',
    author: 'John Doe',
    change: 'Change of name filed: value to value',
  },
  {
    id: 2,
    date: '02/07/2020',
    author: 'Johnna Done',
    change: 'Change of name filed: value to value',
  },
  {
    id: 3,
    date: '22/06/2020',
    author: 'Johnny Do',
    change: 'Change of name filed: value to value',
  },
  {
    id: 4,
    date: '20/06/2020',
    author: 'Johan Does',
    change: 'Change of name filed: value to value',
  },
];

export const dealersDataUsersMockup = [
  {
    id: 1,
    firstName: 'John',
    lastName: 'Doe',
    email: 'jd@example.com',
    mailchimpId: '#99999',
    registeredDate: '',
    dealer: 'JS Car Dealer #6',
    status: UserStatus.ACTIVE,
  },
  {
    id: 7,
    firstName: 'John',
    lastName: 'Doe',
    email: 'jd@example.com',
    mailchimpId: '#99999',
    registeredDate: '',
    dealer: 'JS Car Dealer #6',
    status: UserStatus.ACTIVE,
  },
  {
    id: 2,
    firstName: 'John2',
    lastName: 'Doe',
    email: 'jd@example.com',
    mailchimpId: '#99999',
    registeredDate: '12-04-2020',
    dealer: 'JS Car Dealer #6',
    status: UserStatus.ACTIVE,
  },
  {
    id: 3,
    firstName: 'John3',
    lastName: 'Doe',
    email: 'jd@example.com',
    mailchimpId: '#99999',
    registeredDate: '05-05-2020',
    dealer: 'JS Car Dealer #6',
    status: UserStatus.INACTIVE,
  },
  {
    id: 4,
    firstName: 'John',
    lastName: 'Doe',
    email: 'jd@example.com',
    mailchimpId: '#99999',
    registeredDate: '',
    dealer: 'JS Car Dealer #6',
    status: UserStatus.PENDING,
  },
];

export const dealersBlacklistDataMockup = [
  {
    id: 1,
    name: 'JS Car Dealer #1',
    status: DealerStatus.BLOCKED,
  },
  {
    id: 2,
    name: 'JS Car Dealer #2',
    status: DealerStatus.BLOCKED,
  },
  {
    id: 3,
    name: 'JS Car Dealer #3',
    status: DealerStatus.BLOCKED,
  },
  {
    id: 4,
    name: 'JS Car Dealer #4',
    status: DealerStatus.BLOCKED,
  },
  {
    id: 5,
    name: 'JS Car Dealer #5',
    status: DealerStatus.ACTIVE,
  },
];
