import * as Yup from 'yup';

export default () => {
  return Yup.object().shape({
    title: Yup.string(),
    status: Yup.object().shape({
      value: Yup.string(),
    }),
  });
};
