import * as Yup from 'yup';

export default () => {
  return Yup.object().shape({
    organizationId: Yup.object().shape({
      value: Yup.string().required('Field is required'),
    }),
    organizationMemberId: Yup.object().shape({
      value: Yup.string().required('Field is required'),
    }),
    amountInPound: Yup.string()
      .matches(/[^£0]+/, 'Amount must be greater than 1')
      .required('Field is required'),
  });
};
