import { dateTimeToString } from 'common/utils/date/Date.utils';
import React from 'react';
import PropTypes from 'prop-types';

const UserActivitiesTableRow = ({ item }) => {
  return (
    <>
      <td>{dateTimeToString(item.dateTime)}</td>
      <td>{item.description}</td>
      <td>{item.device}</td>
    </>
  );
};

UserActivitiesTableRow.propTypes = {
  item: PropTypes.object.isRequired,
};

export default UserActivitiesTableRow;
