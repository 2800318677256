import { get } from 'lodash';
import { apiClient, handleError } from '../api/ApiClient';
import { prepareSearchData, SORT_ASC } from '../helpers/prepareSearchData';
import {
  FETCH_CHANGES,
  FETCH_CHANGES_FAILURE,
  FETCH_CHANGES_SUCCESS,
  FETCH_CHANGES_COUNT_SUCCESS,
} from './RequestFormChangesReducer';

const ORGANIZATION_CHANGES_API_ENDPOINT = 'organization-changes';

export const fetchChanges = values => dispatch => {
  return apiClient
    .post(ORGANIZATION_CHANGES_API_ENDPOINT, prepareSearchData(values, { serial: SORT_ASC }))
    .then(res => {
      dispatch({ type: FETCH_CHANGES });
      if (res.status === 200) {
        dispatch({
          type: FETCH_CHANGES_SUCCESS,
          payload: {
            items: get(res, 'data.data.content', []),
            totalItems: get(res, 'data.data.totalElements', 0),
          },
        });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
      dispatch({ type: FETCH_CHANGES_FAILURE });
    });
};

export const getPendingChangesCount = () => dispatch => {
  return apiClient
    .get(`${ORGANIZATION_CHANGES_API_ENDPOINT}/pending/count`)
    .then(res => {
      if (res.status === 200) {
        dispatch({
          type: FETCH_CHANGES_COUNT_SUCCESS,
          payload: {
            totalItems: get(res, 'data.data.count', 0),
          },
        });
      }
    })
    .catch(err => {
      dispatch(handleError(err));
    });
};
