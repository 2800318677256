import React from 'react';
import { Col } from 'reactstrap';
import * as PropTypes from 'prop-types';
import CommonTable from '../../common/components/CommonTable';
import DealerBlacklistTableRow from './DealerBlacklistTableRow';

const headers = [
  {
    label: 'Serial',
  },
  {
    label: 'Name',
  },
  {
    label: 'Action',
  },
];

const DealerBlacklistTable = ({ data, offset, changeOffset, searchParams }) => {
  return (
    <Col xs="12">
      <CommonTable
        rowComponent={DealerBlacklistTableRow}
        rowComponentProps={{ searchParams }}
        data={data}
        offset={offset}
        onOffsetChange={changeOffset}
        headers={headers}
        title="Dealers"
      />
    </Col>
  );
};

DealerBlacklistTable.propTypes = {
  data: PropTypes.object.isRequired,
  offset: PropTypes.number.isRequired,
  changeOffset: PropTypes.func.isRequired,
  searchParams: PropTypes.object.isRequired,
};

export default DealerBlacklistTable;
