import React from 'react';
import { Col, Row } from 'reactstrap';
import { cloneDeep } from 'lodash';
import { constantToSelect } from '../../../common/helpers';
import { DamageTypesName } from '../../../constants/DamageTypes';
import bucketIcon from '../../../assets/img/bucket.svg';
import { checkInteriorPerspective } from '../../../helpers/checkInteriorPerspective';
import { FormSelectField } from '../../../common/components/formFields';

const damageTypes = constantToSelect(DamageTypesName);
const AddDamageDragPreview = ({ photo, updatePhoto }) => {
  const isInteriorPerspective = checkInteriorPerspective(photo.perspective);

  const handleDelete = id => {
    const newPhoto = cloneDeep(photo);
    newPhoto.damages = newPhoto.damages.filter(item => item.mediaMetadataId !== id);
    newPhoto.damagesToDelete = [...(newPhoto.damagesToDelete || []), id];
    updatePhoto(newPhoto);
  };

  const updateDamageType = (e, index) => {
    photo.damages[index].damageType = e.value;
    updatePhoto(photo);
  };

  return (
    <Row className="d-flex justify-content-center">
      <Col>
        {photo &&
          photo.damages &&
          photo.damages.map((e, index) => (
            <div key={Math.random()} className="damages__container">
              <div className="damages__preview">
                <div
                  className="damages__image"
                  style={{ backgroundImage: `url(${e.fileUrl})` }}
                >
                  <button
                    type="button"
                    className="damages_delete_icon"
                    onClick={() => handleDelete(e.mediaMetadataId)}
                  >
                    <img src={bucketIcon} height={18} width={15} alt="alt" />
                  </button>
                </div>
              </div>

              <div className="damages__select-area">
                <Col className="d-flex flex-column">
                  <p className="damages__number">{`Damage ${index + 1}/5`}</p>
                  {!isInteriorPerspective && (
                    <FormSelectField
                      name={`damageType${index}`}
                      label="Damage type"
                      options={damageTypes}
                      onChangeHandler={e => updateDamageType(e, index)}
                    />
                  )}
                </Col>
              </div>
            </div>
          ))}
      </Col>
    </Row>
  );
};

export default AddDamageDragPreview;
