import React from 'react';

import TvDashboard from '../tvdashboard/TvDashboard';

class Dashboard extends React.Component {
  render() {
    return <TvDashboard />;
  }
}

export default Dashboard;
