import React from 'react';
import { Button, Card, CardFooter, CardHeader, Col, Container, Form, Row } from 'reactstrap';
import * as PropTypes from 'prop-types';

const CommonAuthLayoutWrapper = ({ title, submitAction, submitButtonTitle, children }) => {
  return (
    <div className="login-page">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" lg="5" md="6">
            <Form action="" className="form" method="">
              <Card className="card-login">
                <CardHeader>
                  <CardHeader>
                    <h3 className="header text-center">{title}</h3>
                  </CardHeader>
                </CardHeader>
                {children}
                <CardFooter>
                  <Button
                    block
                    className="btn-round mb-3"
                    color="warning"
                    onClick={submitAction}
                  >
                    {submitButtonTitle}
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
      <div
        className="full-page-background"
        style={{
          backgroundImage: `url(${require('assets/img/bg/ilya-yakover.jpg')})`,
        }}
      />
    </div>
  );
};

CommonAuthLayoutWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  submitAction: PropTypes.func.isRequired,
  submitButtonTitle: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
};

export default CommonAuthLayoutWrapper;
