import React, { useEffect } from 'react';
import { Card, Col, Container, Row } from 'reactstrap';
import AuthNavbar from '../common/components/Navbars/AuthNavbar';
import Footer from '../common/components/Footer/Footer';

const ConfirmForgotPassword = () => {
  useEffect(() => {
    document.body.classList.toggle('login-page');
    return () => {
      document.body.classList.toggle('login-page');
    };
  }, []);
  return (
    <>
      <AuthNavbar />
      <div className="full-page section-image">
        <div className="login-page">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" lg="5" md="6">
                <Card className="card-login">
                  <h4 className="header text-center">Reset password confirmation</h4>
                  <p className="text-center">
                    A message with further instructions has been sent to your email address.
                  </p>
                </Card>
              </Col>
            </Row>
          </Container>
          <div
            className="full-page-background"
            style={{
              backgroundImage: `url(${require('assets/img/bg/ilya-yakover.jpg')})`,
            }}
          />
        </div>
        <Footer fluid />
      </div>
    </>
  );
};

export default ConfirmForgotPassword;
