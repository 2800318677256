import * as Yup from 'yup';
import { FormRegExp } from '../../constants/FormRegExp';
import { AuctionStatusType } from '../../constants/AuctionStatusType';
import { isBeforeOrPresentDate } from '../../common/utils/date/Date.utils';

const allowedTransmissionTypes = [
  'Manual',
  'Automatic',
  'Semi-Automatic',
  'CVT',
  'Dual-clutch',
];

const allowedFuelTypes = ['Petrol', 'Diesel', 'Electric', 'Hybrid', 'LPG', 'CNG', 'Hydrogen'];

export default () => {
  return Yup.object().shape({
    numberPlate: Yup.string()
      .required('Field is required')
      .min(1, 'Registration plate must be between 1 and 10 characters')
      .max(10, 'Registration plate must be between 1 and 10 characters'),
    year: Yup.string()
      .required('Field is required')
      .matches(FormRegExp.YEAR, 'Type correct date format')
      .test(
        'valid-year-range',
        'The year cannot be less than 100 years back and not higher than the current one',
        value => {
          const currentYear = new Date().getFullYear();
          if (!value) return false;
          const year = parseInt(value, 10);
          return year >= currentYear - 100 && year <= currentYear;
        },
      ),
    mileage: Yup.number()
      .required('Field is required')
      .min(0, 'Mileage must be greater or equal 0')
      .max(999999999, 'Mileage must be smaller or equal 999 999 999'),
    derivative: Yup.string()
      .required('Field is required')
      .min(1, 'Derivative must be between 1 and 150 characters')
      .max(150, 'Derivative must be between 1 and 150 characters'),
    carType: Yup.string()
      .required('Field is required')
      .min(1, 'Body type must be between 1 and 20 characters')
      .max(20, 'Body type must be between 1 and 20 characters'),
    transmission: Yup.string()
      .required('Field is required')
      .test(
        'valid-value',
        'Possible values are: Manual, Automatic, Semi-Automatic, CVT, Dual-Clutch',
        value => {
          return allowedTransmissionTypes.includes(value);
        },
      ),
    engineSize: Yup.number()
      .when('fuel', {
        is: value => value !== 'Electric',
        then: Yup.number()
          .required('Field is required')
          .min(1, 'Engine size must be greater than 0')
          .max(999999999, 'Engine size must be smaller or equal 999 999 999'),
      })
      .nullable(),
    fuel: Yup.string()
      .required('Field is required')
      .test(
        'valid-value',
        'Possible values are: Petrol, Diesel, Electric, Hybrid, LPG, CNG, Hydrogen',
        value => {
          return allowedFuelTypes.includes(value);
        },
      ),
    colour: Yup.string()
      .required('Field is required')
      .min(1, 'Colour must be between 1 and 40 characters')
      .max(40, 'Colour must be between 1 and 40 characters'),
    doors: Yup.string()
      .required('Field is required')
      .matches(FormRegExp.INTEGER, 'Type integer number')
      .test('valid-value', 'Value must be between 1 and 5 included', value => {
        if (!value) return true;
        const number = Number(value);
        return number >= 1 && number <= 5;
      }),
    previousOwners: Yup.string()
      .required('Field is required')
      .matches(FormRegExp.INTEGER, 'Type integer number')
      .min(0, 'Previous owners must be greater or equal 0')
      .max(99, 'Previous owners must be smaller or equal 99'),
    registrationDate: Yup.string().required('Field is required'),
    launchDate: Yup.string().required('Field is required'),
    vin: Yup.string()
      .required('Field is required')
      .max(17, 'Max length equals 17 characters'),
    services: Yup.array().of(
      Yup.object().shape({
        date: Yup.string().required('Field is required'),
        mileage: Yup.number().required('Field is required'),
        serviceName: Yup.string().required('Field is required'),
      }),
    ),
    type: Yup.object({
      label: Yup.string(),
      value: Yup.string(),
    }),
    tryingToDealDeliveryDateType: Yup.string().required('Field is required'),
    autoAcceptPriceInPence: Yup.string().when('type', {
      is: value => value.value === AuctionStatusType.PHYSICAL,
      then: Yup.string()
        .required('Field is required')
        .max(10, 'Reserve Price must be between 1 and 10 characters')
        .test('minValue', 'Reserve Price must be greater or equal to 100', value => {
          return value ? Number(value.replaceAll('£', '').replaceAll(',', '')) >= 100 : false;
        }),
    }),
    minimumPriceInPence: Yup.string().when('type', {
      is: value => value.value === AuctionStatusType.MINIMUM_PRICE,
      then: Yup.string()
        .max(10, 'Minimum Price must be between 1 and 10 characters')
        .required('Field is required'),
    }),
    endsOn: Yup.string().test('is-future-date', 'The date must be in the future', value => {
      if (!value) {
        return true;
      }
      return isBeforeOrPresentDate(value);
    }),
    description: Yup.string().required('Field is required'),
  });
};
