import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Breadcrumbs from '../common/components/Breadcrumbs';
import ReportsSearch from './search/ReportsSearch';
import { setQueryString, useQueryString } from '../common/helpers/queryString';
import { searchReports, clearReports, generateReport, sendReport } from './ReportsActions';
import ReportsTable from './ReportsTable';
import ReportsActionButtons from './ReportsActionButtons';

const breadcrumbsItems = [
  { id: 1, type: 'home' },
  { id: 2, type: 'span', text: 'Reports' },
  { id: 3, type: 'span', text: 'Reports of Dealers' },
];

const ReportsPage = ({ history, location }) => {
  const query = useQueryString();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useState({
    offset: query.get('page') ? +query.get('page') - 1 : 0,
  });
  const [dealerName, setDealerName] = useState('');
  const [isTableVisible, setIsTableVisible] = useState(false);

  const items = useSelector(state => state.reports.get('reportsItems'));
  const totalItems = useSelector(state => state.reports.get('totalReportsItems'));

  useEffect(() => {
    if (
      searchParams?.organizationId &&
      searchParams?.createdOnTo &&
      searchParams?.createdOnFrom
    ) {
      dispatch(searchReports(searchParams));
    }
  }, [dispatch, searchParams]);

  const handleSearch = (values, { setSubmitting }) => {
    setIsTableVisible(true);
    setSearchParams(prevState => ({
      ...prevState,
      ...values,
      organizationId: values?.dealer?.value,
      offset: 0,
    }));
    setSubmitting(false);
  };

  const handleDealerChange = values => {
    setDealerName(values?.label);
  };

  const handleDownload = () => {
    dispatch(generateReport(searchParams));
  };

  const handleSend = () => {
    dispatch(sendReport(searchParams));
  };

  const handleClear = () => {
    dispatch(clearReports());
    setIsTableVisible(false);
  };

  const changeOffset = value => {
    setSearchParams(prevState => ({ ...prevState, offset: value }));
    history.push(setQueryString(location, 'page', value + 1));
  };

  return (
    <div className="content">
      <Row>
        <Col xs="9">
          <Breadcrumbs items={breadcrumbsItems} />
        </Col>
      </Row>
      <Row>
        <ReportsSearch
          handleSearch={handleSearch}
          handleDealerChange={handleDealerChange}
          handleClear={handleClear}
        />
      </Row>
      {isTableVisible && (
        <>
          <Row>
            <ReportsTable
              data={{ items, total: totalItems }}
              offset={searchParams.offset}
              changeOffset={changeOffset}
            />
          </Row>
          <Row>
            <ReportsActionButtons
              dealerName={dealerName}
              handleDownload={handleDownload}
              handleSend={handleSend}
            />
          </Row>
        </>
      )}
    </div>
  );
};

export default withRouter(ReportsPage);
