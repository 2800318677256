import React from 'react';
import { Button, Card, CardBody, Col, Label, Row } from 'reactstrap';
import { Form } from 'formik';
import * as PropTypes from 'prop-types';
import { FormCheckboxField, FormInputField } from '../../common/components/formFields';
import { SiteStatusName } from '../../constants/SiteCreator';
import SiteCreatorEditor from './SiteCreatorEditor';

const SiteCreatorForm = ({ title, isCustom = true }) => {
  return (
    <Form noValidate>
      <Card>
        <CardBody>
          <h6>{title}</h6>
          <Row>
            <Col xl="4">
              <FormInputField name="title" label="Name" disabled={!isCustom} />
            </Col>
          </Row>
          <Row>
            <Col xl="4">
              <FormInputField
                name="link"
                label="Slug (path to page, e.g. privacy-policy)"
                disabled={!isCustom}
              />
            </Col>
          </Row>
          <Row>
            <Col xl="6">
              <Label>Status</Label>
              <FormCheckboxField
                label={SiteStatusName.ACTIVE}
                name="isActive"
                disabled={!isCustom}
              />
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Label>Page content</Label>
              <SiteCreatorEditor name="content" />
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Button color="primary" className="mt-4 float-right" type="submit">
                Save
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Form>
  );
};

SiteCreatorForm.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SiteCreatorForm;
