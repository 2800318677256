import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { reverse } from 'named-urls';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CommonTable from '../../common/components/CommonTable';
import BidsTableRow from './BidsTableRow';
import RouterPaths from '../../constants/RouterPaths';
import CommonLoader from '../../common/components/CommonLoader';
import { SET_BIDS_SEARCH_PARAMS, SET_SELECTED_BID } from '../BidsReducer';

const headers = [
  {
    label: 'ID',
  },
  {
    label: 'Number plate',
  },
  {
    label: 'Title',
  },
  {
    label: 'Launch date',
  },
  {
    label: 'Bid date',
  },
  {
    label: 'Reserve price',
  },
  {
    label: 'Min. price',
  },
  {
    label: 'Bid price',
  },
  {
    label: 'Seller',
  },
  {
    label: 'Buyer',
  },
  {
    label: 'Bidder',
  },
  {
    label: 'Status',
  },
  {
    label: 'Actions',
    className: 'text-right',
  },
];

const BidsTable = ({ data, offset, changeOffset, history, searchParams }) => {
  const dispatch = useDispatch();
  const loaderStatus = useSelector(state => state.bids.get('loaderStatus'));
  return (
    <Row>
      <Col xs="12">
        <CommonLoader status={loaderStatus}>
          <CommonTable
            rowIdPrefix="bid-row"
            rowComponent={BidsTableRow}
            data={data}
            offset={offset}
            onOffsetChange={changeOffset}
            headers={headers}
            title="Bids"
            handleRowClick={item => {
              dispatch({
                type: SET_SELECTED_BID,
                payload: item.id,
              });
              dispatch({
                type: SET_BIDS_SEARCH_PARAMS,
                payload: searchParams,
              });
              history.push(reverse(RouterPaths.VEHICLE_BIDS, { id: item?.auctionId }));
            }}
          />
        </CommonLoader>
      </Col>
    </Row>
  );
};

BidsTable.propTypes = {
  searchParams: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  offset: PropTypes.number.isRequired,
  changeOffset: PropTypes.func.isRequired,
};

export default withRouter(BidsTable);
