const MUST_FILL_IN = 'MUST FILL IN';

export const clearDealerEmptyValues = data => {
  Object.keys(data).forEach(key => {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      if (data[key] === MUST_FILL_IN) {
        data[key] = null;
      }
    }
  });

  return data;
};

export const clearDealersEmptyValues = data => {
  data.forEach(dealer => {
    clearDealerEmptyValues(dealer);
  });
  return data;
};
