export default {
  // auth
  MAIN: '/',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:token',
  CONFIRM_FORGOT_PASSWORD: '/confirm-forgot-password',

  // homepage
  HOMEPAGE: '/homepage',

  // request for changes
  REQUEST_FOR_CHANGES: '/request-for-changes',

  // users
  USERS: '/users',
  USERS_DEALER: '/users/dealer/:dealerId?',
  EDIT_USER: '/user/:id',

  // groups
  GROUPS: '/groups',
  CREATE_GROUP: '/group',
  EDIT_GROUP: '/group/:id',

  // dealers
  DEALERS: '/dealers',
  DEALER_INFORMATION: '/dealers/:id',
  DEALER_USERS: '/dealers/:id/users',
  DEALER_VEHICLES: '/dealers/:id/vehicles',
  DEALER_BIDS: '/dealers/:id/bids',
  DEALER_TRANSACTIONS: '/dealers/:id/transactions',
  DEALER_INVOICES: '/dealers/:id/invoices',
  DEALER_BLACKLIST: '/dealers/:id/blacklist',

  // vehicles
  VEHICLES: '/vehicles',
  VEHICLES_DEALER: '/vehicles/dealer/:dealerId?',
  EDIT_VEHICLE: '/vehicle/:id',
  VEHICLE_BIDS: '/vehicle/:id/bids',
  VEHICLES_NOTES: '/vehicle/:id/notes',
  VEHICLE_SELL: '/vehicle/:id/sell',

  // bids
  BIDS: '/bids',
  BIDS_DEALER: '/bids/dealer/:dealerId',

  // admins
  ADMINS: '/admins',
  CREATE_ADMIN: '/admin',
  EDIT_ADMIN: '/admin/:id',

  // reports
  REPORTS: '/reports-dealer',
  REPORTS_USER: '/reports-user',

  // site creator
  SITE_CREATOR: '/site-creators',
  CREATE_SITE: '/site-creator',
  EDIT_SITE: '/site-creator/:id',

  // settings
  SETTINGS: '/settings',

  // test
  REST_TEST: '/rest-test',

  // TransactionsSearch
  TRANSACTIONS: '/transactions',
  TRANSACTIONS_DEALER: '/transactions/dealer/:dealerId',

  // Sage auth
  SAGE_AUTH: '/sage/auth/callback',
};
