import React, { useState, useEffect } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumbs from '../common/components/Breadcrumbs';
import DealerUserSearch from './search/UsersSearch';
import UsersTable from './table/UsersTable';
import FilterButtons from '../common/components/FilterButtons';
import { DealerUserStatusName } from '../constants/UserStatus.js';
import { useQueryString, setQueryString } from '../common/helpers/queryString';
import { searchUsers } from './UsersActions';
import { PrepareUrl } from '../common/helpers/PrepareUrl';
import RouterPaths from '../constants/RouterPaths';
import { UserRole } from '../constants/UserRole';

let breadcrumbsItems = [];

const UsersPage = ({ history, location }) => {
  const { dealerId } = useParams();
  const query = useQueryString();
  const dispatch = useDispatch();

  if (dealerId) {
    breadcrumbsItems = [
      { id: 1, type: 'home' },
      { id: 2, type: 'link', text: 'Dealers', url: RouterPaths.DEALERS },
      {
        id: 3,
        type: 'link',
        text: 'Edit Dealer',
        url: PrepareUrl(RouterPaths.DEALER_INFORMATION, { id: dealerId }),
      },
      { id: 4, type: 'span', text: 'Users' },
    ];
  } else {
    breadcrumbsItems = [
      { id: 1, type: 'home' },
      { id: 2, type: 'span', text: 'Users' },
    ];
  }

  const items = useSelector(state => state.users.get('items'));
  const totalItems = useSelector(state => state.users.get('totalItems'));
  const [searchParams, setSearchParams] = useState({
    offset: query.get('page') ? +query.get('page') - 1 : 0,
    organizationId: dealerId || null,
  });

  useEffect(() => {
    setSearchParams(prevState => ({
      ...prevState,
      organizationId: dealerId || null,
    }));
  }, [dispatch, dealerId]);

  useEffect(() => {
    dispatch(searchUsers(searchParams));
  }, [dispatch, searchParams]);

  const handleChangeStatus = status => {
    setSearchParams(prevState => ({
      ...prevState,
      status,
      offset: 0,
    }));
  };

  const handleSearch = (values, { setSubmitting }) => {
    const roles = {
      isBuyer: null,
      isSeller: null,
    };
    if (values?.role && values.role.includes(UserRole.BUYER)) {
      roles.isBuyer = true;
    }
    if (values?.role && values.role.includes(UserRole.SELLER)) {
      roles.isSeller = true;
    }
    setSearchParams(prevState => ({ ...prevState, ...values, ...roles, offset: 0 }));
    setSubmitting(false);
  };

  const changeOffset = value => {
    setSearchParams(prevState => ({
      ...prevState,
      offset: value,
    }));
    history.push(setQueryString(location, 'page', value + 1));
  };

  return (
    <div className="content">
      <Breadcrumbs items={breadcrumbsItems} />
      <DealerUserSearch handleSearch={handleSearch} />
      <FilterButtons data={DealerUserStatusName} handlerChange={handleChangeStatus} />
      <UsersTable
        data={{ items, total: totalItems }}
        offset={searchParams.offset}
        changeOffset={changeOffset}
      />
    </div>
  );
};

export default withRouter(UsersPage);
