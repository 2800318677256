import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import Breadcrumbs from '../common/components/Breadcrumbs';
import VehiclesSearch from './search/VehiclesSearch';
import VehiclesTable from './table/VehiclesTable';
import { setQueryString, useQueryString } from '../common/helpers/queryString';
import { searchAuctions } from './VehicleActions';
import FilterButtons from '../common/components/FilterButtons';
import { AuctionStatusNameNoDraft } from '../constants/AuctionStatus';
import RouterPaths from '../constants/RouterPaths';
import { PrepareUrl } from '../common/helpers/PrepareUrl';
import { LoaderStatus } from '../common/components/CommonLoader';
import { scrollToElement } from '../common/helpers/scrollToElement';

let breadcrumbsItems = [];

const VehiclesPage = ({ history, location }) => {
  const { dealerId } = useParams();
  const query = useQueryString();
  const dispatch = useDispatch();

  if (dealerId) {
    breadcrumbsItems = [
      { id: 1, type: 'home' },
      { id: 2, type: 'link', text: 'Dealers', url: RouterPaths.DEALERS },
      {
        id: 3,
        type: 'link',
        text: 'Edit Dealer',
        url: PrepareUrl(RouterPaths.DEALER_INFORMATION, { id: dealerId }),
      },
      { id: 4, type: 'span', text: 'Vehicles' },
    ];
  } else {
    breadcrumbsItems = [
      { id: 1, type: 'home' },
      { id: 2, type: 'span', text: 'Vehicles' },
    ];
  }

  const previousSearchParams = useSelector(state => state.vehicles.get('searchParams'));
  const previouslySelectedVehicleId = useSelector(state =>
    state.vehicles.get('selectedVehicleId'),
  );
  const loaderStatus = useSelector(state => state.vehicles.get('loaderStatus'));
  const items = useSelector(state => state.vehicles.get('items'));
  const totalItems = useSelector(state => state.vehicles.get('totalItems'));

  const initialSearchParams = {
    offset: query.get('page') ? +query.get('page') - 1 : 0,
    status: Object.keys(AuctionStatusNameNoDraft()),
    sellerOrganizationId: dealerId || null,
    searchCriteria: {},
  };

  const [searchParams, setSearchParams] = useState(
    previousSearchParams?.status ? previousSearchParams : initialSearchParams,
  );

  useEffect(() => {
    if (previousSearchParams && items && loaderStatus === LoaderStatus.SUCCESS) {
      scrollToElement(`vehicle-row-${previouslySelectedVehicleId}`);
    }
  }, [items, previousSearchParams]);

  useEffect(() => {
    if (dealerId) {
      setSearchParams(prevState => ({
        ...prevState,
        sellerOrganizationId: dealerId || null,
      }));
    }
  }, [dealerId]);

  useEffect(() => {
    const flattenedSearchParams = {
      ...searchParams,
      ...searchParams.searchCriteria,
    };
    delete flattenedSearchParams.searchCriteria;
    dispatch(searchAuctions(flattenedSearchParams));
  }, [dispatch, searchParams]);

  const handleSearch = (values, setSubmitting) => {
    setSearchParams(prevState => ({
      ...prevState,
      searchCriteria: values,
      offset: 0,
    }));
    setSubmitting(false);
  };

  const handleChangeStatus = status => {
    if (status === '') {
      status = Object.keys(AuctionStatusNameNoDraft());
    } else {
      status = [status];
    }
    setSearchParams(prevState => ({ ...prevState, status, offset: 0 }));
  };

  const changeOffset = value => {
    setSearchParams(prevState => ({ ...prevState, offset: value }));
    history.push(setQueryString(location, 'page', value + 1));
  };

  return (
    <div className="content">
      <Breadcrumbs items={breadcrumbsItems} />
      <VehiclesSearch handleSearch={handleSearch} />
      <FilterButtons
        handlerChange={handleChangeStatus}
        data={AuctionStatusNameNoDraft()}
        isVehicleFilters={true}
      />
      <VehiclesTable
        searchParams={searchParams}
        setOffset={changeOffset}
        offset={searchParams.offset}
        data={{ items, total: totalItems }}
      />
    </div>
  );
};

export default withRouter(VehiclesPage);
