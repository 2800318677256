import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Formik } from 'formik';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumbs from '../common/components/Breadcrumbs';
import { clearFeesValidationErrors, editSettings, fetchSettings } from './SettingsActions';
import SettingsForm from './form/SettingsForm';
import validationSchema from './form/SettingsForm.schema';
import { AgreeOptions } from '../constants/AgreeOptions';
import SageConnectPanel from './SageConnectPanel';
import { pencesToPounds } from '../common/helpers/pencesToPounds';

const breadcrumbsItems = [
  { id: 1, type: 'home' },
  { id: 2, type: 'span', text: 'Settings' },
];

const SettingsPage = () => {
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({
    vehicleMakeVisible: {
      label: '',
      value: '',
    },
    monthlySubscriptionInPence: '',
    launchInPence: '',
    saleForLessThan1000InPence: '',
    saleBetween1001And2500InPence: '',
    saleBetween2501And5000InPence: '',
    saleBetween5001And10000InPence: '',
    saleBetween10001And20000InPence: '',
    saleForMoreThan20000InPence: '',
    purchaseForLessThan1000InPence: '',
    purchaseBetween1001And2500InPence: '',
    purchaseBetween2501And5000InPence: '',
    purchaseBetween5001And10000InPence: '',
    purchaseBetween10001And20000InPence: '',
    purchaseForMoreThan20000InPence: '',
  });

  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);

  const data = useSelector(state => state.settings.get('mainSettings'));

  useEffect(() => {
    if (data?.size !== 0) {
      setInitialValues({
        ...data,
        launchInPence: pencesToPounds(get(data, 'launchInPence', '')),
        monthlySubscriptionInPence: pencesToPounds(
          get(data, 'monthlySubscriptionInPence', ''),
        ),
        purchaseForLessThan1000InPence: pencesToPounds(
          get(data, 'purchaseForLessThan1000InPence', ''),
        ),
        purchaseBetween1001And2500InPence: pencesToPounds(
          get(data, 'purchaseBetween1001And2500InPence', ''),
        ),
        purchaseBetween2501And5000InPence: pencesToPounds(
          get(data, 'purchaseBetween2501And5000InPence', ''),
        ),
        purchaseBetween5001And10000InPence: pencesToPounds(
          get(data, 'purchaseBetween5001And10000InPence', ''),
        ),
        purchaseBetween10001And20000InPence: pencesToPounds(
          get(data, 'purchaseBetween10001And20000InPence', ''),
        ),
        purchaseForMoreThan20000InPence: pencesToPounds(
          get(data, 'purchaseForMoreThan20000InPence', ''),
        ),
        saleForLessThan1000InPence: pencesToPounds(
          get(data, 'saleForLessThan1000InPence', ''),
        ),
        saleBetween1001And2500InPence: pencesToPounds(
          get(data, 'saleBetween1001And2500InPence', ''),
        ),
        saleBetween2501And5000InPence: pencesToPounds(
          get(data, 'saleBetween2501And5000InPence', ''),
        ),
        saleBetween5001And10000InPence: pencesToPounds(
          get(data, 'saleBetween5001And10000InPence', ''),
        ),
        saleBetween10001And20000InPence: pencesToPounds(
          get(data, 'saleBetween10001And20000InPence', ''),
        ),
        saleForMoreThan20000InPence: pencesToPounds(
          get(data, 'saleForMoreThan20000InPence', ''),
        ),
        vehicleMakeVisible: {
          label: get(data, 'vehicleMakeVisible', '') ? AgreeOptions.YES : AgreeOptions.NO,
          value: get(data, 'vehicleMakeVisible', '') ? AgreeOptions.YES : AgreeOptions.NO,
        },
      });
    }
  }, [data]);

  const handleSubmit = (values, { setSubmitting }) => {
    dispatch(editSettings(values))
      .then(() => {
        setSubmitting(false);
      })
      .catch(() => {});
  };

  useEffect(() => () => dispatch(clearFeesValidationErrors()), [dispatch]);

  return (
    <div className="content">
      <Breadcrumbs items={breadcrumbsItems} />
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                validateOnBlur={true}
                enableReinitialize
              >
                {() => <SettingsForm />}
              </Formik>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <SageConnectPanel />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default SettingsPage;
