import React, { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumbs from '../common/components/Breadcrumbs';
import BidsSearch from './search/BidsSearch';
import BidsStatusFilter from './BidsStatusFilter';
import BidsTable from './table/BidsTable';
import { setQueryString, useQueryString } from '../common/helpers/queryString';
import { searchBids } from './BidsActions';
import CommonLoader, { LoaderStatus } from '../common/components/CommonLoader';
import { scrollToElement } from '../common/helpers/scrollToElement';

const breadcrumbsItems = [
  { id: 1, type: 'home' },
  { id: 2, type: 'span', text: 'Bids' },
];

const BidsPage = ({ history, location }) => {
  const dispatch = useDispatch();
  const query = useQueryString();
  const { dealerId } = useParams();

  const previousSearchParams = useSelector(state => state.bids.get('bidsSearchParams'));
  const loaderStatus = useSelector(state => state.bids.get('loaderStatus'));
  const previouslySelectedBidId = useSelector(state => state.bids.get('selectedBidId'));
  const items = useSelector(state => state.bids.get('items'));
  const totalItems = useSelector(state => state.bids.get('totalItems'));

  const initialSearchParams = {
    offset: query.get('page') ? +query.get('page') - 1 : 0,
    bidderOrganizationId: dealerId || null,
  };

  const [searchParams, setSearchParams] = useState(
    previousSearchParams || initialSearchParams,
  );

  useEffect(() => {
    if (previousSearchParams && items && loaderStatus === LoaderStatus.SUCCESS) {
      scrollToElement(`bid-row-${previouslySelectedBidId}`);
    }
  }, [items, previousSearchParams]);

  useEffect(() => {
    dispatch(searchBids(searchParams));
  }, [dispatch, searchParams]);

  const changeOffset = value => {
    setSearchParams(prevState => ({ ...prevState, offset: value }));
    history.push(setQueryString(location, 'page', value + 1));
  };

  const handleSearch = (values, setSubmitting) => {
    setSearchParams(prevState => ({ ...prevState, ...values, offset: 0 }));
    setSubmitting(false);
  };

  const handleChangeStatus = status => {
    setSearchParams(prevState => ({
      ...prevState,
      status,
      offset: 0,
    }));
  };

  return (
    <div className="content">
      <Breadcrumbs items={breadcrumbsItems} />
      {items ? (
        <>
          <BidsSearch handleSearch={handleSearch} />
          <BidsStatusFilter handleChangeStatus={handleChangeStatus} />
          <BidsTable
            searchParams={searchParams}
            changeOffset={changeOffset}
            offset={searchParams.offset}
            data={{ items, total: totalItems }}
          />
        </>
      ) : (
        <CommonLoader />
      )}
    </div>
  );
};

export default withRouter(BidsPage);
