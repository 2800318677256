import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DashboardTable from './dashboardtable/DashboardTable';
import { fetchTvDashboardData } from './TvDashboardActions';

const TvDashboard = () => {
  const items = useSelector(state => state.tvDashboard.get('tvDashboardData'));

  const dispatch = useDispatch();
  useEffect(() => {
    const interval = setInterval(() => dispatch(fetchTvDashboardData()), 5000);
    dispatch(fetchTvDashboardData());
    return () => {
      clearInterval(interval);
    };
  }, [dispatch]);

  return (
    <div className="content">
      <DashboardTable data={{ items, total: items.length }} />
    </div>
  );
};

export default TvDashboard;
